import React from 'react';
import { useContext } from 'react';
import NewsCard from '../NewsCards/NewsCard';
import PromotionalNewsList from '../NewsCards/PromotionalNewsList';
import { EnvironmentProvider } from '../../store/firebase-context';
import { PUBLISHER_TYPE } from '../../Constants/Constants';

const BookmarksData = ({ news, newsCategories, onRemove, onCardAction }) => {
  const { environment } = useContext(EnvironmentProvider);
  return (
    <>
      {news?.map((news) =>
        news.is_promotional ? (
          <div key={news.newsId}>
            <PromotionalNewsList
              key={news.newsId}
              id={news.newsId}
              image={news.media?.map((image) => image?.url)}
              likeCount={news.news_like_count}
              dislikeCount={news.news_dislike_count}
              newsId={news.newsId}
              isLiked={news.is_liked}
              isDisliked={news.is_disliked}
              isBookmark={news.is_bookmark}
              onRemoveBookmark={onRemove}
              user_type={news.user_type}
            />
          </div>
        ) : (
          <div key={news.newsId}>
            <NewsCard
              key={news.newsId}
              hasVideo={news.video}
              id={news.newsId}
              anonymous={news.anonymous}
              description={news.heading}
              keywords={news.keywords}
              url={news.url}
              maincategoryIcon={news.maincategory_icon}
              mainCategory={news.maincategory_name}
              mainCategoryID={news.maincategory}
              mainCategoryUrl={news.maincategory_url}
              headline={news.heading}
              media={news?.media}
              city={news.location}
              locationID={news.locationId}
              newsDate={news.date_news}
              likeCount={news.news_like_count}
              dislikeCount={news.news_dislike_count}
              authorName={news.authorName}
              authorID={news.authorId}
              trackingID={news.trackingId}
              newsID={news.newsId}
              authorImage={
                news.user_type === PUBLISHER_TYPE && news.authorImage
                  ? news.authorImage
                  : `${environment.userprofile_prefix_url}${news.trackingId}`
              }
              isLiked={news.is_liked}
              isDisliked={news.is_disliked}
              isBookmark={news.is_bookmark}
              isTrending={news.is_trending}
              newsType={news.news_type}
              commentsCount={news.news_comments_count}
              newsCategories={newsCategories}
              onRemoveBookmark={onRemove}
              onCardAction={onCardAction}
              user_type={news.user_type}
            />
          </div>
        ),
      )}
    </>
  );
};

export default BookmarksData;
