import { Children, cloneElement, useEffect, useContext } from 'react';
import { MaxWidth, viewPort } from '../../responsive/viewport.util';
import InArticleAd from '../Ads/InArticleAd';
import { Box } from '@mui/material';
import { EnvironmentProvider } from '../../store/firebase-context';
import './DetailedNewsCard.css';

/**
 * News Description
 *  - displays news story along with ads
 */
function NewsDescription({ children }) {
  const { environment } = useContext(EnvironmentProvider);
  const isMobile = MaxWidth(viewPort.tablet);
  let feedContents =
    Children.toArray(children).length > 1
      ? null
      : Children.toArray(children)[0]?.props?.children;

  if (feedContents) {
    feedContents = Children.toArray(feedContents).filter((child) => {
      return !!child?.props?.children;
    });
  }

  // Side Effect to handle Instagram Embed script initialization
  useEffect(() => {
    if (window.instgrm) {
      window?.instgrm?.Embeds.process();
    }
  }, [window.instgrm]);

  // Side Effect to handle Twitter Embed script initialization
  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load(document.getElementsByClassName('agency_feed'));
    }
  }, [window.twttr]);

  return (
    <>
      {feedContents && feedContents.length > 0 ? (
        <div className='agency_feed'>
          {Children.map(feedContents, (child, index) => {
            const currentIndex = index + 1;
            const showAdd =
              (currentIndex > 0 &&
                currentIndex % environment.news_details_adslot === 0) ||
              feedContents?.length === 1;

            return (
              <>
                {cloneElement(child, {
                  index,
                })}
                {showAdd && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <InArticleAd isMobile={isMobile} />
                  </Box>
                )}
              </>
            );
          })}
          {feedContents.length < environment.news_details_adslot && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <InArticleAd isMobile={isMobile} />
            </Box>
          )}
        </div>
      ) : (
        <div className='agency_feed'>
          {Children.toArray(children).map((child, index) => {
            const currentIndex = index + 1;
            const showAdd =
              currentIndex > 0 &&
              currentIndex % environment.news_details_adslot === 0;
            return (
              <>
                {child}
                {showAdd && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <InArticleAd isMobile={isMobile} />
                  </Box>
                )}
              </>
            );
          })}
          {Children.toArray(children).length <
            environment.news_details_adslot && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <InArticleAd isMobile={isMobile} />
            </Box>
          )}
        </div>
      )}
    </>
  );
}

export default NewsDescription;
