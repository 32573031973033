const defaultConfigurations = {
  development: require('./remote_config_defaults-dev.json'),
  qa: require('./remote_config_defaults-qa.json'),
  'pre-prod': require('./remote_config_defaults-pre-prod.json'),
  production: require('./remote_config_defaults-prod.json'),
};

const awsmobile = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: `${window.location.protocol}//${window.location.host}`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}`,
      responseType: 'code',
    },
  },
  API: {
    aws_appsync_graphqlEndpoint:
      defaultConfigurations[process.env.REACT_APP_ENV]?.graphql_url,
    aws_appsync_region: process.env.REACT_APP_REGION,
    aws_appsync_authenticationType: process.env.REACT_APP_AUTH_TYPE,
  },
  Storage: {
    bucket: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
  },
};

export default awsmobile;
