import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import NewsData from '../NewsCards/NewsData';
import { CardSkeleton } from '../Skeleton/PublisherSkeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TEN } from '../../Constants/Constants';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import classes from './HeadlinesSection.module.css';
import { NavLink } from 'react-router-dom';
import { UserDetailsProvider } from '../../store/user-details-context';
import newsService from '../../services/news.service';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import { useTranslation } from 'react-i18next';
import { startTransition } from 'react';
import {
  DARK_COLOR,
  LIGHT_COLOR,
  MINMINI_V3,
} from '../../themes/color.constant';
import ButtonLoader from '../buttonLoader/ButtonLoader';
import BackIcon from '../../assets/icons/BackIcon';

/**
 * Headlines Section Component
 */
export default function HeadlinesSection({
  category,
  childCategory,
  isFromChildCategory,
  newsCategories,
  filter,
  navBarFilters,
  districts,
}) {
  const [news, setNews] = useState([]);
  const [prevNews, setPrevNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [newsCount, setNewsCount] = useState(TEN);
  const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const { state: userState } = useContext(UserDetailsProvider);
  const newsSessionID = userState?.sessionId;
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const isMobile = MaxWidth(viewPort.tablet);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [districtName, setDistrictName] = useState();
  const navigate = useNavigate();
  const { districtDetail } = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  const districtId =
    districtDetail &&
    districtDetail.split('-')[districtDetail?.split('-').length - 1];

  const urlPathName = window.location.pathname;
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [urlPathName]);

  useEffect(() => {
    if (districtDetail) {
      getDistrictNames();
    }
  }, [districtDetail, isTamil]);

  const getDistrictNames = () => {
    const dName = districtDetail && districtDetail.split('-')[0];
    const found = districts?.find(
      (district) =>
        district?.url.match(dName) ||
        district?.name.match(dName) ||
        district?.name_tamil.match(dName),
    );
    setDistrictName(found ? dName : null);
  };

  const changeInHistory = useCallback(() => {
    setIsEndOfPage(false);
    setIsLoadMoreClicked(false);
    setStartIndex(0);
    setNewsCount(TEN);
  }, [location.pathname]);

  /**
   * Checking for path of Top News and trending and accordingly setting up the news count
   */
  useEffect(() => {
    changeInHistory();
  }, [location]);

  const getFilterData = async () => {
    let params;
    params = {
      startIndex: startIndex,
      count: newsCount,
    };

    if (startIndex > 0) {
      params['time_before'] = news[news?.length - 1]?.date_news;
      if (!filter?.refresh || !userState?.isAuthenticated) {
        params['trending_score'] = news[news?.length - 1]?.trending_score;
      }
    }

    if (filter?.refresh && userState?.isAuthenticated) {
      params['sessionId'] = newsSessionID;
    }

    try {
      if (params) {
        setIsLoading(true);
        const url =
          filter?.refresh && !userState?.isAuthenticated
            ? filter?.guest_api
            : filter?.api;
        const response = await newsService.getFilterNews(url, params);
        if (response?.news?.length) {
          response?.news?.length !== newsCount && setIsEndOfPage(true);
          isLoadMoreClicked
            ? setPrevNews(response?.news)
            : setNews(response?.news);
          setIsLoading(false);
          const newsIds = response?.news.map(({ newsId }) => newsId);

          if (newsIds?.length) {
            getDynamicProps(newsIds);
          }
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getPreferencesData = async () => {
    let newsParams;
    if (childCategory && isFromChildCategory) {
      newsParams = {
        startIndex: startIndex,
        count: newsCount,
        categoryIds: childCategory?.catId,
      };
    } else if (category) {
      newsParams = {
        startIndex: startIndex,
        count: newsCount,
        categoryIds: category?.catId,
      };
    } else if (districtId) {
      newsParams = {
        startIndex: startIndex,
        count: newsCount,
        locationIds: districtId,
      };
    } else if (urlPathName === '/') {
      newsParams = {
        startIndex: startIndex,
        count: newsCount,
      };
    }

    if (startIndex > 0) {
      newsParams['time_before'] = news[news?.length - 1]?.date_news;
    }

    try {
      if (newsParams) {
        setIsLoading(true);
        const response = await newsService.fetchDetailedNews(newsParams);
        if (response?.news?.length) {
          response?.news?.length !== newsCount && setIsEndOfPage(true);
          isLoadMoreClicked
            ? setPrevNews(response?.news)
            : setNews(response?.news);
          setIsLoading(false);
          const newsIds = response?.news.map(({ newsId }) => newsId);

          if (newsIds?.length) {
            getDynamicProps(newsIds);
          }
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * function to updated dynamic props of the news
   * @param {object} newsIds
   */
  const getDynamicProps = async (newsIds) => {
    try {
      const reqObj = {
        newsIds: newsIds.join(','),
      };

      if (newsSessionID) {
        reqObj['sessionId'] = newsSessionID;
      }

      const response = await newsService.fetchNewsDynamicProps(reqObj);
      const dynamicProps = response?.news || [];

      if (dynamicProps?.length) {
        startTransition(() => {
          setNews((prevState) => {
            return prevState?.map((news) => {
              let isAvailable = dynamicProps.find(
                (newsObj) => newsObj.newsId === news.newsId,
              );

              if (isAvailable) {
                return {
                  ...news,
                  ...isAvailable,
                };
              } else {
                return { ...news };
              }
            });
          });
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    let count = 0;
    let isDataFetchedOnce = false;
    let timeOut = null;

    const fetchDataIfConditionsMet = () => {
      if (filter) {
        getFilterData();
      } else {
        getPreferencesData();
      }
    };

    const handleFetchData = () => {
      if (userState?.isAuthenticated && newsSessionID) {
        //Fetch data for authenticated user with newsSessionID
        fetchDataIfConditionsMet();
        clearTimeout(timeOut);
        isDataFetchedOnce = true;
      } else if (!isDataFetchedOnce) {
        //if data is not fetched already, fetch data for guest user after every 1 second to check if user loggedin or not
        if (count === 2) {
          fetchDataIfConditionsMet();
          clearTimeout(timeOut);
        } else {
          setIsLoading(true);
          timeOut = setTimeout(fetchData, 0);
          count++;
        }
      } else {
        fetchDataIfConditionsMet();
      }
    };

    const fetchData = () => {
      handleFetchData();
    };

    fetchData();

    return () => clearTimeout(timeOut);
  }, [
    startIndex,
    filter,
    newsSessionID,
    childCategory,
    isFromChildCategory,
    category,
    districtId,
    userState?.isAuthenticated,
  ]);

  /**
   * Merge function to merge the previously loaded news and new news together
   */
  const mergeNews = useCallback(() => {
    setNews([...news, ...prevNews]);
    setIsLoading(false);
  }, [news, prevNews]);

  /**
   * In case the load more button is clicked and previous news is changed then merge news function
   */
  useEffect(() => {
    isLoadMoreClicked && mergeNews();
  }, [prevNews, isLoadMoreClicked]);

  /**
   * Default news count upon click of Load More Button
   */
  const handleLoadMore = () => {
    setStartIndex((prev) => prev + TEN);
    setIsLoadMoreClicked(true);
  };

  const displayHeading = () => {
    let heading;
    if (category) {
      heading = isTamil
        ? category?.name_tamil || category?.name
        : category?.name;
    } else if (districtName) {
      heading = districtName;
    }

    if (filter) {
      heading = isTamil ? filter?.name_ta || filter?.name : filter?.name;
    }

    return heading;
  };

  /**
   * Handler for Card Actions (like, dislike and bookmark)
   * @param {object} newsObj news object for which action needs to be updated
   */
  const onCardAction = (newsObj) => {
    const modifiedNewsArray = news?.map((newsItem) => {
      if (newsItem.newsId === newsObj.newsId) {
        return { ...newsItem, ...newsObj };
      }
      return newsItem;
    });
    setNews([...modifiedNewsArray]);
  };

  return (
    <>
      {!news?.length && !isLoading ? (
        <EmptyScreen message={t('NO_NEWS_TOPIC')} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: isMobile ? '0 0 2.5rem 0' : '1.5rem 0 2.5rem 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              pt: isMobile ? '1.5rem' : 'none',
              pb: '1.5rem',
              height: '2.5rem',
              maxWidth:
                aboveLap || (belowLap && aboveMobile) ? '32.5rem' : '28.875rem',
              alignSelf: 'center',
            }}
          >
            {urlPathName !== '/foryou' && (
              <IconButton onClick={() => navigate(-1)}>
                <BackIcon
                  fill={
                    isDarkMode
                      ? MINMINI_V3.primary.white
                      : MINMINI_V3.grey.darkGrey
                  }
                  fontSize={
                    aboveLap
                      ? '1.25rem'
                      : belowLap && aboveMobile
                      ? '1rem'
                      : '1rem'
                  }
                />
              </IconButton>
            )}

            <Typography
              variant={isMobile ? 'h8_mobile' : 'h8'}
              sx={{
                textTransform: 'capitalize',
                ml: '0.5rem',
                fontSize: aboveLap
                  ? '1.125rem'
                  : belowLap && aboveMobile
                  ? '1rem'
                  : '0.75rem',
              }}
            >
              {displayHeading()}
            </Typography>
          </Box>

          <Box
            className={
              category?.childs.length > 0
                ? classes.newsCategories
                : classes.hideNewsCategories
            }
            sx={{
              maxWidth:
                aboveLap || (belowLap && aboveMobile) ? '32.5rem' : '28.875rem',
              alignSelf: 'center',
            }}
          >
            {category && category?.url && (
              <NavLink
                className={({ isActive }) =>
                  isActive && !childCategory
                    ? classes.activeNewsCategoryBtn
                    : classes.newsCategoryBtn
                }
                style={({ isActive }) => ({
                  color:
                    isActive && !childCategory && !isDarkMode
                      ? LIGHT_COLOR.primary[100]
                      : isActive && !childCategory && isDarkMode
                      ? DARK_COLOR.primary[100]
                      : isDarkMode
                      ? DARK_COLOR.grey[600]
                      : LIGHT_COLOR.grey[400],
                  background:
                    isActive && !childCategory && !isDarkMode
                      ? LIGHT_COLOR.primary[15]
                      : isActive && !childCategory && isDarkMode
                      ? DARK_COLOR.primary[25]
                      : isDarkMode
                      ? DARK_COLOR.white[25]
                      : LIGHT_COLOR.white[5],
                })}
                to={category?.url}
              >
                {t('all')}
              </NavLink>
            )}
            {category?.childs?.map((childCategory) => (
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? classes.activeNewsCategoryBtn
                    : classes.newsCategoryBtn
                }
                style={({ isActive }) => ({
                  color:
                    isActive && !isDarkMode
                      ? LIGHT_COLOR.primary[100]
                      : isActive && isDarkMode
                      ? DARK_COLOR.primary[100]
                      : isDarkMode
                      ? DARK_COLOR.grey[600]
                      : LIGHT_COLOR.grey[400],
                  background:
                    isActive && !isDarkMode
                      ? LIGHT_COLOR.primary[15]
                      : isActive && isDarkMode
                      ? DARK_COLOR.primary[25]
                      : isDarkMode
                      ? DARK_COLOR.white[25]
                      : LIGHT_COLOR.white[5],
                })}
                to={childCategory?.url}
                key={childCategory?.catId}
              >
                {isTamil
                  ? childCategory?.name_tamil || childCategory?.name
                  : childCategory?.name}
              </NavLink>
            ))}
          </Box>
          {isLoading && startIndex === 0 ? (
            <CardSkeleton cards={3} />
          ) : (
            <NewsData
              news={news}
              newsCategories={newsCategories}
              onCardAction={onCardAction}
            />
          )}
          {!isEndOfPage && (
            <ButtonLoader
              onClick={handleLoadMore}
              color='primary'
              variant='contained'
              disabled={isLoading}
              loading={isLoading}
              sx={{
                padding: '0.75rem 1.5rem',
                lineHeight: '1.25rem',
                maxWidth: isMobile
                  ? isTamil
                    ? '10.75rem'
                    : '7.75rem'
                  : '9.75rem',
                width: '100%',
                height: isMobile ? '2rem' : '3rem',
                textAlign: 'center',
                color: 'primary',
                alignSelf: 'center',
                fontSize: isMobile ? '0.75rem' : '1rem',
              }}
            >
              {t('LOAD_MORE')}
            </ButtonLoader>
          )}
        </Box>
      )}
    </>
  );
}
