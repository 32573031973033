import { useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { saveAs } from 'file-saver';
import assessmentService from '../../../services/assessment.service';
import { REPORTER_ID_FILE_NAME } from '../../../Constants/Constants';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Skeleton from 'react-loading-skeleton';
import BackIcon from '../../../assets/icons/wholeArrowLeft.svg';
import classes from './reporterID.module.css';
import { viewPort, MaxWidth } from '../../../responsive/viewport.util';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMetaStore } from '../../../store/MetaTagHandler';
import { metaAction } from '../../../store/MetaTagHandler/metaAction';
import { META_TAG_TITLE } from '../../../Constants/meta.title.constant';

function ReporterID() {
  const dispatchMetaAction = useMetaStore()[1];
  const isMobile = MaxWidth(viewPort.mobileMax);
  const [file, setFile] = useState();
  const [isloaded, setLoaded] = useState(false);
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const metaObj = {
      title: META_TAG_TITLE.reporterId,
      description: META_TAG_TITLE.reporterId,
      keywords: '',
      canonical_url: location.pathname,
    };
    dispatchMetaAction(metaAction.updateMeta, metaObj);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  useEffect(() => {
    const fetchReporterID = async () => {
      try {
        const response = await assessmentService.downloadReporterID();

        if (response) {
          setFile(response);
        }
      } catch (error) {}
    };
    if (!isloaded) {
      setLoaded(true);
      fetchReporterID();
    }
  }, []);

  const isLoading = () => {
    return <Skeleton height={480} width={300} />;
  };

  const downloadReporterID = () => {
    saveAs(file, REPORTER_ID_FILE_NAME);
  };

  return (
    <Box
      sx={{
        padding: { xs: 0, sm: '1.25rem 4rem 4rem 4rem' },
        backgroundColor: isMobile
          ? (theme) =>
              isDarkMode ? theme.palette.grey[200] : theme.palette.white[100]
          : (theme) => theme.palette.background.default,
      }}
    >
      <Box
        sx={
          isMobile
            ? { padding: '0 4.666vw' }
            : {
                maxWidth: '78.75rem',
                marginLeft: 'auto',
                marginRight: 'auto',
              }
        }
      >
        <Box
          sx={
            isMobile
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '24px',
                  paddingRight: '24px',
                  height: '5rem',
                }
              : { display: 'flex', alignItems: ' initial;' }
          }
        >
          {
            <img
              src={BackIcon}
              className={classes.backIcon}
              onClick={() => navigate('/')}
              alt='back-icon'
            />
          }
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: isMobile ? '1rem' : '1.5rem',
              lineHeight: isMobile ? '1rem' : '1.5rem',
              marginBottom: isMobile ? '' : '1.5rem',
            }}
          >
            {t('reporter-id')}
          </Typography>
        </Box>
      </Box>
      {isMobile && <Divider variant='fullWidth' />}
      <Box
        sx={
          isMobile
            ? { padding: '0 4.666vw 4.666vw 4.666vw' }
            : {
                maxWidth: '78.75rem',
                marginLeft: 'auto',
                marginRight: 'auto',
              }
        }
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              isDarkMode ? theme.palette.grey[200] : theme.palette.white[100],
            borderRadius: isMobile ? '' : '0.625rem',
            border: isMobile ? '' : '1px solid',
            borderColor: isMobile ? '' : 'divider',
            padding: '2.5rem',
          }}
        >
          {file ? (
            <>
              <Box
                sx={{
                  maringBottom: '1.875rem',
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: (theme) =>
                    isDarkMode
                      ? theme.palette.grey[200]
                      : theme.palette.white[100],
                }}
              >
                <Document
                  loading={isLoading}
                  error={isLoading}
                  file={window.URL.createObjectURL(file)}
                >
                  <Page
                    pageNumber={1}
                    loading={isLoading}
                    error={isLoading}
                    renderTextLayer={false}
                  />
                </Document>
              </Box>

              <Box sx={{ textAlign: 'center' }}>
                <Button
                  variant='contained'
                  style={{
                    marginBottom: '1.25rem',
                    padding: '0.75rem',

                    width: '14rem',
                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
                  }}
                  onClick={downloadReporterID}
                >
                  {t('download-id')}
                </Button>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                maringBottom: '1.875rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Skeleton height={480} width={300} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ReporterID;
