import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

/**
 * Make api call for retrieving Minmini Reporters(recommendation)
 * @param {object} data
 * @returns promise
 */
const fetchDTReporterRecommendations = (data) => {
  return apiRequest(api.getDtReporters, METHOD.POST, false, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving followed Reporters
 * @param {object} data
 * @returns promise
 */
const fetchFollowedReporters = (data) => {
  return apiRequest(api.getFollowedReporters, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving unfollowed Reporters
 * @param {object} data
 * @returns promise
 */
const fetchUnfollowedReporters = (data) => {
  return apiRequest(api.getUnfollowedReporters, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for follow and unfollow a reporter
 * @param {object} data
 * @returns promise
 */
const followUnfollowReporter = (data) => {
  return apiRequest(api.followUnfollowReporter, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving followed Publishers
 * @param {object} data
 * @returns promise
 */
const fetchFollowedPublishers = (data) => {
  return apiRequest(api.getFollowedPublishers, METHOD.GET, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for follow and unfollow a Publisher
 * @param {object} data
 * @returns promise
 */
const followUnfollowPublisher = (data) => {
  return apiRequest(api.followUnfollowPublisher, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Publisher Authors List
 * @returns promise
 */
const getPublishersAuthorList = () => {
  return apiRequest(api.getAuthors, METHOD.GET, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Topic Authors List
 * @returns promise
 */
const getTopicsAuthorsList = () => {
  return apiRequest(api.getConfig, METHOD.GET, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving User List
 * @returns promise
 */
const getUsersList = (data) => {
  return apiRequest(
    api.getUsersList,
    METHOD.GET,
    false,
    data,
    true,
    false,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const followersService = {
  fetchDTReporterRecommendations,
  fetchFollowedReporters,
  fetchUnfollowedReporters,
  followUnfollowReporter,

  fetchFollowedPublishers,
  followUnfollowPublisher,

  getPublishersAuthorList,
  getTopicsAuthorsList,

  getUsersList,
};

export default followersService;
