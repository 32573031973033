import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styles } from './trainingStyles';
import { viewPort, MaxWidth } from '../../../responsive/viewport.util';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ASSESSMENT_URL, FALSE } from '../../../Constants/Constants';
import InfoModal from '../../Modal/InfoModal';
import { UserDetailsProvider } from '../../../store/user-details-context';
import userProfileService from '../../../services/user-profile.service';
import './StartAssessment.css';

/**
 * Start Assessment Action Component
 */
function StartAssessment({ trainingCompleted, language }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = MaxWidth(viewPort.mobileMax);
  const buttonProps = {
    fullWidth: isMobile,
  };
  const { state: userState } = useContext(UserDetailsProvider);
  const isOnboardedReporter = userState?.userDetails?.isOnboardedReporter;
  const isTrainingDone = userState?.citizenJournalist?.isTrainingDone;
  const isTrainingSkipped = userState?.citizenJournalist?.isTrainingSkipped;
  const userId = userState?.userDetails?.userId;
  const [showInfoModal, setShowInfoModal] = useState(false);
  const initialCitizenJournalist = {
    draftCount: 0,
    postCount: 0,
    beAReporter: 'false',
    isKYCDone: 'false',
    isTrainingDone: 'false',
    nameInDoc: '',
    tcConsent: 'false',
    trainingLanguage: '',
    isTrainingSkipped: 'false',
    reporterID: null,
    isAssessmentDone: 'false',
  };
  const [citizenJournalist, setCitizenJournalist] = useState(
    initialCitizenJournalist,
  );

  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = () => {
    setCitizenJournalist({
      draftCount: userState?.citizenJournalist?.draftCount,
      postCount: userState?.citizenJournalist?.postCount,
      beAReporter: userState?.citizenJournalist?.beAReporter,
      isKYCDone: userState?.citizenJournalist?.isKYCDone,
      isTrainingDone: userState?.citizenJournalist?.isTrainingDone,
      nameInDoc: userState?.citizenJournalist?.nameInDoc,
      tcConsent: userState?.citizenJournalist?.tcConsent,
      trainingLanguage: userState?.citizenJournalist?.trainingLanguage,
      isTrainingSkipped: userState?.citizenJournalist?.isTrainingSkipped,
      reporterID: userState?.citizenJournalist?.reporterID,
      isAssessmentDone: userState?.citizenJournalist?.isAssessmentDone,
    });
  };

  const handleInfoModal = () => {
    if (isTrainingDone === FALSE && isTrainingSkipped === FALSE) {
      setShowInfoModal(true);
    } else {
      getAssessment();
    }
  };

  const getAssessment = () => {
    if (isTrainingSkipped === FALSE) {
      UpdatePreferences();
    } else {
      isOnboardedReporter ? navigate('/reporter') : navigate(ASSESSMENT_URL);
    }
  };

  const UpdatePreferences = async () => {
    try {
      await userProfileService.updateUserProfile({
        userId: userId,
        citizenJournalist: JSON.stringify({
          ...citizenJournalist,
          isTrainingSkipped: 'true',
        }),
      });
      isOnboardedReporter ? navigate('/reporter') : navigate(ASSESSMENT_URL);
    } catch (error) {}
  };

  return (
    <>
      <Box
        sx={{
          background: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],

          ...styles.startAssessmentContainer,
          border: isMobile ? 'none' : '1px solid',
          borderColor: isMobile
            ? ''
            : isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
      >
        <Typography sx={styles.startAssessmentNote}>
          {!isOnboardedReporter && t('complete_training_note')}
        </Typography>
        <Button
          {...buttonProps}
          sx={{
            borderRadius: '0.625rem',
          }}
          variant='contained'
          onClick={handleInfoModal}
        >
          {isOnboardedReporter ? t('cproceed') : t('take-the-test')}
        </Button>
      </Box>
      <InfoModal
        message={t('training-info-message')}
        open={showInfoModal}
        close={() => setShowInfoModal(false)}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box className='infoProceedButton'>
            <Button
              onClick={() => getAssessment()}
              className='infoProceedButtonText'
              sx={{ fontFamily: 'Sharp Sans' }}
            >
              {t('proceed-anyway')}
            </Button>
          </Box>
          <Box className='infoProceedButton'>
            <Button
              className='infoProceedButtonText'
              onClick={() => setShowInfoModal(false)}
              sx={{ fontFamily: 'Sharp Sans' }}
            >
              {t('CLOSE')}
            </Button>
          </Box>
        </Box>
      </InfoModal>
    </>
  );
}

export default StartAssessment;
