// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mediaBox::-webkit-scrollbar {
  height: 0.4rem;
  width: 0.4rem;
}

.mediaBox::-webkit-scrollbar-track {
  border-radius: 5px;
}

.mediaBox::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--gray-200);
}
`, "",{"version":3,"sources":["webpack://./src/Components/CreateAReport/MediaPicker/MediaPicker.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iCAAiC;AACnC","sourcesContent":[".mediaBox::-webkit-scrollbar {\n  height: 0.4rem;\n  width: 0.4rem;\n}\n\n.mediaBox::-webkit-scrollbar-track {\n  border-radius: 5px;\n}\n\n.mediaBox::-webkit-scrollbar-thumb {\n  border-radius: 5px;\n  background-color: var(--gray-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
