import {
  LIGHT_COLOR,
  DARK_COLOR,
  MINMINI_V2,
  MINMINI_V3,
} from './color.constant';
import CheckboxBlankIcon from '../Components/icons/checkbox/CheckboxBlankIcon';
import CheckedboxIcon from '../Components/icons/checkbox/CheckedboxIcon';
import IndeterminateCheckBoxIcon from '../Components/icons/checkbox/IndeterminateCheckBoxIcon';
import SharpSansMedium from '../assets/fonts/HelveticaNeueMedium.ttf';
export const DtLight = {
  palette: {
    mode: 'light',
    custom: {
      dark: '#000000',
    },
    primary: {
      main: MINMINI_V2.primary.purple,
    },
    success: {
      main: MINMINI_V2.primary.green,
    },
    error: {
      main: MINMINI_V2.primary.errorRed,
      secondary: DARK_COLOR.error[100],
    },

    background: {
      default: MINMINI_V2.primary.white,
      paper: MINMINI_V2.primary.white,
    },

    minmini_grey: {
      grey: MINMINI_V2.grey.grey,
    },

    grey: {
      100: LIGHT_COLOR.grey[100],
      200: LIGHT_COLOR.grey[200],
      300: LIGHT_COLOR.grey[300],
      400: LIGHT_COLOR.grey[400],
      500: LIGHT_COLOR.grey[500],
      600: LIGHT_COLOR.grey[600],
    },
    white: {
      100: LIGHT_COLOR.white[100],
      50: LIGHT_COLOR.white[50],
      25: LIGHT_COLOR.white[20],
      5: LIGHT_COLOR.white[5],
    },
    toaster: {
      errorBorder: LIGHT_COLOR.toaster.errorBorder,
      error: LIGHT_COLOR.toaster.error,
      successBorder: LIGHT_COLOR.toaster.successBorder,
      success: LIGHT_COLOR.toaster.success,
      warningBorder: LIGHT_COLOR.toaster.warningBorder,
      warningText: LIGHT_COLOR.toaster.warningText,
      warning: LIGHT_COLOR.toaster.warning,
    },
    avatar: {
      100: LIGHT_COLOR.avatar[100],
    },
    gradient: {
      flare: LIGHT_COLOR.gradient.flare,
    },
    shadow: {
      100: LIGHT_COLOR.shadow[100],
    },
  },

  typography: {
    fontFamily: 'Sharp Sans',
    color: LIGHT_COLOR.grey[600],
    h8: {
      color: MINMINI_V2.secondary.voilet,
      fontWeight: '700',
      lineHeight: '1.25rem',
      fontSize: '1.25rem',
    },
    h8_mobile: {
      color: MINMINI_V2.secondary.voilet,
      fontWeight: '700',
      lineHeight: '1.25rem',
      fontSize: '0.75rem',
    },
    h7: {
      color: MINMINI_V2.secondary.voilet,
      fontWeight: '700',
      lineHeight: '1.5rem',
    },
    h5: {
      fontWeight: '600',
      lineHeight: '1.5rem',
      color: MINMINI_V2.secondary.voilet,
    },
    h6: {
      fontWeight: '600',
      color: MINMINI_V2.secondary.voilet,
    },
    body1: {
      // its font size is 1 rem color in dark its  font gray #f2f2f2
      color: MINMINI_V2.secondary.voilet,
    },
    body2: {
      color: LIGHT_COLOR.grey[500],
    },
    body3: {
      // its font size is 1 rem color in dark its  font #f2f2f2(gray600)
      fontSize: '1rem',
      color: MINMINI_V2.secondary.blueHue,
    },
    subtitle3: {
      //its font size is 1 rem color in  Dark its font Pure white FFFFFF
      color: MINMINI_V2.secondary.blueHue,
    },
    subtitle4: {
      //its font size is 1 rem color in dark is grey600
      fontSize: '1rem',
      color: MINMINI_V2.secondary.blueHue,
    },
    subtitle6: {
      //its font size is 1 rem color in dark is grey500
      fontSize: '1rem',
      color: MINMINI_V2.secondary.blueHue,
    },
    subtitle1: {
      color: MINMINI_V2.secondary.blueHue,
    },
    subtitle5: {
      //its font size is 1 rem color in Dark is white
      fontSize: '1rem',
      color: MINMINI_V2.secondary.blueHue,
    },
    subtitle2: {
      //its font size is 0.875 rem color in Dark is white
      color: MINMINI_V2.secondary.blueHue,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: MINMINI_V3.primary.white,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          borderColor: MINMINI_V2.secondary.brownHue,
          background: MINMINI_V2.primary.white,
          color: MINMINI_V2.DarkModeText.font1,
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sharp Sans';
          src: url(${SharpSansMedium});
        }
    `,
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: 0.9,
          borderColor: LIGHT_COLOR.grey[200],
          borderBottomWidth: '0.063rem',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            color: LIGHT_COLOR.grey[400],
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          fontSize: '1rem',
          padding: '0.75rem',
          lineHeight: '1.25rem',
          borderRadius: '0.75rem',
        },
        contained: {
          color: MINMINI_V2.primary.white,
          backgroundColor: MINMINI_V2.primary.purple,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            color: MINMINI_V2.primary.white,
            backgroundColor: MINMINI_V2.primary.purple,
          },
        },
        outlined: {
          border: `2px solid ${MINMINI_V2.primary.purple}`,
          backgroundColor: MINMINI_V2.primary.white,
          color: MINMINI_V2.primary.purple,
          '&:hover': {
            border: `2px solid ${MINMINI_V2.primary.purple}`,
            backgroundColor: MINMINI_V2.primary.white,
            color: MINMINI_V2.primary.purple,
          },
        },
        '&.Mui-disabled': {
          color: LIGHT_COLOR.primary[50],
          borderColor: LIGHT_COLOR.grey[300],
        },

        minminiContained: {
          color: MINMINI_V2.primary.white,
          background: MINMINI_V2.primary.purple,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          borderRadius: '12px',
          fontWeight: '700',
          '&:hover': {
            color: MINMINI_V2.primary.white,
            backgroundColor: MINMINI_V2.primary.purple,
          },
        },
        minminiOutlined: {
          border: `2px solid ${MINMINI_V2.primary.purple}`,
          backgroundColor: MINMINI_V2.primary.white,
          color: MINMINI_V2.primary.purple,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          borderRadius: '12px',
          fontWeight: '700',
          '&:hover': {
            border: `2px solid ${MINMINI_V2.primary.purple}`,
            backgroundColor: MINMINI_V2.primary.white,
            color: MINMINI_V2.primary.purple,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: LIGHT_COLOR.grey[300],
        },
        shrink: {
          color: LIGHT_COLOR.grey[400],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': 'unset',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <CheckedboxIcon
            fill={LIGHT_COLOR.primary[100]}
            stroke={LIGHT_COLOR.primary[100]}
          />
        ),
        icon: (
          <CheckboxBlankIcon
            fill={LIGHT_COLOR.white[100]}
            stroke={LIGHT_COLOR.grey[300]}
          />
        ),
        indeterminateIcon: (
          <IndeterminateCheckBoxIcon
            fill={LIGHT_COLOR.primary[100]}
            stroke={LIGHT_COLOR.primary[100]}
          />
        ),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: LIGHT_COLOR.grey[600],
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: LIGHT_COLOR.white[100],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderColor: MINMINI_V2.secondary.brownHue,
        },
      },
    },
  },
};
