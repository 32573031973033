// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MediaPreview_mediaPreview__CzfKt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;
  width: 100%;
  max-width: 56.25rem;
  border-width: 2.75rem 0.626rem 0.626rem 0.626rem;
  border-style: solid;
  border-color: var(--black);
  border-radius: 0.625rem;
  background: var(--black);
}

.MediaPreview_crossIcon__3vD0Q {
  position: absolute;
  top: -2.25rem;
  right: -0.25rem;
}

.MediaPreview_previewImage__p8NfO {
  aspect-ratio: 16 / 9;
  max-width: 55rem;
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1067px) {
  .MediaPreview_mediaPreview__CzfKt {
    max-width: 37.5rem;
  }
  .MediaPreview_previewImage__p8NfO {
    max-width: 36rem;
  }
}

@media screen and (max-width: 767px) {
  .MediaPreview_mediaPreview__CzfKt {
    max-width: 21.5rem;
  }
  .MediaPreview_previewImage__p8NfO {
    max-width: 20rem;
  }
}

@media screen and (max-width: 479px) {
  .MediaPreview_mediaPreview__CzfKt {
    max-width: 18.5rem;
  }
  .MediaPreview_previewImage__p8NfO {
    max-width: 17rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Drafts/MediaPreview.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,cAAc;EACd,WAAW;EACX,mBAAmB;EACnB,gDAAgD;EAChD,mBAAmB;EACnB,0BAA0B;EAC1B,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".mediaPreview {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-shadow: 24;\n  width: 100%;\n  max-width: 56.25rem;\n  border-width: 2.75rem 0.626rem 0.626rem 0.626rem;\n  border-style: solid;\n  border-color: var(--black);\n  border-radius: 0.625rem;\n  background: var(--black);\n}\n\n.crossIcon {\n  position: absolute;\n  top: -2.25rem;\n  right: -0.25rem;\n}\n\n.previewImage {\n  aspect-ratio: 16 / 9;\n  max-width: 55rem;\n  width: 100%;\n  object-fit: contain;\n}\n\n@media screen and (max-width: 1067px) {\n  .mediaPreview {\n    max-width: 37.5rem;\n  }\n  .previewImage {\n    max-width: 36rem;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .mediaPreview {\n    max-width: 21.5rem;\n  }\n  .previewImage {\n    max-width: 20rem;\n  }\n}\n\n@media screen and (max-width: 479px) {\n  .mediaPreview {\n    max-width: 18.5rem;\n  }\n  .previewImage {\n    max-width: 17rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediaPreview": `MediaPreview_mediaPreview__CzfKt`,
	"crossIcon": `MediaPreview_crossIcon__3vD0Q`,
	"previewImage": `MediaPreview_previewImage__p8NfO`
};
export default ___CSS_LOADER_EXPORT___;
