import React, { useState, useEffect, useContext } from 'react';
import { Stack, Grid, Typography, Box, Divider } from '@mui/material';
import checkbox from '../../assets/icons/inactive-checkbox-outlined.svg';
import selectedCheckBox from '../../assets/icons/active-checkbox-outlined.svg';
import selectedCheckBoxDark from '../../assets/icons/active-checkbox-outlined-dark.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import PublisherSkeleton from '../Skeleton/PublisherSkeleton';
import { THREE } from '../../Constants/Constants';
import selectFewIcon from '../../assets/icons/partial-checkbox.svg';
import selectAllIcon from '../../assets/icons/active-checkbox.svg';
import uncheckedIcon from '../../assets/icons/empty-checkbox.svg';
import selectFewIconDark from '../../assets/icons/partial-checkbox-dark.svg';
import selectAllIconDark from '../../assets/icons/active-checkbox-dark.svg';
import uncheckedIconDark from '../../assets/icons/empty-checkbox-dark.svg';
import { useTranslation } from 'react-i18next';
import userProfileService from '../../services/user-profile.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import ButtonLoader from '../buttonLoader/ButtonLoader';

const Preferences = (props) => {
  const { showPreferences, closeModal, navBarCategories } = props;
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { state: userState } = useContext(UserDetailsProvider);
  const userId = userState?.userDetails?.userId
    ? userState?.userDetails?.userId
    : localStorage.getItem('userID');
  const { t } = useTranslation();
  const following = userState?.following;
  const isMobile = MaxWidth(viewPort.tablet);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const [isTopicLoading, setIsTopicLoading] = useState(false);

  useEffect(() => {
    setShowTopicModal(showPreferences);
    setTopics(navBarCategories);
    getTopicList();
  }, [showPreferences]);

  useEffect(() => {
    if (following?.topics?.length === 0) {
      setSelectedTopics(topics && topics?.map((topic) => topic));
    }
  }, [following?.topics?.length, topics]);

  const getTopicList = () => {
    if (following?.topics.length > 0) {
      const selectedTopicByContext = following?.topics?.map((catId) => ({
        catId,
      }));
      setSelectedTopics(selectedTopicByContext);
    }
    setIsLoading(false);
  };

  /**
   * Updating the selected topic to update the preferences
   */
  const selectTopic = (topicId) => {
    const isTopicExist =
      selectedTopics &&
      selectedTopics?.some((topic) => topic.catId === topicId);
    if (isTopicExist) {
      let newData = selectedTopics.filter((topic) => topic.catId !== topicId);
      setSelectedTopics(newData);
    } else {
      setSelectedTopics((prev) => [
        ...prev,
        {
          catId: topicId,
        },
      ]);
    }
  };

  /**
   * Updating the user profile with followed topics
   */
  const updateFollowings = async () => {
    try {
      setIsTopicLoading(true);
      const topic = selectedTopics?.map((topic) => topic.catId);
      const followings = {
        topics: topic,
      };
      await userProfileService.updateUserProfile({
        userId: userId,
        following: JSON.stringify(followings),
      });
      closeModal();
    } catch (error) {
    } finally {
      setIsTopicLoading(false);
    }
  };

  /**
   * Selects or unselects all topics onClick of 3 state button
   */
  const selectAllTopics = () => {
    if (topics?.length !== selectedTopics?.length) {
      setSelectedTopics(topics && topics?.map((topic) => topic));
    } else {
      setSelectedTopics([]);
    }
  };

  return (
    <>
      {showTopicModal && (
        <>
          <Box
            sx={{
              marginTop: '-1.2rem',
              maxHeight: isMobile ? '30rem' : '20rem',
              minHeight: '20rem',
              overflowY: 'scroll',
              scrollBehavior: 'smooth',
              '&::-webkit-scrollbar': {
                background: 'transparent',
                appearance: 'none',
                width: 0,
                height: 0,
              },
              paddingBottom: '0.63rem',
            }}
          >
            <Box
              sx={{
                py: 1.5,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              {selectedTopics?.length !== 0 &&
                selectedTopics?.length === topics?.length && (
                  <img
                    alt='check all following'
                    src={isDarkMode ? selectAllIconDark : selectAllIcon}
                    onClick={selectAllTopics}
                    style={{ cursor: 'pointer', marginRight: '0.625rem' }}
                  />
                )}
              {selectedTopics?.length !== 0 &&
                selectedTopics?.length !== topics?.length && (
                  <img
                    alt='check all following'
                    src={isDarkMode ? selectFewIconDark : selectFewIcon}
                    onClick={selectAllTopics}
                    style={{ cursor: 'pointer', marginRight: '0.625rem' }}
                  />
                )}
              {selectedTopics?.length === 0 && (
                <img
                  alt='check all following'
                  src={isDarkMode ? uncheckedIconDark : uncheckedIcon}
                  onClick={selectAllTopics}
                  style={{ cursor: 'pointer', marginRight: '0.625rem' }}
                />
              )}{' '}
              <Box
                component='span'
                sx={{
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  color: isDarkMode ? '#F2F2F2' : '#808080',
                  textTransform: 'uppercase',
                }}
              >
                {t('FOLLOW_ALL_TOPICS')}
              </Box>
            </Box>
            {isLoading && <PublisherSkeleton cards={2} />}
            {topics &&
              topics?.map((topic) => {
                return (
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                      py: 3,
                      borderBottom: 1,
                      borderColor: 'divider',
                    }}
                    key={topic.id}
                    onClick={() => {
                      selectTopic(topic.catId);
                    }}
                  >
                    <Grid>
                      <Typography
                        sx={{ color: isDarkMode ? '#F2F2F2' : '#545454' }}
                      >
                        {isTamil ? topic.name_tamil : topic.name}
                      </Typography>
                    </Grid>
                    <Grid>
                      <img
                        src={
                          isDarkMode
                            ? selectedTopics?.some(
                                (e) => e.catId === topic.catId,
                              )
                              ? selectedCheckBoxDark
                              : checkbox
                            : selectedTopics?.some(
                                (e) => e.catId === topic.catId,
                              )
                            ? selectedCheckBox
                            : checkbox
                        }
                        alt='checkBox'
                        style={{ cursor: 'pointer' }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
          </Box>
          <Stack sx={{ mb: 2.5 }}>
            <Divider sx={{ marginX: '-1.25rem' }} variant='fullWidth' />
          </Stack>
          <Stack sx={{ alignItems: 'center' }}>
            <ButtonLoader
              variant='contained'
              disabled={selectedTopics?.length < THREE ? true : false}
              onClick={updateFollowings}
              sx={{
                maxWidth: '20rem',
                width: '100%',
                height: '2.75rem',

                borderRadius: '0.625rem',
                backgroundColor: 'primary',
                fontWeight: '600',
              }}
              loading={isTopicLoading}
            >
              {t('continue')}
            </ButtonLoader>
          </Stack>
        </>
      )}
    </>
  );
};

export default Preferences;
