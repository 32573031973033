// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoProceedButton {
  background: var(--pacific-blue);
  font-size: var(--font-size-m);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  height: 2.75rem;
  margin-bottom: 1rem;
}

.infoProceedButtonText {
  color: var(--white) !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Reporter/Training/StartAssessment.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,6BAA6B;EAC7B,qBAAqB;EACrB,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".infoProceedButton {\n  background: var(--pacific-blue);\n  font-size: var(--font-size-m);\n  border-radius: 0.5rem;\n  display: flex;\n  justify-content: center;\n  height: 2.75rem;\n  margin-bottom: 1rem;\n}\n\n.infoProceedButtonText {\n  color: var(--white) !important;\n  text-transform: uppercase;\n  font-weight: 600 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
