import React from 'react';
import classes from './RelatedNews.module.css';
import NewsCard from '../NewsCards/NewsCard';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';

export default function RelatedNews(props) {
  const { t } = useTranslation();
  const isMobile = MaxWidth(viewPort.tablet);
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  return (
    <div className={classes.relatedNews}>
      <Box
        sx={{
          display: 'flex',
          width: aboveLap || (belowLap && aboveMobile) ? '32.5rem' : '100%',
        }}
      >
        <Typography
          variant='h5'
          sx={{
            fontWeight: 700,
            marginBottom: isMobile ? 0 : '1rem',
            marginTop: isMobile ? 0 : '1rem',
            p: isMobile ? '0rem 1.32rem 1rem 1rem' : '0 0.563rem 1.5rem 0rem',
            fontSize: aboveLap
              ? '1.3rem'
              : belowLap && aboveMobile
              ? '1.2rem'
              : '1rem',
          }}
          className={classes.relatedNewsTypography}
        >
          {t('related-stories')}
        </Typography>
      </Box>

      {props.news?.map(
        (news) =>
          !news.is_promotional && (
            <div key={news.newsId} className={classes.relatedNewsCardContainer}>
              <NewsCard
                key={news.newsId}
                id={news.newsId}
                anonymous={news.anonymous}
                url={news.url}
                hasVideo={news.video}
                description={news.heading}
                keywords={news.keywords}
                maincategoryIcon={news.maincategory_icon}
                mainCategory={news.maincategory_name}
                mainCategoryID={news.maincategory}
                mainCategoryUrl={news.maincategory_url}
                headline={news.heading}
                media={news?.media}
                city={news.location}
                locationID={news.locationId}
                newsDate={news.date_news}
                likeCount={news.news_like_count}
                dislikeCount={news.news_dislike_count}
                authorName={news.authorName}
                authorID={news.authorId}
                trackingID={news.trackingId}
                newsID={news.newsId}
                authorImage={news.authorImage}
                isLiked={news.is_liked}
                isDisliked={news.is_disliked}
                isBookmark={news.is_bookmark}
                isTrending={news.is_trending}
                newsType={news.news_type}
                commentsCount={news.news_comments_count}
                newsCategories={props.newsCategories}
                verifiedByDT={news.param_isVerifiedByDT}
                user_type={news.user_type}
              />
            </div>
          ),
      )}
    </div>
  );
}
