import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import classes from './MediaPreview.module.css';
import crossIcon from '../../assets/icons/cross-white.svg';
import JWPlayer from '@jwplayer/jwplayer-react';
import noImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import { EnvironmentProvider } from '../../store/firebase-context';

/**
 * Media Preview Component
 */
export default function MediaPreview(props) {
  const { previewMedia, isMediaPreviewVisible } = props;
  const { environment } = useContext(EnvironmentProvider);
  const [open, setOpen] = useState(isMediaPreviewVisible);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  /**
   * Modal to display the media clicked by the user based upon the initial state passed as props
   * from EditDrafts Component and closing the modal with close Icon
   */
  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={classes.mediaPreview} sx={{ background: '#000' }}>
          {previewMedia?.mediaType === 'VIDEO' && (
            <JWPlayer
              config={{
                responsive: true,
                aspectratio: '16:9',
                width: '100%',
                autostart: 'viewable',
                stretching: 'uniform',
                volume: 25,
                autoPause: {
                  viewability: true,
                  pauseAds: true,
                },
              }}
              playlist={[{ file: previewMedia?.presigned_mediaId }]}
              library={environment.web_jwp_library_url}
            />
          )}
          {previewMedia?.mediaType === 'IMAGE' && (
            <img
              src={
                previewMedia?.presigned_mediaId || isDarkMode
                  ? noImageDark
                  : noImage
              }
              className={classes.previewImage}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = isDarkMode ? noImageDark : noImage;
              }}
              alt='preview-media'
            />
          )}
          <img
            src={crossIcon}
            alt='cross'
            className={classes.crossIcon}
            onClick={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
}
