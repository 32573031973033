import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import HorizontalMenuBar from '../HorizontalMenuBar/HorizontalMenuBar';
import './Districts.css';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';

const DistrictsMobile = ({ navBarCategories, navBarFilters, districts }) => {
  let navigate = useNavigate();
  const isMobile = MaxWidth(viewPort.mobile);
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const setDistrictID = (id) => {
    navigate(`/districts${id?.url}-${id?.locationId}`);
  };

  return (
    <div>
      <HorizontalMenuBar
        newsCategories={navBarCategories}
        navBarFilters={navBarFilters}
        isBackRequired={false}
        districts={districts}
      />
      <Box sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
        {districts?.map((district, index) => {
          return (
            <Box key={index}>
              <Typography
                variant='body1'
                className='districtNames'
                onClick={() => setDistrictID(district)}
              >
                {isTamil ? district.name_tamil : district.name}
              </Typography>
              <Divider
                variant='fullWidth'
                sx={{
                  borderBottomWidth: '0.063rem',
                  margin: isMobile ? '0.625rem 0 0.625rem 1.875rem' : '',
                  overflow: 'hidden',
                }}
                className='districtHorizontalLine'
              />
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default DistrictsMobile;
