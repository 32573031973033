import React, { useState, useEffect } from 'react';
import { firebase } from '../firebase/firebase';
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from 'firebase/remote-config';
export const EnvironmentProvider = React.createContext({});
const remoteConfig = getRemoteConfig(firebase);

// For development only
const defaultConfigurations = {
  development: require('../remote_config_defaults-dev.json'),
  qa: require('../remote_config_defaults-qa.json'),
  'pre-prod': require('../remote_config_defaults-pre-prod.json'),
  production: require('../remote_config_defaults-prod.json'),
};

remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
};

export const RemoteConfig = ({ children }) => {
  const defaults = defaultConfigurations[process.env.REACT_APP_ENV];
  const [environment, setEnv] = useState(defaults);

  useEffect(() => {
    remoteConfig.defaultConfig = defaults;

    fetchAndActivate(remoteConfig)
      .then((activated) => {
        return getAll(remoteConfig);
      })
      .then((remoteFlags) => {
        for (let key in remoteFlags) {
          if (remoteFlags.hasOwnProperty(key)) {
            remoteFlags[key] = remoteFlags[key]?._value;
          }
        }

        setEnv(remoteFlags);
      });
  }, []);

  return (
    <EnvironmentProvider.Provider value={{ environment, setEnv }}>
      {children}
    </EnvironmentProvider.Provider>
  );
};
