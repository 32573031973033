import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import inactiveListIcon from '../../assets/icons/inactive-list.svg';
import activeListIcon from '../../assets/icons/active-list.svg';
import inactiveGridIcon from '../../assets/icons/inactive-grid.svg';
import activeGridIcon from '../../assets/icons/active-grid.svg';
import inactiveListDarkIcon from '../../assets/icons/inactive-list-dark.svg';
import activeListDarkIcon from '../../assets/icons/active-list-dark.svg';
import inactiveGridDarkIcon from '../../assets/icons/inactive-grid-dark.svg';
import activeGridDarkIcon from '../../assets/icons/active-grid-dark.svg';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../store/app-context';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const ViewToggle = () => {
  const { t } = useTranslation();
  const { state: appCtxState, dispatch: appCtxDispatch } =
    useContext(AppContext);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  /**
   * Handler for toggling between list and grid
   */
  const handleView = () => {
    appCtxDispatch({
      type: 'UPDATE_NEWS_VIEW',
      payload: {
        activeGrid: !appCtxState?.newsView?.activeGrid,
        activeList: !appCtxState?.newsView?.activeList,
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '6.5rem',
        justifyContent: 'space-between',
        marginLeft: '1rem',
        height: '4rem',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          letterSpacing: 0.02,
          color: isDarkMode ? DARK_COLOR.white[100] : LIGHT_COLOR.grey[500],
        }}
      >
        {t('view')}
      </Typography>
      <Box onClick={handleView}>
        {appCtxState?.newsView?.activeList ? (
          <Box
            component='img'
            src={isDarkMode ? activeListDarkIcon : activeListIcon}
            alt='disabled-switch'
          />
        ) : (
          <Box
            component='img'
            src={isDarkMode ? inactiveListDarkIcon : inactiveListIcon}
            style={{ cursor: 'pointer' }}
            alt='disabled-switch'
          />
        )}
      </Box>
      <Box onClick={handleView}>
        {appCtxState?.newsView?.activeGrid ? (
          <Box
            component='img'
            src={isDarkMode ? activeGridDarkIcon : activeGridIcon}
            alt='disabled-switch'
          />
        ) : (
          <Box
            component='img'
            src={isDarkMode ? inactiveGridDarkIcon : inactiveGridIcon}
            style={{ cursor: 'pointer' }}
            alt='disabled-switch'
          />
        )}
      </Box>
    </Box>
  );
};

export default ViewToggle;
