import {
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import MediaPicker from '../MediaPicker/MediaPicker';
import { MaxWidth, viewPort } from '../../../responsive/viewport.util';
import plusIcon from '../../../assets/icons/plus.svg';
import plusDarkIcon from '../../../assets/icons/plusDark.svg';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import DialogBox from '../../DialogBox/DialogBox';
import CompletedIcon from '../../../assets/icons/completed.svg';
import InfoIcon from '../../../assets/icons/info.svg';
import InfoIconDark from '../../../assets/icons/infoDark.svg';
import LocationIcon from '../../../assets/icons/locationLight.svg';
import LocationDisabledIcon from '../../../assets/icons/locationDissabled.svg';
import LocationDisabledDarkIcon from '../../../assets/icons/LocationDisabledDark.svg';
import LocationBlueIconDark from '../../../assets/icons/location-blue-dark.svg';
import MediaCriteriaInfo from './MediaCriteriaInfo/MediaCriteriaInfo';
import MediaRemoveIcon from '../../../assets/icons/media-delete.svg';
import draftService from '../../../services/drafts.service';
import { UserDetailsProvider } from '../../../store/user-details-context';
import {
  PENDING,
  PROCESSING,
  SUBMITTED,
  TRUE,
  FALSE,
  UGC_REPORTER,
} from '../../../Constants/Constants';
import reportService from '../../../services/report.service';
import MediaPreview from '../../Drafts/MediaPreview';
import { EnvironmentProvider } from '../../../store/firebase-context';
import noImage from '../../../assets/icons/no-image.svg';
import noImageDark from '../../../assets/icons/no-image-dark.svg';
import AreaPicker from '../../area-picker/AreaPicker';
import ButtonLoader from '../../buttonLoader/ButtonLoader';
/**
 * ReportForm
 *  props
 *    - categories - main topics
 *    - isFromDraft - boolean to decide its from drafts flow
 *    - data - drafts data
 *    - goToDrafts - callback to navigate back to drafts
 */

const formError = {
  topic: false,
  title: false,
  media: false,
  location: false,
};
function ReportForm({
  configCategories,
  isFromDraft,
  data,
  goToDrafts,
  district,
  point_lat,
  point_long,
  stopFetchDistrict,
}) {
  const { environment } = useContext(EnvironmentProvider);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [err, setErr] = useState(formError);
  const { state: userState } = useContext(UserDetailsProvider);
  const isMobile = MaxWidth(viewPort.mobileMax);
  const isTablet = MaxWidth(viewPort.tablet);
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const newsSessionID = userState?.sessionId;
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isDTVerifiedReporter =
    userState?.userDetails.userType === UGC_REPORTER ? TRUE : FALSE;

  const [categories, setCategories] = useState();
  const [isMediaPickerOpen, setIsMediaPickerOpen] = useState(false);
  const [isAnonymousPost, setIsAnonymousPost] = useState(false);
  const [medias, setMedias] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [mediaUnderPreview, setMediaUnderPreview] = useState();

  const [showPublishNowConfirmModal, setShowPublishNowConfirmModal] =
    useState(false);
  const [showMediaRequiredModal, setShowMediaRequiredModal] = useState(false);
  const [showLocationInstructionModal, setShowLocationInstructionModal] =
    useState(false);
  const [showPublishLaterConfirmModal, setShowPublishLaterConfirmModal] =
    useState(false);
  const [showPublishNowSuccessModal, setShowPublishNowSuccessModal] =
    useState(false);
  const [showPublishLaterSuccessModal, setShowPublishLaterSuccessModal] =
    useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showDraftsLimitFullModal, setShowDraftsLimitFullModal] =
    useState(false);
  const [showMediaCriteriaInfo, setShowMediaCriteriaInfo] = useState(false);
  const [isMediaPreviewOpen, setIsMediaPreviewOpen] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const [showMediaProcessingModal, setShowMediaProcessingModal] =
    useState(false);

  const [lat, setLat] = useState(undefined);
  const [long, setLong] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [localDistrict, setLocalDistrict] = useState('');
  const [saveToDraftLoader, setSaveToDraftLoader] = useState(false);
  const [publishReportLoader, setPublishReportLoader] = useState(false);
  const [isSaveDraftLoading, setIsSaveDraftLoading] = useState(false);

  useEffect(() => {
    if (!localDistrict && district) {
      setLocalDistrict(district);
    }
    if (!lat && !long && point_lat && point_long) {
      setLat(point_lat);
      setLong(point_long);
    }
  }, [district, point_lat, point_long]);

  useEffect(() => {
    if (configCategories) {
      setCategories(configCategories);
    }
  }, [configCategories, isDTVerifiedReporter]);

  /**
   * Side Effect to stop reload button click in case user is editing drafts
   * to show warning modals
   */
  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      if (isDirty) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('popstate', handlePopState);
      }
    };
  }, [isDirty]);

  // side effect to assign values for fields when edit drafts
  useEffect(() => {
    if (isFromDraft && data && categories) {
      const selectedCategoryRecord = categories?.find(
        (category) => category.catId?.toString() === data.categoryID,
      );

      setSelectedTopic({ ...selectedCategoryRecord } || '');
      setTitle(data?.heading || '');
      setDescription(data?.story || '');
      setMedias(data?.media?.length ? data?.media : []);
    }
  }, [isFromDraft, data, categories]);

  const handlePopState = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDiscardModal(true);
  };

  const handleBeforeUnload = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.returnValue = '';
    setShowDiscardModal(true);
  };

  /**
   * Change Handler for Topic field
   * @param {object} event event object
   */
  const onTopicChange = (event) => {
    setIsDirty(true);
    const selectedValue = event.target.value;
    const selectedCategoryRecord = categories?.find(
      (category) =>
        selectedValue === category.name ||
        selectedValue === category.name_tamil,
    );
    setSelectedTopic({
      ...selectedCategoryRecord,
    });
    onChangeHandler('topic', !!selectedCategoryRecord);
  };

  /**
   * Change hander for Title field
   * @param {object} e event object
   */
  const onTitleChange = (e) => {
    setIsDirty(true);
    setTitle(e.target.value);
    onChangeHandler('title', !!e.target.value);
  };

  /**
   * Change Handler for Description field
   * @param {string} value html string
   */
  const onDescriptionChange = (value) => {
    setIsDirty(true);
    setDescription(value);
  };

  /**
   * Handler to open the Media Picker
   */
  const onAddMedia = () => {
    setIsMediaPickerOpen(true);
  };

  /**
   * Handler to close the Media Picker
   */
  const onMediaPickerClose = () => {
    setIsMediaPickerOpen(false);
  };

  /**
   * Handler to pick media
   * @param {object} medias media object
   */
  const onMediaPicked = (pickedMedias) => {
    setIsDirty(true);

    const items = [];

    for (let oldMedia of medias) {
      if (oldMedia?.isDT) {
        const availableObj = pickedMedias.find(
          (media) => media.mediaId === oldMedia.mediaId,
        );

        if (availableObj) {
          items.push(availableObj);
        }
      } else {
        items.push(oldMedia);
      }
    }

    for (let newPicked of pickedMedias) {
      const notAvailableMedia = medias
        .filter((item) => item.isDT)
        .find((oldMedia) => newPicked?.mediaId === oldMedia?.mediaId);

      if (!notAvailableMedia) {
        items.push(newPicked);
      }
    }

    setMedias([...items]);
  };

  /**
   * onclick of publish now button we will check valid fild are validate or not and then show error according to that
   */
  const onPublishNowClick = () => {
    if (localDistrict && title && selectedTopic && medias.length) {
      if (medias.some((item) => !item?.isMediaProcessed)) {
        setShowMediaProcessingModal(true);
      } else {
        setShowPublishNowConfirmModal(true);
      }
    } else {
      setErr({
        ...err,
        location: !localDistrict,
        topic: !selectedTopic,
        title: !title,
      });
      if (!medias.length) {
        setShowMediaRequiredModal(true);
      }
    }
  };

  /**
   * Handler to save as drafts
   * onclick of save as drafts, we first check whether Title and Topic are validated or not
   * if Topic are validated are validated and if we the drafts are not full then we save the report to drafts, else we show an alert saying drafts are full
   * if Title and Topic are not validated, then we show the error message.
   */
  const handlePublishLater = async () => {
    if (title && selectedTopic) {
      if (!isFromDraft) {
        try {
          setIsSaveDraftLoading(true);
          const stats = await reportService.getUserStats({
            sessionId: newsSessionID,
          });
          if (stats?.stories_draft >= environment.draft_max_count) {
            setShowPublishLaterConfirmModal(false);
            setShowDraftsLimitFullModal(true);
            setIsSaveDraftLoading(false);
          } else {
            publishDrafts(PENDING);
          }
        } catch (error) {
          setIsSaveDraftLoading(false);
        }
      } else {
        setIsSaveDraftLoading(true);
        publishDrafts(PENDING);
      }
    } else {
      setErr({
        ...err,
        topic: !selectedTopic,
        title: !title,
      });
    }
  };

  /**
   * Handler to publish now
   */
  const handlePublishNow = () => {
    let videos = [];
    for (const mediaObj of medias) {
      if (mediaObj?.mediaType === 'VIDEO') {
        videos.push(mediaObj);
      }
    }
    if (videos?.length) {
      publishDrafts(PROCESSING, videos);
    } else {
      publishDrafts(SUBMITTED);
    }
  };

  /**
   * Create Feed handler
   * @param {string} draftState draft state
   * @param {object} videoTypeMedias - video media object
   */
  const publishDrafts = async (draftState, videoTypeMedias) => {
    if (draftState === PENDING) setIsSaveDraftLoading(true);
    try {
      const req = {
        sessionId: newsSessionID,
        heading: title,
        story: description,
        location: localDistrict,
        point_lat: lat,
        point_long: long,
        categoryId: selectedTopic?.catId,
        news_type:
          isDTVerifiedReporter === TRUE || isDTVerifiedReporter === true
            ? 'STORY'
            : 'CITIZEN_FEED',
        mediaIds: medias?.map(
          ({
            mediaId,
            thumbnail,
            mediaType,
            expiry,
            duration,
            location,
            created_date,
            isMediaProcessed,
            isDT,
            video_orientation,
          }) => ({
            mediaId,
            thumbnail,
            mediaType,
            expiry,
            duration,
            location,
            created_date,
            isMediaProcessed,
            isDT,
            video_orientation,
          }),
        ),
        state: draftState,
        anonymous: isAnonymousPost,
      };

      if (isFromDraft) {
        req['feedId'] = data.feedID;
      }

      const postAttachDateMediaIds = medias.filter(
        (mediaObj) => !!!mediaObj?.post_attach_date && mediaObj?.isDT,
      );

      if (postAttachDateMediaIds?.length) {
        await reportService.updatePostAttachDate({
          ID: postAttachDateMediaIds.map((obj) => obj.id),
        });
      }
      if (draftState === PENDING) {
        setSaveToDraftLoader(true);
      }
      if (draftState === SUBMITTED) {
        setPublishReportLoader(true);
      }

      const resp = await draftService.createDraft(req);

      if (draftState === PENDING) {
        setSaveToDraftLoader(false);
        setShowPublishLaterConfirmModal(false);
        resetReportForm();
        setShowPublishLaterSuccessModal(true);
        setIsSaveDraftLoading(false);
      } else if (draftState === SUBMITTED) {
        setPublishReportLoader(false);
        setShowPublishNowConfirmModal(false);
        resetReportForm();
        setShowPublishNowSuccessModal(true);
      } else if (draftState === PROCESSING) {
        try {
          const [{ mediaId, location, duration }] = videoTypeMedias;
          const videoResponse = await draftService.uploadToJWP({
            mediaUrl: mediaId,
            feedId: `${resp.feedId}`,
            location,
            duration,
            name: mediaId?.split('/')[1],
          });
          if (!videoResponse.statusCode) {
            setShowPublishNowConfirmModal(false);
            resetReportForm();
            setShowPublishNowSuccessModal(true);
          }
        } catch (error) {}
      }
    } catch (error) {
      setIsSaveDraftLoading(false);
    }
  };

  /**
   * Handler to remove attached media
   * @param {object} mediaObj media object
   */
  const onRemoveMedia = (mediaObj) => {
    setIsDirty(true);
    const filteredMedias = medias?.filter(
      (obj) => obj.mediaId !== mediaObj.mediaId,
    );
    setMedias([...filteredMedias]);
  };

  /**
   * Handler for Media Preivew
   * @param {object} media media object
   */
  const handleMediaPreview = (media) => {
    setMediaUnderPreview(media);
    setIsMediaPreviewOpen(true);
  };

  /**
   * Resets Form
   */
  const resetReportForm = () => {
    setSelectedTopic('');
    setTitle('');
    setDescription('');
    setMedias([]);
    setIsAnonymousPost(false);
    setIsDirty(false);
  };

  const onChangeHandler = (input, valid) => {
    setErr({ ...err, [input]: !valid });
  };

  const handleSelectedArea = (area) => {
    setLat(area?.point_lat);
    setLong(area?.point_long);
    setLocalDistrict(area?.name);
  };
  const openAreaPicker = () => {
    stopFetchDistrict();
    setIsOpen(true);
    setShowLocationInstructionModal(false);
  };

  return (
    <>
      <Box
        component='form'
        sx={{
          padding: isMobile ? '1.875rem 0' : '2.5rem 0',
          width: isMobile ? '100%' : '50%',
          margin: '0 auto',
        }}
      >
        <TextField
          select
          error={err.topic}
          helperText={err.topic ? t('please-select-topic') : ''}
          variant='outlined'
          placeholder={t('topic')}
          label={t('topic')}
          value={
            (isTamil ? selectedTopic?.name_tamil : selectedTopic?.name) || ''
          }
          sx={{ width: '100%', marginBottom: '1.25rem' }}
          onChange={onTopicChange}
          InputLabelProps={{
            shrink:
              (isTamil ? selectedTopic?.name_tamil : selectedTopic?.name) || '',
          }}
        >
          {categories?.map((category) => (
            <MenuItem
              key={category.catId}
              value={isTamil ? category.name_tamil : category.name}
            >
              {isTamil ? category.name_tamil : category.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          error={err.title}
          helperText={err.title ? t('please-select-title') : ''}
          sx={{ width: '100%', marginBottom: '1.25rem' }}
          label={t('title')}
          value={title}
          onChange={onTitleChange}
        ></TextField>

        <RichTextEditor
          key={isTamil} //TODO: workaround
          style={{ marginBottom: '1.875rem' }}
          isDarkMode={isDarkMode}
          value={description}
          onChange={onDescriptionChange}
          placeholder={t('description')}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Typography sx={{ fontWeight: 600 }}>{`${t('media')}`}</Typography>
            <Typography
              sx={{ marginRight: '0.625rem' }}
            >{`(${medias?.length}/5)`}</Typography>

            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setShowMediaCriteriaInfo(true);
              }}
            >
              <img src={isDarkMode ? InfoIconDark : InfoIcon} alt='info icon' />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={openAreaPicker}
          >
            <img
              src={
                localDistrict
                  ? isDarkMode
                    ? LocationBlueIconDark
                    : LocationIcon
                  : err.location
                  ? LocationDisabledIcon
                  : LocationDisabledDarkIcon
              }
              alt='info icon'
              style={{ marginRight: '0.313rem', width: '1.5rem' }}
            />

            <Typography
              color={
                localDistrict
                  ? 'primary'
                  : err.location
                  ? 'error'
                  : (theme) => theme.palette.grey[400]
              }
              sx={{ fontWeight: 600, textTransform: 'uppercase' }}
            >
              {localDistrict ? localDistrict : t('not-enabled')}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', marginBottom: '1.875rem' }}>
          <Grid container spacing={isMobile ? 2 : 1.3}>
            <Grid item xs={4} md={2}>
              <Box
                sx={{
                  borderRadius: '10px',
                  width: isMobile ? '5.625rem' : '5.938rem',
                  height: isMobile ? '5.625rem' : '5.938rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid',
                  cursor: 'pointer',
                  borderColor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[300]
                      : theme.palette.grey[200],
                  background: (theme) =>
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[100]
                      : '#FFFFFF',
                }}
                onClick={onAddMedia}
              >
                <img
                  src={isDarkMode ? plusDarkIcon : plusIcon}
                  sx={{ marginBottom: '0.313rem' }}
                  alt='add media'
                />

                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '0.875rem',
                    textTransform: 'uppercase',
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {t('add-media')}
                </Typography>
              </Box>
            </Grid>

            {medias?.map((mediaObj) => (
              <Grid key={mediaObj.mediaId} item xs={4} md={2}>
                <Box
                  sx={{
                    width: isMobile ? '5.625rem' : '5.938rem',
                    height: isMobile ? '5.625rem' : '5.938rem',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    position: 'relative',
                    border: '1px solid #484848',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (mediaObj?.isMediaProcessed)
                      handleMediaPreview(mediaObj);
                  }}
                >
                  <img
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                    loading='lazy'
                    alt='data'
                    src={
                      mediaObj?.isMediaProcessed
                        ? mediaObj?.presigned_thumbnail
                        : isDarkMode
                        ? noImageDark
                        : noImage
                    }
                  />

                  {mediaObj?.mediaType === 'VIDEO' && mediaObj?.duration && (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: '0.313rem',
                        bottom: '0.313rem',
                        color: '#FFFFFF',
                        background: (theme) =>
                          theme.palette.mode === 'dark'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[600],
                        padding: '0.125rem 0.5rem',
                        borderRadius: '0.75rem',
                        fontWeight: '400',
                        fontSize: '0.75rem',
                        lineHeight: '0.938rem',
                      }}
                    >
                      {new Date(mediaObj?.duration * 1000)
                        .toISOString()
                        .slice(14, 19)}
                    </Box>
                  )}

                  <Box
                    sx={{
                      position: 'absolute',
                      top: '0.313rem',
                      right: '0.313rem',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveMedia(mediaObj);
                    }}
                  >
                    <img src={MediaRemoveIcon} alt='media remove' />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'stretch' : 'center',
          }}
        >
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAnonymousPost}
                    onChange={(_, checked) => {
                      setIsDirty(true);
                      setIsAnonymousPost(checked);
                    }}
                  />
                }
                label={t('post-anonymously')}
              />
            </FormGroup>
          </Box>

          <Box
            sx={{
              display: isMobile ? 'block' : 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'flex-end',
              padding: '1.875rem 0',
            }}
          >
            <Button
              color='primary'
              variant='contained'
              sx={
                isMobile
                  ? {
                      marginBottom: '1.25rem',
                      height: '2.75rem',
                    }
                  : {
                      marginBottom: 0,
                      height: isTablet ? '5.25rem' : '2.75rem',
                      width: isTablet ? '5.25rem' : '8.9rem',
                    }
              }
              onClick={onPublishNowClick}
              fullWidth={isMobile}
            >
              {t('publish-now')}
            </Button>
            <ButtonLoader
              color='primary'
              variant='outlined'
              sx={
                isMobile
                  ? {
                      marginRight: 0,
                      height: '2.75rem',
                    }
                  : {
                      marginRight: '0.625rem',
                      height: isTablet ? '5.25rem' : '2.75rem',
                      width: isTablet ? '5.25rem' : '10rem',
                    }
              }
              onClick={handlePublishLater}
              loading={isSaveDraftLoading}
              fullWidth={isMobile}
            >
              {t('save-as-drafts')}
            </ButtonLoader>
          </Box>
        </Box>

        {isMediaPickerOpen && (
          <MediaPicker
            isOpen={isMediaPickerOpen}
            onClose={onMediaPickerClose}
            onPicked={onMediaPicked}
            availableMedias={medias}
            nonDTMedias={medias.filter((media) => !media.isDT)}
          />
        )}
      </Box>

      {showPublishLaterConfirmModal && (
        <DialogBox
          isOpen={showPublishLaterConfirmModal}
          onClose={() => setShowPublishLaterConfirmModal(false)}
          title={t('publish-later')}
          content={t('publish-later-info')}
          primaryButtonAction={handlePublishLater}
          primaryButtonText={t('save-to-my-drafts')}
          primaryLoading={saveToDraftLoader}
          secondaryButtonAction={() => setShowPublishLaterConfirmModal(false)}
          secondaryButtonText={t('cancel')}
        />
      )}

      {showPublishLaterSuccessModal && (
        <DialogBox
          isOpen={showPublishLaterSuccessModal}
          onClose={() => setShowPublishLaterSuccessModal(false)}
          title={t('SAVE_DRAFT')}
          heading={t('saved')}
          content={t('draft-is-successfully-saved')}
          primaryButtonAction={() => {
            setShowPublishLaterSuccessModal(false);
            if (isFromDraft) {
              goToDrafts();
            } else {
              navigate('/drafts');
            }
          }}
          primaryButtonText={t('go-to-my-drafts')}
        />
      )}

      {showDraftsLimitFullModal && (
        <DialogBox
          isOpen={showDraftsLimitFullModal}
          onClose={() => setShowDraftsLimitFullModal(false)}
          title={t('SAVE_DRAFT')}
          heading={t('failed')}
          content={t(
            'sorry-your-draft-limits-is-full-delete-previous-drafts-to-add-new-drafts',
          )}
          primaryButtonAction={() => {
            setShowDraftsLimitFullModal(false);
            if (isFromDraft) {
              goToDrafts();
            } else {
              navigate('/drafts');
            }
          }}
          primaryButtonText={t('go-to-my-drafts')}
          secondaryButtonAction={() => setShowDraftsLimitFullModal(false)}
          secondaryButtonText={t('cancel')}
        />
      )}

      {showPublishNowConfirmModal && (
        <DialogBox
          isOpen={showPublishNowConfirmModal}
          onClose={() => setShowPublishNowConfirmModal(false)}
          title={t('publish-report')}
          heading={t('confirm')}
          content={t('are-you-sure-you-want-to-publish-news')}
          primaryButtonAction={handlePublishNow}
          primaryButtonText={t('publish')}
          primaryLoading={publishReportLoader}
          secondaryButtonAction={() => setShowPublishNowConfirmModal(false)}
          secondaryButtonText={t('cancel')}
        />
      )}

      {showMediaRequiredModal && (
        <DialogBox
          isOpen={showMediaRequiredModal}
          onClose={() => setShowMediaRequiredModal(false)}
          title={t('publish-now')}
          heading={t('attach-media')}
          content={t(
            'please-attach-the-supporting-image-or-video-before-publishing',
          )}
          primaryButtonAction={() => setShowMediaRequiredModal(false)}
          primaryButtonText={t('Ok')}
        />
      )}

      {showLocationInstructionModal && (
        <DialogBox
          isOpen={showLocationInstructionModal}
          onClose={() => setShowLocationInstructionModal(false)}
          title={t('enable-location-service')}
          heading={t('enable-location-service')}
          content={t(
            'please-Enable-location-service-in-your-browser-setting-to-enable-location-access',
          )}
          primaryButtonAction={() => setShowLocationInstructionModal(false)}
          primaryButtonText={t('Ok')}
        />
      )}

      {showPublishNowSuccessModal && (
        <DialogBox
          isOpen={showPublishNowSuccessModal}
          onClose={() => {
            navigate('/');
            setShowPublishNowSuccessModal(false);
          }}
          title={t('report-published')}
          icon={CompletedIcon}
          heading={t('submitted')}
          content={t('awesome-your-report-has-been-submitted')}
          // secondaryButtonAction={() => navigate('/citizen-reports')}
          // secondaryButtonText={t('CITIZEN_REPORTER_PAGE')}
        />
      )}

      {showDiscardModal && (
        <DialogBox
          isOpen={showDiscardModal}
          onClose={() => setShowDiscardModal(false)}
          title={t('discard-edits')}
          content={t('discard-edits-info')}
          primaryButtonAction={() => {
            setShowDiscardModal(false);
            if (isFromDraft) {
              goToDrafts();
            } else {
              resetReportForm();
            }
          }}
          primaryButtonText={t('discard')}
          secondaryButtonAction={() => {
            setShowDiscardModal(false);
            handlePublishLater();
          }}
          secondaryButtonText={t('SAVE_DRAFT')}
          tertiaryButtonAction={() => setShowDiscardModal(false)}
          tertiaryButtonText={t('cancel')}
        />
      )}

      {showMediaCriteriaInfo && (
        <MediaCriteriaInfo
          isOpen={showMediaCriteriaInfo}
          onClose={() => {
            setShowMediaCriteriaInfo(false);
          }}
        />
      )}

      {isMediaPreviewOpen && (
        <MediaPreview
          previewMedia={mediaUnderPreview}
          isMediaPreviewVisible={isMediaPreviewOpen}
          onClose={() => {
            setIsMediaPreviewOpen(false);
          }}
        />
      )}

      {showMediaProcessingModal && (
        <DialogBox
          isOpen={showMediaProcessingModal}
          onClose={() => setShowMediaProcessingModal(false)}
          title={t('publish-report')}
          content={t('your-uploads-are-still-processing')}
          primaryButtonAction={() => setShowMediaProcessingModal(false)}
          primaryButtonText={t('ok')}
        />
      )}
      {isOpen && (
        <AreaPicker
          open={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          updateSelectedArea={handleSelectedArea}
        />
      )}
    </>
  );
}

ReportForm.propTypes = {
  categories: PropTypes.object.isRequired,
  isFromDraft: PropTypes.bool,
  data: PropTypes.object,
  goToDrafts: PropTypes.func,
};

export default ReportForm;
