import graphQLRequest from './graphql';
import {
  createDTUserProfile,
  updateDTUserProfile,
  manageDTUserFollow,
} from './graphql/mutations';
import { getDTUserProfile, getDTUserFollow } from './graphql/queries';
import { Storage } from 'aws-amplify';

/**
 * Make api call for creating user profile
 * @param {object} data
 * @returns promise
 */
const createUserProfile = (data) => {
  return graphQLRequest(createDTUserProfile, {
    input: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for updating user profile
 * @param {object} data
 * @returns promise
 */
const updateUserProfile = (data) => {
  return graphQLRequest(updateDTUserProfile, {
    input: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for updating user profile
 * @param {object} data
 * @returns promise
 */
const updateUserFollow = (data) => {
  return graphQLRequest(manageDTUserFollow, {
    input: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving user profile
 * @param {object} data
 * @returns promise
 */
const getUserProfile = (data) => {
  return graphQLRequest(getDTUserProfile, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * upload profile image of the user
 * @param {object} object object with user id and profile image file
 * @returns promise
 */
const uploadProfileImage = ({ userId, image }) =>
  Storage.put(`profile/${userId}`, image);
const profileUrl = (key) => Storage.getUrl(key);

/**
 * fetch profile image of the user
 * @returns promise
 */
const getProfileImage = (imageUrl) => Storage.get(`profile/${imageUrl}`);

/**
 * delete profile image of the user
 * @param {object} object object with user id and profile image file
 * @returns promise
 */
const deleteProfileImage = ({ userId, image }) =>
  Storage.remove(`profile/${userId}`, image);

/**
 * Make api call for getting the follow list
 * @param {object} data
 * @returns promise
 */
const getUserFollow = (data) => {
  return graphQLRequest(getDTUserFollow, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const userProfileService = {
  createUserProfile,
  updateUserProfile,
  getUserProfile,
  uploadProfileImage,
  getProfileImage,
  deleteProfileImage,
  profileUrl,
  getUserFollow,
  updateUserFollow,
};

export default userProfileService;
