import React, { useState, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import noImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import labelWhiteIcon from '../../assets/icons/label-white.svg';
import editIcon from '../../assets/icons/edit.svg';
import deleteIcon from '../../assets/icons/Delete.svg';
import editIconDark from '../../assets/icons/editDark.svg';
import deleteIconDark from '../../assets/icons/delete-dark.svg';
import format from 'date-fns/format';
import add from 'date-fns/add';
import {
  ONE,
  MORE_LABEL,
  NO_TITLE,
  NO_LOCATION,
  FIVE,
  THIRTY,
  DRAFTS_TIME_FORMAT,
  DOT,
  NO_DATE,
  PENDING,
} from '../../Constants/Constants';
import draftService from '../../services/drafts.service';
import { useTranslation } from 'react-i18next';
import { UserDetailsProvider } from '../../store/user-details-context';
import DialogBox from '../DialogBox/DialogBox';
import parse from 'html-react-parser';

/**
 * DraftsNewsCard Component
 */
const DraftsNewsCard = (props) => {
  const {
    feedID,
    heading,
    story,
    media,
    location,
    categoryID,
    categoryName,
    date,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { state: userState } = useContext(UserDetailsProvider);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [firstImage] = media ? media : isDarkMode ? [noImageDark] : [noImage];
  const imageCount = media ? media.length - 1 : 0;
  const newsSessionID = userState?.sessionId;
  const { t } = useTranslation();

  /**
   * Converting the UTC time in IST by adding +5:30 hours and then formatting to display date and time
   */
  const draftDateIST = add(new Date(date), {
    hours: FIVE,
    minutes: THIRTY,
  });
  const draftDate = format(draftDateIST, DRAFTS_TIME_FORMAT);

  /**
   * Delete API to delete the selected draft corresponding to its feedId
   */
  const handleDraftDelete = async (event) => {
    event.stopPropagation();
    try {
      const response = await draftService.deleteDraft({
        feedId: feedID,
        sessionId: newsSessionID,
      });
      response && props.onDelete();
    } catch (error) {}
  };

  /**
   * Passing the data to be edited to EditDrafts Component through its parent Drafts via props
   */
  const handleDraftEdit = async (event) => {
    event.stopPropagation();

    try {
      const response = await draftService.draftList({
        sessionId: newsSessionID,
        feedIds: feedID,
        state: PENDING,
      });
      const media = response.news[0]?.mediaIds || [];
      const dataToBeEdited = {
        feedID,
        heading,
        story,
        media,
        location,
        categoryID,
        categoryName,
      };
      props.onEdit(dataToBeEdited);
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        maxWidth: '78.75rem',
        width: '100%',
        marginBottom: '1rem',
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: '0.625rem',
        display: 'flex',
        background: isDarkMode
          ? (theme) => theme.palette.grey[200]
          : (theme) => theme.palette.white[100],
        cursor: 'pointer',
        '@media screen and (max-width: 600px)': {
          flexDirection: 'column',
          maxWidth: '26rem',
          maxHeight: 'unset',
          padding: '1.25rem',
          alignItems: 'center',
        },
        '@media screen and (max-width: 479px)': {
          maxWidth: '20.625rem',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '15.875rem',
          maxHeight: '9.5rem',
          width: '100%',
          height: '100%',
          margin: '1.25rem',
          position: 'relative',
          '@media screen and (max-width: 600px)': {
            margin: '0 1.25rem 0 1.25rem',
            maxWidth: '23.5rem',
            maxHeight: '15rem',
          },
          '@media screen and (max-width: 479px)': {
            maxWidth: '18.125rem',
            maxHeight: '10.25rem',
          },
        }}
      >
        <Box
          component='img'
          src={
            firstImage?.presigned_thumbnail
              ? firstImage?.presigned_thumbnail
              : isDarkMode
              ? noImageDark
              : noImage
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = isDarkMode ? noImageDark : noImage;
          }}
          alt='image'
          sx={{
            objectFit: 'cover',
            maxHeight: '9.5rem',
            width: '100%',
            height: '100%',
            borderRadius: '0.5rem',
            '@media screen and (max-width: 600px)': {
              maxHeight: '15rem',
            },
            '@media screen and (max-width: 479px)': {
              maxWidth: '18.125rem',
              maxHeight: '10.25rem',
            },
          }}
        />

        {imageCount > ONE && (
          <Box
            sx={{
              display: 'flex',
              width: '5.5625rem',
              height: '2.125rem',
              borderRadius: '0.5rem',
              backgroundColor: isDarkMode
                ? (theme) => theme.palette.grey[100]
                : (theme) => theme.palette.grey[600],
              position: 'absolute',
              bottom: '0.625rem',
              right: '0.625rem',
              justifyContent: 'space-evenly',
              alignItems: 'baseline',
            }}
          >
            <Box aria-label='image-label'>
              <img src={labelWhiteIcon} alt='more media'></img>
            </Box>
            <Typography
              sx={{
                color: (theme) => theme.palette.white[100],
                paddingTop: '0.3rem',
              }}
            >
              {imageCount} {MORE_LABEL}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: '59rem',
          width: '100%',
          margin: '1.25rem 1.25rem 1.25rem 0',
          '@media screen and (max-width: 600px)': {
            maxWidth: '23.75rem',
            minHeight: '14rem',
            justifyContent: 'space-between',
            margin: 'unset',
          },
          '@media screen and (max-width: 479px)': {
            maxWidth: '18.125rem',
            minHeight: '10.25rem',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-around',
            '@media screen and (max-width: 600px)': {
              justifyContent: 'space-around',
              padding: 'unset',
              minHeight: '9.625rem',
            },
            '@media screen and (max-width: 479px)': {
              minHeight: '8rem',
            },
          }}
        >
          <Typography
            variant='h6'
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.grey[600],
              fontWeight: 700,
              marginBottom: '0.25rem',
            }}
          >
            {heading ? parse(heading) : NO_TITLE}
          </Typography>

          <Typography
            variant='caption'
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '0.3125rem',
              color: isDarkMode
                ? (theme) => theme.palette.grey[500]
                : (theme) => theme.palette.white[400],
            }}
          >
            {location ? location : NO_LOCATION}
            {DOT}
            {draftDate ? draftDate : NO_DATE}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: isDarkMode
              ? (theme) => theme.palette.grey[100]
              : '#F4F4F4',
            borderRadius: '0.625rem',
            minHeight: '3.375rem',
            '@media screen and (max-width: 1199px)': {
              minHeight: '3rem',
            },
            '@media screen and (max-width: 600px)': {
              minHeight: '3.375rem',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginLeft: '1rem',
              maxWidth: '12rem',
              width: '100%',
              '@media screen and (max-width: 600px)': {
                flexDirection: 'row-reverse',
                margin: '0 0.75rem 0 1rem',
                maxWidth: 'unset',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '4rem',
                width: '100%',
              }}
            >
              <Box
                component='img'
                src={isDarkMode ? editIconDark : editIcon}
                alt='edit-icon'
                onClick={handleDraftEdit}
              ></Box>
              <Box
                component='img'
                src={isDarkMode ? deleteIconDark : deleteIcon}
                alt='delete-icon'
                onClick={() => setShowDeleteModal(true)}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {showDeleteModal && (
        <DialogBox
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          title={t('delete-draft')}
          content={t('are-you-sure-you-want-to-delete')}
          primaryButtonAction={handleDraftDelete}
          primaryButtonText={t('delete')}
          secondaryButtonAction={() => setShowDeleteModal(false)}
          secondaryButtonText={t('cancel')}
        />
      )}
    </Box>
  );
};

export default DraftsNewsCard;
