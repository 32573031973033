import { SIGN_IN_WITH_APPLE } from '../../Constants/Constants';

export const ModifyData = (props) => {
  const {
    userId,
    firstName,
    lastName,
    followersCount,
    isOnboardedReporter,
    userName,
    followingCount,
    userType,
  } = props;
  let userDetails = null;
  try {
    userDetails = JSON.parse(props.userDetails);
  } catch {}

  if (userName) {
    if (userDetails) {
      userDetails['userName'] = userName;
    } else {
      userDetails = {};
      userDetails['userName'] = userName;
    }
  }
  const following = JSON.parse(props.following);
  const preferences = JSON.parse(props.preferences);
  const citizenJournalist = JSON.parse(props.citizenJournalist);
  const parsedObject = {
    userDetails,
    following,
    preferences,
    followersCount,
    userId,
    firstName,
    lastName,
    citizenJournalist,
    isOnboardedReporter,
    followingCount,
    userType,
  };
  return parsedObject;
};

export const getUserName = (userDetails) => {
  let uName;
  if (userDetails?.attributes && userDetails?.attributes['custom:user_name']) {
    uName = userDetails?.attributes['custom:user_name'];
  } else if (userDetails?.attributes?.name) {
    uName = userDetails?.attributes?.name;
  } else if (userDetails?.username?.match(SIGN_IN_WITH_APPLE)) {
    uName = userDetails?.attributes?.email;
  } else if (
    userDetails?.attributes &&
    userDetails?.attributes['custom:user_name']
  ) {
    uName = userDetails?.attributes['custom:user_name'];
  } else {
    uName = userDetails?.username;
  }
  return uName;
};

/**
 * This function converts the props i.e. userId and searchHistory into javascript objects
 * @param {userId} userId of the user
 * @param {searchHistory} searchHistory of the user
 * @returns object
 */
export const ModifySearchData = ({ userId, searchHistory }) => {
  searchHistory = JSON.parse(searchHistory);
  const parsedObject = {
    userId,
    searchHistory,
  };
  return parsedObject;
};
