import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DOT } from '../../../Constants/Constants';
import reportersLogo from '../../../assets/icons/avatar-medium.svg';
import { Typography, Box, Avatar, Menu, ListItem } from '@mui/material';
import enterIcon from '../../../assets/icons/enterIcon.svg';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { TIME_UNITS } from '../../../Constants/Constants';
import commentService from '../../../services/comments.service';
import { UserDetailsProvider } from '../../../store/user-details-context';
import { AppContext } from '../../../store/app-context';
import inactiveThumbUpIcon from '../../../assets/icons/inactive-thumb-up.svg';
import editCommentIcon from '../../../assets/icons/edit-comment.svg';
import inactiveThumbDownIcon from '../../../assets/icons/inactive-thumb-down.svg';
import deleteCommentIcon from '../../../assets/icons/delete-comment.svg';
import BlockUserIcon from '../../../assets/icons/block-user-icon.svg';
import moreHorizontal from '../../../assets/icons/more-horizontal-icon.svg';
import inactiveThumbDownIconDark from '../../../assets/icons/inactive-thumb-down-white.svg';
import CommentsModal from '../../Modal/CommentsModal';
import { EnvironmentProvider } from '../../../store/firebase-context';
import {
  MinWidth,
  MaxWidth,
  viewPort,
} from '../../../responsive/viewport.util';
import activeHeartDarkMode from '../../../assets/icons/active-thumb-up.svg';
import activeHeartIconlighMode from '../../../assets/icons/active-thumb-light.svg';
import inactiveThumbUpIconDark from '../../../assets/icons/inactive-thumb-up-white.svg';
import activeDislikeIconDarkMode from '../../../assets/icons/activeHeartDislikeIconDarkMode.svg';
import activeDislikeIconLightMode from '../../../assets/icons/activeHeartDislikeIconLightMode.svg';

/**
 *  Reply comments component for  listing replies
 * @param {*} commentDetail is the detail of comment which are getting replies
 * @returns
 */

export default function ReplyComment({ commentDetail, onCommentReply }) {
  const { state: userState } = useContext(UserDetailsProvider);
  const { dispatch: appCtxDispatch } = useContext(AppContext);
  const isAuthenticated = userState?.isAuthenticated;
  const userID = userState?.userDetails?.userId;
  const [repliesDetail, setRepliesDetail] = useState([]);
  const [activeReply, setActiveReply] = useState();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [fetchCommentsLimit, setFetchCommentsLimit] = useState(5);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [showReplyDeleteCommentsModal, setShowReplyDeleteCommentsModal] =
    useState(false);
  const [showEditReplyCommentsModal, setShowEditReplyCommentsModal] =
    useState(false);
  const [replyText, setReplyText] = useState('');
  const open = Boolean(anchorEl);
  const { environment } = useContext(EnvironmentProvider);
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  /**
   * handling more event option for repies event
   * @param {*} event
   */
  const handleMoreOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * for closing menu popover
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReplyDeleteComments = () => {
    setShowReplyDeleteCommentsModal(true);
    handleClose();
  };

  const handleEditReplyComments = (reply) => {
    setShowEditReplyCommentsModal(true);
    setReplyText(reply.text);
    handleClose();
  };

  useEffect(() => {
    fetchCommentreplyies();
  }, [commentDetail, fetchCommentsLimit]);

  /**
   * for fetching repies of comments listed
   */
  const fetchCommentreplyies = async () => {
    const payload = {
      comment_id: commentDetail.id,
      limit: fetchCommentsLimit,
    };

    try {
      const response = await commentService.fetchReplyComment(
        payload,
        isAuthenticated,
      );

      if (response && response.data) {
        setRepliesDetail(response.data);
      } else {
        setRepliesDetail([]);
      }
    } catch (error) {}
  };

  const handleReplyDeleteComment = async (replyid) => {
    try {
      const response = await commentService.replyDeleteComment({
        reply_id: replyid,
      });
      if (response?.message) {
        fetchCommentreplyies();
      }
      setActiveReply(null);
      setShowReplyDeleteCommentsModal(false);
    } catch (error) {}
  };

  const handleEditReplyComment = async (replyid) => {
    try {
      const response = await commentService.editReplyComment({
        reply_text: replyText,
        reply_id: replyid,
      });

      if (response?.message) {
        fetchCommentreplyies();
        setReplyText('');
      }
      setActiveReply(null);
      setShowEditReplyCommentsModal(false);
    } catch (error) {}
  };

  /**
   *api call to block user
   */
  const handleBlockUser = async (userid) => {
    try {
      const payload = {
        userId: userID,
        block: {
          IDs: [userid],
        },
      };
      const response = await commentService.blockUser(payload);
      if (response) {
        fetchCommentreplyies();
      }
      handleClose();
    } catch (error) {}
  };

  /**
   *  date handler will convert date in to time gab between commented time and current time
   * @param {*} date
   * @returns
   */
  const dateHandler = (date) => {
    const dateFormat = new Date(date * 1000);

    return formatDistanceToNowStrict(dateFormat)
      .replace(TIME_UNITS, '')
      .split(' ')
      .join('');
  };

  /**
   * Default comments  count upon click of view More text
   */
  const updatePagination = () => {
    setFetchCommentsLimit(fetchCommentsLimit + 5);
  };

  /**
   *For liking comment reply and call api
   */
  const handleLikeComment = async (reply) => {
    try {
      const response = await commentService.likeReplyComment({
        reply_id: reply.id,
      });
      if (response?.message) {
        fetchCommentreplyies();
      }
    } catch (error) {}
  };

  /**
   *For liking comment reply and call api
   */
  const handleDislikeComment = async (reply) => {
    try {
      const response = await commentService.dislikeReplyComment({
        reply_id: reply.id,
      });
      if (response?.message) {
        fetchCommentreplyies();
      }
    } catch (error) {}
  };

  const handleCommunityUserRedirection = (username) => {
    onCommentReply(username);
  };

  return (
    <Box sx={{ marginLeft: '0.625rem' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {repliesDetail && repliesDetail.length
          ? repliesDetail?.map((reply) => (
              <Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', ml: '1.2rem' }}
                >
                  <img
                    src={enterIcon}
                    style={{
                      width: '0.625rem',
                      height: '0.613rem',
                      marginRight: '0.625rem',
                    }}
                    className={'showHand'}
                    alt='enter-icon'
                  />
                  <Avatar
                    src={`${environment.userprofile_prefix_url}${reply.user_id}`}
                    alt='author-img'
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = reportersLogo;
                    }}
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                      width: '1.25rem',
                      height: '1.25rem',
                      borderRadius: '50%',
                      color: (theme) => theme.palette.white[100],
                      fontSize: '1rem',
                      fontWeight: '600',
                      backgroundColor: (theme) => theme.palette.avatar[100],
                      marginRight: '0.63rem',
                    }}
                  >
                    {(reply.username?.charAt(0) || '').toUpperCase()}
                    {(reply.username?.indexOf(' ') !== -1
                      ? reply.username?.charAt(reply.username.indexOf(' ') + 1)
                      : ''
                    )?.toUpperCase()}
                  </Avatar>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      variant='subtitle3'
                      sx={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontSize: aboveLap
                          ? '1.125rem'
                          : belowLap && aboveMobile
                          ? '0.875rem'
                          : '0.75rem',
                        fontWeight: 700,
                        marginRight: '0.43rem',
                      }}
                      onClick={() =>
                        handleCommunityUserRedirection(reply.username)
                      }
                    >
                      {reply.username}
                    </Typography>
                    <Typography variant='subtitle4' sx={{ fontSize: '0.5rem' }}>
                      {DOT}&nbsp;
                      {dateHandler(reply.time)}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant='subtitle3'
                    sx={{
                      paddingLeft: '4.25rem',
                      fontSize: aboveLap
                        ? '1.125rem'
                        : belowLap && aboveMobile
                        ? '0.875rem'
                        : '0.75rem',
                    }}
                  >
                    {reply.text}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.875rem',
                    marginTop: '0.5rem',
                    paddingLeft: '4.25rem',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '1.125rem',
                    }}
                  >
                    <div
                      onClick={() => {
                        isAuthenticated
                          ? handleLikeComment(reply)
                          : appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
                      }}
                    >
                      {reply.reaction_like_active ? (
                        <img
                          style={{
                            width: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            height: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            marginRight: '0.313rem',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={
                            isDarkMode
                              ? activeHeartDarkMode
                              : activeHeartIconlighMode
                          }
                          alt='active-thumb-up-icon'
                        />
                      ) : (
                        <img
                          style={{
                            width: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            height: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            marginRight: '0.313rem',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={
                            isDarkMode
                              ? inactiveThumbUpIconDark
                              : inactiveThumbUpIcon
                          }
                          alt='inactive-thumb-up-icon'
                        />
                      )}
                    </div>
                    {reply?.reaction_likes ? (
                      <Typography
                        variant='subtitle3'
                        sx={{ fontSize: '0.625rem' }}
                      >
                        {reply.reaction_likes}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '1.125rem',
                    }}
                  >
                    <div
                      onClick={() => {
                        isAuthenticated
                          ? handleDislikeComment(reply)
                          : appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
                      }}
                    >
                      {reply.reaction_dislike_active ? (
                        <img
                          style={{
                            width: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            height: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            marginRight: '0.313rem',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={
                            isDarkMode
                              ? activeDislikeIconDarkMode
                              : activeDislikeIconLightMode
                          }
                          alt='active-thumb-down-icon'
                        />
                      ) : (
                        <img
                          style={{
                            width: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            height: aboveLap
                              ? '1.2rem'
                              : belowLap && aboveMobile
                              ? '1.2rem'
                              : '1rem',
                            marginRight: '0.313rem',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          src={
                            isDarkMode
                              ? inactiveThumbDownIconDark
                              : inactiveThumbDownIcon
                          }
                          alt='inactive-thumb-down-icon'
                        />
                      )}
                    </div>
                    {reply?.reaction_dislikes ? (
                      <Typography
                        variant='subtitle3'
                        sx={{ fontSize: '0.625rem' }}
                      >
                        {reply.reaction_dislikes}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {isAuthenticated && (
                      <img
                        src={moreHorizontal}
                        alt='more-icon'
                        onClick={(event) => {
                          handleMoreOptions(event);
                          setActiveReply(reply);
                        }}
                        sx={{
                          width: '0.75rem',
                          height: '0.688rem',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                    <Menu
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      sx={{
                        '& .MuiPaper-root': {
                          backgroundColor: '#2C2C2E',
                          borderRadius: '5px',
                          mt:
                            activeReply?.user_id === userID
                              ? '-4.063rem'
                              : '-2.063rem',
                          ml: '7.5rem',
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {activeReply?.user_id === userID && (
                        <ListItem
                          onClick={() => handleEditReplyComments(activeReply)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <img src={editCommentIcon} alt='edit-reply' />
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.white[100],
                              fontSize: '0.875rem',
                              ml: '0.817rem',
                            }}
                          >
                            {t('edit-reply')}
                          </Typography>
                        </ListItem>
                      )}
                      <ListItem
                        onClick={
                          activeReply?.user_id === userID
                            ? handleReplyDeleteComments
                            : () => handleBlockUser(activeReply?.user_id)
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        <img
                          src={
                            activeReply?.user_id === userID
                              ? deleteCommentIcon
                              : BlockUserIcon
                          }
                          alt='delete-reply'
                        />
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.white[100],
                            fontSize: '0.875rem',
                            ml: '0.861rem',
                          }}
                        >
                          {activeReply?.user_id === userID
                            ? t('delete-reply')
                            : t('block-user')}
                        </Typography>
                      </ListItem>
                      {showReplyDeleteCommentsModal && (
                        <CommentsModal
                          isOpen={showReplyDeleteCommentsModal}
                          onClose={() => {
                            setShowReplyDeleteCommentsModal(false);
                            setActiveReply(null);
                          }}
                          heading={t('delete-reply')}
                          content={t('delete-reply-confirmation')}
                          primaryButtonAction={
                            isAuthenticated
                              ? () => {
                                  handleReplyDeleteComment(activeReply.id);
                                  setActiveReply(null);
                                }
                              : () =>
                                  appCtxDispatch({
                                    type: 'SHOW_LOGIN_REQUIRED',
                                  })
                          }
                          primaryButtonText={t('delete-Reply')}
                          secondaryButtonAction={() => {
                            setShowReplyDeleteCommentsModal(false);
                            setActiveReply(null);
                          }}
                          secondaryButtonText={t('cancel')}
                        />
                      )}
                      {showEditReplyCommentsModal && (
                        <CommentsModal
                          isOpen={showEditReplyCommentsModal}
                          onClose={() => {
                            setActiveReply(null);
                            showEditReplyCommentsModal(false);
                          }}
                          heading={t('edit-reply')}
                          commentText={replyText}
                          setCommentText={setReplyText}
                          largeTextArea={true}
                          primaryButtonAction={() => {
                            isAuthenticated
                              ? handleEditReplyComment(activeReply.id)
                              : appCtxDispatch({
                                  type: 'SHOW_LOGIN_REQUIRED',
                                });
                          }}
                          primaryButtonText={t('post')}
                          secondaryButtonAction={() => {
                            setActiveReply(null);
                            setShowEditReplyCommentsModal(false);
                          }}
                          secondaryButtonText={t('cancel')}
                        />
                      )}
                    </Menu>
                  </Box>
                </Box>
              </Box>
            ))
          : null}

        {repliesDetail.length &&
        repliesDetail.length % fetchCommentsLimit === 0 ? (
          <Typography sx={{ cursor: 'pointer' }} onClick={updatePagination}>
            {t('view-more')}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}
