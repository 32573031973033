import React, { useReducer } from 'react';

/**
 * App Context to store app related states
 */
export const AppContext = React.createContext();

const initialState = {
  showLoginRequired: false,
  showLogin: false,
  showSignUp: false,
  showProfile: false,
  showExitModal: false,
  searchTerm: '',
};

/**
 * App Context reducer funciton
 * @param {object} state context state
 * @param {object} action context action
 * @returns {object} state
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_LOGIN_REQUIRED':
      return {
        ...state,
        showLoginRequired: true,
        showLogin: false,
      };
    case 'HIDE_LOGIN_REQUIRED': {
      return {
        ...state,
        showLoginRequired: false,
        showLogin: false,
      };
    }
    case 'SHOW_PROFILE':
      return {
        ...state,
        showProfile: true,
      };
    case 'HIDE_PROFILE': {
      return {
        ...state,
        showProfile: false,
      };
    }
    case 'PROCEED_TO_LOGIN': {
      return {
        ...state,
        showLoginRequired: false,
        showLogin: true,
      };
    }
    case 'PROCEED_TO_SIGNUP': {
      return {
        ...state,
        showSignUp: true,
      };
    }
    case 'HIDE_SIGNUP': {
      return {
        ...state,
        showSignUp: false,
      };
    }
    case 'SET_SEARCH_TERM': {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }
    case 'SHOW_LOGIN_BEFORE_SIGNIN':
      localStorage.setItem('isFromBeforeSignIn', true);
      return {
        ...state,
        showLogin: true,
      };
    case 'HIDE_LOGIN_BEFORE_SIGNIN':
      localStorage.removeItem('isFromBeforeSignIn');
      return {
        ...state,
        showLogin: false,
      };
    case 'SHOW_EXIT_POPUP':
      return {
        ...state,
        showExitModal: true,
      };
    case 'HIDE_EXIT_POPUP': {
      return {
        ...state,
        showExitModal: false,
      };
    }
    default:
      return { ...state };
  }
};

/**
 * App Context Provider
 */
export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
