import React, { useContext, useState } from 'react';
import { EnvironmentProvider } from '../../store/firebase-context';
import { useEffect } from 'react';

const document = {
  'privacy-policy': 'signup_policy_url',
  'terms-of-use': 'signup_terms_url',
  'community-guidelines': 'community_guidelines_url',
  'citizen-journalism-guidelines': 'kyc_tc_url',
};

/**
 * Component to show privacy policy
 * @returns
 */
const PolicyDocument = ({ documentName }) => {
  const { environment } = useContext(EnvironmentProvider);
  const [policyUrl, setPolicyUrl] = useState();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  /**
   * once mode will change that version file is updated
   */
  useEffect(() => {
    if (documentName) {
      const url = isDarkMode
        ? environment[`${document[documentName]}_dark`]
        : environment[document[documentName]];
      setPolicyUrl(url);
    }
  }, [isDarkMode]);

  /**
   * scroll to top
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <iframe
      title={documentName}
      width='100%'
      src={policyUrl}
      style={{
        height: '100%',
        border: 'none',
      }}
      allowFullScreen
    ></iframe>
  );
};

export default PolicyDocument;
