export const META_TAG_TITLE = {
  trainingVideos: 'Training Videos',
  assessment: 'Assessment',
  kycScreen: 'KYC Screen',
  CreateReport: 'Create Report',
  searchLocation: 'Search Location Results',
  searchReporters: 'Search Reporters Results',
  searchPublisher: 'Search Publishers Results',
  serchNews: 'Search News Results',
  search: 'Search Screen',
  social: 'Social',
  reporterId: 'View Reporter ID',
};
