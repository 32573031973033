import { useContext } from 'react';
import MobileInArticleImage from '../../assets/images/ads/Ad_300x250.png';
import DesktopInArticleImage from '../../assets/images/ads/Ad_728x90.png';
import { EnvironmentProvider } from '../../store/firebase-context';
import { TRUE } from '../../Constants/Constants';

function InArticleAd({ isMobile = false }) {
  const { environment } = useContext(EnvironmentProvider);

  return (
    <>
      {environment?.web_ad_enable === TRUE && (
        <img
          src={isMobile ? MobileInArticleImage : DesktopInArticleImage}
          alt='inarticle ad'
          style={{
            width: '100%',
            maxWidth: isMobile ? '300px' : '728px',
            height: isMobile ? '250px' : '90px',
          }}
        />
      )}
    </>
  );
}

export default InArticleAd;
