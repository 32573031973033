import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  startTransition,
} from 'react';
import { Box, Button, Typography } from '@mui/material';
import ViewToggle from '../ViewToggle/ViewToggle';
import { LaptopMin } from '../../responsive/breakpoints';
import NewsPostsData from './NewsPostsData';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import MostRead from '../MostRead/MostRead';
import newsService from '../../services/news.service';
import { useTranslation } from 'react-i18next';
import { NewsPostsSkeleton } from '../Skeleton/PublisherSkeleton';
import { INITIAL_VALUE, TEN } from '../../Constants/Constants';
import { UserDetailsProvider } from '../../store/user-details-context';
import HorizontalMenuBar from '../HorizontalMenuBar/HorizontalMenuBar';
import { AppContext } from '../../store/app-context';
import { MaxWidth, viewPort } from '../../responsive/viewport.util';

/**
 * NewsPosts UI Component
 */
const NewsPosts = ({ newsCategories, navBarCategories, navBarFilters }) => {
  const { state: appCtxState } = useContext(AppContext);
  const [newsPostsNews, setNewsPostsNews] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { state: userState } = useContext(UserDetailsProvider);
  const newsSessionID = userState?.sessionId;
  const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [updatedNews, setUpdatedNews] = useState([]);
  const isMobile = MaxWidth(viewPort.tablet);

  /**
   * side effect to call newsposts api
   */
  useEffect(() => {
    getNewsPosts();
  }, [newsSessionID, startIndex]);

  /**
   * In case the load more button is clicked and previous news is changed then merge news function
   */
  useEffect(() => {
    isLoadMoreClicked && mergeNews();
  }, [updatedNews, isLoadMoreClicked]);

  /**
   * Default news count upon click of Load More Button
   */
  const handleLoadMore = () => {
    setStartIndex((prev) => prev + TEN);
    setIsLoadMoreClicked(true);
  };

  /**
   * Merge function to merge the previously loaded news and new news together
   */
  const mergeNews = useCallback(() => {
    setNewsPostsNews([...newsPostsNews, ...updatedNews]);
    setIsLoading(false);
  }, [newsPostsNews, updatedNews]);

  /**
   * Getting the Search Result List from the api
   */
  const getNewsPosts = async () => {
    try {
      const response = await newsService.fetchMyNews({
        sessionId: newsSessionID,
        startIndex: startIndex,
        count: TEN,
      });

      if (response) {
        isLoadMoreClicked
          ? setUpdatedNews(response?.news)
          : setNewsPostsNews(response?.news);
        response?.news.length !== TEN && setIsEndOfPage(true);
        const newsIds = response?.news.map(({ newsId }) => newsId);

        if (newsIds?.length) {
          getDynamicProps(newsIds);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * function to updated dynamic props of the news
   * @param {object} newsIds
   */
  const getDynamicProps = async (newsIds) => {
    try {
      const reqObj = {
        newsIds: newsIds.join(','),
      };

      if (newsSessionID) {
        reqObj['sessionId'] = newsSessionID;
      }

      const response = await newsService.fetchNewsDynamicProps(reqObj);
      const dynamicProps = response?.news || [];

      if (dynamicProps?.length) {
        startTransition(() => {
          setNewsPostsNews((prevState) => {
            return prevState?.map((news) => {
              let isAvailable = dynamicProps.find(
                (newsObj) => newsObj.newsId === news.newsId,
              );

              if (isAvailable) {
                return {
                  ...news,
                  ...isAvailable,
                };
              } else {
                return { ...news };
              }
            });
          });
        });
      }
    } catch (error) {}
  };

  /**
   * Handler for Card Actions (like, dislike and bookmark)
   * @param {object} newsObj news object for which action needs to be updated
   */
  const onCardAction = (newsObj) => {
    const modifiedNewsPostsNewsArray = newsPostsNews?.map((newsItem) => {
      if (newsItem.newsId === newsObj.newsId) {
        return { ...newsItem, ...newsObj };
      }
      return newsItem;
    });
    setNewsPostsNews([...modifiedNewsPostsNewsArray]);
  };

  return (
    <>
      <HorizontalMenuBar
        newsCategories={navBarCategories}
        navBarFilters={navBarFilters}
        isBackRequired={true}
        title={t('NEWS_POSTS')}
      />
      {!newsPostsNews?.length && !isLoading ? (
        <EmptyScreen message={t('NO_REPORTER_POSTS')} />
      ) : (
        <Box
          sx={{
            background: (theme) => theme.palette.background.default,
            display: 'flex',
            justifyContent: 'center',
            padding: '1.5rem 6.25vw',
            width: '100%',
            minHeight: '44rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '1rem',
              alignItems: 'center',
              maxWidth: '78.75rem',
              width: '100%',
            }}
          >
            <Box
              sx={{
                height: '4rem',
                paddingTop: '0.25rem',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant='h5'>{t('NEWS_POSTS')}</Typography>
              <LaptopMin>
                <ViewToggle />
              </LaptopMin>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {isLoading ? (
                  <NewsPostsSkeleton />
                ) : (
                  newsPostsNews?.news?.length !== INITIAL_VALUE && (
                    <NewsPostsData
                      news={newsPostsNews}
                      newsCategories={newsCategories}
                      onCardAction={onCardAction}
                    />
                  )
                )}
                {!isEndOfPage && !isLoading && (
                  <Button
                    onClick={handleLoadMore}
                    color='primary'
                    variant='contained'
                    sx={{
                      padding: '0.75rem 1.5rem',
                      lineHeight: '1.25rem',
                      maxWidth: isMobile ? '7.75rem' : '9.75rem',
                      height: isMobile ? '2rem' : '3rem',
                      fontSize: isMobile ? '0.75rem' : '1rem',
                      width: '100%',
                      textAlign: 'center',
                      color: 'primary',
                      alignSelf: 'center',
                    }}
                  >
                    {t('LOAD_MORE')}
                  </Button>
                )}
              </Box>
              <LaptopMin>
                {appCtxState?.newsView?.activeList && (
                  <Box
                    sx={{
                      marginLeft: '1.25rem',
                      maxWidth: '13.75rem',
                      width: '100%',
                    }}
                  >
                    <MostRead />
                  </Box>
                )}
              </LaptopMin>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default NewsPosts;
