import { Box, Checkbox, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { MaxWidth, viewPort } from '../../../../responsive/viewport.util';

/**
 * Media Component
 *  props
 *  - media - media object from Gallery
 *  - isSelected - boolean flag to indicate whether the media item is selected
 *  - onChange - change handler for selection of media
 */
function Media({ media, isSelected, onChange }) {
  const isMobile = MaxWidth(viewPort.mobileMax);

  return (
    <FormControlLabel
      sx={{ width: '100%', margin: 0 }}
      control={
        <Box
          sx={{
            width: '100%',
            height: isMobile ? '20vh' : '18vh',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <img
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            alt='data'
            src={media.thumbnail_presigned_url}
          />

          <Checkbox
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            checked={isSelected}
            onChange={(_, checked) => {
              onChange(media, checked);
            }}
            inputProps={{ 'aria-label': 'media-checkbox' }}
          />

          {media?.type === 'VIDEO' && media?.metadata?.duration && (
            <Box
              sx={{
                position: 'absolute',
                right: '0.625rem',
                bottom: '0.625rem',
                color: '#FFFFFF',
                background: (theme) =>
                  theme.palette.mode === 'dark'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[600],
                padding: '0.125rem 0.5rem',
                borderRadius: '0.75rem',
                fontWeight: '600',
                fontSize: '1rem',
                lineHeight: '1.25rem',
              }}
            >
              {new Date(media?.metadata?.duration * 1000)
                .toISOString()
                .slice(14, 19)}
            </Box>
          )}
        </Box>
      }
    />
  );
}

Media.propTypes = {
  media: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Media;
