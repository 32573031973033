import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Box, Button, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import followersService from '../../services/followers.service';
import purpletickIcon from '../../assets/icons/purple-tick.svg';
import bluetickIcon from '../../assets/icons/blue-influencer-tick-icon.svg';
import userProfileService from '../../services/user-profile.service';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import FollowingTabBackdrop from './FollowingTabBackdrop';
import {
  CITIZEN_REPORTER,
  PUBLISHER_TYPE,
  UGC_REPORTER,
  INFLUENCER,
  CITIZEN_REPORTER_CAPITAL,
} from '../../Constants/Constants';
import { UserDetailsProvider } from '../../store/user-details-context';
import { EnvironmentProvider } from '../../store/firebase-context';

/***
 * Follwed Reporters Section
 */
const Reporters = () => {
  const [reporters, setReporters] = useState();
  const [reportersList, setReportersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { state: userState } = useContext(UserDetailsProvider);
  const userID = userState?.userDetails?.userId;
  const follows = userState?.follows;
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.tablet);
  const { environment } = useContext(EnvironmentProvider);

  /**
   * side effect to calls reporters listing api on initialization
   */
  useEffect(() => {
    reporterList();
  }, []);

  /**
   * Side Effect to update the followed reporters list once any reporter has been followed/unfollowed
   */
  useEffect(() => {
    updateFollowedReporters();
  }, [reportersList, follows]);

  /**
   * Follow/Unfollow Action Handler
   * @param {string} followersID - reporter userID
   * @param {boolean} isFollow - boolean flag to denote whether to follow or unfollow, true to follow and false to unfollow
   */
  const followUnfollowReporter = async (reporterID, isFollowedByUser) => {
    try {
      setIsLoading(true);
      const followData = {
        userId: userID,
      };
      if (isFollowedByUser) {
        followData['unfollow'] = JSON.stringify({ IDs: [reporterID] });
      } else {
        followData['follow'] = JSON.stringify({ IDs: [reporterID] });
      }
      await userProfileService.updateUserFollow(followData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Function to retrieve Followed Reporters
   *  - if there is no followers, it lists Minmini Reporter as recommendation where the user can follow from
   *  - if there are followers, it lists the followed reporters as well as 20 unfollowed reporters
   */
  const reporterList = async () => {
    try {
      setIsLoading(true);
      const followPayload = {
        listType: 'following',
        userType: `${UGC_REPORTER},${CITIZEN_REPORTER}`,
        limit: 1000,
      };
      const followedListResponse = await followersService.getUsersList(
        followPayload,
      );
      const followedList = followedListResponse?.followersList;

      const unfollowPayload = {
        listType: 'unfollow',
        userType: UGC_REPORTER,
        limit: 1000,
      };
      const unfollowedListResponse = await followersService.getUsersList(
        unfollowPayload,
      );

      const unfollowedList = unfollowedListResponse?.followersList;

      const mergedList = [
        ...followedList.map((item) => ({ ...item, isFollowed: true })),
        ...(unfollowedList?.map((item) => ({ ...item, isFollowed: false })) ||
          []),
      ];

      setReportersList(mergedList);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Updates the list of reporters with the information about whether each reporter is followed.
   *
   * @function
   * @name updateFollowedReporters
   * @returns {void}
   * @description This function maps through the reportersList and updates each reporter's "isFollowed" property based on whether the user follows them. The updated list is then set using the setReporters function.
   */
  const updateFollowedReporters = () => {
    const followedList = reportersList?.map((user) => {
      const isFollowed = follows.includes(user.userId);
      return { ...user, isFollowed };
    });

    setReporters(followedList);
  };

  return (
    <Box
      sx={{
        maxHeight: isMobile ? '28rem' : '24rem',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          background: 'transparent',
          appearance: 'none',
          width: 0,
          height: 0,
        },
        paddingBottom: '1rem',
      }}
    >
      <FollowingTabBackdrop isLoading={isLoading} />
      {!!reporters?.length &&
        reporters?.map((reporter) => (
          <Grid
            container
            key={reporter.userId}
            alignItems='center'
            sx={{
              py: 2,
              borderBottom: 1,
              borderColor: 'divider',
              minHeight: '4.25rem',
            }}
          >
            <Grid item xs={2}>
              <Box sx={{ position: 'relative' }}>
                <Avatar
                  alt='reporter avatar'
                  src={`${environment.userprofile_prefix_url}${reporter.userId}`}
                  sx={{
                    cursor: 'pointer',
                    position: 'relative',
                    width: '2.25rem',
                    height: '2.25rem',
                    borderRadius: '50%',
                    color: (theme) => theme.palette.white[100],
                    backgroundColor: (theme) => theme.palette.avatar[100],
                  }}
                >
                  {`${(reporter?.userName?.[0] || '').toUpperCase()}`}
                </Avatar>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{
                  color: isDarkMode
                    ? (theme) => theme.palette.grey[600]
                    : (theme) => theme.palette.grey[500],
                }}
              >
                {reporter?.userName}
                {
                  <Box
                    component='img'
                    src={
                      [PUBLISHER_TYPE, UGC_REPORTER, INFLUENCER].includes(
                        reporter?.userType,
                      )
                        ? bluetickIcon
                        : reporter?.userType === CITIZEN_REPORTER_CAPITAL
                        ? purpletickIcon
                        : null
                    }
                    alt='reporter'
                    width={15}
                    sx={{ height: 'auto', marginLeft: '2px' }}
                  ></Box>
                }
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Button
                variant='text'
                onClick={() =>
                  followUnfollowReporter(
                    reporter.userId,
                    reporter.isFollowed ? true : false,
                  )
                }
                sx={{
                  ml: reporter.isFollowed ? '0.8rem' : '2rem',
                  fontWeight: 600,
                  fontSize: '.8rem',
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                }}
              >
                {reporter.isFollowed ? t('unfollow') : t('follow')}
              </Button>
            </Grid>
          </Grid>
        ))}
    </Box>
  );
};

export default Reporters;
