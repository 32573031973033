/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDTUserProfile = /* GraphQL */ `
  query GetDTUserProfile($userId: String!) {
    getDTUserProfile(userId: $userId) {
      userId
      firstName
      lastName
      userName
      followersCount
      userDetails
      preferences
      following
      citizenJournalist
      isOnboardedReporter
      isCitizenReporter
      isDTVerifiedReporter
      isInfluencer
      followingCount
      userType
      blockedByOtherUsersCount
      blockedUserCount
      coverPostId
      phone
      __typename
    }
  }
`;
export const listDTUserProfiles = /* GraphQL */ `
  query ListDTUserProfiles(
    $filter: TableDTUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDTUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        firstName
        lastName
        userName
        followersCount
        userDetails
        preferences
        following
        citizenJournalist
        isOnboardedReporter
        isCitizenReporter
        isDTVerifiedReporter
        isInfluencer
        followingCount
        userType
        blockedByOtherUsersCount
        blockedUserCount
        coverPostId
        phone
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSearchItems = /* GraphQL */ `
  query GetSearchItems($userId: String) {
    getSearchItems(userId: $userId) {
      userId
      searchHistory
      __typename
    }
  }
`;
export const getDTUserFollow = /* GraphQL */ `
  query GetDTUserFollow($userId: String!) {
    getDTUserFollow(userId: $userId) {
      userId
      follow
      unfollow
      __typename
    }
  }
`;
export const getDTUserBlock = /* GraphQL */ `
  query GetDTUserBlock($userId: String!) {
    getDTUserBlock(userId: $userId) {
      userId
      block
      unblock
      __typename
    }
  }
`;
