import { METHOD } from '../enums/api.enums';
// import { toast } from 'react-toastify';
// import { ERROR_MSG } from '../Constants/Constants';
import { api } from '../Constants/api.constants';
import { getEnv, defaultConfigurations } from '../firebase/firebaseConfig';

const { GET } = METHOD;

/** Add Path Name to hide the toaster */
const pathExclude = [
  api.getAadharToken,
  api.verifyAadhar,
  api.getNewsById,
  api.reportNews,
  api.updateUserNews,
  api.userCheck,
  api.updateBankDetail,
  api.getBankDetail,
  api.notificationUnreadCount,
];

/**
 * Function to retrieve ID Token from localStorage
 * @returns {string} token - id token of the logged in user
 */
const getToken = () => {
  return localStorage.getItem('token');
};

/**
 * Function to make api calls using Fetch API
 * @param {string} path - api path
 * @param {string} method - GET|POST|DELETE|PUT
 * @param {boolean} isHocalWire - true if hocalwire api else false
 * @param {object} data - data object
 * @param {boolean} withAuth - true to pass token and false not to pass the token
 * @param {boolean} isBlob - true to return blob else return json
 * @returns promise - api promise
 */
const apiRequest = (
  path,
  method,
  isHocalWire = false,
  data,
  withAuth = false,
  isBlob = false,
  withoutBearer = false,
  isParamsToken = '',
) => {
  const BASE_URL =
    getEnv('new_api_host')?._value ||
    defaultConfigurations[process.env.REACT_APP_ENV]?.new_api_host;

  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  if (isParamsToken) {
    options.headers['Authorization'] = `Bearer ${isParamsToken}`;
  }

  if (isHocalWire) {
    options.headers['s-id'] = process.env.REACT_APP_HOCALWIRE_SESSION_ID;
  }
  // if authentication required, attaches Authorization header with token
  if (withAuth) {
    const token = getToken();

    if (token && withoutBearer) {
      options.headers['Authorization'] = token;
    } else if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  // pass data in body for POST|PUT|DELETE
  if (method !== GET && data) {
    options.body = JSON.stringify(data);
  }

  return fetch(
    `${BASE_URL}${path}${
      method === GET && data ? `?${new URLSearchParams(data).toString()}` : ''
    }`,
    options,
  )
    .then((response) => {
      if (response?.ok) {
        if (response?.errorMessage) {
          // toast.error(ERROR_MSG, {
          //   toastId: 'errorMessage',
          // });
          return Promise.reject();
        } else {
          return isBlob ? response.blob() : response.json();
        }
      }
      if (
        (path === api.getNewsById && response?.status === 403) ||
        (path === api.updateUserNews && response?.status === 403) ||
        (path === api.reportNews && response?.status === 429) ||
        (path === api.userCheck && response?.status === 409)
      ) {
        return response.json();
      }

      if (response?.status !== 200 && !pathExclude.includes(path)) {
        // toast.error(ERROR_MSG, {
        //   toastId: 'statusCode',
        // });
        return Promise.reject();
      }
    })
    .catch((error) => {
      if (!pathExclude.includes(path)) {
        // toast.error(ERROR_MSG, {
        //   toastId: 'error',
        // });
      }
      return Promise.reject(error);
    });
};

export default apiRequest;
