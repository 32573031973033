// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailNewsModalContainer .MuiButtonBase-root,
.detailNewsModalOutlineBtn .MuiButtonBase-root {
  height: 3rem;
  width: 18.75rem;
  margin: 0.93rem 0 0 0.5rem;
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-m);
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.detailNewsModalContainer .MuiButtonBase-root {
  /* margin-top: 1.88rem; */
  background: var(--pacific-blue);
  color: var(--white);
}

.detailNewsModalOutlineBtn .MuiButtonBase-root {
  background: var(--white);
  color: var(--pacific-blue);
  border: 0.01rem solid var(--gray-300);
}

.detailNewsModalOutlineBtn .MuiButtonBase-root:hover {
  background: var(--white);
}

@media only screen and (max-width: 479px) {
  .detailNewsModalContainer .MuiButtonBase-root,
  .detailNewsModalOutlineBtn .MuiButtonBase-root {
    width: 18.75rem;
  }
}

.newsDetailAd {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}

/* CLASSNAME FROM HOCALWIRE */
.agency_feed {
  overflow: hidden;
}
.agency_feed img {
  margin-bottom: 0.6rem;
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/News/DetailedNewsCard.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,eAAe;EACf,0BAA0B;EAC1B,+CAA+C;EAC/C,6BAA6B;EAC7B,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,qCAAqC;AACvC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;;IAEE,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA,6BAA6B;AAC7B;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".detailNewsModalContainer .MuiButtonBase-root,\n.detailNewsModalOutlineBtn .MuiButtonBase-root {\n  height: 3rem;\n  width: 18.75rem;\n  margin: 0.93rem 0 0 0.5rem;\n  font-family: var(--font-family-source_sans_pro);\n  font-size: var(--font-size-m);\n  text-transform: uppercase;\n  border-radius: 0.5rem;\n}\n\n.detailNewsModalContainer .MuiButtonBase-root {\n  /* margin-top: 1.88rem; */\n  background: var(--pacific-blue);\n  color: var(--white);\n}\n\n.detailNewsModalOutlineBtn .MuiButtonBase-root {\n  background: var(--white);\n  color: var(--pacific-blue);\n  border: 0.01rem solid var(--gray-300);\n}\n\n.detailNewsModalOutlineBtn .MuiButtonBase-root:hover {\n  background: var(--white);\n}\n\n@media only screen and (max-width: 479px) {\n  .detailNewsModalContainer .MuiButtonBase-root,\n  .detailNewsModalOutlineBtn .MuiButtonBase-root {\n    width: 18.75rem;\n  }\n}\n\n.newsDetailAd {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 1.25rem;\n}\n\n/* CLASSNAME FROM HOCALWIRE */\n.agency_feed {\n  overflow: hidden;\n}\n.agency_feed img {\n  margin-bottom: 0.6rem;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
