import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';
import { api } from '../Constants/api.constants';
import graphQLRequest from './graphql';
import { createSearchItems, updateSearchItems } from './graphql/mutations';
import { getSearchItems } from './graphql/queries';

/**
 * Make api call for retrieving Trending Search List
 * @returns promise
 */
const fetchTrendingSearchList = () => {
  return apiRequest(api.trendingSearch, METHOD.GET, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for creating recent search list
 * @param {object} data
 * @returns promise
 */
const createSearchItemsData = (data) => {
  return graphQLRequest(createSearchItems, {
    input: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for updating recent search list
 * @param {object} data
 * @returns promise
 */
const updateSearchItemsData = (data) => {
  return graphQLRequest(updateSearchItems, {
    input: data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving user profile
 * @param {object} data
 * @returns promise
 */
const getRecentSearchList = (data) => {
  return graphQLRequest(getSearchItems, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Search Result List
 * @returns promise
 */
const fetchSearchResultList = (data) => {
  return apiRequest(api.newsAgrigator, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const searchUsername = (data) => {
  return apiRequest(api.searchUsername, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const searchService = {
  fetchTrendingSearchList,
  createSearchItemsData,
  updateSearchItemsData,
  getRecentSearchList,
  fetchSearchResultList,
  searchUsername,
};

export default searchService;
