/**
 * MediaOrder enum
 */
const MediaOrder = {
  VIDEO: 'video',
  IMAGE: 'image',
};

/**
 * Orders media array object
 * @param {array} media media object array
 * @returns {array} sorted media array with video at beginning and image later
 */
export const getOrderedMedia = (media) => {
  const order = [];
  for (let key in MediaOrder) {
    order.push(key);
  }

  return media?.sort((a, b) => {
    const index1 = order.findIndex((key) => MediaOrder[key] === a.type);
    const index2 = order.findIndex((key) => MediaOrder[key] === b.type);
    return index1 - index2;
  });
};

export const getReactionStatus = (
  { isLiked, isDisliked, likeCount, dislikeCount },
  type,
) => {
  let likeCnt = likeCount;
  let isLikedVar = isLiked;
  let dislikeCnt = dislikeCount;
  let isDislikedVar = isDisliked;

  if (type === 'like') {
    likeCnt = likeCnt ? likeCnt + 1 : 1;
    if (isDisliked) {
      dislikeCnt = dislikeCnt ? dislikeCnt - 1 : 0;
    }
    isLikedVar = true;
    isDislikedVar = false;
  }

  if (type === 'dislike') {
    if (isLiked) {
      likeCnt = likeCnt ? likeCnt - 1 : 0;
    }
    dislikeCnt = dislikeCnt ? dislikeCnt + 1 : 1;
    isLikedVar = false;
    isDislikedVar = true;
  }

  if (type === 'like_none') {
    likeCnt = likeCnt ? likeCnt - 1 : 0;
    isLikedVar = false;
    isDislikedVar = false;
  }

  if (type === 'dislike_none') {
    dislikeCnt = dislikeCnt ? dislikeCnt - 1 : 0;
    isLikedVar = false;
    isDislikedVar = false;
  }

  return {
    isLiked: isLikedVar,
    isDisliked: isDislikedVar,
    likeCount: likeCnt,
    dislikeCount: dislikeCnt,
  };
};
