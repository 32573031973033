import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DOT } from '../../Constants/Constants';
import { Typography, Box, Avatar, Menu, ListItem } from '@mui/material';
import reportersLogo from '../../assets/defaultProfilePic.png';
import moreHorizontal from '../../assets/icons/more-horizontal-icon.svg';
import activeHeartDarkMode from '../../assets/icons/active-thumb-up.svg';
import activeHeartIconlighMode from '../../assets/icons/active-thumb-light.svg';
import editCommentIcon from '../../assets/icons/edit-comment.svg';
import deleteCommentIcon from '../../assets/icons/delete-comment.svg';
import ReportIcon from '../../assets/icons/report-icon.svg';
import BlockUserIcon from '../../assets/icons/block-user-icon.svg';
import commentService from '../../services/comments.service';
import CommentsModal from '../Modal/CommentsModal';
import { UserDetailsProvider } from '../../store/user-details-context';
import { AppContext } from '../../store/app-context';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { TIME_UNITS } from '../../Constants/Constants';
import ReplyComment from './reply-comments/replyComment';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import { EnvironmentProvider } from '../../store/firebase-context';
import AddIcon from '../../assets/icons/AddIcon';
import inactiveThumbUpIcon from '../../assets/icons/inactive-thumb-up.svg';
import inactiveThumbUpIconDark from '../../assets/icons/inactive-thumb-up-white.svg';
import activeDislikeIconDarkMode from '../../assets/icons/activeHeartDislikeIconDarkMode.svg';
import activeDislikeIconLightMode from '../../assets/icons/activeHeartDislikeIconLightMode.svg';
import inactiveThumbDownIcon from '../../assets/icons/inactive-thumb-down.svg';
import inactiveThumbDownIconDark from '../../assets/icons/inactive-thumb-down-white.svg';
import replyCommentDarkModeIcon from '../../assets/icons/replyCommentIconDarkMode.svg';
import replyCOmmentIconLightMode from '../../assets/icons/replyCommentIconLightMode.svg';

const modalTypes = {
  reply: 'REPLY',
  post: 'NEW_COMMENT',
  editPost: 'EDIT_COMMENT',
};

export default function Comments(props) {
  const [commentsData, setCommentData] = useState([]);
  const [modalType, setModalType] = useState(modalTypes.reply);
  const [activeComment, setActiveComment] = useState();
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [showDeleteCommentsModal, setShowDeleteCommentsModal] = useState(false);
  const [commentText, setCommentText] = useState('');
  const { newsID, commentsRef, url } = props;
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const { state: userState } = useContext(UserDetailsProvider);
  const { dispatch: appCtxDispatch } = useContext(AppContext);
  const isAuthenticated = userState?.isAuthenticated;
  const userID = userState?.userDetails?.userId;
  const [fetchCommentsLimit, setFetchCommentsLimit] = useState(10);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.mobile);
  const { environment } = useContext(EnvironmentProvider);
  // let navigate = useNavigate();
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchComments = async () => {
    const payload = {
      news_id: newsID,
      limit: fetchCommentsLimit,
      type: 'NEWS',
    };

    try {
      const response = await commentService.fetchComments(
        payload,
        isAuthenticated,
      );

      if (response && response.data) {
        setCommentData([...response.data]);
      } else {
        setCommentData([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchComments();
  }, [newsID, fetchCommentsLimit]);

  const handleAddComments = (modalType) => {
    setModalType(modalType);
    if (
      modalType === modalTypes.editPost &&
      activeComment &&
      activeComment.text
    ) {
      setCommentText(activeComment.text);
    } else {
      setCommentText('');
    }
    setShowAddCommentsModal(true);
    handleClose();
  };

  const handleDeleteComments = () => {
    setShowDeleteCommentsModal(true);
    handleClose();
  };

  const handleAddComment = async () => {
    if (modalType === modalTypes.reply) {
      try {
        const response = await commentService.addReplyComment({
          comment_id: activeComment.id,
          reply_text: commentText,
        });

        if (response?.message) {
          fetchComments();
        }
        setShowAddCommentsModal(false);
        setActiveComment(null);
      } catch (error) {}
    } else if (modalType === modalTypes.post) {
      try {
        const response = await commentService.addComment({
          news_id: newsID,
          news_url: `${window.location.origin}/news${url}`,
          comment_text: commentText,
          type: 'NEWS',
        });
        if (response?.message) {
          fetchComments();
        }
        setShowAddCommentsModal(false);
      } catch (error) {}
    } else if (modalType === modalTypes.editPost) {
      try {
        const response = await commentService.editComment({
          post_id: activeComment.post_id,
          comment_id: activeComment.id,
          comment_text: commentText,
          type: 'NEWS',
        });

        if (response?.message) {
          fetchComments();
          setActiveComment(null);
        }
        setShowAddCommentsModal(false);
      } catch (error) {}
    }
  };

  /**
   *api call to delete comment
   */
  const handleDeleteComment = async (postid, commentid) => {
    try {
      const response = await commentService.deleteComment({
        post_id: postid,
        comment_id: commentid,
        type: 'NEWS',
      });
      if (response?.message) {
        fetchComments();
      }
      setShowDeleteCommentsModal(false);
    } catch (error) {}
  };

  /**
   *api call to block user
   */
  const handleBlockUser = async (userid) => {
    try {
      const payload = {
        userId: userID,
        block: {
          IDs: [userid],
        },
      };
      const response = await commentService.blockUser(payload);
      if (response) {
        fetchComments();
      }
      handleClose();
    } catch (error) {}
  };

  /**
   *api call to report comment
   */
  const handleReportComment = async (commentid) => {
    try {
      const response = await commentService.reportComment({
        comment_id: commentid,
        type: 'NEWS',
      });
      if (response?.message) {
        fetchComments();
      }
      handleClose();
    } catch (error) {}
  };

  const dateHandler = (date) => {
    const dateFormat = new Date(date * 1000);
    return formatDistanceToNowStrict(dateFormat)
      .replace(TIME_UNITS, '')
      .split(' ')
      .join('');
  };

  const { t } = useTranslation();

  /**
   * Default comments  count upon click of view More text
   */
  const updatePagination = () => {
    setFetchCommentsLimit(fetchCommentsLimit + 10);
    fetchComments();
  };

  /**
   *For liking comment and call api
   */
  const handleLikeComment = async (comment) => {
    try {
      const response = await commentService.likeComment({
        comment_id: comment.id,
        type: 'NEWS',
      });
      if (response?.message) {
        fetchComments();
      }
    } catch (error) {}
  };

  /**
   *For liking comment and call api
   */
  const handleDislikeComment = async (comment) => {
    try {
      const response = await commentService.dislikeComment({
        comment_id: comment.id,
        type: 'NEWS',
      });
      if (response?.message) {
        fetchComments();
      }
    } catch (error) {}
  };

  /**
   * We are setting flag which comment we want to show by user click
   * @param {*} selectedComment comment which is cliked that object
   */

  const handleReplyShow = (selectedComment) => {
    const editCommentData = [];
    commentsData.forEach((comment) => {
      let cComent = { ...comment };

      if (comment.id === selectedComment.id) {
        cComent['showReply'] = !comment?.showReply;
      }
      editCommentData.push(cComent);
    });
    setCommentData(editCommentData);
  };

  const handleCommunityUserRedirection = (username) => {
    // navigate('/social', {
    //   state: {
    //     communityUserProfileUrl: `${username}?app_in_app=true&dark_mode=${isDarkMode}&language=${
    //       TRAINING_LANGUAGE[userState?.preferences?.contentLanguage] || 'en'
    //     }&profile_redirect=true`,
    //   },
    // });
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          p: '0.313rem',
          pl: '1rem',
          mt: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
        ref={commentsRef}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              fontSize: aboveLap
                ? '1.125rem'
                : belowLap && aboveMobile
                ? '0.875rem'
                : '0.75rem',
              mb: '1.063rem',
              mr: isMobile ? '0.75rem' : '1.75rem',
              textTransform: 'capitalize',
            }}
          >
            {t('comments')}
          </Typography>
          <Box
            variant='contained'
            sx={{
              width: isTamil ? '15.125rem' : isMobile ? '8rem' : '11.125rem',
              height: '1.563rem',
              borderRadius: '12px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleAddComments(modalTypes.post);
            }}
          >
            <AddIcon />
            <Typography
              component='span'
              sx={{
                ml: '0.438rem',
                fontWeight: '700',
                fontSize: isMobile ? '0.5rem' : '0.938rem',
                textTransform: 'uppercase',
              }}
            >
              {t('add-comment')}
            </Typography>
          </Box>
        </Box>
        {commentsData && commentsData.length ? (
          commentsData?.map((comment) => (
            <Box sx={{ pr: '0.5rem' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={`${environment.userprofile_prefix_url}${comment.user_id}`}
                  alt='author-img'
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = reportersLogo;
                  }}
                  sx={{
                    cursor: 'pointer',
                    position: 'relative',
                    width: '1.25rem',
                    height: '1.25rem',
                    borderRadius: '50%',
                    color: (theme) => theme.palette.white[100],
                    fontSize: '1rem',
                    fontWeight: '600',
                    backgroundColor: (theme) => theme.palette.avatar[100],
                    marginRight: '0.63rem',
                  }}
                >
                  {(comment.username?.charAt(0) || '').toUpperCase()}
                  {(comment.username?.indexOf(' ') !== -1
                    ? comment.username?.charAt(
                        comment.username.indexOf(' ') + 1,
                      )
                    : ''
                  )?.toUpperCase()}
                </Avatar>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography
                    variant='subtitle3'
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: aboveLap
                        ? '1.125rem'
                        : belowLap && aboveMobile
                        ? '0.875rem'
                        : '0.75rem',
                      fontWeight: 700,
                      marginRight: '0.43rem',
                      cursor: 'pointer',
                      // '&:hover': {
                      //   textDecoration: 'underline',
                      // },
                    }}
                    onClick={() =>
                      handleCommunityUserRedirection(comment.username)
                    }
                  >
                    {comment.username}
                  </Typography>
                  <Typography variant='subtitle4' sx={{ fontSize: '0.5rem' }}>
                    {DOT}&nbsp;
                    {dateHandler(comment.time)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant='subtitle3'
                  sx={{
                    paddingLeft: '1.75rem',
                    fontSize: aboveLap
                      ? '1.125rem'
                      : belowLap && aboveMobile
                      ? '0.875rem'
                      : '0.75rem',
                  }}
                >
                  {comment.text}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.875rem',
                  marginTop: '0.5rem',
                  paddingLeft: '1.75rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '1.125rem',
                  }}
                >
                  <div
                    onClick={() => {
                      isAuthenticated
                        ? handleLikeComment(comment)
                        : appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
                    }}
                  >
                    {comment?.reaction_like_active ? (
                      <img
                        style={{
                          width: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          height: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          marginRight: '0.313rem',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        src={
                          isDarkMode
                            ? activeHeartDarkMode
                            : activeHeartIconlighMode
                        }
                        alt='active-thumb-up-icon'
                      />
                    ) : (
                      <img
                        style={{
                          width: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          height: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          marginRight: '0.313rem',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        src={
                          isDarkMode
                            ? inactiveThumbUpIconDark
                            : inactiveThumbUpIcon
                        }
                        alt='inactive-thumb-up-icon'
                      />
                    )}
                  </div>
                  {comment?.reaction_likes ? (
                    <Typography
                      variant='subtitle3'
                      sx={{ fontSize: '0.625rem' }}
                    >
                      {comment.reaction_likes}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '1.125rem',
                  }}
                >
                  <div
                    onClick={() => {
                      isAuthenticated
                        ? handleDislikeComment(comment)
                        : appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
                    }}
                  >
                    {comment.reaction_dislike_active ? (
                      <img
                        style={{
                          width: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          height: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          marginRight: '0.313rem',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        src={
                          isDarkMode
                            ? activeDislikeIconDarkMode
                            : activeDislikeIconLightMode
                        }
                        alt='active-thumb-down-icon'
                      />
                    ) : (
                      <img
                        style={{
                          width: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          height: aboveLap
                            ? '1.2rem'
                            : belowLap && aboveMobile
                            ? '1.2rem'
                            : '1rem',
                          marginRight: '0.313rem',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        src={
                          isDarkMode
                            ? inactiveThumbDownIconDark
                            : inactiveThumbDownIcon
                        }
                        alt='inactive-thumb-down-icon'
                      />
                    )}
                  </div>
                  {comment?.reaction_dislikes ? (
                    <Typography
                      variant='subtitle3'
                      sx={{ fontSize: '0.625rem' }}
                    >
                      {comment.reaction_dislikes}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '0.938rem',
                  }}
                >
                  <div
                    sx={{ padding: 0 }}
                    onClick={() => {
                      handleReplyShow(comment);
                    }}
                  >
                    <img
                      style={{
                        width: aboveLap
                          ? '1.2rem'
                          : belowLap && aboveMobile
                          ? '1.2rem'
                          : '1rem',
                        height: aboveLap
                          ? '1.2rem'
                          : belowLap && aboveMobile
                          ? '1.2rem'
                          : '1rem',
                        marginRight: '0.313rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      src={
                        isDarkMode
                          ? replyCommentDarkModeIcon
                          : replyCOmmentIconLightMode
                      }
                      alt='active-thumb-down-icon'
                    />
                  </div>
                  {comment?.replies_count ? (
                    <Typography
                      variant='subtitle3'
                      sx={{ fontSize: '0.625rem' }}
                      onClick={() => {
                        handleReplyShow(comment);
                      }}
                    >
                      {comment.replies_count}
                    </Typography>
                  ) : (
                    ''
                  )}
                  <Typography
                    variant='subtitle3'
                    sx={{
                      fontSize: aboveLap
                        ? '0.875rem'
                        : belowLap && aboveMobile
                        ? '0.75rem'
                        : '0.625rem',
                      cursor: 'pointer',
                      marginLeft: '0.313rem',
                    }}
                    onClick={() => {
                      handleAddComments(modalTypes.reply);
                      setActiveComment(comment);
                    }}
                  >
                    {t('Reply')}
                  </Typography>
                </Box>
                {isAuthenticated && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      src={moreHorizontal}
                      alt='more-icon'
                      onClick={(event) => {
                        handleClick(event);
                        setActiveComment(comment);
                      }}
                      sx={{
                        width: '0.75rem',
                        height: '0.688rem',
                        cursor: 'pointer',
                      }}
                    />
                    <Menu
                      id='comment-option'
                      open={Boolean(open)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      sx={{
                        '& .MuiPaper-root': {
                          backgroundColor: '#2C2C2E',
                          boxShadow: 'none',
                          borderRadius: '5px',
                          mt: '-4.063rem',
                          ml: '7.5rem',
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <ListItem
                        onClick={
                          activeComment?.user_id === userID
                            ? () => {
                                handleAddComments(modalTypes.editPost);
                                setActiveComment(activeComment);
                              }
                            : () => handleReportComment(activeComment?.id)
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        <img
                          src={
                            activeComment?.user_id === userID
                              ? editCommentIcon
                              : ReportIcon
                          }
                          alt='edit-comment'
                        />
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.white[100],
                            fontSize: '0.875rem',
                            ml: '0.817rem',
                          }}
                        >
                          {activeComment?.user_id === userID
                            ? t('edit-comment')
                            : t('report-comment')}
                        </Typography>
                      </ListItem>
                      <ListItem
                        onClick={
                          activeComment?.user_id === userID
                            ? handleDeleteComments
                            : () => handleBlockUser(activeComment?.user_id)
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        <img
                          src={
                            activeComment?.user_id === userID
                              ? deleteCommentIcon
                              : BlockUserIcon
                          }
                          alt='delete-comment'
                        />
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.white[100],
                            fontSize: '0.875rem',
                            ml: '0.861rem',
                          }}
                        >
                          {activeComment?.user_id === userID
                            ? t('delete-comment')
                            : t('block-user')}
                        </Typography>
                      </ListItem>
                    </Menu>
                  </Box>
                )}
              </Box>
              {comment?.showReply ? (
                <ReplyComment
                  commentsData={commentsData}
                  commentDetail={comment}
                  setActiveComment={setActiveComment}
                  onCommentReply={handleCommunityUserRedirection}
                />
              ) : null}
            </Box>
          ))
        ) : (
          <Typography
            sx={{
              fontSize: aboveLap
                ? '1.125rem'
                : belowLap && aboveMobile
                ? '0.875rem'
                : '0.75rem',
            }}
          >
            {t('be-the-first-to-comment')}
          </Typography>
        )}
        {commentsData.length &&
        commentsData.length % fetchCommentsLimit === 0 ? (
          <Typography sx={{ cursor: 'pointer' }} onClick={updatePagination}>
            {t('view-more')}
          </Typography>
        ) : null}
      </Box>
      {showDeleteCommentsModal && (
        <CommentsModal
          isOpen={showDeleteCommentsModal}
          onClose={() => {
            setShowDeleteCommentsModal(false);
            setActiveComment(null);
          }}
          heading={t('delete-Comment')}
          content={t('delete-comment-confirmation')}
          primaryButtonAction={
            isAuthenticated
              ? () => {
                  handleDeleteComment(activeComment.post_id, activeComment.id);
                  setActiveComment(null);
                }
              : () => appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' })
          }
          primaryButtonText={t('delete-Comment')}
          secondaryButtonAction={() => {
            setShowDeleteCommentsModal(false);
            setActiveComment(null);
          }}
          secondaryButtonText={t('cancel')}
        />
      )}
      {showAddCommentsModal && (
        <CommentsModal
          isOpen={showAddCommentsModal}
          onClose={() => {
            setShowAddCommentsModal(false);
            modalTypes.editPost
              ? setActiveComment(activeComment)
              : setActiveComment(null);
          }}
          heading={
            modalType === modalTypes.reply
              ? t('reply-comment')
              : modalType === modalTypes.editPost
              ? t('edit-comment')
              : t('add-comment')
          }
          commentText={commentText}
          setCommentText={setCommentText}
          largeTextArea={true}
          primaryButtonAction={
            isAuthenticated
              ? handleAddComment
              : () => appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' })
          }
          primaryButtonText={t('post')}
          secondaryButtonAction={() => {
            setShowAddCommentsModal(false);
            setActiveComment(null);
          }}
          secondaryButtonText={t('cancel')}
          replyToUser={
            modalType === modalTypes.reply ? activeComment?.username || '' : ''
          }
        />
      )}
    </>
  );
}
