import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n/config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        position='bottom-left'
        limit={1}
        autoClose={5000}
      />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
