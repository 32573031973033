import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Divider,
} from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import CloseIcon from '@mui/icons-material/Close';
import Profile from '../Profile/Profile';
import { useTranslation } from 'react-i18next';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';

const ProfileModal = ({
  showProfileModal,
  closeModal,
  changeHeight,
  navigateToHome,
  handleVerification,
}) => {
  const { t } = useTranslation();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const isMobile = MaxWidth(viewPort.mobile);
  const isTablet = MaxWidth(990);

  return (
    <Dialog
      open={showProfileModal}
      aria-labelledby='responsive-dialog-title'
      aria-describedby='dialog-description'
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth:
              showSuccessMsg && isMobile
                ? '100%'
                : showSuccessMsg
                ? '22.5rem'
                : isMobile
                ? '100%'
                : isTablet
                ? '24rem'
                : '48rem',
            width: '100%',
            maxHeight: isMobile ? '100%' : '29.5rem',
            height: '100%',
            marginTop: '5rem',
            margin: isMobile ? '0' : '2rem',
          },
        },
      }}
    >
      <DialogTitle id='responsive-dialog-title'>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <div id='modalHeader'>{t('profile')}</div>
          <IconButton
            aria-label='close'
            onClick={closeModal}
            sx={{ position: 'absolute', right: 12, top: isMobile ? 8 : 13 }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider variant='fullWidth' />
      <Scrollbars>
        <DialogContent>
          <Profile
            closeModal={closeModal}
            changeHeight={changeHeight}
            navigateToHome={navigateToHome}
            handleVerification={handleVerification}
            setShowSuccessMsg={setShowSuccessMsg}
            showSuccessMsg={showSuccessMsg}
          />
        </DialogContent>
      </Scrollbars>
    </Dialog>
  );
};

export default ProfileModal;
