export const styles = {
  trainingWrapper: {
    padding: { xs: 0, sm: '1.25rem 0rem 1.25rem 0rem' },
    maxWidth: '78.75rem',
    width: '100%',
  },
  mobileTrainingHeading: {
    fontFamily: 'Sharp Sans',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    padding: '1.125rem',
    paddingLeft: '0.125rem',
    '& img': {
      marginRight: '0.5rem',
    },
  },
  trainingHeading: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    marginBottom: '1.5rem',
  },
  stepper: {
    width: '100%',
    display: 'flex',
    marginBottom: '1.25rem',
    alignItems: 'center',
    justifyContent: { xs: 'center', sm: 'left' },
    marginTop: { xs: '1.25rem', sm: 0 },
  },
  stepperItem: {
    display: 'flex',
    alignItems: 'center',
  },
  stepperStep: {
    width: '1.875rem',
    height: '1.875rem',
    borderRadius: '50%',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.625rem',
    fontWeight: 600,
  },
  completedStep: {
    border: '1px solid #8E3BD9',
    background: 'rgba(0, 144, 224, 0.25)',
  },
  stepperText: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
  },
  trainingContainer: {
    display: { xs: 'block', sm: 'flex' },
    width: '100%',
    gap: '1.25rem',
    minHeight: { xs: '100%', sm: '25rem' },
  },
  listWrapper: {
    flex: 1,
    borderRadius: '0.625rem',
    minHeight: { xs: 'auto', sm: '70vh' },
    overflow: 'auto',
    maxHeight: { xs: 'auto', sm: '100vh' },
  },
  languageSelector: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2.5rem',
    paddingBottom: '1.25rem',
    borderBottom: '1px solid  #E0E0E0',
  },
  languageSelectField: {
    borderRadius: '0.625rem',
    fontSize: '1rem',
    paddingLeft: '.5rem',
    fontWeight: 400,
  },
  listItem: {
    gap: '1rem',
  },
  thumbnailContainer: { width: '5rem', height: '3rem' },
  playlistItemTitle: {
    fontFamily: 'Sharp Sans',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  playlistItemDuration: {
    fontFamily: 'Sharp Sans',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
  },
  videoWrapper: {
    display: { xs: 'none', sm: 'block' },
    flex: 3,
    borderRadius: '5px',
  },
  startAssessmentContainer: {
    fontWeight: 400,
    fontSize: '0.875rem',
    display: { xs: 'block', sm: 'flex' },
    textAlign: { xs: 'center', sm: 'left' },
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.25rem',
    marginBottom: { xs: 0, sm: '1.25rem' },
    borderRadius: '0.625rem',
    overflow: 'hidden',
  },
  startAssessmentNote: {
    fontFamily: 'Sharp Sans',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    marginBottom: { xs: '1.25rem', sm: 0 },
  },
  videoContainer: {
    padding: '1.25rem',
    borderRadius: '0.625rem',
    flex: 1,
  },
  videoTitle: {
    fontFamily: 'Sharp Sans',
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginBottom: '1.25rem',
  },
  videoDescription: {
    fontFamily: 'Sharp Sans',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    marginBottom: '1.25rem',
  },
  prevNextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.25rem',
    fontWeight: '600',
  },
  mobilePrevNextContainer: {
    width: '100%',
    margin: 0,
    padding: '1.25rem',
  },
  playerContainer: {
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
};
