import React from 'react';
import './Timer.css';

const Timer = ({ resetTimer, resetOtp, hideTimer }) => {
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(true);

  React.useEffect(() => {
    let timerId;
    if (runTimer || resetTimer) {
      setCountDown(10 * 3);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer, resetTimer]);

  React.useEffect(() => {
    if ((countDown < 0 && runTimer) || (countDown < 0 && resetTimer)) {
      setRunTimer(false);
      setCountDown(0);
      resetOtp();
    }
  }, [countDown, runTimer, resetTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  return (
    <div className={hideTimer ? 'hideTimer' : 'showTimer'}>
      {minutes}:{seconds}
    </div>
  );
};

export default Timer;
