import { SvgIcon } from '@mui/material';
/**
 * custom uncheckbox
 * @param {*} props  fill stroke color string
 * @returns
 */
const CheckboxBlankIcon = (props) => {
  const { fill, stroke } = props;
  return (
    <SvgIcon viewBox='0 0 20 20' fontSize='small'>
      <rect
        x='0.5'
        y='0.5'
        width='19'
        height='19'
        rx='4.5'
        fill={fill}
        stroke={stroke}
      />
    </SvgIcon>
  );
};
export default CheckboxBlankIcon;
