// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modalHeaderEnglish,
#appearanceModalHeaderEnglish,
#modalHeaderTamil,
#appearanceModalHeaderTamil {
  font-size: var(--font-size-l);
  font-weight: 600 !important;
}

.modalArrowLeft {
  cursor: pointer;
}

#modalContent {
  font-size: 0.75rem;
  color: var(--gray-400);
  font-weight: 400;
}

.comment-buttons {
  max-width: 18.75rem;
  width: 100%;
  height: 3rem;
}

@media only screen and (max-width: 479px) {
  #modalHeaderTamil {
    font-size: var(--font-size-s);
  }

  #appearanceModalHeaderEnglish,
  #appearanceModalHeaderTamil {
    margin-top: 0.3rem;
  }
}

@media only screen and (max-width: 360px) {
  #modalHeaderTamil {
    font-size: var(--font-size-xs);
    font-weight: bolder;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modal/Modal.css"],"names":[],"mappings":"AAAA;;;;EAIE,6BAA6B;EAC7B,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,6BAA6B;EAC/B;;EAEA;;IAEE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,8BAA8B;IAC9B,mBAAmB;EACrB;AACF","sourcesContent":["#modalHeaderEnglish,\n#appearanceModalHeaderEnglish,\n#modalHeaderTamil,\n#appearanceModalHeaderTamil {\n  font-size: var(--font-size-l);\n  font-weight: 600 !important;\n}\n\n.modalArrowLeft {\n  cursor: pointer;\n}\n\n#modalContent {\n  font-size: 0.75rem;\n  color: var(--gray-400);\n  font-weight: 400;\n}\n\n.comment-buttons {\n  max-width: 18.75rem;\n  width: 100%;\n  height: 3rem;\n}\n\n@media only screen and (max-width: 479px) {\n  #modalHeaderTamil {\n    font-size: var(--font-size-s);\n  }\n\n  #appearanceModalHeaderEnglish,\n  #appearanceModalHeaderTamil {\n    margin-top: 0.3rem;\n  }\n}\n\n@media only screen and (max-width: 360px) {\n  #modalHeaderTamil {\n    font-size: var(--font-size-xs);\n    font-weight: bolder;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
