import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';
import { api } from '../Constants/api.constants';

/**
 * Make api call for verify aadhar
 * @returns promise
 */
const aadharVerification = (data) => {
  return apiRequest(api.verifyAadhar, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for verify aadhar
 * @returns promise
 */
const aadharOtp = (data) => {
  return apiRequest(api.getAadharToken, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for verify driving license
 * @returns promise
 */
const drivingLicenseVerification = (data) => {
  return apiRequest(api.verifyDrivingLicense, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for verify passport
 * @returns promise
 */
const passportVerification = (data) => {
  return apiRequest(api.verifyPassport, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const ekycService = {
  aadharVerification,
  aadharOtp,
  drivingLicenseVerification,
  passportVerification,
};

export default ekycService;
