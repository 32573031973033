import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import { TextField, Stack, Typography, Divider, Box } from '@mui/material';
import { LaptopMin, MobileMax } from '../../responsive/breakpoints';
import './SignInForm.css';
import { useTranslation } from 'react-i18next';
import completedIcon from '../../assets/completed.png';
import {
  INITIAL_VALUE,
  SIX,
  NEW_PASSWORD_REQUIRED,
  TEN,
} from '../../Constants/Constants';
import authService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
// import SocialSignIn from '../SocialSignIn/SocialSignIn';
// import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { AppContext } from '../../store/app-context';
import ButtonLoader from '../buttonLoader/ButtonLoader';

const SignInForm = (props) => {
  const {
    closeModal,
    openSignUp,
    openPassword,
    changeHeight,
    changeHeaderContent,
  } = props;
  const initialUserValue = {
    phoneNumber: '',
    password: '',
    newPassword: '',
    confirmPassword: '',
  };
  const initialOutline = {
    phoneNumber: '',
    password: '',
    newPassword: '',
    confirmPassword: '',
  };
  const [user, setUser] = useState(initialUserValue);
  const [errMsg, setErrMsg] = useState('');
  const [errOutLine, setErrOutLine] = useState(initialOutline);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [cognitoUserObject, setCognitoUserObject] = useState([]);
  const [isPasswordsMatch, setPasswordsMatch] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const inputRef = React.useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { dispatch: appCtxDispatch } = useContext(AppContext);

  /**
   * if password and confirmpassword matches and also the length of them is greater than 6,
   * we will update the isPasswordMatch to true and display the text "Passwords Match"
   */
  useEffect(() => {
    if (
      user.newPassword === user.confirmPassword &&
      user.newPassword.length >= SIX &&
      user.confirmPassword.length >= SIX
    ) {
      setPasswordsMatch(true);
    }
  }, [user.newPassword, user.confirmPassword]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleChange = (e) => {
    if (e.target.name === 'phoneNumber') {
      if (e?.target?.value?.length === INITIAL_VALUE) {
        setErrOutLine({
          ...errOutLine,
          [e.target.name]: t('phone-number-required'),
        });
      } else if (e?.target?.value?.length !== TEN) {
        setErrOutLine({
          ...errOutLine,
          [e.target.name]: t('enter-a-valid-phone-number'),
        });
      } else {
        setErrOutLine({
          ...errOutLine,
          [e.target.name]: '',
        });
      }
      setUser({
        ...user,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ''),
      });
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
      setErrOutLine({
        ...errOutLine,
        [e.target.name]: '',
      });
      setErrMsg('');
    }
  };

  const showSignUp = () => openSignUp();

  const openForgotPassword = () => {
    closeModal();
    openPassword();
    changeHeight(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    appCtxDispatch({ type: 'HIDE_LOGIN_REQUIRED' });
    const phoneNumber = user?.phoneNumber;
    const password = user?.password;
    let errMsg = {};

    if (phoneNumber?.length === TEN && password?.length > INITIAL_VALUE) {
      setLoginLoading(true);
      await authService
        .signIn({ userName: `+91${phoneNumber}`, password })
        .then((response) => {
          setLoginLoading(false);
          if (response.challengeName === NEW_PASSWORD_REQUIRED) {
            setCognitoUserObject(response);
            changeHeight(true);
            changeHeaderContent(true);
            setShowChangePassword(true);
          } else {
            closeModal();
            localStorage.removeItem('lang');
            localStorage.removeItem('theme');
          }
        })
        .catch((error) => {
          setLoginLoading(false);
          setErrMsg(error.message);
        });
    } else {
      if (phoneNumber.length === INITIAL_VALUE) {
        errMsg['phoneNumber'] = t('phone-number-required');
      } else if (phoneNumber.length !== TEN) {
        errMsg['phoneNumber'] = t('enter-a-valid-phone-number');
      }
      if (password.length === INITIAL_VALUE) {
        errMsg['password'] = t('password-required');
      }
    }
    setErrOutLine(errMsg);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const newPassword = user?.newPassword;
    const confirmPassword = user?.confirmPassword;
    let errMsg = {};

    if (
      newPassword.length === INITIAL_VALUE &&
      confirmPassword.length === INITIAL_VALUE
    ) {
      setErrMsg(t('REQUIRED_ERR_MSG'));
    } else if (
      newPassword.length === INITIAL_VALUE &&
      confirmPassword.length > INITIAL_VALUE
    ) {
      errMsg['newPassword'] = t('NEW_PASSWORD_REQUIRED');
    } else if (
      newPassword.length > INITIAL_VALUE &&
      confirmPassword.length === INITIAL_VALUE
    ) {
      errMsg['confirmPassword'] = t('RE_ENTER_PASSWORD_REQUIRED');
    } else if (newPassword !== confirmPassword) {
      setErrMsg(t('passwords-do-not-match'));
    } else if (
      newPassword === confirmPassword &&
      (newPassword.length < SIX) & (confirmPassword.length < SIX)
    ) {
      setErrMsg(t('PASSWORD_LENGTH_ERR_MSG'));
    }
    setErrOutLine(errMsg);

    if (newPassword.length >= SIX && confirmPassword.length >= SIX) {
      if (newPassword === confirmPassword) {
        await authService
          .completeNewPassword({
            user: cognitoUserObject,
            password: user.newPassword,
          })
          .then((response) => {
            response && setShowSuccessMsg(true);
          })
          .catch(() => {});
      } else {
        setErrMsg(t('passwords-do-not-match'));
      }
    }
  };

  const closeSignInModal = () => {
    closeModal();
    navigate(0);
  };

  return (
    <>
      {showChangePassword ? (
        showSuccessMsg ? (
          <>
            <Stack>
              <div className='changePasswordContainer'>
                <img
                  src={completedIcon}
                  alt='completedIcon'
                  className='changePasswordIcon'
                />
              </div>
            </Stack>
            <Stack>
              <div className='changePasswordContainer'>
                <h6 className='changePasswordSuccessContent'>
                  {t('PASSWORD_CHANGED_SUCCESSFULLY')}
                </h6>
              </div>
            </Stack>
            <Stack>
              <div className='changePasswordContainer'>
                <div className='changePasswordBtn'>
                  <Button
                    variant='contained'
                    onClick={() => closeSignInModal()}
                  >
                    {t('cproceed')}
                  </Button>
                </div>
              </div>
            </Stack>
          </>
        ) : (
          <>
            <form onSubmit={handleChangePassword}>
              <div className='changePasswordContainer'>
                <Typography variant='body1'>
                  {t('CHANGE_PASSWORD_CONTENT')}
                </Typography>
              </div>
              <Stack spacing={2}>
                <div className='changePasswordContainer'>
                  <TextField
                    type='password'
                    error={errOutLine.newPassword || errMsg ? true : false}
                    helperText={
                      errOutLine.newPassword ? errOutLine.newPassword : ''
                    }
                    inputRef={inputRef}
                    className={'signInTextField'}
                    label='New Password'
                    variant='outlined'
                    name='newPassword'
                    value={user.newPassword}
                    onChange={handleChange}
                  />
                </div>
              </Stack>
              <Stack spacing={2}>
                <div className='changePasswordContainer'>
                  <TextField
                    type='password'
                    error={errOutLine.confirmPassword || errMsg ? true : false}
                    helperText={
                      errOutLine.confirmPassword
                        ? errOutLine.confirmPassword
                        : ''
                    }
                    className={'signInTextField'}
                    label='Re enter Password'
                    variant='outlined'
                    name='confirmPassword'
                    value={user.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
              </Stack>
              <span className='signInPasswordMatch'>
                {isPasswordsMatch && 'Passwords Match'}
              </span>
              <div className='changePasswordContainer'>
                <div className='changePasswordErrorMsg'>{errMsg && errMsg}</div>
              </div>
              <Stack spacing={2}>
                <div className='changePasswordContainer'>
                  <div className='changePasswordBtn'>
                    <Button variant='contained' type='submit'>
                      {'Change Password'}
                    </Button>
                  </div>
                </div>
              </Stack>
              <Stack spacing={2}>
                <div className='changePasswordContainer'>
                  <div className='changePasswordOutlineBtn'>
                    <Button
                      onClick={() => closeModal()}
                      variant='outlined'
                      sx={{
                        width: '18.75rem',
                      }}
                    >
                      {t('cancel')}
                    </Button>
                  </div>
                </div>
              </Stack>
            </form>
          </>
        )
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <div className='signInFlexContainer'>
                <TextField
                  error={errOutLine.phoneNumber || errMsg ? true : false}
                  helperText={
                    errOutLine.phoneNumber ? errOutLine.phoneNumber : ''
                  }
                  className={'signInTextField'}
                  label={t('phone-number')}
                  variant='outlined'
                  name='phoneNumber'
                  value={user.phoneNumber}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, 10);
                  }}
                />
              </div>
            </Stack>
            <Stack spacing={4}>
              <div className='signInFlexContainer' style={{ marginTop: 0 }}>
                <Typography
                  sx={{
                    marginBottom: '0.25rem',
                    fontSize: '0.75rem',
                    color: (t) =>
                      isDarkMode
                        ? t.palette.minmini_grey.grey
                        : t.palette.minmini_grey.grey,
                  }}
                >
                  {t('outside-country-phone')}
                </Typography>
              </div>
            </Stack>
            <Stack spacing={4}>
              <div className='signInFlexContainer'>
                <TextField
                  error={errOutLine.password || errMsg ? true : false}
                  helperText={errOutLine.password ? errOutLine.password : ''}
                  type='password'
                  className={'signInTextField'}
                  label={t('password')}
                  variant='outlined'
                  name='password'
                  value={user.password}
                  onChange={handleChange}
                />
              </div>
            </Stack>
            <Stack spacing={4} alignItems={'center'}>
              <Box
                className='signInFlexContainer'
                sx={{
                  maxWidth: '18.75rem',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant='text'
                  sx={{
                    fontSize: '0.875rem',
                    padding: 'unset',
                    textTransform: 'uppercase',
                  }}
                  onClick={openForgotPassword}
                >
                  {t('forgotpassword')}?
                </Button>
              </Box>
            </Stack>
            <div className='signInFlexContainer'>
              <div className='changePasswordErrorMsg'>{errMsg && errMsg}</div>
            </div>
            <Stack spacing={2}>
              <div className='signInFlexContainer'>
                <div className='loginBtn'>
                  <ButtonLoader
                    variant='contained'
                    loading={loginLoading}
                    type='submit'
                  >
                    {t('log-in')}
                  </ButtonLoader>
                </div>
              </div>
            </Stack>
          </form>
          <Stack>
            {/* <Stack spacing={2}>
              <Divider>
                <Typography
                  variant='body1'
                  sx={{
                    marginTop: '1.25rem',
                    marginBottom: '1rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[600]
                      : LIGHT_COLOR.grey[400],
                  }}
                  className='signIn-line-content'
                >
                  {t('or')}
                </Typography>
              </Divider>
            </Stack> */}
            {/* <Stack>
              <div className='signInFlexContainer'>
                <Typography
                  variant='body1'
                  sx={{
                    marginTop: '0.62rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[600]
                      : LIGHT_COLOR.grey[400],
                  }}
                >
                  {t('LOG_IN_WITH')}
                </Typography>
              </div>
            </Stack> */}
            {/* <div className='signInFlexContainer'>
              <SocialSignIn />
            </div> */}
            <Stack>
              <Divider
                variant='fullWidth'
                sx={{ marginBottom: '1.25rem', marginTop: '1.813rem' }}
              />
            </Stack>
            <Stack spacing={2}>
              <LaptopMin>
                <div className='signInFlexContainer'>
                  <Typography variant='body1'>
                    {t('new-to-minmini?')}
                    <span className='signInnavLink' onClick={showSignUp}>
                      {' '}
                      {t('sign-up-now')}
                    </span>
                  </Typography>
                </div>
              </LaptopMin>
              <MobileMax>
                <div className='signInFlexContainer'>
                  <Typography variant='body1'>
                    {t('new-to-minmini?')}
                  </Typography>
                </div>
                <div className='signInFlexContainer'>
                  <Button
                    variant='outlined'
                    onClick={showSignUp}
                    sx={{ maxWidth: '18.75rem', width: '100%' }}
                  >
                    {t('sign-up-now')}
                  </Button>
                </div>
              </MobileMax>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default SignInForm;
