import { Box, Button, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import appService from '../../services/app.service';
import { useTranslation } from 'react-i18next';

function AreaPicker({ open, handleClose, updateSelectedArea }) {
  const { t } = useTranslation();
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;

  useEffect(() => {
    appService
      .fetchDistricts()
      .then((response) => {
        setDistricts(response.locations);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
    if (selectedDistrict) {
      appService
        .fetchAreas({ parentId: selectedDistrict?.locationId })
        .then((response) => {
          setAreas(response.locations);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedDistrict]);
  const handleDistrictChange = (event) => {
    const selectedDistrictValue = event.target.value;
    const selectedDistrictObject = districts.find(
      (district) => district.name === selectedDistrictValue,
    );
    setSelectedDistrict(selectedDistrictObject);
    setSelectedArea(null);
  };

  const handleAreaChange = (event) => {
    const selectedAreaName = event.target.value;
    const selectedArea = areas.find((area) => area.name === selectedAreaName);
    setSelectedArea(selectedArea);
  };

  return (
    <Modal sx={{ outline: 'none' }} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: '20rem',
          height: '19rem',
          border: '#2E2933',
          background: isDarkMode ? '#19072D' : '#F2F2F2',
          borderRadius: '0.7rem',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
        }}
      >
        <Box sx={{ margin: '2.7rem 1.06rem 2rem 1rem' }}>
          <Typography
            sx={{
              color: isDarkMode ? '#F2F2F2' : '#19072D',
              fontSize: '0.8rem',
            }}
          >
            {t('choose-city-or-district')}
          </Typography>
          <select
            onChange={handleDistrictChange}
            style={{
              padding: '0.75rem',
              width: '18rem',
              marginTop: '0.6rem',
              fontSize: '0.688rem',
              background: isDarkMode ? '#2E2933' : '#E0E0E0',
              color: isDarkMode ? '#F2F2F2' : '#19072D',
              borderRadius: '10px',
              outline: 'none',
              border: '1px solid',
              borderColor: isDarkMode ? '#2E2933' : 'rgb(149 141 151)',
            }}
            onMouseEnter={(e) => {
              isDarkMode
                ? (e.target.style.borderColor = '#ffffff')
                : (e.target.style.borderColor = '#2E2933');
            }}
            onMouseLeave={(e) => {
              e.target.style.borderColor = '#2E2933';
            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#8e3bd9';
              e.target.style.borderWidth = '2px';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#2E2933';
              e.target.style.borderWidth = '1px';
            }}
          >
            <option label={t('select')} disabled='disabled' selected={true} />
            {districts?.map((district, index) => (
              <option key={index} value={district.name}>
                {isTamil ? district.name_tamil : district.name}
              </option>
            ))}
          </select>
        </Box>
        <Box sx={{ margin: '0 0 2rem 1rem ' }}>
          <Typography
            sx={{
              color: isDarkMode ? '#F2F2F2' : '#19072D',
              fontSize: '0.8rem',
            }}
          >
            {t('choose-area')}
          </Typography>
          <select
            onChange={handleAreaChange}
            disabled={!selectedDistrict}
            style={{
              padding: '0.75rem',
              width: '18rem',
              marginTop: '0.6rem',
              fontSize: '0.688rem',
              background: isDarkMode ? '#2E2933' : '#E0E0E0',
              color: isDarkMode ? '#F2F2F2' : '#19072D',
              borderRadius: '10px',
              outline: 'none',
              border: '1px solid',
              borderColor: isDarkMode ? '#2E2933' : 'rgb(149 141 151)',
            }}
            onMouseEnter={(e) => {
              isDarkMode
                ? (e.target.style.borderColor = '#ffffff')
                : (e.target.style.borderColor = '#2E2933');
            }}
            onMouseLeave={(e) => {
              e.target.style.borderColor = '#2E2933';
            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#8e3bd9';
              e.target.style.borderWidth = '2px';
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#2E2933';
              e.target.style.borderWidth = '1px';
            }}
          >
            <option label={t('select')} disabled='disabled' selected={true} />
            {areas?.map((area, index) => (
              <option key={index} value={area.name}>
                {isTamil ? area.name_tamil : area.name}
              </option>
            ))}
          </select>
        </Box>
        <Button
          onClick={() => {
            handleClose();
            updateSelectedArea(selectedArea);
          }}
          variant='contained'
          sx={{
            width: '18rem',
            height: '3rem',
            borderRadius: '0.7rem',
            margin: '0 0.6rem 0.8rem 1rem',
          }}
          disabled={!(selectedArea && selectedDistrict)}
        >
          {t('set-location')}
        </Button>
      </Box>
    </Modal>
  );
}
export default AreaPicker;
