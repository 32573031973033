import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Stack,
  Button,
  Grid,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import completedIcon from '../../assets/completed.png';
import { LaptopMin, MobileMax } from '../../responsive/breakpoints';
import './SignUpForm.css';
import { useTranslation } from 'react-i18next';
import Timer from '../Timer/Timer';
import {
  INITIAL_VALUE,
  TEN,
  FOUR,
  SIX,
  TWENTY,
} from '../../Constants/Constants';
import authService from '../../services/auth.service';
import appService from '../../services/app.service';
// import SocialSignIn from '../SocialSignIn/SocialSignIn';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import DialogBox from '../DialogBox/DialogBox';
import { AppContext } from '../../store/app-context';
import ButtonLoader from '../buttonLoader/ButtonLoader';
import withCaptcha from '../WithCaptcha/withCaptcha';
import { ThreeDots } from 'react-loader-spinner';

const SignUpForm = (props) => {
  const {
    openSignIn,
    changeHeight,
    openPreferences,
    verifyCaptcha,
    captchaVerified,
    skipCaptcha,
    userDetailProp,
    openCaptcha,
  } = props;
  const initialUserValue = {
    username: '',
    password: '',
    email: '',
    phone_number: '',
    code: '',
    confirmPassword: '',
  };
  const initialErrMsg = {
    username: '',
    password: '',
    email: '',
    valideEmail: '',
    phone_number: '',
    validatePhoneNumber: '',
    code: '',
    confirmPassword: '',
  };
  const [userDetails, setUserDetails] = useState(initialUserValue);
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [signUpErr, setSignUpErr] = useState(initialErrMsg);
  const inputRef = React.useRef();
  const [sendOtp, setSendOtp] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const { t } = useTranslation();
  const [isPassword, setPassword] = useState(false);
  const [isPasswordsMatch, setPasswordsMatch] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { dispatch: appCtxDispatch } = useContext(AppContext);
  const lowercaseUsername = userDetails.username
    .toLowerCase()
    .replace(/ /g, '');
  const [signUpLoading, setSignUpLoading] = useState(false);
  const specialCharacterRegex = /^[^[\]{}()*&^%$#@,.:;!/?\\\-"+=~`|]+$/;
  const tamilRegex = /[\u0b80-\u0bff]/g;
  const isTamil = tamilRegex.test(lowercaseUsername);

  useEffect(() => {
    if (captchaVerified || skipCaptcha) {
      setLoading(true);
      userSignUp();
    }
  }, [captchaVerified, skipCaptcha]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  /**
   * if password and confirmpassword matches and also the length of them is greater than 6,
   * we will update the isPasswordMatch to true and display the text "Passwords Match"
   */
  useEffect(() => {
    if (
      userDetails.password === userDetails.confirmPassword &&
      userDetails.password.length >= SIX &&
      userDetails.confirmPassword.length >= SIX
    ) {
      setPassword(false);
      setPasswordsMatch(true);
    }
  }, [userDetails.password, userDetails.confirmPassword]);

  const reg =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const tamilCharCount = (str) => {
    const tamilCharCountRegex =
      /[\u0300-\u036f\u1dc0-\u1dff\u20d0-\u20ff\ufe20-\ufe2f\u0b82\u0b83\u0bbe\u0bbf\u0bc0-\u0bc2\u0bc6-\u0bc8\u0bca-\u0bcd\u0bd7]/g;
    return str.replace(tamilCharCountRegex, '').length;
  };

  const userSignUp = async () => {
    try {
      await authService.signUp({
        username:
          Object.keys(userDetailProp).length > 0
            ? `91${userDetailProp.phone_number}`
            : `91${userDetails.phone_number}`,
        password:
          Object.keys(userDetailProp).length > 0
            ? userDetailProp.password
            : userDetails.password,
        attributes: {
          email:
            Object.keys(userDetailProp).length > 0
              ? userDetailProp.email
              : userDetails.email,
          phone_number:
            Object.keys(userDetailProp).length > 0
              ? `+91${userDetailProp.phone_number}`
              : `+91${userDetails.phone_number}`,
          'custom:user_name':
            Object.keys(userDetailProp).length > 0
              ? userDetailProp.username.toLowerCase().replace(/ /g, '')
              : lowercaseUsername,
        },
        autoSignIn: { enabled: true },
      });
      openCaptcha();
      setSignUpLoading(false);
      setLoading(false);
      setShowCodeForm(true);
      changeHeight(true);
      setErrMsg('');
    } catch (error) {
      setSignUpLoading(false);
      if (error?.message?.includes('User already exists')) {
        setShowInfoModal(true);
        setErrMsg(t('PHONE_DUPLICATE_ERROR'));
      } else if (error?.message?.includes('DuplicateEmailFound')) {
        setShowInfoModal(true);
        setErrMsg(t('EMAIL_DUPLICATE_ERROR'));
      } else if (error?.message?.includes('DuplicatePhoneFound')) {
        setShowInfoModal(true);
        setErrMsg(t('PHONE_DUPLICATE_ERROR'));
      } else {
        setErrMsg(error?.message);
      }
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    appCtxDispatch({ type: 'HIDE_SIGNUP' });
    let errMsg = {};

    const isUsernameValid = specialCharacterRegex.test(userDetails.username);

    const validityCheck = (
      isTamil
        ? tamilCharCount(lowercaseUsername) >= FOUR &&
          tamilCharCount(lowercaseUsername) <= TWENTY
        : lowercaseUsername.length >= FOUR &&
          lowercaseUsername.length <= TWENTY &&
          isUsernameValid
    )
      ? await userValidityCheck(lowercaseUsername)
      : false;

    const validCharCount = isTamil
      ? tamilCharCount(lowercaseUsername) >= FOUR &&
        tamilCharCount(lowercaseUsername) <= TWENTY
      : lowercaseUsername.length >= FOUR && lowercaseUsername.length <= TWENTY;

    if (validCharCount && isUsernameValid && !validityCheck) {
      errMsg['username'] = t('USERNAME_DUPLICATE_ERROR');
    }
    if (lowercaseUsername.length <= INITIAL_VALUE) {
      errMsg['username'] = t('please-fill-the-required-details');
    }
    if (
      (lowercaseUsername.length > INITIAL_VALUE) & isTamil
        ? tamilCharCount(lowercaseUsername) < FOUR
        : lowercaseUsername.length < FOUR
    ) {
      errMsg['username'] = t('username-must-contain-at-least-4-characters');
    }
    if (
      isTamil
        ? tamilCharCount(lowercaseUsername) > TWENTY
        : lowercaseUsername.length > TWENTY
    ) {
      errMsg['username'] = t('username-must-contain-less-than-20-characters');
    }
    if (!isUsernameValid) {
      errMsg['username'] = t(
        'username-must-contain-only-lowercase-alphanumeric-characters',
      );
    }

    if (userDetails.phone_number.length <= INITIAL_VALUE) {
      errMsg['phone_number'] = t('please-fill-the-required-details');
    } else if (userDetails.phone_number.length !== TEN) {
      errMsg['validatePhoneNumber'] = t('enter-a-valid-phone-number');
    }
    if (userDetails.password.length <= INITIAL_VALUE) {
      errMsg['password'] = t('please-fill-the-required-details');
    }
    if (userDetails.confirmPassword.length <= INITIAL_VALUE) {
      errMsg['confirmPassword'] = t('please-fill-the-required-details');
    }
    if (
      (userDetails.password.length > INITIAL_VALUE) &
      (userDetails.password.length < SIX)
    ) {
      errMsg['password'] = t('PASSWORD_LENGTH_ERR_MSG');
    }
    if (
      (userDetails.confirmPassword.length > INITIAL_VALUE) &
      (userDetails.confirmPassword.length < SIX)
    ) {
      errMsg['confirmPassword'] = t('CONFIRM_PASSWORD_ERR');
    }
    if (
      userDetails &&
      userDetails.email.trim() &&
      !reg.test(userDetails.email)
    ) {
      errMsg['valideEmail'] = t('please-enter-valid-email-address');
    }
    setSignUpErr(errMsg);
    if (
      validCharCount &&
      userDetails.phone_number.length === TEN &&
      userDetails.password.length >= SIX &&
      userDetails.confirmPassword.length >= SIX &&
      isUsernameValid &&
      validityCheck &&
      ((userDetails.email.trim() && reg.test(userDetails.email)) ||
        userDetails.email.trim().length === 0)
    ) {
      if (userDetails.password === userDetails.confirmPassword) {
        const userValidation = await userValidityCheck(
          userDetails.username,
          userDetails.phone_number,
        );
        if (userValidation?.phoneMessage === 'DuplicatePhoneFound') {
          setShowInfoModal(true);
          setErrMsg(t('PHONE_DUPLICATE_ERROR'));
        } else {
          if (
            process.env.REACT_APP_ENV === 'development' ||
            process.env.REACT_APP_ENV === 'qa'
          ) {
            setSignUpLoading(true);
            userSignUp();
          } else {
            openCaptcha();
            verifyCaptcha(userDetails);
          }
        }
      } else {
        setPassword(true);
        setSignUpErr({
          confirmPassword: t('passwords-do-not-match'),
        });
      }
    }
  };

  const userValidityCheck = async (username, phone_number) => {
    try {
      let payload = {
        userName: username,
      };
      if (phone_number) {
        payload['phoneNumber'] = `+91${phone_number}`;
      }
      const availableUser = await appService.validateUserAvailabilty(payload);
      return availableUser;
    } catch (err) {
      return false;
    }
  };

  /**
   * Function to confirm OTP entered by the user. Opens publisher Modal after 3 seconds
   * @param {object} e - Event generated by confirm button
   */
  const handleConfirmCode = async (e) => {
    e.preventDefault();
    if (userDetails.code?.length === 0) {
      setSignUpErr({
        code: t('REQUIRED_ERR_MSG'),
      });
    } else if (userDetails.code?.length === 6) {
      try {
        const response = await authService.confirmSignUp({
          username: `91${userDetails.phone_number}`,
          code: userDetails.code,
        });
        if (response) {
          setShowSuccessMsg(true);
          setTimeout(() => {
            openPreferences();
          }, 3000);
        }
      } catch (error) {
        if (error?.message?.includes('Invalid')) {
          setErrMsg(t('OTP-IS-INCORRECT_PLEASE_TRY_AGAIN'));
        } else {
          setErrMsg(error.message);
        }
      }
    }
  };

  const handlePublisher = (e) => {
    e.preventDefault();
    openPreferences();
  };

  const handleBlur = async (e) => {
    if (e.target.name === 'username') {
      const userNameSpaceReplace = e.target.value.replace(/ /g, '');
      setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
      if (!e.target.value.length) {
        setSignUpErr({
          ...signUpErr,
          username: t('please-fill-the-required-details'),
        });
      } else if (
        isTamil
          ? tamilCharCount(userNameSpaceReplace) < FOUR
          : userNameSpaceReplace.length < FOUR
      ) {
        setSignUpErr({
          ...signUpErr,
          username: t('username-must-contain-at-least-4-characters'),
        });
      } else if (
        isTamil
          ? tamilCharCount(userNameSpaceReplace) > TWENTY
          : userNameSpaceReplace.length > TWENTY
      ) {
        setSignUpErr({
          ...signUpErr,
          username: t('username-must-contain-less-than-20-characters'),
        });
      } else if (specialCharacterRegex.test(e.target.value)) {
        const userValidation = await userValidityCheck(userNameSpaceReplace);
        if (userValidation?.message === 'DuplicateUsernameFound') {
          setSignUpErr({
            ...signUpErr,
            username: t('USERNAME_DUPLICATE_ERROR'),
          });
        }
      } else {
        setSignUpErr({
          ...signUpErr,
          username: t(
            'username-must-contain-only-lowercase-alphanumeric-characters',
          ),
        });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'code') {
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ''),
      });
      setSignUpErr({ ...signUpErr, [e.target.name]: '' });
    } else if (e.target.name === 'phone_number') {
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ''),
      });
      if (e.target.value.length <= INITIAL_VALUE) {
        setSignUpErr({
          ...signUpErr,
          [e.target.name]: t('please-fill-the-required-details'),
        });
      } else if (e.target.value.length !== TEN) {
        setSignUpErr({
          ...signUpErr,
          [e.target.name]: t('enter-a-valid-phone-number'),
        });
      } else {
        setSignUpErr({
          ...signUpErr,
          [e.target.name]: '',
          validatePhoneNumber: '',
        });
      }
    } else if (e.target.name === 'email') {
      const validate = reg.test(e.target.value);
      setUserDetails({ ...userDetails, [e.target.name]: e.target.value });

      setSignUpErr({
        ...signUpErr,
        [e.target.name]: '',
        valideEmail:
          !e.target.value.trim() || validate
            ? ''
            : t('please-enter-valid-email-address'),
      });
    } else if (e.target.name === 'password') {
      setUserDetails({ ...userDetails, [e.target.name]: e.target.value });

      if (e.target.value.length <= INITIAL_VALUE) {
        setSignUpErr({
          ...signUpErr,
          [e.target.name]: t('please-fill-the-required-details'),
        });
      } else if (e.target.value.length < SIX) {
        setSignUpErr({
          ...signUpErr,
          [e.target.name]: t('PASSWORD_LENGTH_ERR_MSG'),
        });
      } else {
        setSignUpErr({ ...signUpErr, [e.target.name]: '' });
      }
    } else {
      setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
      setSignUpErr({ ...signUpErr, [e.target.name]: '' });
    }

    setPasswordsMatch(false);
    setPassword(false);
  };

  const showSignIn = () => openSignIn();

  const resendOtp = () => {
    if (sendOtp) {
      authService
        .resendSignUp(`91${userDetails.phone_number}`)
        .then(setSendOtp(false), setResetTimer(true));
    }
  };

  /**
   * Function to prevent increment & decrement of value by pressing arrow up/down key
   */
  const handleKeyEvent = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            backgroundColor: isDarkMode ? '#2C2C2C' : '#FFFFFF',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ThreeDots
            height='80'
            width='80'
            radius='9'
            color={isDarkMode ? '#484848' : '#D9D9D9'}
            ariaLabel='loading'
            wrapperStyle
            wrapperClass
          />
        </Box>
      ) : !showCodeForm ? (
        <div id='signup-form'>
          <form onSubmit={handleSignUpSubmit}>
            <Stack>
              <div className='signupFlexContainer'>
                <TextField
                  error={
                    signUpErr.email || signUpErr.valideEmail ? true : false
                  }
                  helperText={
                    signUpErr.valideEmail
                      ? signUpErr.valideEmail
                      : signUpErr.email
                      ? signUpErr.email
                      : ''
                  }
                  inputRef={inputRef}
                  className={'signupTextField'}
                  label={t('email-address')}
                  variant='outlined'
                  name='email'
                  placeholder={t('email-address-optional')}
                  value={userDetails.email}
                  onChange={handleChange}
                />
              </div>
            </Stack>
            <Stack>
              <div className='signupFlexContainer'>
                <TextField
                  onKeyUp={handleKeyEvent}
                  onKeyDown={handleKeyEvent}
                  onWheel={(event) => {
                    event?.preventDefault();
                  }}
                  error={
                    signUpErr.phone_number || signUpErr.validatePhoneNumber
                      ? true
                      : false
                  }
                  helperText={
                    signUpErr.validatePhoneNumber
                      ? signUpErr.validatePhoneNumber
                      : signUpErr.phone_number
                      ? signUpErr.phone_number
                      : ''
                  }
                  className={'signupTextField signupPhoneNumber'}
                  label={t('phone-number')}
                  variant='outlined'
                  name='phone_number'
                  value={userDetails.phone_number}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, 10);
                  }}
                />
              </div>
              <Typography
                sx={{
                  margin: ' 0.45rem',
                  marginLeft: '1.3rem',
                  fontSize: '0.75rem',
                  color: (t) =>
                    isDarkMode
                      ? t.palette.minmini_grey.grey
                      : t.palette.minmini_grey.grey,
                }}
              >
                {t('outside-country-phone')}
              </Typography>
            </Stack>
            <Stack>
              <div className='signupFlexContainer'>
                <TextField
                  error={signUpErr.username ? true : false}
                  className={'signupTextField'}
                  label={t('username')}
                  variant='outlined'
                  name='username'
                  value={userDetails.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={signUpErr.username ? signUpErr.username : ''}
                />
              </div>
            </Stack>
            <Stack>
              <div className='signupFlexContainer'>
                <TextField
                  error={signUpErr.password || isPassword ? true : false}
                  className={'signupTextField'}
                  label={t('password')}
                  variant='outlined'
                  name='password'
                  value={userDetails.password}
                  onChange={handleChange}
                  type='password'
                  helperText={signUpErr.password ? signUpErr.password : ''}
                />
              </div>
            </Stack>
            <Stack>
              <div className='signupFlexContainer'>
                <TextField
                  error={signUpErr.confirmPassword || isPassword ? true : false}
                  className={'signupTextField'}
                  label={t('confirm-password')}
                  variant='outlined'
                  name='confirmPassword'
                  value={userDetails.confirmPassword}
                  onChange={handleChange}
                  type='password'
                  helperText={
                    signUpErr.confirmPassword ? signUpErr.confirmPassword : ''
                  }
                />
              </div>
            </Stack>
            {isPasswordsMatch && (
              <Stack spacing={4} alignItems={'center'}>
                <Box
                  className='signUpFlexContainer'
                  sx={{
                    maxWidth: '18.75rem',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: '0.125rem',
                      fontWeight: '600',
                      color: (theme) => theme.palette.success.main,
                    }}
                  >
                    {t('passwords-match')}
                  </Typography>
                </Box>
              </Stack>
            )}
            <Stack>
              {errMsg && !showInfoModal && (
                <div className='signupFlexContainer'>
                  <p className='error-message'>{errMsg}</p>
                </div>
              )}
            </Stack>
            <Stack>
              <div className='signupFlexContainer'>
                <div style={{ maxWidth: '18.75rem' }}>
                  <Typography
                    variant='subtitle4'
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                    }}
                    component='span'
                  >
                    {t('by-signing-up-you-agree-to-our')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      color: (theme) => theme.palette.primary.main,
                      margin: '0 0.188rem',
                      cursor: 'pointer',
                    }}
                    component='span'
                    onClick={() =>
                      window.open(
                        '/terms-of-use',
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  >
                    {t('terms-of-use')}
                  </Typography>
                  <Typography
                    variant='subtitle4'
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                    }}
                    component='span'
                  >
                    ,
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      color: (theme) => theme.palette.primary.main,
                      margin: '0 0.188rem',
                      cursor: 'pointer',
                    }}
                    component='span'
                    onClick={() =>
                      window.open(
                        '/community-guidelines',
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  >
                    {t('community-guidelines')}
                  </Typography>
                  <Typography
                    variant='subtitle4'
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                    }}
                    component='span'
                  >
                    ,
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      color: (theme) => theme.palette.primary.main,
                      margin: '0 0.188rem',
                      cursor: 'pointer',
                    }}
                    component='span'
                    style={{ marginRight: 0 }}
                    onClick={() =>
                      window.open(
                        '/citizen-journalism-guidelines',
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  >
                    {t('cj-guidelines')}
                  </Typography>
                  <Typography
                    variant='subtitle4'
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      margin: '0 0.188rem',
                    }}
                    component='span'
                  >
                    {t('and')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      color: (theme) => theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    component='span'
                    style={{ marginRight: 0 }}
                    onClick={() =>
                      window.open(
                        '/privacy-policy',
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  >
                    {t('privacy-policy')}
                  </Typography>
                  <Typography
                    variant='subtitle4'
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                    }}
                    component='span'
                  >
                    .
                  </Typography>
                </div>
              </div>
            </Stack>
            <Stack>
              <div className='signupFlexContainer'>
                <Box className='signUpBtn'>
                  <ButtonLoader
                    variant='contained'
                    type='submit'
                    loading={signUpLoading}
                  >
                    {t('signup')}
                  </ButtonLoader>
                </Box>
              </div>
            </Stack>
          </form>
          <Stack>
            {/* <Stack spacing={2}>
              <Divider>
                <Typography
                  variant='body1'
                  sx={{
                    marginTop: '1.25rem',
                    marginBottom: '1rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[600]
                      : LIGHT_COLOR.grey[400],
                    fontSize: '0.75rem',
                  }}
                  className='signUp-line-content'
                >
                  {t('or')}
                </Typography>
              </Divider>
            </Stack> */}
            {/* <Stack>
              <div className='signupFlexContainer'>
                <Typography
                  variant='body1'
                  sx={{
                    marginTop: '0.62rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[600]
                      : LIGHT_COLOR.grey[400],
                  }}
                >
                  {t('sign-up-with')}
                </Typography>
              </div>
            </Stack> */}
            {/* <div className='signupFlexContainer'>
              <SocialSignIn />
            </div> */}
            <Stack>
              <Divider
                variant='fullWidth'
                sx={{ marginBottom: '1.25rem', marginTop: '1.813rem' }}
              />
            </Stack>
            <Stack spacing={2}>
              <LaptopMin>
                <Box className='signupFlexContainer'>
                  <Typography variant='body1'>
                    {t('already-have-an-account?')}{' '}
                    <span className='navLink' onClick={showSignIn}>
                      {' '}
                      {t('log-in')}{' '}
                    </span>
                  </Typography>
                </Box>
              </LaptopMin>
              <MobileMax>
                <Box className='signupFlexContainer'>
                  <Typography variant='body1'>
                    {t('already-have-an-account?')}
                  </Typography>
                </Box>
                <div className='signupFlexContainer'>
                  <Button
                    variant='outlined'
                    onClick={showSignIn}
                    sx={{ maxWidth: '18.75rem', width: '100%' }}
                  >
                    {t('log-in')}
                  </Button>
                </div>
              </MobileMax>
            </Stack>
          </Stack>
        </div>
      ) : (
        <>
          {!showSuccessMsg ? (
            <form onSubmit={handleConfirmCode}>
              <Stack>
                <div>
                  <Typography
                    sx={{
                      marginBottom: '0.625rem',
                      fontWeight: '600',
                      fontSize: '1.5rem',
                      color: isDarkMode
                        ? DARK_COLOR.white[100]
                        : LIGHT_COLOR.grey[600],
                      textAlign: 'center',
                    }}
                  >
                    {t('verify-your-phone')}
                  </Typography>
                </div>
              </Stack>
              <Stack>
                <div>
                  <Typography
                    sx={{
                      color: isDarkMode
                        ? DARK_COLOR.grey[600]
                        : LIGHT_COLOR.grey[400],
                      marginBottom: '2.5rem',
                      textAlign: 'center',
                    }}
                  >
                    {t('we-have-sent-a-6-digit-otp-to-your-phone')}
                  </Typography>
                </div>
              </Stack>
              <Stack>
                <div className='signupFlexContainer'>
                  <TextField
                    inputRef={inputRef}
                    className='signupTextField'
                    label={
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === 'dark'
                              ? DARK_COLOR.grey[400]
                              : LIGHT_COLOR.grey[300],
                        }}
                      >
                        {t('enter-6-digit-otp')}
                      </Typography>
                    }
                    variant='outlined'
                    name='code'
                    value={userDetails.code}
                    error={signUpErr?.code?.length ? true : false}
                    helperText={signUpErr?.code ? signUpErr?.code : ''}
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 6);
                    }}
                    onKeyUp={handleKeyEvent}
                    onKeyDown={handleKeyEvent}
                  />
                </div>
              </Stack>
              <Stack>
                <Box className='signupFlexContainer'>
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ maxWidth: '18.75rem' }}
                  >
                    <div className='signupCounter'>
                      <Timer
                        resetTimer={resetTimer}
                        resetOtp={() => setSendOtp(true)}
                        hideTimer={sendOtp}
                      />
                    </div>
                    <div
                      className={
                        sendOtp ? 'signupResendCode' : 'disableSignupResendCode'
                      }
                      disabled={true}
                      onClick={resendOtp}
                    >
                      {' '}
                      <Typography
                        sx={{
                          fontWeight: '600',
                          color: isDarkMode
                            ? DARK_COLOR.primary[50]
                            : LIGHT_COLOR.primary[100],
                        }}
                      >
                        {t('cresend-otp')}
                      </Typography>
                    </div>
                  </Grid>
                </Box>
              </Stack>
              <Stack>
                {errMsg && (
                  <div className='signupFlexContainer'>
                    <p className='error-message'>{errMsg}</p>
                  </div>
                )}
              </Stack>
              <Stack>
                <div className='signupFlexContainer'>
                  <Box className='signUpBtn'>
                    <Button variant='contained' type='submit'>
                      {t('cverify')}
                    </Button>
                  </Box>
                </div>
              </Stack>
            </form>
          ) : (
            <div className='verifiedMessage'>
              <form onSubmit={handlePublisher}>
                <Stack>
                  <div className='signupFlexContainer'>
                    <img
                      src={completedIcon}
                      alt='completedIcon'
                      className='completeIcon'
                    />
                  </div>
                </Stack>
                <Stack>
                  <div className='signupFlexContainer'>
                    <Typography
                      sx={{
                        fontWeight: '600',
                        color: isDarkMode
                          ? DARK_COLOR.white[100]
                          : LIGHT_COLOR.grey[600],
                      }}
                    >
                      {t('PHONE_VERIFIED_SUCCESSFULLY')}
                    </Typography>
                  </div>
                </Stack>
              </form>
            </div>
          )}
        </>
      )}

      {showInfoModal && (
        <DialogBox
          isOpen={showInfoModal}
          onClose={() => {
            setShowInfoModal(false);
            setErrMsg('');
          }}
          title={t('ERROR')}
          content={errMsg}
          primaryButtonAction={() => {
            setShowInfoModal(false);
            setErrMsg('');
          }}
          primaryButtonText={t('ok')}
        />
      )}
    </>
  );
};

export default withCaptcha(SignUpForm);
