import React, { useEffect, useState, useContext, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Menu,
  Button,
  Typography,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Badge,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  InputBase,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ModalBox from '../Modal/Modal';
import SignInForm from '../SingInForm/SignInForm';
import SignUpForm from '../SignUpForm/SignUpForm';
import classes from './Navbar.module.css';
import logo from '../../assets/images/minmini-text-logo.png';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import Preferences from '../Preferences/Preferences';
import FollowingTab from '../FollowingTab/FollowingTab';
import avatarIcon from '../../assets/defaultProfilePic.png';
import avatarIconSmall from '../../assets/defaultProfilePic.png';
import SettingPrivacy from '../SettingsPrivacy/SettingPrivacy';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import ReporterModal from '../Reporter/ReporterModal';
import {
  TRUE,
  TAMIL,
  PDF_FILE_NAME,
  EKYC_URL,
  ASSESSMENT_URL,
  INITIAL_VALUE,
  TRAINING_URL,
  REPORTER_ID_FILE_NAME,
  CITIZEN_REPORTER_CAPITAL,
  PUBLISHER_TYPE,
  UGC_REPORTER,
  INFLUENCER,
  ENTER,
  FALSE,
} from '../../Constants/Constants';
import ProfileModal from '../Modal/ProfileModal';
import { saveAs } from 'file-saver';
import NotificationDropdown from '../Notifications/NotificationDropdown';
import assessmentService from '../../services/assessment.service';
import authService from '../../services/auth.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import Verify from '../Profile/Verify';
import TickIcon from '../../assets/icons/TickIcon';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import notificationService from '../../services/notification.service';
import { AppContext } from '../../store/app-context';
import DialogBox from '../DialogBox/DialogBox';
import NavbarProfile from './NavbarProfile';
import {
  DARK_COLOR,
  LIGHT_COLOR,
  MINMINI_V3,
} from '../../themes/color.constant';
import ToasterWarningIcon from '../../assets/icons/toaster-warning.svg';
import ToasterWarningIconDark from '../../assets/icons/toaster-warning-dark.svg';
import { META_TAG_TITLE } from '../../Constants/meta.title.constant';
import { EnvironmentProvider } from '../../store/firebase-context';
import NotificationIcon from '../../assets/icons/NotificationIcon';
import SearchIcon from '../../assets/icons/SearchIcon';
import EditIcon from '../../assets/icons/EditIcon';
import useDebounce from '../../hooks/useDebounce';
import { Close } from '@mui/icons-material';
import SearchIconMobile from '../../assets/icons/SearchIconMobile';

export default function NavBar({ navBarCategories, navBarFilters }) {
  const dispatchMetaAction = useMetaStore()[1];
  const initailFullName = {
    firstName: '',
    lastName: '',
  };
  const { i18n, t } = useTranslation();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotificationDropdown, setOpenNotificationDropdown] =
    useState(null);
  const [changeModalSize, setChangeModalSize] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [showFollowingTab, setShowFollowingTab] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showAppearanceTitle, setShowAppearanceTitle] = useState('');
  const [backIcon, setBackIcon] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [showReporterModal, setShowReporterModal] = useState(false);
  const [showKYCTitle, setShowKYCTitle] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { state: appCtxState, dispatch: appCtxDispatch } =
    useContext(AppContext);
  const isFromBeforeSignIn = localStorage.getItem('isFromBeforeSignIn');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [fullname, setFullname] = useState(initailFullName);
  const [userFullName, setUserFullName] = useState('');
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [verifyModal, setVerifyModal] = useState(true);
  const [smallModalSize, setSmallModalSize] = useState(false);
  const [verificationType, setVerificationType] = useState('');
  const [topicsFollowCount, setTopicsFollowCount] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  const [showBecomeBtn, setShowBecomeBtn] = useState(false);
  const { state: userState, dispatch } = useContext(UserDetailsProvider);
  const userId = userState?.userDetails?.userId;
  const userName = userState?.userDetails?.userName;
  const firstName = userState?.userDetails?.firstName;
  const lastName = userState?.userDetails?.lastName;
  const isCitizenReporter =
    userState?.userDetails?.userType === CITIZEN_REPORTER_CAPITAL
      ? TRUE
      : FALSE;
  const userType = userState?.userDetails?.userType;
  const showBlueTickIcon = !![
    PUBLISHER_TYPE,
    UGC_REPORTER,
    INFLUENCER,
  ].includes(userType);
  const isDTVerifiedReporter =
    userState?.userDetails.userType === UGC_REPORTER ? TRUE : FALSE;
  const isInfluencer =
    userState?.userDetails.userType === INFLUENCER ? TRUE : FALSE;
  const following = userState?.following;
  const [notificationsList, setNotificationsList] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isCountLoading, setIsCountLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isNavigatedToSearch, setIsNavigatedToSearch] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = MaxWidth(viewPort.mobile);
  const isSmallScreen = MaxWidth(viewPort.mobileMax);
  const isDesktop = MinWidth(viewPort.dekstopView);
  let navigate = useNavigate();
  const history = useLocation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isAuthenticated = userState?.isAuthenticated;
  const isKYCDone = userState?.citizenJournalist?.isKYCDone;
  const debouncedValue = useDebounce(searchTerm, 300);

  const { environment } = useContext(EnvironmentProvider);
  const [profileUrl, setProfileUrl] = useState('');
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  useEffect(() => {
    if (
      isCitizenReporter === TRUE ||
      isDTVerifiedReporter === TRUE ||
      isInfluencer === TRUE
    ) {
      setShowBecomeBtn(false);
    } else {
      setShowBecomeBtn(true);
    }
  }, [userState]);

  useEffect(() => {
    if (userId) {
      const path = history.pathname;
      let destination = path;
      if (!(isDTVerifiedReporter === '' || isCitizenReporter === '')) {
        if (isFromBeforeSignIn) {
          if (!(isDTVerifiedReporter === TRUE || isCitizenReporter === TRUE)) {
            destination = isKYCDone === TRUE ? TRAINING_URL : EKYC_URL;
          }
          appCtxDispatch({ type: 'HIDE_LOGIN_BEFORE_SIGNIN' });
          navigate(destination);
        } else {
          navigate(destination);
        }
      }
    }
  }, [userId, isFromBeforeSignIn, isDTVerifiedReporter, isCitizenReporter]);

  useEffect(() => {
    history.pathname === TRAINING_URL ||
    history.pathname === ASSESSMENT_URL ||
    history.pathname === EKYC_URL
      ? setDisableBtn(true)
      : setDisableBtn(false);
    if (history.pathname !== '/search') {
      setIsNavigatedToSearch(false);
      setSearchTerm('');
    }
  }, [history]);

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (appCtxState?.showLogin) {
        setShowLogin(true);
      } else if (appCtxState?.showSignUp) {
        setShowSignUp(true);
      }
    }
  }, [appCtxState?.showLogin, appCtxState?.showSignUp]);

  useEffect(() => {
    if (
      fullname?.firstName?.length > INITIAL_VALUE &&
      fullname?.lastName?.length > INITIAL_VALUE
    ) {
      setUserFullName(fullname?.firstName + ' ' + fullname?.lastName);
    } else {
      setUserFullName('');
    }
  }, [fullname]);

  /**
   * Side Effect to display signup publisher modal if user closes the browser after entering OTP
   */

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (userId && following?.topics?.length < 3 && !showFollowingTab) {
        setShowPreferences(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userId, following, showFollowingTab]);

  /**
   * Side Effect to Fetch Profile Picture with userId, history.state as dependency
   */
  useEffect(() => {
    if (userId) {
      setProfileUrl(`${environment.userprofile_prefix_url}${userId}`);
    }
  }, [userId]);

  /**
   * Function to show warning modals if Following Tab button close button is clicked or user clicks browser reload button and
   * topic follow count and publisher follow count is less than 3
   */

  const handleFollowCountWarningModals = () => {
    if (topicsFollowCount === null || topicsFollowCount >= 3) {
      setShowFollowingTab(false);
      if (history.pathname === navBarFilters[0]?.url) {
        navigate(0);
      }
    } else if (topicsFollowCount !== null && topicsFollowCount < 3) {
      setErrorAlertMessage(t('select-three-topics-info'));
      setShowErrorAlert(true);
    }
  };

  useEffect(() => {
    fetchPreferences();
  }, [history, userId]);

  useEffect(() => {
    const interval = setInterval(() => {
      userId && getNotificationsCount();
    }, 20 * 1000);
    return () => clearInterval(interval);
  }, [userId]);

  const fetchPreferences = async () => {
    setFullname({
      firstName: firstName,
      lastName: lastName,
    });
    if (!userId) {
      localStorage.getItem('lang') === TAMIL
        ? i18n.changeLanguage('tn')
        : i18n.changeLanguage('en');
    }
  };

  const signOutUser = async () => {
    try {
      await authService.signout();
      dispatch({ type: 'AUTHENTICATE_FAILED' });
    } catch (error) {}
  };

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    if (history.pathname !== '/assessment') {
      setAnchorEl(event.currentTarget);
    } else if (history.pathname === '/assessment') {
      appCtxDispatch({ type: 'SHOW_EXIT_POPUP' });
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   * Function to handle dropdown menu
   */
  const handleDropdownMenu = (event) => {
    if (!userId) {
      appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
    }

    if (history.pathname !== '/assessment') {
      setOpenNotificationDropdown(event.currentTarget);
      getNotificationsList();
    } else if (history.pathname === '/assessment') {
      appCtxDispatch({ type: 'SHOW_EXIT_POPUP' });
    }
  };

  const closeDropdownMenu = () => {
    updateNotificationStatus();
    setOpenNotificationDropdown(null);
  };

  const showLang = () => {
    setShowAppearanceTitle('ccontent-language');
    setBackIcon(true);
    setShowOptions(true);
  };

  const showAppearance = () => {
    setShowAppearanceTitle('appearance');
    setBackIcon(true);
    setShowOptions(true);
  };

  const showKYC = () => {
    setShowKYCTitle(true);
  };

  const navigateToHome = () => {
    navigate('/', { state: true });
  };

  /**
   * Function to close modal and navigate to home page with state as true
   */
  const closeProfileModal = () => {
    appCtxDispatch({ type: 'HIDE_PROFILE' });
  };

  const openFollowings = () => {
    setShowFollowingTab(true);
    handleMenuClose();
  };

  const openReporters = () => {
    setShowReporterModal(true);
    handleMenuClose();
  };

  const openSettingsModal = () => {
    changeHeight(true);
    handleMenuClose();
    setShowSettingsModal(true);
  };

  const goBack = () => {
    setBackIcon(false);
    setShowAppearanceTitle(false);
    setShowOptions(false);
  };

  /**
   * Function to show alternate image if onError triggered
   */
  const replaceImage = (error) => {
    userFullName.length === 0
      ? (error.target.src = isMobile ? avatarIconSmall : avatarIcon)
      : setProfileUrl('');
  };

  /**
   * Fetching Notifications Count from the api
   */
  const getNotificationsCount = async () => {
    try {
      const response =
        await notificationService.fetchNotificationsUnreadCount();

      if (response) {
        setNotificationsCount(response?.notification_count);
        setIsCountLoading(false);
      }
    } catch (error) {
      setIsCountLoading(false);
    }
  };

  /**
   * Fetching Notifications List from the api
   */
  const getNotificationsList = async () => {
    if (userId) {
      try {
        const response = await notificationService.fetchNotificationsList();

        if (response) {
          setNotificationsList(response?.notificationList || []);
          setIsLoading(false);
        }
      } catch (error) {}
    }
  };

  /**
   * update notification status
   */

  const updateNotificationStatus = async () => {
    const readAvailable = notificationsList.find(
      (list) => list.status === 'UNREAD',
    );
    if (readAvailable) {
      try {
        const response = await notificationService.updateNotificaitionStatus();
        if (response) {
          notificationService.fetchNotificationsUnreadCount();
        }
      } catch (error) {}
    }
  };

  /*
   * On Update, we call this method to fetch the latest status of the notifications
   */
  const onUpdate = (id) => {
    const notifObj = notificationsList?.find((obj) => obj.id === id);
    if (notifObj) {
      notifObj.status = 'READ';
      setNotificationsList([...notificationsList]);
    }
  };

  const firstFiveNotifications = notificationsList.slice(0, 5);

  const generatePDF = async () => {
    const data = {
      userId: userId ? userId : localStorage.getItem('userID'),
    };

    try {
      const response = await assessmentService.downloadCertificate(data);

      if (response) {
        saveAs(response, PDF_FILE_NAME);
        handleMenuClose();
      }
    } catch (error) {}
  };

  /**
   * Function to Download reporter ID
   */
  const generateReporterID = async () => {
    try {
      const response = await assessmentService.downloadReporterID();

      if (response) {
        saveAs(response, REPORTER_ID_FILE_NAME);
        handleMenuClose();
      }
    } catch (error) {}
  };

  const goToCreateAReport = () => {
    navigate('/create-a-report');
    handleMenuClose();
  };

  useEffect(() => {
    handleSearch(debouncedValue);
  }, [debouncedValue]);

  const handleSearch = (e) => {
    if (debouncedValue?.length >= 2 || e.key === ENTER) {
      if (!isNavigatedToSearch) {
        setIsNavigatedToSearch(true);
        navigate('/search');
      }
      appCtxDispatch({
        type: 'SET_SEARCH_TERM',
        payload: debouncedValue,
      });
    }
    if (isNavigatedToSearch && debouncedValue === '') {
      navigate('/');
      setIsNavigatedToSearch(false);
      setSearchTerm('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === ENTER) {
      handleSearch(searchTerm);
    }
  };

  const handlePreventAssessementExit = () => {
    history.pathname === '/assessment' &&
      appCtxDispatch({ type: 'SHOW_EXIT_POPUP' });
    if (history.pathname !== '/assessment') {
      const metaObj = {
        title: META_TAG_TITLE.search,
        description: META_TAG_TITLE.search,
        keywords: '',
        canonical_url: history.pathname,
      };
      dispatchMetaAction(metaAction.updateMeta, metaObj);
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      marginThreshold={isMobile && 0}
      PaperProps={{
        style: {
          width: isMobile && '100%',
          height: isMobile && '100%',
          maxWidth: isMobile && '100%',
          maxHeight: isMobile && '100%',
        },
      }}
      sx={{
        '& .MuiPaper-root': {
          '&::-webkit-scrollbar': {
            width: '0.4rem',
          },

          '$::-webkit-scrollbar-track': {
            borderRadius: '5px',
            backgroundColor: (theme) => theme.palette.white[100],
          },

          '$::-webkit-scrollbar-track:active': {
            backgroundColor: (theme) => theme.palette.white[100],
          },
          '$::-webkit-scrollbar-thumb': {
            borderRadius: '5px',
            backgroundColor: (theme) => theme.palette.grey[600],
          },
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box
        className={classes.menuBox}
        sx={{
          backgroundColor: isDarkMode ? (theme) => theme.palette.grey[200] : '',
        }}
      >
        {isAuthenticated ? (
          <>
            {isMobile && <NavbarProfile handleMenuClose={handleMenuClose} />}
            <Box
              sx={{
                margin: '0.65rem 1.25rem',
                cursor: 'pointer',
              }}
            >
              <MenuList>
                <MenuItem
                  sx={{
                    px: 0,
                    background: 'inherit',
                    '&:hover': {
                      backgroundColor: 'inherit',
                    },
                  }}
                >
                  <ListItemIcon>
                    <Badge
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      invisible={
                        userType !== CITIZEN_REPORTER_CAPITAL &&
                        !showBlueTickIcon
                      }
                      sx={{
                        '& .MuiBadge-badge': {
                          right: '0.25rem',
                          top: '0.375rem',
                        },
                      }}
                      badgeContent={
                        <TickIcon
                          fill={
                            userType === CITIZEN_REPORTER_CAPITAL
                              ? MINMINI_V3.tick.voilet
                              : showBlueTickIcon && MINMINI_V3.tick.blue
                          }
                          fontSize='1rem'
                        />
                      }
                    >
                      {profileUrl || userFullName.length === 0 ? (
                        <img
                          onError={replaceImage}
                          src={
                            `${
                              environment.userprofile_prefix_url
                            }${userId}?${Date.now()}`
                              ? `${
                                  environment.userprofile_prefix_url
                                }${userId}?${Date.now()}`
                              : avatarIcon
                          }
                          alt='avatar'
                          className={classes.navAvatarMenu}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: (theme) =>
                              theme.palette.avatar[100],
                            padding: '0.25rem',
                            fontWeight: '600',
                          }}
                          className={
                            isCitizenReporter === TRUE
                              ? classes.loggedavatarLetterMenu
                              : classes.avatarLetterMenu
                          }
                        >
                          {fullname?.firstName?.length &&
                            fullname?.lastName?.length &&
                            fullname.firstName[0].toUpperCase() +
                              fullname.lastName[0].toUpperCase()}
                        </Box>
                      )}
                    </Badge>
                  </ListItemIcon>
                  <ListItemText>
                    {isAuthenticated && (
                      <Typography
                        sx={{
                          fontWeight: 900,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'justify',
                          ml: '0.75rem',
                          lineHeight: '1.25rem',
                        }}
                        variant='subtitle1'
                      >
                        {userState?.userDetails?.profileName}
                      </Typography>
                    )}
                  </ListItemText>
                  <Typography variant='body2' color='text.secondary'>
                    <IconButton
                      onClick={() => {
                        setShowLogin(true);
                        appCtxDispatch({ type: 'SHOW_PROFILE' });
                        handleMenuClose();
                      }}
                    >
                      <EditIcon
                        fontSize='1.5rem'
                        styleProps={{
                          ml: '0.6 rem',
                        }}
                        fill={
                          isDarkMode
                            ? DARK_COLOR.white[100]
                            : LIGHT_COLOR.grey[200]
                        }
                        stroke={
                          isDarkMode
                            ? DARK_COLOR.white[100]
                            : LIGHT_COLOR.grey[400]
                        }
                      />
                    </IconButton>
                  </Typography>
                </MenuItem>
              </MenuList>
            </Box>
            <Divider variant='fullWidth' />
            {showBecomeBtn ? (
              <>
                <div className={classes.becomeAReporterButton}>
                  <Button
                    fullWidth={true}
                    color='primary'
                    variant='contained'
                    onClick={disableBtn ? undefined : openReporters}
                    className={classes.becomeAReporterButtonName}
                  >
                    {t('become-a-reporter')}
                  </Button>
                </div>
                <Divider variant='fullWidth' />
              </>
            ) : (
              <>
                {isCitizenReporter === TRUE && (
                  <>
                    <Button
                      color='primary'
                      variant='text'
                      sx={{
                        justifyContent: 'flex-start',
                        paddingLeft: '1.25rem',
                      }}
                      className={classes.becomeAReporterTextButton}
                      onClick={() => generatePDF()}
                    >
                      {t('download-certificate')}
                    </Button>

                    <Divider variant='fullWidth' />

                    <Button
                      color='primary'
                      variant='text'
                      sx={{
                        justifyContent: 'flex-start',
                        paddingLeft: '1.25rem',
                      }}
                      className={classes.becomeAReporterTextButton}
                      onClick={() => generateReporterID()}
                    >
                      {t('DOWNLOAD_REPORTER_ID')}
                    </Button>
                    <Divider variant='fullWidth' />
                  </>
                )}
                {(isCitizenReporter === TRUE ||
                  isDTVerifiedReporter === TRUE) && (
                  <>
                    <Box sx={{ padding: '1.25rem' }}>
                      <Button
                        fullWidth
                        color='primary'
                        variant='contained'
                        sx={{ fontWeight: '600' }}
                        onClick={() => goToCreateAReport()}
                      >
                        {t('create-a-report')}
                      </Button>
                    </Box>
                    <Divider variant='fullWidth' />
                  </>
                )}
              </>
            )}
            {(isCitizenReporter === TRUE || isDTVerifiedReporter === TRUE) && (
              <>
                <NavLink
                  to={`/author-news/${userName}`}
                  className={classes.navLink}
                >
                  <div
                    className={classes.navMenuItem}
                    onClick={() => handleMenuClose()}
                  >
                    <Typography variant='body1'>
                      {t('view-my-profile')}
                    </Typography>
                  </div>
                </NavLink>
                <NavLink to='/drafts' className={classes.navLink}>
                  <div
                    className={classes.navMenuItem}
                    onClick={() => handleMenuClose()}
                  >
                    <Typography variant='body1'>{t('drafts')}</Typography>
                  </div>
                </NavLink>
              </>
            )}
            <NavLink to='/bookmarks' className={classes.navLink}>
              <div
                className={classes.navMenuItem}
                onClick={() => handleMenuClose()}
              >
                <Typography variant='body1'>{t('bookmarks')}</Typography>
              </div>
            </NavLink>
            <div onClick={openFollowings} className={classes.navMenuItem}>
              <Typography variant='body1'>{t('following')}</Typography>
            </div>
            <div onClick={openSettingsModal} className={classes.navMenuItem}>
              <Typography variant='body1'>
                {t('settings-&-preferences')}
              </Typography>
            </div>
            <NavLink to='/' className={classes.navLink}>
              <div
                onClick={() => {
                  signOutUser();
                  handleMenuClose();
                }}
                className={classes.navMenuItem}
              >
                <Typography variant='body1'> {t('log-out')}</Typography>
              </div>
            </NavLink>
          </>
        ) : (
          <>
            {isMobile && <NavbarProfile handleMenuClose={handleMenuClose} />}
            <div
              onClick={() => {
                setShowLogin(true);
                handleMenuClose();
              }}
              className={classes.MenuItem}
            >
              <img
                src={avatarIcon}
                alt='avatar'
                className={classes.navAvatarMenu}
                style={{ marginRight: '0.813rem' }}
              />
              {t('clogin/sign-up')}
            </div>
            <Divider variant='fullWidth' />

            <div onClick={openSettingsModal} className={classes.navMenuItem}>
              <Button
                variant='contained'
                sx={{ width: '100%' }}
                onClick={openReporters}
              >
                {t('become-a-reporter')}
              </Button>
            </div>
            <Divider variant='fullWidth' />

            <div onClick={openSettingsModal} className={classes.navMenuItem}>
              <Typography variant='body1'>
                {t('settings-&-preferences')}
              </Typography>
            </div>
          </>
        )}
      </Box>
    </Menu>
  );

  const renderDropdownMenu = (
    <Menu
      anchorEl={openNotificationDropdown}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={openNotificationDropdown}
      onClose={closeDropdownMenu}
      className={classes.openDropdownMenu}
      sx={{
        '& .MuiPaper-root': {
          marginTop: '1.8rem',
        },
      }}
    >
      <NotificationDropdown
        onClick={closeDropdownMenu}
        open={closeDropdownMenu}
        notifications={firstFiveNotifications}
        getNotificationsList={getNotificationsList}
        onUpdate={onUpdate}
        isLoading={isLoading}
      />
    </Menu>
  );

  const closeModal = () => {
    setShowLogin(false);
    setShowSignUp(false);
    setShowForgotPassword(false);
    setChangeModalSize(false);
    setShowPreferences(false);
    setShowFollowingTab(false);
    appCtxDispatch({ type: 'HIDE_PROFILE' });
    setShowAppearanceTitle('');
    setBackIcon(false);
    setShowOptions(false);
    setShowSettingsModal(false);
    setShowKYCTitle(false);
    setShowReporterModal(false);
    setTimeout(() => {
      fetchPreferences();
    }, 1000);
  };

  const openSignIn = () => {
    setShowSignUp(false);
    setShowLogin(true);
  };

  const openSignUp = () => {
    setShowSignUp(true);
    setShowLogin(false);
  };

  const changeHeight = (isChange) => {
    setChangeModalSize(isChange);
  };

  const openPassword = () => setShowForgotPassword(true);
  const openPreferences = () => {
    setTimeout(() => {
      setShowPreferences(true);
    }, 500);
  };

  const changeHeaderContent = (isChangeHeader) =>
    setShowChangePassword(isChangeHeader);

  /**
   * Function to Close Verify Modal
   */
  const closeVerifyModal = () => {
    setShowVerifyOtp(false);
    navigate('/', { state: true });
  };

  /**
   * Function to Change the height of Modal to small
   */
  const smallModal = () => {
    setVerifyModal(false);
    setSmallModalSize(true);
  };

  /**
   * Function to Check the type of Attribute to verify
   */
  const handleVerification = (type) => {
    appCtxDispatch({ type: 'HIDE_PROFILE' });
    setShowVerifyOtp(true);
    type === 'email'
      ? setVerificationType('email')
      : setVerificationType('phone_number');
  };

  const openCaptcha = () => setShowCaptcha(!showCaptcha);

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '0.906rem',
    backgroundColor: isFocused
      ? isDarkMode
        ? MINMINI_V3.grey.darkGrey
        : MINMINI_V3.grey.lightGrey
      : 'unset',
    border: isFocused
      ? isDarkMode
        ? 'none'
        : `1px solid ${MINMINI_V3.grey.grey}`
      : 'none',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: '0',
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 0.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const CloseIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '4px 0 4px 0',
      paddingLeft: 'calc(1em + 1.5rem)',
      color: isDarkMode
        ? MINMINI_V3.primary.white
        : MINMINI_V3.secondary.voilet,
      fontSize: '0.625rem',
      [theme.breakpoints.up('xs')]: {
        width: '0ch',
        '&:focus': {
          maxWidth: '30ch',
          width: '100%',
        },
      },
    },
  }));

  return (
    <>
      <AppBar
        elevation={0}
        position='sticky'
        sx={{
          px: isDesktop ? '3.25rem' : '6.25vw',
          borderBottom: isDesktop
            ? isDarkMode
              ? `2px solid ${MINMINI_V3.grey.darkGrey}`
              : `2px solid ${MINMINI_V3.primary.offWhite}`
            : '',
        }}
      >
        <Toolbar
          sx={{
            '&.MuiToolbar-root': {
              paddingLeft: 'unset',
              paddingRight: 'unset',
              alignItems: 'center',
              justifyContent: isSmallScreen ? 'center' : 'space-evenly',
              bgColor: (theme) => theme.palette.white[100],
              minHeight: aboveLap
                ? '6.25rem'
                : belowLap & aboveMobile
                ? '5.5rem'
                : '4.313rem',
              borderBottom: !isDesktop
                ? `1px solid ${
                    isDarkMode
                      ? MINMINI_V3.grey.midGrey
                      : MINMINI_V3.primary.offWhite
                  }`
                : '',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: isMobile ? '' : 'center',
                flexDirection: isSmallScreen ? 'column' : 'row',
              }}
            >
              <NavLink
                style={{
                  pointerEvents: 'visible',
                }}
                to='/'
              >
                <img
                  src={logo}
                  alt='minmini'
                  className={classes.logo}
                  style={{
                    height: aboveLap
                      ? '3.875rem'
                      : belowLap & aboveMobile
                      ? '3.125rem'
                      : '2.438rem',
                  }}
                />
              </NavLink>
            </Box>
            {!isMobile && (
              <TextField
                placeholder={`${t('search')} ......`}
                fullWidth
                type='text'
                variant='outlined'
                autoComplete='off'
                size='large'
                sx={{
                  mb: 0,
                  width: '50%',
                  '& .MuiInputBase-root': {
                    width: '90%',
                    maxWidth: aboveLap
                      ? '35.5rem'
                      : belowLap & aboveMobile
                      ? '31.875rem'
                      : 'none',
                    background: isDarkMode
                      ? MINMINI_V3.grey.darkGrey
                      : MINMINI_V3.grey.lightGrey,
                    height: aboveLap
                      ? '3.125rem'
                      : belowLap & aboveMobile
                      ? '2.813rem'
                      : 'none',
                    borderRadius: '0.625rem',
                  },

                  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: isDarkMode
                        ? 'transparent'
                        : MINMINI_V3.grey.grey,
                    },
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor: isDarkMode
                      ? 'transparent'
                      : MINMINI_V3.grey.grey,
                  },
                }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                onKeyDown={handleSearch}
                onClick={handlePreventAssessementExit}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon
                        styleProps={{
                          fontSize: '1.5rem',
                        }}
                        stroke={
                          isDarkMode
                            ? MINMINI_V3.grey.grey
                            : MINMINI_V3.grey.darkGrey
                        }
                      />
                    </InputAdornment>
                  ),
                  endAdornment: searchTerm && (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setSearchTerm('')}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isMobile && (
                <Search>
                  <SearchIconWrapper>
                    <SearchIconMobile
                      styleProps={{
                        fontSize: '2rem',
                      }}
                      stroke={
                        isDarkMode
                          ? MINMINI_V3.primary.white
                          : MINMINI_V3.grey.grey
                      }
                    />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={`${t('search')}...`}
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyDown={handleKeyDown}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    value={searchTerm}
                    onFocus={() => {
                      setIsFocused(true);
                    }}
                    onBlur={() => {
                      setIsFocused(false);
                    }}
                    sx={{
                      fontSize: '1.125rem',
                    }}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={isFocused}
                    onClick={handlePreventAssessementExit}
                  />
                  {searchTerm && isFocused && (
                    <CloseIconWrapper onClick={() => setSearchTerm('')}>
                      <IconButton>
                        <Close />
                      </IconButton>
                    </CloseIconWrapper>
                  )}
                </Search>
              )}
              {!isMobile && isAuthenticated && (
                <IconButton onClick={handleDropdownMenu}>
                  <Badge
                    color='primary'
                    badgeContent={notificationsCount}
                    invisible={isCountLoading}
                  >
                    <NotificationIcon
                      styleProps={{
                        fontSize: '1.9rem',
                      }}
                      stroke={
                        isDarkMode
                          ? MINMINI_V3.primary.white
                          : MINMINI_V3.secondary.voilet
                      }
                    />
                  </Badge>
                </IconButton>
              )}
              {!isMobile && isAuthenticated && renderDropdownMenu}

              {isAuthenticated ? (
                <Badge
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  invisible={
                    userType !== CITIZEN_REPORTER_CAPITAL && !showBlueTickIcon
                  }
                  sx={{
                    '& .MuiBadge-badge': {
                      right: '0.25rem',
                      top: '0.375rem',
                    },
                  }}
                  badgeContent={
                    <TickIcon
                      fill={
                        userType === CITIZEN_REPORTER_CAPITAL
                          ? MINMINI_V3.tick.voilet
                          : showBlueTickIcon && MINMINI_V3.tick.blue
                      }
                      fontSize='1rem'
                    />
                  }
                >
                  <Box
                    sx={{
                      ml: '1.25rem',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <>
                      {profileUrl || userFullName.length === 0 ? (
                        <img
                          onError={replaceImage}
                          src={
                            `${
                              environment.userprofile_prefix_url
                            }${userId}?${Date.now()}`
                              ? `${
                                  environment.userprofile_prefix_url
                                }${userId}?${Date.now()}`
                              : avatarIconSmall
                          }
                          alt='avatar'
                          style={{
                            width: isDesktop ? '1.875rem' : '1.813rem',
                            height: isDesktop ? '1.875rem' : '1.813rem',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            objectFit: 'cover',
                          }}
                          onClick={handleProfileMenuOpen}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: (theme) =>
                              theme.palette.avatar[100],
                            padding: '0.5rem',
                            fontWeight: '600',
                            width: isDesktop ? '1.875rem' : '1.813rem',
                            height: isDesktop ? '1.875rem' : '1.813rem',
                            lineHeight: '1.5rem',
                            textAlign: 'center',
                            borderRadius: '50%',
                          }}
                          onClick={handleProfileMenuOpen}
                        >
                          {fullname?.firstName?.length &&
                            fullname?.lastName?.length &&
                            fullname.firstName[0].toUpperCase() +
                              fullname.lastName[0].toUpperCase()}
                        </Box>
                      )}
                    </>
                  </Box>
                </Badge>
              ) : (
                <Box
                  sx={{
                    marginLeft: isMobile ? '0.5rem' : '1.25rem',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                >
                  <img
                    src={avatarIcon}
                    alt='avatar'
                    style={{
                      width: isDesktop ? '1.875rem' : '1.813rem',
                      height: isDesktop ? '1.875rem' : '1.813rem',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      objectFit: 'cover',
                    }}
                    onClick={handleProfileMenuOpen}
                  />
                  <ModalBox
                    showModal={showLogin}
                    closeModal={() => {
                      setShowLogin(false);
                      setChangeModalSize(false);
                      appCtxDispatch({ type: 'HIDE_LOGIN_BEFORE_SIGNIN' });
                      appCtxDispatch({ type: 'HIDE_SIGNUP' });
                    }}
                    modalHeader={
                      showChangePassword ? t('CHANGE_PASSWORD') : t('login')
                    }
                    isBackdropClick={true}
                    minModal={true}
                  >
                    <SignInForm
                      closeModal={closeModal}
                      openSignUp={openSignUp}
                      openPassword={openPassword}
                      changeHeight={changeHeight}
                      changeHeaderContent={changeHeaderContent}
                    />
                  </ModalBox>
                  <ModalBox
                    showModal={showSignUp}
                    closeModal={() => {
                      setShowSignUp(false);
                      setChangeModalSize(false);
                      appCtxDispatch({ type: 'HIDE_LOGIN_BEFORE_SIGNIN' });
                      appCtxDispatch({ type: 'HIDE_SIGNUP' });
                    }}
                    modalHeader={t('sign-up')}
                    modalSize={changeModalSize}
                    isBackdropClick={true}
                    isCaptcha={showCaptcha}
                  >
                    <SignUpForm
                      closeModal={closeModal}
                      openSignIn={openSignIn}
                      changeHeight={changeHeight}
                      openPreferences={openPreferences}
                      openCaptcha={openCaptcha}
                    />
                  </ModalBox>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <ModalBox
        showModal={showForgotPassword}
        closeModal={() => {
          setShowForgotPassword(false);
          setChangeModalSize(false);
        }}
        modalHeader={t('forgotpassword')}
        minModal={changeModalSize}
        isBackdropClick={true}
      >
        <ForgotPassword
          closeModal={closeModal}
          openSignIn={openSignIn}
          changeHeight={changeHeight}
        />
      </ModalBox>
      <ModalBox
        showModal={showPreferences}
        closeModal={() => setShowPreferences(false)}
        modalHeader={t('topics')}
        headerContent={
          <Typography
            variant='caption'
            sx={{
              color: isDarkMode
                ? (theme) => theme.palette.grey[600]
                : (theme) => theme.palette.grey[400],
              letterSpacing: 'unset',
              width: '13.938rem',
              textAlign: 'center',
              mt: '0.5rem',
            }}
          >
            {t('SELECT_THREE_TOPICS')}&nbsp;
            <Typography
              variant='caption'
              component='span'
              sx={{ fontWeight: 600 }}
            >
              {t('SFOR_YOU')}
            </Typography>
            &nbsp;{t('NEWS_FEED')}
          </Typography>
        }
        isHeaderChange={true}
        isBackdropClick={true}
      >
        <Preferences
          showPreferences={showPreferences}
          closeModal={closeModal}
          navBarCategories={navBarCategories}
        />
      </ModalBox>

      <ModalBox
        showModal={showFollowingTab}
        closeModal={handleFollowCountWarningModals}
        modalHeader={t('following')}
        isBackdropClick={true}
      >
        <FollowingTab
          closeModal={closeModal}
          setTopicsFollowCount={setTopicsFollowCount}
        />
      </ModalBox>

      <ModalBox
        showModal={showSettingsModal}
        closeModal={closeModal}
        modalHeader={
          showAppearanceTitle
            ? t(showAppearanceTitle)
            : t('settings-and-privacy')
        }
        smallModal={true}
        backIcon={backIcon}
        goBack={goBack}
        isBackdropClick={true}
      >
        <SettingPrivacy
          closeModal={closeModal}
          changeHeight={changeHeight}
          showLang={showLang}
          showOptions={showOptions}
          showAppearance={showAppearance}
        />
      </ModalBox>

      <ModalBox
        showModal={showReporterModal}
        closeModal={closeModal}
        modalHeader={
          showKYCTitle ? t('kyc-verification') : t('become-a-reporter')
        }
        modalSize={isAuthenticated ? changeModalSize : false}
        isBackdropClick={true}
      >
        <ReporterModal
          closeModal={closeModal}
          changeHeight={changeHeight}
          showKYC={showKYC}
        />
      </ModalBox>
      {appCtxState?.showProfile && (
        <ProfileModal
          showProfileModal={appCtxState?.showProfile}
          closeModal={closeProfileModal}
          changeHeight={changeHeight}
          navigateToHome={navigateToHome}
          handleVerification={handleVerification}
        />
      )}
      <ModalBox
        showModal={showVerifyOtp}
        closeModal={closeVerifyModal}
        modalHeader={t('verify')}
        modalSize={verifyModal}
        isBackdropClick={true}
        smallModal={smallModalSize}
      >
        <Verify smallModal={smallModal} verificationType={verificationType} />
      </ModalBox>
      <DialogBox
        isOpen={appCtxState?.showLoginRequired}
        onClose={() => {
          appCtxDispatch({ type: 'HIDE_LOGIN_REQUIRED' });
        }}
        title={t('LOGIN_REQUIRED')}
        content={t('LOGIN_REQUIRED_MESSAGE')}
        primaryButtonAction={() => {
          appCtxDispatch({ type: 'PROCEED_TO_LOGIN' });
        }}
        primaryButtonText={t('PROCEED_TO_LOGIN')}
        secondaryButtonAction={() => {
          appCtxDispatch({ type: 'HIDE_LOGIN_REQUIRED' });
        }}
        secondaryButtonText={t('cancel')}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showErrorAlert}
        autoHideDuration={6000}
        onClose={() => setShowErrorAlert(false)}
        sx={{ opacity: 0.9, width: '100%' }}
      >
        <Alert
          onClose={() => setShowErrorAlert(false)}
          variant='filled'
          severity='error'
          icon={
            <img
              alt='warning icon'
              src={isDarkMode ? ToasterWarningIconDark : ToasterWarningIcon}
            />
          }
          sx={{
            borderRadius: '0.625rem',
            py: '0.1875rem',
            alignItems: 'center',
            maxWidth: '20rem',
            width: '100%',
            backgroundColor: isDarkMode
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.toaster.error,
            '& .MuiAlert-message': {
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.toaster.errorBorder,
              fontWeight: 600,
            },
            '& .MuiAlert-action': {
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.toaster.errorBorder,
            },
          }}
        >
          {errorAlertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
