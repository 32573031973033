import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: '2.188rem',
  height: '1.25rem',
  padding: 0,
  display: 'flex',
  borderRadius: '0.625rem',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(0.563rem)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '0.2rem 0.1rem 0 0.2rem',
    '&.Mui-checked': {
      transform: 'translateX(0.75rem)',
      color: theme.palette.white[100],
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 0.125rem 0.25rem 0 rgb(0 35 11 / 20%)',
    width: '0.875rem',
    height: '0.875rem',
    borderRadius: '0.625rem',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: theme.palette.white[100],
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.grey[400]
        : theme.palette.grey[300],
    boxSizing: 'border-box',
  },
}));

export default AntSwitch;
