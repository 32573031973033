import React, { useContext } from 'react';
import { UserDetailsProvider } from '../../store/user-details-context';
import { useNavigate } from 'react-router-dom';
import { EKYC_URL, TRAINING_URL, TRUE } from '../../Constants/Constants';
import { useTranslation } from 'react-i18next';
import { Button, Box, Typography } from '@mui/material';
import { AppContext } from '../../store/app-context';
import './Reporter.css';

const ReporterModal = (props) => {
  const { closeModal } = props;
  const { t } = useTranslation();
  const { state: userState } = useContext(UserDetailsProvider);
  const isAssessmentDone = userState?.citizenJournalist?.isAssessmentDone;
  const isAuthenticated = userState?.isAuthenticated;
  let navigate = useNavigate();
  const { dispatch: appCtxDispatch } = useContext(AppContext);

  const becomeReporter = (e) => {
    e.preventDefault();
    closeModal();

    if (isAuthenticated) {
      if (isAssessmentDone === TRUE) {
        navigate(EKYC_URL);
      } else {
        navigate(TRAINING_URL);
      }
    } else {
      appCtxDispatch({ type: 'SHOW_LOGIN_BEFORE_SIGNIN' });
    }
  };
  return (
    <div>
      <form onSubmit={becomeReporter}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}
            className='reporterContent'
          >
            <Typography
              variant='body3'
              sx={{ marginBottom: '1rem' }}
              className='reporterList'
            >
              {isAuthenticated ? (
                t('to-become-a-citizen-reporter-please-complete-your-ekyc')
              ) : (
                <>
                  {t('become-a-repoter-info-guest')}{' '}
                  <Typography variant='body3' component='span' fontWeight='700'>
                    {t('sign-up')}
                  </Typography>{' '}
                  {t('create-your-account-info-guest')}{' '}
                  <Typography variant='body3' component='span' fontWeight='700'>
                    {t('login')}
                  </Typography>
                </>
              )}
            </Typography>
            <Typography
              variant='body3'
              sx={{ marginBottom: '1rem' }}
              className='reporterList'
            >
              {t(
                'ekyc-is-allowed-using-aadhar-and-dl-in-case-you-are-residing-in-india-and-passport-if-you-are-residing-outside',
              )}
            </Typography>
          </Box>
          <Box>
            <Button
              fullWidth
              sx={{
                bottom: '0',
                width: 'fill-available',
                maxWidth: '100%',
              }}
              variant='contained'
              type='submit'
            >
              {t('proceed')}
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default ReporterModal;
