import { firebase } from '../firebase/firebase';
import { getRemoteConfig } from 'firebase/remote-config';
import { getValue } from 'firebase/remote-config';

export const getEnv = (variableName) => {
  const remoteConfig = getRemoteConfig(firebase);
  return getValue(remoteConfig, `${variableName}`);
};

export const defaultConfigurations = {
  development: require('../remote_config_defaults-dev.json'),
  qa: require('../remote_config_defaults-qa.json'),
  'pre-prod': require('../remote_config_defaults-pre-prod.json'),
  production: require('../remote_config_defaults-prod.json'),
};
