// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ads_ads__MiOvm {
  display: flex;
  flex-direction: column;
  max-width: 18.75rem;
  width: 100%;
  margin-left: 1.25rem;
}

.Ads_ads__MiOvm > .Ads_adsImage__sKvMV {
  margin-bottom: 1.25rem;
}

@media screen and (max-width: 1280px) {
  .Ads_ads__MiOvm {
    max-width: 14.25rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Ads/Ads.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".ads {\n  display: flex;\n  flex-direction: column;\n  max-width: 18.75rem;\n  width: 100%;\n  margin-left: 1.25rem;\n}\n\n.ads > .adsImage {\n  margin-bottom: 1.25rem;\n}\n\n@media screen and (max-width: 1280px) {\n  .ads {\n    max-width: 14.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ads": `Ads_ads__MiOvm`,
	"adsImage": `Ads_adsImage__sKvMV`
};
export default ___CSS_LOADER_EXPORT___;
