import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

/**
 * Make api call for retrieving Drafts
 * @param {object} data
 * @returns promise
 */
const draftList = (data) => {
  return apiRequest(api.getFeeds, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteDraft = (data) => {
  return apiRequest(api.deleteFeed, METHOD.POST, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const createDraft = (data) => {
  return apiRequest(api.createFeed, METHOD.POST, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const uploadToJWP = (data) => {
  return apiRequest(api.uploadToJWP, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const draftService = {
  draftList,
  deleteDraft,
  uploadToJWP,
  createDraft,
};

export default draftService;
