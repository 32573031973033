import { SvgIcon } from '@mui/material';

const SearchIcon = ({ styleProps, stroke }) => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      sx={{ ...styleProps, fill: 'none' }}
    >
      <path
        d='M34 34L24.7614 24.7614M24.7614 24.7614C27.1743 22.3486 28.6667 19.0152 28.6667 15.3333C28.6667 7.96954 22.6971 2 15.3333 2C7.96954 2 2 7.96954 2 15.3333C2 22.6971 7.96954 28.6667 15.3333 28.6667C19.0152 28.6667 22.3486 27.1743 24.7614 24.7614Z'
        stroke={stroke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
};
export default SearchIcon;
