// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share_borderRadius__alsGk {
  border-radius: 50%;
  margin: 0.625rem;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/Components/share-modal/share.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".borderRadius {\n  border-radius: 50%;\n  margin: 0.625rem;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderRadius": `share_borderRadius__alsGk`
};
export default ___CSS_LOADER_EXPORT___;
