import { Buffer } from 'buffer';

/**
 * Function to create hash password
 * @returns {string} hashHex hash password
 */
async function createHashPassword() {
  const utf8 = new TextEncoder().encode(
    process.env.REACT_APP_ENCRYPTION_PASSWORD,
  );
  const hashBuffer = await crypto.subtle.digest('SHA-512', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')
    .substring(0, 16);
  return hashHex;
}

/**
 * Function to create Byte array
 * @param {string} str string to converted to byte array
 * @returns {string} byte array
 */
function asciiToUint8Array(str) {
  var chars = [];
  for (var i = 0; i < str.length; ++i) {
    chars.push(str.charCodeAt(i));
  }
  return new Uint8Array(chars);
}

/**
 * Function to enncrypt string
 * @param {string} plaintext string to encrypt
 * @returns {string} encrypted string
 */
export const encrypt = async (plaintext) => {
  const iv = crypto.getRandomValues(new Uint8Array(16));

  const encodedPlaintext = new TextEncoder().encode(plaintext);

  const hashKey = await createHashPassword();

  const secretKey = await crypto.subtle.importKey(
    'raw',
    Buffer.from(asciiToUint8Array(hashKey), 'base64'),
    {
      name: 'AES-CBC',
      length: 128,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const ciphertext = await crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    secretKey,
    encodedPlaintext,
  );

  return (
    Buffer.from(ciphertext).toString('base64') +
    ':' +
    Buffer.from(iv).toString('base64')
  );
};

/**
 * Function to decrypt encrypted string
 * @param {string} encText string to encrypt
 * @returns {string} decrypted string
 */
export const decrypt = async (encText) => {
  const hashKey = await createHashPassword();

  const secretKey = await crypto.subtle.importKey(
    'raw',
    Buffer.from(asciiToUint8Array(hashKey), 'base64'),
    {
      name: 'AES-CBC',
      length: 128,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const [cipherData, ivData] = encText.split(':');
  const iv = Buffer.from(ivData, 'base64');

  const decryptedText = await crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      length: 128,
      iv,
    },
    secretKey,
    Buffer.from(cipherData, 'base64'),
  );

  return new TextDecoder().decode(decryptedText);
};
