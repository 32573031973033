import { Button, Dialog, DialogContent, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PaymentModel({
  show,
  message,
  message2,
  icon,
  doubleButton,
  onSubmit,
  onClose,
}) {
  const { t } = useTranslation();
  return (
    <>
      {show && (
        <Dialog
          open={show}
          onClose={onClose}
          maxWidth='md'
          PaperProps={{ sx: { borderRadius: '0.9375rem' } }}
        >
          <DialogContent
            sx={{
              borderRadius: '10px',
              minWidth: '12rem',
              minHeight: '9rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {icon ? (
              <img
                src={icon}
                alt='message-icon'
                width={'30px'}
                height={'30px'}
              />
            ) : null}
            <Typography
              variant='h2'
              sx={{
                marginTop: '0.123rem',
                wordWrap: 'break-word',
              }}
            >
              {message}
            </Typography>
            {message2 ? (
              <Typography
                variant='h2'
                sx={{
                  marginTop: '0.123rem',
                  wordBreak: 'break-all',
                  wordWrap: 'break-word',
                }}
              >
                {message2}
              </Typography>
            ) : null}
            {doubleButton ? (
              <>
                <Box>
                  <Button
                    variant='contained'
                    style={{
                      marginTop: '1.125rem',
                      marginRight: '2.125rem',
                      padding: '1rem 2rem',
                    }}
                    onClick={onSubmit}
                  >
                    {'Delete'}
                  </Button>
                  <Button
                    variant='contained'
                    style={{ marginTop: '1.125rem', padding: '1rem 2rem' }}
                    onClick={onClose}
                  >
                    {'Cancel'}
                  </Button>
                </Box>
              </>
            ) : (
              <Button
                variant='contained'
                style={{ marginTop: '1.125rem' }}
                onClick={onClose}
              >
                {t('okay')}
              </Button>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default PaymentModel;
