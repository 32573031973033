import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import ButtonLoader from '../buttonLoader/ButtonLoader';

/**
 * BootstrapDialogTitle
 */
function BootstrapDialogTitle({ children, onClose, ...other }) {
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontWeight: '600',
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        padding: '1.25rem',
        textTransform: 'capitalize',
      }}
      {...other}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      )}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

/**
 * DialogBox
 */
function DialogBox({
  title,
  heading,
  content,
  primaryButtonText,
  secondaryButtonText,
  tertiaryButtonText,
  primaryButtonAction,
  secondaryButtonAction,
  tertiaryButtonAction,
  isOpen,
  onClose,
  icon,
  primaryLoading,
  secondaryLoading,
  tertiaryLoading,
}) {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '22.5rem',
          borderRadius: '0.5rem',
        },
      }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby='share-with-community'
    >
      <BootstrapDialogTitle id='share-with-community' onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent sx={{ padding: '1.875rem' }} dividers>
        {icon && (
          <Box sx={{ textAlign: 'center', marginBottom: '1.25rem' }}>
            <img src={icon} alt='icon' />
          </Box>
        )}
        {heading && (
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: '600',
              fontSize: '1.5rem',
              lineHeight: '1.875rem',
              marginBottom: '1.25rem',
            }}
          >
            {heading}
          </Typography>
        )}
        <Typography
          variant='body1'
          sx={{
            lineHeight: '1.125rem',
            textAlign: 'center',
            marginBottom: '3.125rem',
          }}
        >
          {content}
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', margin: '0.625rem' }}
        >
          {primaryButtonText && (
            <ButtonLoader
              variant='contained'
              sx={{
                mb: secondaryButtonText ? '1.25rem' : 0,
              }}
              onClick={primaryButtonAction}
              loading={primaryLoading}
            >
              {primaryButtonText}
            </ButtonLoader>
          )}
          {secondaryButtonText && (
            <ButtonLoader
              variant='outlined'
              sx={{
                mb: tertiaryButtonText ? '1.25rem' : 0,
              }}
              onClick={secondaryButtonAction}
              loading={secondaryLoading}
            >
              {secondaryButtonText}
            </ButtonLoader>
          )}
          {tertiaryButtonText && (
            <ButtonLoader
              variant='outlined'
              onClick={tertiaryButtonAction}
              loading={tertiaryLoading}
            >
              {tertiaryButtonText}
            </ButtonLoader>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

DialogBox.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string,
  content: PropTypes.string,
  ucon: PropTypes.any,
  primaryButtonText: PropTypes.string,
  primaryButtonAction: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
  tertiaryButtonText: PropTypes.string,
  tertiaryButtonAction: PropTypes.func,
};

export default DialogBox;
