import { SvgIcon } from '@mui/material';

const AddIcon = ({ styleProps, fill, fontSize }) => {
  return (
    <SvgIcon
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      sx={{ ...styleProps, fontSize }}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.5013 15.5827C5.16219 15.5827 3.49263 15.5827 2.4553 14.5454C1.41797 13.508 1.41797 11.8385 1.41797 8.49935C1.41797 5.16023 1.41797 3.49068 2.4553 2.45335C3.49263 1.41602 5.16219 1.41602 8.5013 1.41602C11.8404 1.41602 13.51 1.41602 14.5473 2.45335C15.5846 3.49068 15.5846 5.16023 15.5846 8.49935C15.5846 11.8385 15.5846 13.508 14.5473 14.5454C13.51 15.5827 11.8404 15.5827 8.5013 15.5827ZM8.5013 5.8431C8.7947 5.8431 9.03255 6.08095 9.03255 6.37435V7.96812H10.6263C10.9197 7.96812 11.1576 8.20597 11.1576 8.49937C11.1576 8.79277 10.9197 9.03062 10.6263 9.03062H9.03255L9.03255 10.6243C9.03255 10.9178 8.7947 11.1556 8.5013 11.1556C8.2079 11.1556 7.97005 10.9178 7.97005 10.6243V9.03062H6.3763C6.0829 9.03062 5.84505 8.79277 5.84505 8.49937C5.84505 8.20597 6.0829 7.96812 6.3763 7.96812H7.97005L7.97005 6.37435C7.97005 6.08095 8.2079 5.8431 8.5013 5.8431Z'
        fill='#8E3BD9'
      />
      {/* <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z'
        fill={fill}
      /> */}
    </SvgIcon>
  );
};

export default AddIcon;
