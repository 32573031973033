import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import CloseIcon from '@mui/icons-material/Close';
import Report from './Report';
import '../Report/Report.css';
import { useTranslation } from 'react-i18next';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';

/**
 * Report Modal UI Component
 */
const ReportModal = ({ showReportModal, closeModal, newsID }) => {
  const { t } = useTranslation();
  const mobileView = MaxWidth(viewPort.mobileMax);
  return (
    <Dialog
      fullScreen={mobileView}
      fullWidth={true}
      open={showReportModal}
      aria-labelledby='responsive-dialog-title'
      aria-describedby='dialog-description'
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: !mobileView ? '50rem' : '100%',
            maxHeight: !mobileView ? '33.75rem' : '100%',
            height: '100%',
            marginTop: !mobileView && '5rem',
          },
        },
      }}
    >
      <DialogTitle id='responsive-dialog-title'>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            variant='h6'
            sx={{ fontWeight: '600', fontSize: '1.125rem' }}
          >
            {t('report')}
          </Typography>
          <IconButton aria-label='close' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider variant='fullWidth' />
      <Scrollbars>
        <div className='report-modal-innercontainer'>
          <DialogContent>
            <Report closeModal={closeModal} newsID={newsID} />
          </DialogContent>
        </div>
      </Scrollbars>
    </Dialog>
  );
};

export default ReportModal;
