import SharpSansMedium from '../assets/fonts/HelveticaNeueMedium.ttf';

const COLOR = {
  primary: '#8E3BD9',
  secondary: '#EA8C72',
  MinSecVoilet: '#19072D',
  success: '#31C173',
  error: '#FF5B5C',
  white: '#FFFFFF',
  grey: '#7F7F7F',
  black: '#000000',
  background: '#F2F2F2',
  header: '#F3F3F3',
  brownHueViolet: '#2E2933',
};

export const PaymentTheme = {
  palette: {
    mode: 'light',

    primary: {
      main: COLOR.primary,
    },
    secondary: {
      main: COLOR.secondary,
    },
    success: {
      main: COLOR.success,
    },

    error: {
      main: COLOR.error,
    },

    background: {
      default: COLOR.background,
      paper: COLOR.background,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
              @font-face {
                font-family: 'Sharp Sans';
                src: url(${SharpSansMedium});
              }
            `,
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: COLOR.header,
          color: 'white',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          height: '40px',
          borderRadius: '10px',
          color: COLOR.MinSecVoilet,
          // background: '#F2F2F2',
          header: '#F3F3F3',
        },
        notchedOutline: {
          border: `1px solid ${COLOR.brownHueViolet}`,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: COLOR.MinSecVoilet,
          fontWeight: 500,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '.75rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          height: '40px',
          borderRadius: '0.625rem',
          color: COLOR.backgroundColor,
          borderColor: COLOR.primary,
          padding: '0.6875rem 1rem',
          fontSize: '0.875rem',
          fontWeight: 700,
        },
        text: {
          color: COLOR.MinSecVoilet,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: COLOR.primary,
        },
      },
    },
    //  -root MuiRadio-colorPrimary PrivateSwitchBase-root MuiRadio-root MuiRadio-colorPrimary MuiRadio-root MuiRadio-colorPrimary css-pacco9-MuiButtonBase-root-MuiRadio-root
  },

  typography: {
    fontFamily: 'Sharp Sans',
    fontSize: 14,
    h1: {
      fontSize: 18,
      fontWeight: 700,
    },
    h2: {
      fontSize: 16,
      fontWeight: 700,
    },
    h3: {
      fontSize: 14,
      fontWeight: 700,
    },
    h4: {
      fontSize: 15,
      fontWeight: 400,
    },
    h5: {
      fontSize: 21,
      fontWeight: 700,
    },
    h6: {
      fontSize: 51,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 700,
      textTransform: 'capitalize',
      color: '#F2E3FF',
    },
  },
};
