import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * BootstrapDialogTitle
 */
function BootstrapDialogTitle({ children, onClose, ...other }) {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontWeight: '700',
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        padding: '1.25rem',
        textTransform: 'uppercase',
        color: isDarkMode
          ? (theme) => theme.palette.white[100]
          : (theme) => theme.palette.grey[600],
      }}
      {...other}
    >
      {children}

      {onClose && (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      )}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

/**
 * MediaCriteriaInfo
 *  props
 *    - isOpen - boolean to open/close modal
 *    - onClose - function to close the modal
 */
function MediaCriteriaInfo({ isOpen, onClose }) {
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '0.5rem',
        },
      }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby='media-criteria'
    >
      <BootstrapDialogTitle id='title' onClose={onClose}>
        {t('media-criteria')}

        <Typography
          variant='body2'
          sx={{
            textTransform: 'capitalize',
            color: isDarkMode
              ? (theme) => theme.palette.white[100]
              : (theme) => theme.palette.grey[400],
          }}
        >
          {t('below-are-the-criteria-for-media-to-upload')}
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <Box sx={{ mx: 2 }}>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.125rem',
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.grey[500],
            }}
          >
            <List sx={{ listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item', pl: 1 }}>
                {t('adding-media-is-mandatory-to-publish-a-report')}
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 1 }}>
                {t(
                  'you-can-add-1-video-or-max-5-images-through-the-in-app-gallery',
                )}
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 1 }}>
                {t('video-should-not-be-more-than-2-min')}
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 1 }}>
                {t(
                  'you-can-access-the-captured-media-until-7-days-to-create-a-report',
                )}
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 1 }}>
                {t(
                  'post-publishing-you-will-have-access-to-the-media-for-another-7-days',
                )}
              </ListItem>
            </List>
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <Button
          onClick={onClose}
          variant='contained'
          sx={{ px: '1.5rem', textTransform: 'uppercase' }}
        >
          {t('okay')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MediaCriteriaInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MediaCriteriaInfo;
