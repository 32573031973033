import React from 'react';
import { Grid, IconButton, Typography, Divider, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const NavbarProfile = ({ handleMenuClose }) => {
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return (
    <>
      <Box sx={{ padding: '1.25rem' }}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '1.125rem',
              color: isDarkMode ? DARK_COLOR.white[100] : LIGHT_COLOR.grey[600],
            }}
          >
            {t('profile')}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleMenuClose}
            style={{ padding: '0' }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Box>
      <Divider variant='fullWidth' />
    </>
  );
};

export default NavbarProfile;
