import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';

/**
 * ContactUs Modal UI Component
 */
const ContactUsModal = ({ showContactUsModal, closeModal }) => {
  const { t } = useTranslation();
  const mobileView = MaxWidth(viewPort.mobileMax);
  return (
    <Dialog
      fullScreen={mobileView}
      fullWidth={true}
      open={showContactUsModal}
      aria-labelledby='responsive-dialog-title'
      aria-describedby='dialog-description'
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: !mobileView ? '50rem' : '100%',
            maxHeight: !mobileView ? '33.75rem' : '100%',
            height: '100%',
            marginTop: !mobileView && '5rem',
          },
        },
      }}
    >
      <DialogTitle id='responsive-dialog-title'>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            variant='h6'
            sx={{ fontWeight: '600', fontSize: '1.125rem' }}
          >
            {t('contact-us')}
          </Typography>
          <IconButton aria-label='close' onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider variant='fullWidth' />

      <DialogContent sx={{ padding: 0 }}>
        <iframe
          title='Social'
          width='100%'
          src={'https://tally.so/r/3y2YO6'}
          style={{
            height: '100vh',
            border: 'none',
          }}
          allowFullScreen
        ></iframe>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsModal;
