import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css';

/**
 * Quill Board component Rich Text editor
 */

/**
 *
 * @param {value setValue uploadImage uploadVideo } props
 * we neeed 4 props
 * value is the where we need data and ,
 * setValue is occur on onchange event,
 * uploadImage :- upload image is required or not
 * uploadVIdeo:- video upload is required or not
 */
function RichTextEditor({
  value,
  onChange,
  uploadImage,
  uploadVideo,
  style,
  placeholder,
  isDarkMode,
}) {
  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  const imageVideoArray = ['link'];

  if (uploadImage) {
    imageVideoArray.push('image');
  }

  if (uploadVideo) {
    imageVideoArray.push('video');
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      imageVideoArray,
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  return (
    <ReactQuill
      style={style}
      className={isDarkMode ? 'dark' : 'light'}
      formats={formats}
      theme='snow'
      modules={modules}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

export default RichTextEditor;
