import { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styles } from './trainingStyles';
import JWPlayer from '@jwplayer/jwplayer-react';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import './skin.css';
import { TRAINING_VIDEO_STATUS } from '../../../Constants/Constants';
import { EnvironmentProvider } from '../../../store/firebase-context';

/**
 * Player View Component
 */
function PlayerView({
  playlistId,
  playlistItem,
  index,
  onPlaylistItem,
  playlistLength,
  onPrevious,
  onNext,
  onUpdateData,
  currentPlayListState,
}) {
  const { t } = useTranslation();
  const { environment } = useContext(EnvironmentProvider);
  const [player, setPlayer] = useState();
  const [seekValue, setSeekValue] = useState({
    currentPosition: null,
    offSetTime: null,
  });
  const [maxPositionTime, setMaxPositionTime] = useState(0);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  /**
   * playerMountedCallback will call once the video is set and going to start
   * @param{object} it will have player detail of current id
   */
  const playerMountedCallback = ({ player }) => {
    // if there is seek position we will start video from there
    if (
      currentPlayListState &&
      parseInt(currentPlayListState.seek_time) &&
      currentPlayListState.seek_time < playlistItem.duration - 10
    ) {
      let seekTime = parseInt(currentPlayListState.seek_time);
      player.seek(seekTime);
      setMaxPositionTime(seekTime);
    }

    //if video is not completed then we will make it as in progress
    if (currentPlayListState.status !== TRAINING_VIDEO_STATUS.COMPLETED) {
      const payload = {
        ...currentPlayListState,
        status: TRAINING_VIDEO_STATUS.IN_PROGRESS,
      };
      onUpdateData(payload, false);
    }
    setPlayer(player);
  };

  /**
   * if seek value get change on the basis of that we rewind or forward the screen
   */
  useEffect(() => {
    //current postion time is greater then watch time we will update maximum watched time
    if (
      seekValue &&
      maxPositionTime < seekValue.offSetTime &&
      seekValue.currentPosition < seekValue.offSetTime
    ) {
      player.seek(seekValue.currentPosition);
    }
  }, [seekValue]);

  /**
   *when user seek then this event triggered
   * @param {*} event as per jw player
   */
  const onSeek = (event) => {
    setSeekValue({
      currentPosition: event.currentTime,
      offSetTime: event.offset,
    });
  };

  /**
   * once the video is ended then this event is tiggered
   */
  const onCompleted = () => {
    const payload = {
      ...currentPlayListState,
      status: TRAINING_VIDEO_STATUS.COMPLETED,
    };
    onUpdateData(payload, true);
  };

  // need to update backend on every 10 sec so seektime wil persist
  useEffect(() => {
    const interval = setInterval(() => {
      // video is not completed then only updating video seek position status
      if (
        player &&
        currentPlayListState.status !== TRAINING_VIDEO_STATUS.COMPLETED &&
        maxPositionTime < parseInt(player.getPosition())
      ) {
        const payload = {
          ...currentPlayListState,
          seek_time: Math.trunc(player.getPosition()),
          status:
            Math.trunc(player.getPosition()) > 0 &&
            currentPlayListState.status === TRAINING_VIDEO_STATUS.NOT_STARTED
              ? TRAINING_VIDEO_STATUS.IN_PROGRESS
              : currentPlayListState.status,
        };
        onUpdateData(payload, false);

        setMaxPositionTime(() => payload.seek_time);
      }
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [player, maxPositionTime, currentPlayListState]);

  return (
    <Box
      sx={{
        background: isDarkMode
          ? (theme) => theme.palette.grey[200]
          : (theme) => theme.palette.white[100],

        ...styles.videoContainer,
        border: '1px solid',
        borderColor: isDarkMode
          ? (theme) => theme.palette.grey[300]
          : (theme) => theme.palette.grey[200],
      }}
    >
      <Typography sx={styles.videoTitle}>{playlistItem.title}</Typography>
      <Typography variant='body3' sx={styles.videoDescription}>
        {playlistItem?.description}
      </Typography>
      <Box sx={styles.prevNextContainer}>
        <Button
          sx={{ fontWeight: '600', color: (t) => t.palette.primary.main }}
          disabled={!index}
          color='primary'
          onClick={onPrevious}
          startIcon={<ArrowBackIosNew />}
        >
          {t('previous')}
        </Button>
        <Button
          sx={{ fontWeight: '600', color: (t) => t.palette.primary.main }}
          disabled={index === playlistLength - 1}
          color='primary'
          onClick={onNext}
          endIcon={<ArrowForwardIos />}
        >
          {t('next')}
        </Button>
      </Box>
      <Box sx={styles.playerContainer}>
        {playlistItem && Object.keys(currentPlayListState).length ? (
          <>
            <JWPlayer
              onPlaylistItem={onPlaylistItem}
              didMountCallback={playerMountedCallback}
              playlistIndex={index}
              onComplete={onCompleted}
              onSeek={onSeek}
              config={{
                responsive: true,
                aspectratio: '16:9',
                width: '100%',
                stretching: 'uniform',
                volume: 25,
                autostart: true,
                autoPause: {
                  viewability: true,
                  pauseAds: true,
                },
                skin: {
                  url: './skin.module.css',
                  name: 'myskin',
                },
              }}
              playlist={`${environment.web_jwp_playlist_url}${playlistId}`}
              library={environment.web_jwp_library_url}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default PlayerView;
