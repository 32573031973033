import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import HorizontalMenuBar from '../HorizontalMenuBar/HorizontalMenuBar';
import './Topics.css';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';

const TopicMobile = ({ navBarCategories, categories, navBarFilters }) => {
  let navigate = useNavigate();
  const isMobile = MaxWidth(viewPort.mobile);
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;

  return (
    <div>
      <HorizontalMenuBar
        newsCategories={navBarCategories}
        navBarFilters={navBarFilters}
        isBackRequired={false}
      />
      <Box sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
        {categories.map((item, index) => {
          return (
            <Box key={index}>
              <Typography
                variant='body1'
                className='topicName'
                onClick={() => navigate(item.url)}
              >
                {isTamil ? item.name_tamil : item.name}
              </Typography>
              <Divider
                variant='fullWidth'
                sx={{
                  borderBottomWidth: '0.063rem',
                  margin: isMobile ? '0.625rem 0 0.625rem 1.875rem' : '',
                  overflow: 'hidden',
                }}
                className='topicHorizontalLine'
              />
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default TopicMobile;
