import React from 'react';
import SearchUsersCard from '../SearchCards/SearchUsersCard';
import '../SearchCards/SearchReportersCard.css';
import { Box } from '@mui/material';

const SearchUsers = (props) => {
  return (
    <div className='relatedNews'>
      {props.usersResult?.map((result) => (
        <Box
          key={result.userId}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <SearchUsersCard
            key={result.userId}
            userId={result.userId}
            userName={result.userName}
            followersCount={result.followersCount}
            followingCount={result.followingCount}
            userType={result.userType}
            userDetails={result.userDetails}
          />
        </Box>
      ))}
    </div>
  );
};

export default SearchUsers;
