import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

/**
 * Make api call for retrieving Bookmarks
 * @param {object} data
 * @returns promise
 */
const bookmarkNewsList = (data) => {
  return apiRequest(api.getBookmarkNews, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for Removing Bookmark
 * @param {object} data
 * @returns promise
 */
const removeBookmarks = (data) => {
  return apiRequest(api.bookmarkNews, METHOD.POST, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const bookmarkService = { bookmarkNewsList, removeBookmarks };

export default bookmarkService;
