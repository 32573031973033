import { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  ImageListItem,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { groupBy, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import ToasterWarningIcon from '../../../assets/icons/toaster-warning.svg';
import ToasterWarningIconDark from '../../../assets/icons/toaster-warning-dark.svg';
import reportService from '../../../services/report.service';
import Media from './Media/Media';
import { DARK_COLOR, LIGHT_COLOR } from '../../../themes/color.constant';
import { MaxWidth, viewPort } from '../../../responsive/viewport.util';
import ghostIcon from '../../../assets/icons/ghost-icon.svg';
import ghostIconDark from '../../../assets/icons/ghostIconDark.svg';
import './MediaPicker.css';

/**
 * Skeleton Loader for Media Picker
 */
function MediaPickerLoader() {
  const isMobile = MaxWidth(viewPort.mobileMax);

  return (
    <>
      {Array(2)
        .fill('a')
        .map(() => (
          <Box
            sx={{
              marginBottom: '1px',
            }}
          >
            <Box>
              <Skeleton
                sx={{ marginBottom: '1px' }}
                variant='rectangular'
                width='100%'
                height='2.4rem'
              />
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isMobile
                    ? 'repeat(3, 1fr)'
                    : 'repeat(5,1fr)',
                  gap: '1px',
                }}
              >
                {Array(8)
                  .fill('a')
                  .map(() => (
                    <Skeleton
                      variant='rectangular'
                      width='100%'
                      height='18vh'
                    />
                  ))}
              </Box>
            </Box>
          </Box>
        ))}
    </>
  );
}

/**
 * Media Picker
 *  props
 *    - isOpen - boolean to determine whether the picker is open
 *    - onClose - function to close the media picker
 *    - onPicked - callback function when media is selected/unselected
 *    - availableMedias - selected media objects
 */
function MediaPicker({
  isOpen,
  onClose,
  onPicked,
  availableMedias,
  nonDTMedias,
}) {
  const { t } = useTranslation();
  const [medias, setMedias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMedias, setSelectedMedias] = useState([]);
  const [showError, setShowError] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.mobileMax);

  // side effect to check for media criteria and show the error
  useEffect(() => {
    if (selectedMedias?.length || nonDTMedias?.length) {
      const videoCount = selectedMedias?.filter(
        (media) => media.type === 'VIDEO',
      )?.length;

      const nonDTVideoCount = nonDTMedias?.filter(
        (media) => media.mediaType === 'VIDEO',
      )?.length;

      const imageCount = selectedMedias?.filter(
        (media) => media.type === 'IMAGE',
      )?.length;

      const nonDTImageCount = nonDTMedias?.filter(
        (media) => media.mediaType === 'IMAGE',
      )?.length;

      if (
        (videoCount + nonDTVideoCount === 1 &&
          imageCount + nonDTImageCount < 5) ||
        (videoCount + nonDTVideoCount === 0 &&
          imageCount + nonDTImageCount <= 5)
      ) {
        setShowError(false);
      } else {
        setShowError(true);
      }
    }
  }, [selectedMedias]);

  // side effect to fetch the media from gallary and group it based on expiry
  useEffect(() => {
    const fetchMedia = async () => {
      try {
        setIsLoading(true);
        const response = await reportService.getMedia();

        if (response?.mediaList) {
          if (availableMedias?.length) {
            const matchedMedias = response?.mediaList?.filter(
              (galleryMedia) => {
                const isAvailable = availableMedias?.find(
                  (selectedMedia) =>
                    selectedMedia.mediaId === galleryMedia.media_url,
                );

                return !!isAvailable;
              },
            );

            setSelectedMedias(matchedMedias);
          }

          const groupedMedia = groupBy(response?.mediaList, 'expiry');

          setMedias(groupedMedia);
        }
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchMedia();
  }, []);

  /**
   * Media Selection Change Handler
   * @param {object} mediaObj - media object selected/unselected
   * @param {boolean} checked - if true, media is selected else unselected
   */
  const onSelectionChange = (mediaObj, checked) => {
    if (checked) {
      setSelectedMedias((prevState) => [...prevState, mediaObj]);
    } else {
      const filteredMedia = selectedMedias?.filter(
        (media) => media.id !== mediaObj.id,
      );

      setSelectedMedias([...filteredMedia]);
    }
  };

  /**
   * Handler to add the selected medias to report
   */
  const onAddToReport = () => {
    const transformedMediasObj = selectedMedias?.map((mediaObj) => ({
      id: mediaObj?.id,
      post_attach_date: mediaObj?.post_attach_date,
      mediaId: mediaObj?.media_url,
      thumbnail: mediaObj?.thumbnail_url,
      mediaType: mediaObj.type,
      expiry: mediaObj?.expiry_date,
      duration: mediaObj?.metadata?.duration || null,
      location: mediaObj?.metadata?.location || null,
      created_date: mediaObj?.creation_date,
      presigned_thumbnail: mediaObj?.thumbnail_presigned_url,
      presigned_mediaId: mediaObj?.media_presigned_url,
      isMediaProcessed: true,
      isDT: true,
    }));

    onPicked([...transformedMediasObj]);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen={fullScreen}
      onClose={onClose}
      aria-labelledby='media-picker'
      fullWidth={true}
    >
      <DialogTitle
        sx={{
          fontWeight: '700',
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
          padding: '1.25rem',
          textTransform: 'capitalize',
          color: '#323232',
        }}
      >
        <Typography
          sx={{
            fontWeight: '700',
            marginBottom: '0.375rem',
          }}
        >
          {t('media-gallery')}
        </Typography>

        {showError ? (
          <Alert
            variant='outlined'
            severity='error'
            icon={
              <img
                alt='warning icon'
                src={isDarkMode ? ToasterWarningIconDark : ToasterWarningIcon}
              />
            }
            sx={{
              textTransform: 'none',
              margin: '0 -1.25rem -1.25rem -1.25rem',
              borderRadius: 0,
              border: 'none',
              alignItems: 'center',
              padding: '0.75rem 1.25rem',
              backgroundColor: !isDarkMode ? '#FCE8E8' : DARK_COLOR.error[50],
              '& .MuiAlert-message': {
                p: 0,
                color: isDarkMode
                  ? '#FFFFFF'
                  : (theme) => theme.palette.error.main,
                fontSize: '0.875rem',
                fontWeight: 400,
              },
              '& .MuiAlert-icon': {
                p: 0,
                marginRight: '0.313rem',
              },
            }}
          >
            {t('report-media-constrain-msg')}
          </Alert>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                fontWeight: 400,
              }}
            >
              {t('select')} &nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                fontWeight: 600,
              }}
            >
              {t('one')} &nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                fontWeight: 400,
              }}
            >
              {t('video-or-max')} &nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                fontWeight: 600,
              }}
            >
              {t('five')} &nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                fontWeight: 400,
              }}
            >
              {t('images')}
            </Typography>
          </Box>
        )}

        {onClose && (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent className='mediaBox' sx={{ padding: 0 }} dividers>
        <Box>
          {isLoading && <MediaPickerLoader />}

          {!isLoading && !isEmpty(medias) ? (
            <Box>
              {Object.keys(medias)
                .sort()
                .reverse()
                .map((expiry) => (
                  <>
                    <Box
                      sx={{
                        padding: '10px',
                        background: (theme) => theme.palette.grey[100],
                        fontWeight: 600,
                        fontSize: '1rem',
                        lineHeight: '1.125rem',
                      }}
                    >{`${t('expiry-in')} ${expiry} ${
                      expiry === '1' ? t('day') : t('days')
                    }`}</Box>

                    <FormGroup>
                      <Box
                        sx={{
                          backgroundColor: isDarkMode ? '' : '#FFFFFF',
                          display: 'grid',
                          gridTemplateColumns: isMobile
                            ? 'repeat(3, 1fr)'
                            : 'repeat(5,1fr)',
                          gap: '1px',
                        }}
                      >
                        {medias[expiry].map((mediaObj) => (
                          <ImageListItem key={mediaObj.id}>
                            <Media
                              isSelected={
                                !!selectedMedias?.find(
                                  (obj) => obj.id === mediaObj.id,
                                )
                              }
                              media={mediaObj}
                              onChange={onSelectionChange}
                            />
                          </ImageListItem>
                        ))}
                      </Box>
                    </FormGroup>
                  </>
                ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                minHeight: '65vh',
                background: (theme) => theme.palette.background.default,
              }}
            >
              <img
                src={isDarkMode ? ghostIconDark : ghostIcon}
                alt='ghost-icon'
              />
              <Typography
                sx={{
                  color: isDarkMode ? DARK_COLOR.grey[500] : LIGHT_COLOR[400],
                }}
              >
                {t('no-media')}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          padding: '1.25rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {selectedMedias?.length ? (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontSize: '1.125rem',
            }}
          >
            {selectedMedias?.length} Selected
          </Typography>
        ) : (
          <Typography></Typography>
        )}

        <Button
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
          color='primary'
          disabled={showError || !selectedMedias?.length}
          variant='contained'
          onClick={onAddToReport}
        >
          {t('add-to-report')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MediaPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPicked: PropTypes.func.isRequired,
  availableMedias: PropTypes.object.isRequired,
};

export default MediaPicker;
