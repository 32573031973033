import React, { useEffect, useState, useContext } from 'react';
import { Box, Menu, Typography } from '@mui/material';
import moreOptionsIcon from '../../assets/icons/vertical-ellipsis.svg';
import moreOptionsDarkIcon from '../../assets/icons/vertical-ellipsis-dark.svg';
import selectMoreDarkIcon from '../../assets/icons/select-ellipsis-dark.svg';
import selectMoreLightIcon from '../../assets/icons/select-ellipsis-light.svg';
import deleteIcon from '../../assets/icons/Delete.svg';
import deleteDarkIcon from '../../assets/icons/DeleteDark.svg';
import backgroundIcon from '../../assets/icons/reaction-background.svg';
import likeIcon from '../../assets/icons/like.svg';
import followIcon from '../../assets/icons/followIcon.svg';
import commentIcon from '../../assets/icons/commentIcon.svg';
import UserIcon from '../../assets/icons/UserIcon.svg';
import UserIconDark from '../../assets/icons/UserIconDark.svg';
import DtIcon from '../../assets/icons/minmini-logo.svg';
import DtIconDark from '../../assets/icons/minmini-logo.svg';
import MicIcon from '../../assets/icons/micIcon.svg';
import MicIconDark from '../../assets/icons/micIconDark.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AUTHOR_NEWS, TIME_UNITS, ENGLISH } from '../../Constants/Constants';
import notificationService from '../../services/notification.service';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import userProfileService from '../../services/user-profile.service';
import './Notifications.css';
import { UserDetailsProvider } from '../../store/user-details-context';
import { AppContext } from '../../store/app-context';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const NotificationsList = ({
  id,
  source,
  timestamp,
  owner_id,
  news_story_id,
  news_story_thumbnail,
  post_id,
  user_action,
  user_action_id,
  message_en,
  message_tn,
  status,
  redirect_required,
  channel_id,
  group_id,
  user_id,
  news_url,
  post_type,
  onUpdate,
  onDelete,
}) => {
  const [openDeleteMenu, setOpenDeleteMenu] = useState(null);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { dispatch: appCtxDispatch } = useContext(AppContext);

  const [followersImage, setFollowersImage] = useState(null);

  const { state: userState } = useContext(UserDetailsProvider);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.mobile);
  const handleDeleteMenu = (event) => {
    setOpenDeleteMenu(event.currentTarget);
  };

  const closeDeleteMenu = () => {
    setOpenDeleteMenu(null);
  };

  const handleNavigation = async () => {
    try {
      const response = await notificationService.fetchAuthorDetails({
        userIds: `${user_action_id}`,
      });
      if (response) {
        const [authorsdetail] = response?.authors;
        navigate(`/${AUTHOR_NEWS}/${authorsdetail.name}`);
      }
    } catch (error) {}
  };

  const handleNewsDetailPost = () => {
    navigate(`/news${news_url}`);
  };

  /*
   * when user receives notifications with respect to like or comments from community, then we will redirect to particular post in community
   */
  const handleCommmunityDetails = () => {
    // navigate('/social', {
    //   state: {
    //     notificationUrl: `post/${post_id}?app_in_app=true&dark_mode=${isDarkMode}&language=${
    //       TRAINING_LANGUAGE[userState?.preferences?.contentLanguage] || 'en'
    //     }&profile_redirect=true`,
    //     isLoggedIn:
    //       localStorage.getItem('community_login') === 'true' ? true : false,
    //   },
    // });
  };

  /*
   * when user receives notifications with respect to follow from community, then we will redirect to particular user profile in community
   */
  const handleCommmunity = () => {
    // navigate('/social', {
    //   state: {
    //     communityUserProfileUrl: `${user_id}?app_in_app=true&dark_mode=${isDarkMode}&language=${
    //       TRAINING_LANGUAGE[userState?.preferences?.contentLanguage] || 'en'
    //     }&profile_redirect=true`,
    //     isLoggedIn:
    //       localStorage.getItem('community_login') === 'true' ? true : false,
    //   },
    // });
  };

  const timeInHours = formatDistanceToNowStrict(new Date(timestamp))
    .replace(TIME_UNITS, '')
    .split(' ')
    .join('');

  /**
   * Side effect to calls Image API if Reporter tracking ID is available
   */
  useEffect(() => {
    user_action_id && getFollowersImage();
  }, [user_action_id]);

  const handleProfile = () => {
    appCtxDispatch({ type: 'SHOW_PROFILE' });
  };

  /**
   * Function to retrieve images using the userId of the the Reporter
   */
  const getFollowersImage = () => {
    if (
      user_action === 'FOLLOW' ||
      user_action === 'LIKE' ||
      user_action === 'COMMENT'
    ) {
      userProfileService.getProfileImage(user_action_id).then((image) => {
        setFollowersImage(image);
      });
    } else {
      setFollowersImage(UserIcon);
    }
  };

  /**
   * Deleting the Notification by passing id
   */
  const deleteNotification = async (id) => {
    try {
      const response = await notificationService.deleteNotification({
        notification_id: `${id}`,
      });

      if (response) {
        onDelete(id);
      }
    } catch (error) {}
  };

  /**
   * Updating the Notification status to read by passing id
   */
  const updateNotification = async (id) => {
    try {
      const response = await notificationService.updateNotification({
        notification_id: `${id}`,
      });
      if (response) {
        onUpdate(id);
      }
    } catch (error) {}
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          borderRadius: isMobile ? '0' : '10px',
          background:
            isDarkMode && isMobile && status === 'UNREAD'
              ? DARK_COLOR.grey[100]
              : isDarkMode && isMobile && status === 'READ'
              ? DARK_COLOR.grey[200]
              : !isDarkMode && isMobile && status === 'UNREAD'
              ? LIGHT_COLOR.grey[100]
              : !isDarkMode && isMobile && status === 'READ'
              ? LIGHT_COLOR.white[100]
              : isDarkMode && !isMobile
              ? DARK_COLOR.grey[200]
              : LIGHT_COLOR.white[100],
          border:
            isDarkMode && !isMobile && status === 'UNREAD'
              ? `0.063rem solid ${DARK_COLOR.primary[100]}`
              : isDarkMode && !isMobile && status === 'READ'
              ? `0.063rem solid ${DARK_COLOR.grey[300]}`
              : !isDarkMode && !isMobile && status === 'UNREAD'
              ? `0.063rem solid ${LIGHT_COLOR.primary[100]}`
              : !isDarkMode && !isMobile && status === 'READ'
              ? `0.063rem solid ${LIGHT_COLOR.grey[300]}`
              : 'transparent',
          borderBottom: isMobile ? 1 : '',
          borderColor: isMobile ? 'divider' : '',
          padding: '1.25rem',
          marginBottom: isMobile ? '0' : '0.625rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <div
          className='listInnerContainer'
          onClick={() => {
            updateNotification(id);
            if (redirect_required) {
              if (source === 'COMMUNITY') {
                if (user_action === 'FOLLOW') {
                  handleCommmunity();
                } else if (
                  user_action === 'LIKE' ||
                  user_action === 'COMMENT'
                ) {
                  handleCommmunityDetails();
                }
              }
              if (source === 'CMS') {
                if (
                  user_action === 'LIKE' ||
                  user_action === 'POST' ||
                  user_action === 'COMMENT' ||
                  user_action === 'FLAG'
                ) {
                  handleNewsDetailPost();
                }
              }
              if (source === 'APP') {
                user_action === 'FOLLOW' && handleNavigation();
                (user_action === 'PROFILE' || user_action === 'WELCOME') &&
                  handleProfile();
              }
            }
          }}
        >
          {followersImage ? (
            <>
              <img
                className='notificationImage'
                src={followersImage}
                alt='notification image'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = UserIcon;
                }}
              />
              {(user_action === 'FOLLOW' ||
                user_action === 'LIKE' ||
                user_action === 'COMMENT') && (
                <>
                  <img
                    src={backgroundIcon}
                    alt='reaction-background'
                    className='reaction-background'
                  />
                  <img
                    src={
                      user_action === 'FOLLOW'
                        ? followIcon
                        : user_action === 'LIKE'
                        ? likeIcon
                        : commentIcon
                    }
                    alt='like'
                    className='like-reaction'
                  />
                </>
              )}
            </>
          ) : (
            <img
              style={{
                border: `1px solid ${DARK_COLOR.primary[100]}`,
              }}
              className='notification-minmini-image'
              src={
                user_action === 'SIGNUP' ||
                user_action === 'PROFILE' ||
                user_action === 'WELCOME'
                  ? isDarkMode
                    ? DtIconDark
                    : DtIcon
                  : user_action === 'POST' ||
                    user_action === 'REPORTER' ||
                    user_action === 'ASSESSMENT'
                  ? isDarkMode
                    ? MicIconDark
                    : MicIcon
                  : (user_action === 'EKYC' || user_action === 'FLAG') &&
                    isDarkMode
                  ? UserIconDark
                  : UserIcon
              }
              alt='minmini image'
            />
          )}
          {news_story_thumbnail != null ? (
            <div className='notificationSectionOne'>
              <div className='notificationSectionOneContainer'>
                <Typography
                  sx={{
                    marginBottom: '0',
                    color: isDarkMode
                      ? DARK_COLOR.white[100]
                      : LIGHT_COLOR.grey[500],
                    cursor: 'pointer',
                    fontWeight: status !== 'READ' && '600',
                    width: isMobile ? '9.625rem' : '100%',
                    marginRight: isMobile ? '0.625rem' : '0',
                  }}
                >
                  {userState?.preferences?.contentLanguage === ENGLISH
                    ? message_en
                    : message_tn}
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    fontSize: '0.875rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[500]
                      : LIGHT_COLOR.grey[400],
                  }}
                >
                  {timeInHours + ' ago'}
                  <Typography
                    component='span'
                    sx={{
                      color: isDarkMode
                        ? DARK_COLOR.grey[500]
                        : LIGHT_COLOR.grey[300],
                      margin: '0 0.313rem',
                    }}
                  >
                    &bull;
                  </Typography>
                  <Typography
                    component='span'
                    sx={{
                      fontSize: '0.875rem',
                      color: isDarkMode
                        ? DARK_COLOR.grey[500]
                        : LIGHT_COLOR.grey[400],
                    }}
                  >
                    {source}
                  </Typography>
                </Typography>
              </div>
              <img
                className='notificationFullImage'
                alt='notification full image'
                src={news_story_thumbnail}
              />
            </div>
          ) : (
            <div className='notificationSectionTwo'>
              <Typography
                sx={{
                  marginBottom: '0',
                  color: isDarkMode
                    ? DARK_COLOR.white[100]
                    : LIGHT_COLOR.grey[500],
                  fontWeight: status !== 'READ' && '600',
                  cursor: 'pointer',
                }}
              >
                {userState?.preferences?.contentLanguage === ENGLISH
                  ? message_en
                  : message_tn}
              </Typography>
              <Typography
                component='span'
                sx={{
                  fontSize: '0.875rem',
                  color: isDarkMode
                    ? DARK_COLOR.grey[500]
                    : LIGHT_COLOR.grey[400],
                }}
              >
                {timeInHours + ' ago'}
                <Typography
                  component='span'
                  sx={{
                    color: isDarkMode
                      ? DARK_COLOR.grey[500]
                      : LIGHT_COLOR.grey[300],
                    margin: '0 0.313rem',
                  }}
                >
                  &bull;
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    fontSize: '0.875rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[500]
                      : LIGHT_COLOR.grey[400],
                  }}
                >
                  {source}
                </Typography>
              </Typography>
            </div>
          )}
        </div>
        <div>
          <img
            src={
              isDarkMode
                ? openDeleteMenu
                  ? selectMoreDarkIcon
                  : moreOptionsDarkIcon
                : openDeleteMenu
                ? selectMoreLightIcon
                : moreOptionsIcon
            }
            alt='more options icon'
            className='deleteMenuContainer'
            onClick={handleDeleteMenu}
          />
          <Menu
            anchorEl={openDeleteMenu}
            open={openDeleteMenu}
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: isDarkMode ? '#323232' : '',
                border: isDarkMode
                  ? ''
                  : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
                marginTop: '1.5rem',
                width: isMobile ? '12.5rem' : '15.75rem',
                marginLeft: '0.625rem',
              },
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={closeDeleteMenu}
            className='notificationDeleteDropdown'
          >
            <Box
              sx={{
                background: isDarkMode
                  ? DARK_COLOR.grey[300]
                  : LIGHT_COLOR.white[100],
              }}
              className='notificationDeleteMenu'
              onClick={closeDeleteMenu}
            >
              <img
                alt='delete notification icon'
                className='notificationDeleteIcon'
                src={isDarkMode ? deleteDarkIcon : deleteIcon}
              />
              <div
                onClick={() => {
                  deleteNotification(id);
                  closeDeleteMenu();
                }}
              >
                <Typography
                  sx={{
                    color: isDarkMode
                      ? DARK_COLOR.white[100]
                      : LIGHT_COLOR.grey[400],
                  }}
                >
                  {t('DELETE_NOTIFICATION')}
                </Typography>
              </div>
            </Box>
          </Menu>
        </div>
      </Box>
    </>
  );
};

export default NotificationsList;
