import React, { useContext } from 'react';
import NewsCard from './NewsCard';
// import NewsCardGrid from './NewsCardGrid';
import PromotionalNewsList from './PromotionalNewsList';
// import PromotionalNewsGrid from './PromotionalNewsGrid';
import { Grid } from '@mui/material';
// import { AppContext } from '../../store/app-context';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './NewsCard.css';
import InArticleAd from '../Ads/InArticleAd';
import { EnvironmentProvider } from '../../store/firebase-context';
import { PUBLISHER_TYPE, TRUE } from '../../Constants/Constants';

const NewsData = ({ news, newsCategories, onCardAction }) => {
  // const { state: appCtxState } = useContext(AppContext);
  const { environment } = useContext(EnvironmentProvider);

  const theme = useTheme();
  const xsMatch = useMediaQuery(theme.breakpoints.only('xs'));
  // const smMatch = useMediaQuery(theme.breakpoints.only('sm'));
  // const mdMatch = useMediaQuery(theme.breakpoints.only('md'));
  // const lgMatch = useMediaQuery(theme.breakpoints.only('lg'));

  // let cardCount = 1;
  // let currentAdPlacement = 1 * Number(environment.web_grid_card_ads_row);
  // if (smMatch) {
  //   cardCount = 2;
  //   currentAdPlacement = 2 * Number(environment.web_grid_card_ads_row) - 1;
  // } else if (mdMatch) {
  //   cardCount = 3;
  //   currentAdPlacement = 3 * Number(environment.web_grid_card_ads_row) - 1;
  // } else if (lgMatch) {
  //   cardCount = 4;
  //   currentAdPlacement = 4 * Number(environment.web_grid_card_ads_row) - 1;
  // }

  /**
   * Getting the category names from CONFIG API to be displayed in the select dropdown menu
   */

  return (
    <>
      {news?.map((news, index) => (
        <div key={news.newsId}>
          {index % parseInt(environment.other_tabs_adslot) === 0 &&
            index !== 0 &&
            !(
              environment.news_max_adcount * environment.other_tabs_adslot <
              index
            ) && (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  display='flex'
                  justifyContent='center'
                  marginBottom='1rem'
                >
                  {environment?.web_ad_enable === TRUE &&
                    (xsMatch ? (
                      <InArticleAd isMobile={true} />
                    ) : (
                      <InArticleAd />
                    ))}
                </Grid>
              </>
            )}
          {news.is_promotional ? (
            <div key={news.newsId}>
              <PromotionalNewsList
                key={news.newsId}
                id={news.newsId}
                image={news.media?.map((image) => image?.url)}
                likeCount={news.news_like_count}
                dislikeCount={news.news_dislike_count}
                newsId={news.newsId}
                isLiked={news.is_liked}
                isDisliked={news.is_disliked}
                isBookmark={news.is_bookmark}
                user_type={news.user_type}
              />
            </div>
          ) : (
            <div key={news.newsId}>
              <NewsCard
                key={news.newsId}
                id={news.newsId}
                anonymous={news.anonymous}
                description={news.heading}
                keywords={news.keywords}
                url={news.url}
                hasVideo={news.video}
                maincategoryIcon={news.maincategory_icon}
                mainCategory={news.maincategory_name}
                mainCategoryID={news.maincategory}
                mainCategoryUrl={news.maincategory_url}
                headline={news.heading}
                media={news?.media}
                city={news.location}
                locationID={news.locationId}
                newsDate={news.date_news}
                likeCount={news.news_like_count}
                dislikeCount={news.news_dislike_count}
                authorName={news.authorName}
                authorID={news.authorId}
                trackingID={news.trackingId}
                newsID={news.newsId}
                authorImage={
                  news.user_type === PUBLISHER_TYPE && news.authorImage
                    ? news.authorImage
                    : `${environment.userprofile_prefix_url}${news.trackingId}`
                }
                isLiked={news.is_liked}
                isDisliked={news.is_disliked}
                isBookmark={news.is_bookmark}
                isTrending={news.is_trending}
                newsType={news.news_type}
                commentsCount={news.news_comments_count}
                newsCategories={newsCategories}
                verifiedByDT={news.param_isVerifiedByDT}
                onCardAction={onCardAction}
                user_type={news.user_type}
              />
            </div>
          )}
        </div>
      ))}

      {/* {appCtxState?.newsView?.activeGrid && (
        <Grid
          container
          spacing={{ xs: 2 }}
          columns={{ xs: 'auto', sm: 6, md: 9, lg: 12 }}
          justifyContent='center'
        >
          {news?.map((news, index) => (
            <>
              {index % currentAdPlacement === 0 &&
                index !== 0 &&
                !(
                  environment.news_max_adcount * currentAdPlacement <
                  index
                ) && (
                  <>
                    {environment?.web_ad_enable === TRUE && (
                      <Grid item xs={3} alignSelf='center'>
                        <InArticleAd isMobile={true} />
                      </Grid>
                    )}
                  </>
                )}
              {(index + parseInt(index / currentAdPlacement)) %
                (cardCount * parseInt(environment.other_tabs_adslot)) ===
                0 &&
                index !== 0 &&
                !(
                  environment.news_max_adcount *
                    cardCount *
                    parseInt(environment.other_tabs_adslot) <
                  index + parseInt(index / currentAdPlacement)
                ) &&
                environment?.web_ad_enable === TRUE && (
                  <Grid xs={12} display='flex' justifyContent='center'>
                    <InArticleAd />
                  </Grid>
                )}
              {news.is_promotional ? (
                <Grid item xs={3} key={news.newsId}>
                  <PromotionalNewsGrid
                    key={news.newsId}
                    id={news.newsId}
                    image={news.media?.map((image) => image?.url)}
                    likeCount={news.news_like_count}
                    dislikeCount={news.news_dislike_count}
                    newsId={news.newsId}
                    isLiked={news.is_liked}
                    isDisliked={news.is_disliked}
                    isBookmark={news.is_bookmark}
                    isTrending={news.is_trending}
                    newsType={news.news_type}
                  />
                </Grid>
              ) : (
                <Grid item xs={3} key={news.newsId}>
                  <NewsCardGrid
                    key={news.newsId}
                    id={news.newsId}
                    anonymous={news.anonymous}
                    url={news.url}
                    hasVideo={news.video}
                    maincategoryIcon={news.maincategory_icon}
                    mainCategory={news.maincategory_name}
                    mainCategoryID={news.maincategory}
                    mainCategoryUrl={news.maincategory_url}
                    headline={news.heading}
                    media={news?.media}
                    city={news.location}
                    locationID={news.locationId}
                    newsDate={news.date_news}
                    likeCount={news.news_like_count}
                    dislikeCount={news.news_dislike_count}
                    authorName={news.authorName}
                    authorID={news.authorId}
                    trackingID={news.trackingId}
                    newsID={news.newsId}
                    authorImage={
                      news.user_type === PUBLISHER_TYPE && news.authorImage
                        ? news.authorImage
                        : `${environment.userprofile_prefix_url}${news.trackingId}`
                    }
                    isLiked={news.is_liked}
                    isDisliked={news.is_disliked}
                    isBookmark={news.is_bookmark}
                    isTrending={news.is_trending}
                    newsType={news.news_type}
                    commentsCount={news.news_comments_count}
                    newsCategories={newsCategories}
                    verifiedByDT={news.param_isVerifiedByDT}
                    onCardAction={onCardAction}
                    user_type={news.user_type}
                  />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      )} */}
    </>
  );
};

export default NewsData;
