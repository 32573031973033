import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

const getBankDetail = (token) => {
  return apiRequest(
    api.getBankDetail,
    METHOD.GET,
    false,
    null,
    false,
    false,
    false,
    token,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateBankDetail = (data, token) => {
  return apiRequest(
    api.updateBankDetail,
    METHOD.POST,
    false,
    data,
    false,
    false,
    false,
    token,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const paymentService = { getBankDetail, updateBankDetail };

export default paymentService;
