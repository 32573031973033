import React, { useEffect, useState, useContext } from 'react';
import DetailedNewsData from './DetailedNewsData';
import RelatedNews from './RelatedNews';
import Ads from '../Ads/Ads';
import { Box, Typography } from '@mui/material';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { CardSkeleton } from '../Skeleton/PublisherSkeleton';
import { useTranslation } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import HorizontalMenuBar from '../HorizontalMenuBar/HorizontalMenuBar';
import newsService from '../../services/news.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import { startTransition } from 'react';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import recombeeService from '../../services/recombee.service';

export default function DetailedNews({
  newsCategories,
  navBarCategories,
  navBarFilters,
  districts,
}) {
  const { id, newsDetail, detailid, authorDetailNews } = useParams();
  const newsDetailId =
    newsDetail && newsDetail.split('-')[newsDetail.split('-').length - 1];
  const newsID = id && id.split('-')[id.split('-').length - 1];
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [news, setNews] = useState([]);
  const [newsByCategory, setNewsByCategory] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRelatedNewsLoading, setIsRelatedNewsLoading] = useState(true);
  const [isPrivatePost, setIsPrivatePost] = useState(false);
  const isMobile = MaxWidth(viewPort.tablet);
  const { t } = useTranslation();
  const isLaptop = MinWidth(viewPort.laptop);

  const { state: userState } = useContext(UserDetailsProvider);
  const newsSessionID =
    userState?.sessionId || localStorage.getItem('session_id');
  const location = useLocation();
  const navigate = useNavigate();
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);
  const isAuthenticated = userState?.isAuthenticated;
  const userID = userState?.userDetails?.userId;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated) {
      captureRecombeeEvents();
    }
  }, [newsID, newsDetailId, detailid, authorDetailNews, isAuthenticated]);

  useEffect(() => {
    getNewsDetails();
  }, [newsID, newsDetailId, detailid, authorDetailNews]);

  const getNewsDetails = async () => {
    let newsIds = newsDetailId
      ? newsDetailId
      : detailid
      ? detailid
      : authorDetailNews
      ? authorDetailNews
      : newsID;

    let validNewsIds = Number(newsIds);

    if (validNewsIds) {
      try {
        const detailNewsData = {
          newsId: newsIds,
        };

        const response = await newsService.fetchNewsById(detailNewsData);
        if (response?.errorMessage) {
          setIsPrivatePost(true);
        }
        if (response?.news) {
          const [{ newsId }] = response?.news;
          setNews(response?.news?.length ? response.news[0] : null);
          getSimilarNews(newsId);
          getDynamicProps({ newsIds: newsIds });
        }
      } catch (error) {
      } finally {
        setIsRelatedNewsLoading(false);
        setIsLoading(false);
      }
    } else {
      setIsRelatedNewsLoading(false);
      setIsLoading(false);
    }
  };

  /**
   * function to updated dynamic props of the news
   * @param {object} req api request object
   */
  const getDynamicProps = async (req) => {
    try {
      if (newsSessionID) {
        req['sessionId'] = newsSessionID;
      }

      const response = await newsService.fetchNewsDynamicProps(req);

      if (news) {
        startTransition(() => {
          setNews((prevState) => {
            return {
              ...prevState,
              ...response.news[0],
            };
          });
        });
      }
    } catch (error) {}
  };

  /**
   * function to updated dynamic props of the related news
   * @param {object} newsIds
   */
  const getDynamicPropsRelatedNews = async (newsIds) => {
    try {
      const reqObj = {
        newsIds: newsIds.join(','),
      };

      if (newsSessionID) {
        reqObj['sessionId'] = newsSessionID;
      }

      const response = await newsService.fetchNewsDynamicProps(reqObj);
      const dynamicProps = response?.news || [];

      if (dynamicProps?.length) {
        startTransition(() => {
          setNewsByCategory((prevState) => {
            return prevState?.map((news) => {
              let isAvailable = dynamicProps.find(
                (newsObj) => newsObj.newsId === news.newsId,
              );

              if (isAvailable) {
                return {
                  ...news,
                  ...isAvailable,
                };
              } else {
                return { ...news };
              }
            });
          });
        });
      }
    } catch (error) {}
  };

  const getSimilarNews = async (newsId) => {
    try {
      const response = await newsService.similarNews({
        newsId: newsId,
        count: 3,
      });
      if (response?.news?.length) {
        setNewsByCategory(response.news);

        const newsIds = response?.news?.map(({ newsId }) => newsId);

        if (newsIds?.length) {
          getDynamicPropsRelatedNews(newsIds);
        }
      }
    } catch (error) {
    } finally {
      setIsRelatedNewsLoading(false);
    }
  };

  /**
   * Captures a Recombee interaction event
   * @async
   * @function captureRecombeeEvents
   * @throws {Error} If an error occurs while capturing the interaction.
   * @returns {Promise<void>}
   */
  const captureRecombeeEvents = async () => {
    try {
      const payload = {
        eventName: 'detailView',
        userId: userID,
        itemId: parseInt(newsID),
      };
      await recombeeService.captureRecombeeInteraction(payload);
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode
          ? (theme) => theme.palette.black[100]
          : (theme) => theme.palette.background.default,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: isLaptop ? 'row' : 'column',
      }}
    >
      <Box sx={{ width: isMobile ? '100%' : '22%' }}>
        <HorizontalMenuBar
          newsCategories={navBarCategories}
          isBackRequired={true}
          navBarFilters={navBarFilters}
          districts={districts}
        />
      </Box>
      <Box sx={{ width: isMobile ? '100%' : '50%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems:
              aboveLap || (belowLap && aboveMobile) ? 'center' : 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',

              width: aboveLap || (belowLap && aboveMobile) ? '32.5rem' : 'auto',
            }}
          >
            <Box
              sx={{
                p: aboveLap
                  ? '1rem 1.32rem 1rem 0rem'
                  : belowLap && aboveMobile
                  ? '1rem 1.32rem 1rem 0rem'
                  : '0.563rem 0.563rem 1rem 0.5rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (location.key !== 'default') {
                  navigate(-1);
                } else {
                  navigate('/', { replace: true });
                }
              }}
            >
              <Typography
                sx={{
                  fontSize: aboveLap
                    ? '1.25rem'
                    : belowLap && aboveMobile
                    ? '1rem'
                    : '0.75rem',
                }}
                variant='h8'
              >
                &lt; &nbsp;{t('back')}
              </Typography>
            </Box>
          </Box>

          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth:
                  aboveLap || (belowLap && aboveMobile) ? '30.5rem' : 'none',
                width: '100%',
              }}
            >
              <CardSkeleton cards={1} />
            </Box>
          ) : news && (news.length || Object.keys(news)?.length) ? (
            <DetailedNewsData news={news} newsCategories={newsCategories} />
          ) : (
            <EmptyScreen
              message={
                isPrivatePost
                  ? t('Looks like this post has been removed.')
                  : t('page-not-found')
              }
            />
          )}
        </Box>
        {isRelatedNewsLoading ? (
          <CardSkeleton cards={1} />
        ) : newsByCategory?.length > 0 &&
          news &&
          (news.length || Object.keys(news)?.length) ? (
          <RelatedNews news={newsByCategory} newsCategories={newsCategories} />
        ) : null}
      </Box>

      {!isMobile && (
        <Box sx={{ width: isMobile ? '100%' : '24%' }}>
          {news && (news.length || Object.keys(news)?.length) ? <Ads /> : null}
        </Box>
      )}
    </Box>
  );
}
