// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publisher_authorImgCircle__FZU49 {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.625rem;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/Components/FollowingTab/publisher.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".authorImgCircle {\n  width: 2.25rem;\n  height: 2.25rem;\n  margin-right: 0.625rem;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authorImgCircle": `publisher_authorImgCircle__FZU49`
};
export default ___CSS_LOADER_EXPORT___;
