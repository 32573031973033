import React, { useContext } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { useTranslation } from 'react-i18next';
import { EnvironmentProvider } from '../../store/firebase-context';
import SuccessBadge from '../../assets/icons/Badge-Success.svg';
import FailedBadge from '../../assets/icons/Badge-Fail.svg';
import CountUp from 'react-countup';
import ButtonLoader from '../buttonLoader/ButtonLoader';
import { ONE } from '../../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import BackIcon from '../../assets/icons/back-arrow.svg';

const AssessmentResults = ({
  percentage,
  answerCount,
  assesmentData,
  setShowBadgeScreen,
  setDisplayOrder,
  assesmentAPI,
  setShowAssessment,
  setAnchorEl,
}) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { environment } = useContext(EnvironmentProvider);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = MaxWidth(viewPort.mobile);

  const tryAgain = () => {
    setShowBadgeScreen(false);
    setDisplayOrder(ONE);
    assesmentAPI();
    setShowAssessment(true);
    setAnchorEl(null);
  };

  return isMobile ? (
    <Box
      sx={{
        background: isDarkMode ? DARK_COLOR.grey[200] : LIGHT_COLOR.white[100],
      }}
    >
      <div className='mobileAssessmentTitle'>
        <span>
          <img
            src={BackIcon}
            className='mobileAssessmentTitleIcon'
            alt='back-icon'
            onClick={() => navigate('/')}
          />
        </span>{' '}
        <Box className='mobileAssessmentHeading'>
          <Typography sx={{ fontWeight: '600' }}>
            {t('become-a-reporter')}
          </Typography>
        </Box>
      </div>
      <hr className='mobileAssessmentHeadingLine' />
      <Box className='mobileBadgeScreen'>
        {percentage >= 60 ? (
          <>
            <Typography
              variant='h5'
              sx={{
                color: (theme) => theme.palette.success.main,
                marginBottom: '1.25rem',
              }}
            >
              {t('Congratulations')}
            </Typography>
            <img
              src={SuccessBadge}
              alt='SuccessBadge'
              className='mobileSuccessBadge'
            />
            <Typography
              sx={{
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[400],
                marginBottom: '1rem',
              }}
              className='badgeScreenContent'
            >
              {t('ASSESSMENT_SUCCESS_MESSAGE')}
            </Typography>
            <div className='mobileBadgeScreenScore'>
              <CountUp end={answerCount} duration={2} />/
              <span className='mobileBadgeScreenTotal'>
                {assesmentData?.length}
              </span>
            </div>
            <Grid container display={'flex'} justifyContent='center'>
              <ButtonLoader
                variant='contained'
                color='primary'
                onClick={() => navigate('/reporter')}
              >
                {t('proceed')}
              </ButtonLoader>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant='h5'>{t('OOPS')}</Typography>
            <img
              src={FailedBadge}
              alt='FailedBadge'
              className='mobileFailedBadge'
            />
            <Typography
              sx={{
                marginBottom: '1rem',
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[400],
              }}
            >
              {t('ASSESSMENT_FAILED_MESSAGE')}
            </Typography>
            <Box
              sx={{
                color: (theme) => theme.palette.error.main,
              }}
              className='mobileBadgeScreenFailedScore'
            >
              <CountUp end={answerCount} duration={2} />/
              <span className='mobileBadgeScreenFailedTotal'>
                {assesmentData?.length}
              </span>{' '}
            </Box>
            <Grid container display='flex' justifyContent='center'>
              <div className='confirmSubmitAssesment'>
                <Button variant='containted' onClick={tryAgain}>
                  {t('TRY_AGAIN')}
                </Button>
              </div>
              <div className='assessmentOutlineBtn'>
                <Button
                  variant='outlined'
                  onClick={() => navigate('/')}
                  style={{ marginBottom: '1rem' }}
                >
                  {t('RETAKE-LATER')}
                </Button>
              </div>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={{ padding: '0 4.666vw' }}>
      <Box sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
        <Box className='assesmentTitleSection'>
          <div className={'assesmentTitleMd'}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant='h5'>{t('become-a-reporter')}</Typography>
            </Box>
          </div>
        </Box>
        <Box
          className='badgeScreen'
          sx={{
            background: isDarkMode
              ? DARK_COLOR.grey[200]
              : LIGHT_COLOR.white[100],
          }}
        >
          {percentage >= environment.assessment_pass_percentage ? (
            <>
              <Typography
                variant='h5'
                sx={{
                  color: (theme) => theme.palette.success.main,
                  marginBottom: '1.25rem',
                }}
              >
                {t('CONGRATULATIONS')}
              </Typography>
              <img
                src={SuccessBadge}
                alt='SuccessBadge'
                className='successBadge'
              />
              <Typography
                sx={{
                  color: isDarkMode
                    ? DARK_COLOR.grey[600]
                    : LIGHT_COLOR.grey[400],
                  marginBottom: '1rem',
                }}
                className='badgeScreenContent'
              >
                {t('ASSESSMENT_SUCCESS_MESSAGE')}
              </Typography>
              <Box
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
                className='badgeScreenScore'
              >
                <CountUp end={answerCount} duration={2} />/
                <span className='badgeScreenTotal'>
                  {assesmentData?.length}
                </span>
              </Box>
              <ButtonLoader
                variant='contained'
                color='primary'
                onClick={() => navigate('/reporter')}
              >
                {t('proceed')}
              </ButtonLoader>
            </>
          ) : (
            <>
              <Typography variant='h5'>{t('OOPS')}</Typography>
              <img
                src={FailedBadge}
                alt='FailedBadge'
                className='failedBadge'
              />
              <Typography
                sx={{
                  marginBottom: '1rem',
                  color: isDarkMode
                    ? DARK_COLOR.grey[600]
                    : LIGHT_COLOR.grey[400],
                }}
              >
                {t('ASSESSMENT_FAILED_MESSAGE')}
              </Typography>
              <Box
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
                className='badgeScreenFailedScore'
              >
                <CountUp end={answerCount} duration={2} />/
                <span className='badgeScreenFailedTotal'>
                  {assesmentData?.length}
                </span>
              </Box>
              <div className='confirmSubmitAssesment'>
                <Button variant='containted' onClick={tryAgain}>
                  {t('TRY_AGAIN')}
                </Button>
              </div>
              <div className='retakeAssessment'>
                <Button variant='outlined' onClick={() => navigate('/')}>
                  {t('RETAKE-LATER')}
                </Button>
              </div>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AssessmentResults;
