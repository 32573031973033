import { api } from '../Constants/api.constants';
import apiRequest from './fetch';
import { METHOD } from '../enums/api.enums';

/** work to do
 * we need to add three file
 * @param {String} playlistID Based on language
 *  @returns promise
 */
const fetchPlaylist = (playlistID) => {
  return apiRequest(
    `${api.getTrainingPlaylist}/${playlistID}`,
    METHOD.GET,
    false,
    null,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for get info of playlist data
 * @param {object} data query params
 * @returns promise
 */
const getPlayListStatusData = (data) => {
  return apiRequest(`${api.videoTrackingData}`, METHOD.GET, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for add info of playlist data
 * @param {object} data request payload
 * @returns promise
 */

const addPlayListStatusData = (data) => {
  return apiRequest(api.videoTrackingData, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for update info of playlist data
 * @param {object} data request payload
 * @returns promise
 */
const updatePlayListStatusData = (id, data) => {
  return apiRequest(
    `${api.videoTrackingData}/${id}`,
    METHOD.PUT,
    false,
    data,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for update info of playlist data
 * @param {object} data request payload
 * @returns promise
 */
const trainingCompleted = (lang) => {
  return apiRequest(
    `${api.trainingCompleted}/${lang}`,
    METHOD.POST,
    false,
    '',
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const trainingService = {
  getPlayListStatusData,
  addPlayListStatusData,
  updatePlayListStatusData,
  fetchPlaylist,
  trainingCompleted,
};

export default trainingService;
