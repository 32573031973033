import { useState } from 'react';
import authService from '../../services/auth.service';

function withCaptcha(WrappedComponent) {
  return function (props) {
    const [showWrappedComponent, setShowWrappedComponent] = useState(true);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [skipCaptcha, setSkipCaptcha] = useState(false);
    const [userDetails, setUserDetails] = useState({});

    const verifyCaptcha = (data) => {
      setUserDetails(data);
      authService
        .verifyCaptcha()
        .then((response) => {
          if (response.status === 405) {
            const container = document.getElementById('my-captcha-container');
            setShowWrappedComponent(false);
            window.AwsWafCaptcha.renderCaptcha(container, {
              apiKey: process.env.REACT_APP_CAPTCHA_KEY,
              onSuccess() {
                setCaptchaVerified(true);
                setShowWrappedComponent(true);
              },
              onError(err) {
                console.log('error', err);
              },
            });
          } else if (response.status === 200) {
            setSkipCaptcha(true);
          } else {
            setShowWrappedComponent(true);
          }
        })
        .catch((err) => {
          console.log('ERROR', err);
        });
    };

    return (
      <div>
        {!captchaVerified && <div id='my-captcha-container'></div>}
        {showWrappedComponent ? (
          <WrappedComponent
            {...props}
            verifyCaptcha={verifyCaptcha}
            captchaVerified={captchaVerified}
            skipCaptcha={skipCaptcha}
            userDetailProp={userDetails}
          />
        ) : null}
      </div>
    );
  };
}

export default withCaptcha;
