import React, { useState, useEffect, useContext } from 'react';
import inActiveSwitch from '../../assets/icons/inactive-switch.svg';
import inActiveSwitchDark from '../../assets/icons/inactive-switch-dark.svg';
import activeSwitch from '../../assets/icons/active-switch.svg';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import { useTranslation } from 'react-i18next';
import './NotificationSettings.css';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/icons/arrow-left.svg';
import backIconDark from '../../assets/icons/arrow-left-white.svg';
import notificationService from '../../services/notification.service';
import { NotificationSettingskeleton } from '../Skeleton/PublisherSkeleton';
import { UserDetailsProvider } from '../../store/user-details-context';
import {
  Grid,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ModalBox from '../Modal/Modal';
import { AppContext } from '../../store/app-context';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

/**
 * Notification Settings
 */
const NotificationSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = MaxWidth(viewPort.tablet);

  const { state: userState } = useContext(UserDetailsProvider);
  const isEmailVerified = userState?.userDetails?.isEmailVerified;

  const { dispatch: appCtxDispatch } = useContext(AppContext);

  const [emailpreferenceChange, setEmailPreferenceChange] = useState(false);
  const [pushpreferenceChange, setPushPreferenceChange] = useState(false);

  const [showEmailVerifiedModal, setShowEmailVerifiedModal] = useState(false);
  const changeModalSize = '22.5rem';

  const [isLoading, setIsLoading] = useState(true);

  const [pushOptions, setPushOptions] = useState({
    post_comment_related_activity: false,
    post_from_following: false,
    followers_added: false,
  });

  const [emailOptions, setEmailOptions] = useState({
    post_comment_related_activity: false,
    post_from_following: false,
    followers_added: false,
  });

  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  /**
   * Function to retrieve Followed Push Preferences
   */
  const fetchPushPreferences = async () => {
    try {
      const response = await notificationService.fetchPreferencesList({
        type: 'push',
      });
      const {
        post_comment_related_activity,
        post_from_following,
        followers_added,
      } = response;
      setPushOptions({
        post_comment_related_activity,
        post_from_following,
        followers_added,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Follow/Unfollow Push Preferences
   */
  const pushPreferencesData = async () => {
    try {
      const response = await notificationService.savePreferences({
        type: 'push',
        post_comment_related_activity:
          pushOptions['post_comment_related_activity'],
        post_from_following: pushOptions['post_from_following'],
        followers_added: pushOptions['followers_added'],
      });
      setPushPreferenceChange(false);
      if (response) {
        fetchPushPreferences();
      }
    } catch (error) {}
  };

  /**
   * Function to retrieve Followed Email Preferences
   */
  const fetchEmailPreferences = async () => {
    try {
      const response = await notificationService.fetchPreferencesList({
        type: 'email',
      });
      const {
        post_comment_related_activity,
        post_from_following,
        followers_added,
      } = response;
      setEmailOptions({
        post_comment_related_activity,
        post_from_following,
        followers_added,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Follow/Unfollow Email Preferences
   */
  const emailPreferencesData = async () => {
    try {
      const response = await notificationService.savePreferences({
        type: 'email',
        post_comment_related_activity:
          emailOptions['post_comment_related_activity'],
        post_from_following: emailOptions['post_from_following'],
        followers_added: emailOptions['followers_added'],
      });
      setEmailPreferenceChange(false);
      if (response) {
        fetchEmailPreferences();
      }
    } catch (error) {}
  };

  /**
   * side effect to calls push and email preferences api on initialization
   */
  useEffect(() => {
    fetchPushPreferences();
    fetchEmailPreferences();
  }, []);

  /**
   * side effect to call Follow/Unfollow push preferences, when there is a change in push preferences.
   */
  useEffect(() => {
    if (pushpreferenceChange) {
      pushPreferencesData();
    }
  }, [pushOptions, pushpreferenceChange]);

  /**
   * side effect to call Follow/Unfollow email preferences, when there is a change in email preferences.
   */
  useEffect(() => {
    if (emailpreferenceChange) {
      emailPreferencesData();
    }
  }, [emailOptions, emailpreferenceChange]);

  const handleBackButton = () => {
    navigate(-1);
  };

  /**
   * Toggles push notification options
   * @param {object} option push notification options object
   */
  const handlePushOptionChange = (itemValue) => {
    setPushOptions({
      ...pushOptions,
      [itemValue]: !pushOptions[itemValue],
    });
    setPushPreferenceChange(true);
  };

  /**
   * Toggles email notification options
   * @param {object} option email notification options object
   */
  const handleEmailOptionChange = (itemValue) => {
    if (isEmailVerified) {
      setEmailOptions({
        ...emailOptions,
        [itemValue]: !emailOptions[itemValue],
      });
      setEmailPreferenceChange(true);
    } else {
      setShowEmailVerifiedModal(true);
    }
  };

  const navigateToEditProfile = () => {
    appCtxDispatch({ type: 'SHOW_PROFILE' });
    setShowEmailVerifiedModal(false);
  };

  return (
    <>
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            background: isDarkMode
              ? DARK_COLOR.grey[200]
              : LIGHT_COLOR.white[100],
          }}
        >
          <Box
            className='notificationNavbar'
            sx={{
              background: isDarkMode
                ? DARK_COLOR.grey[200]
                : LIGHT_COLOR.white[100],
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Box
              className='notificationNavbarTitleContainer'
              sx={{
                background: isDarkMode
                  ? DARK_COLOR.grey[200]
                  : LIGHT_COLOR.white[100],
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <img
                src={isDarkMode ? backIconDark : backIcon}
                className='back-icon'
                alt='back icon'
                onClick={handleBackButton}
              />
              <Typography
                className='notificationNavbarTitle'
                sx={{
                  color: isDarkMode
                    ? DARK_COLOR.white[100]
                    : LIGHT_COLOR.grey[600],
                  fontWeight: '600',
                }}
              >
                {t('NOTIFICATION_SETTINGS')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1.5rem 6.25vw',
          width: '100%',
          minHeight: '44rem',
          background: isDarkMode ? DARK_COLOR.grey[100] : LIGHT_COLOR.grey[100],
        }}
      >
        <div className='notification-settings-outer-container'>
          <>
            {!isMobile && (
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: '600',
                  marginBottom: '0.625rem',
                  color: isDarkMode
                    ? DARK_COLOR.white[100]
                    : LIGHT_COLOR.grey[600],
                }}
              >
                {t('NOTIFICATION_SETTINGS')}
              </Typography>
            )}
            <Typography
              variant='body2'
              sx={{
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[500],
                marginBottom: '1.25rem',
              }}
            >
              {t('NOTIFICATION_SETTINGS_DESCRIPTION')}
            </Typography>
          </>

          <Box className='notification-settings-container'>
            <List
              className='push-notification-list'
              sx={{
                background: isDarkMode
                  ? DARK_COLOR.grey[200]
                  : LIGHT_COLOR.white[100],
                border: isDarkMode
                  ? `0.063rem solid ${DARK_COLOR.grey[300]}`
                  : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
              }}
            >
              <ListItem
                className='title'
                sx={{
                  height: '60px',
                }}
              >
                <Box className='notification-item'>
                  <ListItemText>
                    <Typography
                      className='label'
                      sx={{
                        color: isDarkMode
                          ? DARK_COLOR.white[100]
                          : LIGHT_COLOR.grey[600],
                        fontWeight: 600,
                      }}
                    >
                      {t('PUSH_NOTIFICATION')}
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItem>

              {isLoading ? (
                <NotificationSettingskeleton cards={3} />
              ) : (
                Object.entries(pushOptions).map(([key, value]) => (
                  <li
                    key={`push_${key}`}
                    style={{
                      borderTop: isDarkMode
                        ? `0.063rem solid ${DARK_COLOR.grey[300]}`
                        : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
                    }}
                  >
                    <div
                      className='notification-item'
                      onClick={() => handlePushOptionChange(key)}
                    >
                      <Typography
                        sx={{
                          color: isDarkMode
                            ? DARK_COLOR.grey[600]
                            : LIGHT_COLOR.grey[500],
                        }}
                        className='label'
                      >
                        {t(key)}
                      </Typography>
                      <img
                        src={
                          value
                            ? activeSwitch
                            : isDarkMode
                            ? inActiveSwitchDark
                            : inActiveSwitch
                        }
                        alt={key}
                      />
                    </div>
                  </li>
                ))
              )}
            </List>

            <List
              className='email-notification-list'
              sx={{
                background: isDarkMode
                  ? DARK_COLOR.grey[200]
                  : LIGHT_COLOR.white[100],
                border: isDarkMode
                  ? `0.063rem solid ${DARK_COLOR.grey[300]}`
                  : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
              }}
            >
              <ListItem className='title' sx={{ height: '60px' }}>
                <Box className='notification-item'>
                  <ListItemText>
                    <Typography
                      className='label'
                      sx={{
                        color: isDarkMode
                          ? DARK_COLOR.white[100]
                          : LIGHT_COLOR.grey[600],
                        fontWeight: 600,
                      }}
                    >
                      {t('EMAIL_NOTIFICATION')}
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItem>

              {isLoading ? (
                <NotificationSettingskeleton cards={3} />
              ) : (
                Object.entries(emailOptions).map(([key, value]) => (
                  <li
                    key={`email_${key}`}
                    style={{
                      borderTop: isDarkMode
                        ? `0.063rem solid ${DARK_COLOR.grey[300]}`
                        : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
                    }}
                  >
                    <div
                      className='notification-item'
                      onClick={() => handleEmailOptionChange(key)}
                    >
                      <Typography
                        sx={{
                          color: isDarkMode
                            ? DARK_COLOR.grey[600]
                            : LIGHT_COLOR.grey[500],
                        }}
                        className='label'
                      >
                        {t(key)}
                      </Typography>
                      <img
                        src={
                          value
                            ? activeSwitch
                            : isDarkMode
                            ? inActiveSwitchDark
                            : inActiveSwitch
                        }
                        alt={key}
                      />
                    </div>
                  </li>
                ))
              )}
            </List>
          </Box>
        </div>
        <ModalBox
          showModal={showEmailVerifiedModal}
          closeModal={() => setShowEmailVerifiedModal(false)}
          modalHeader='Email Notifications'
          isBackdropClick={true}
          className='settings-modal'
          modalSize={changeModalSize}
        >
          <div className='modalContainer'>
            <Typography
              variant='h5'
              sx={{
                marginBottom: '1.25rem',
                color: isDarkMode ? '' : LIGHT_COLOR.grey[600],
              }}
            >
              {t('Verify-Email-ID')}
            </Typography>
            <Typography
              sx={{
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[400],
                marginBottom: '3rem',
                width: '18.75rem',
                textAlign: 'center',
              }}
            >
              {t('Verify-email-id-in-Edit-Profile')}
            </Typography>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignContent='center'
            >
              <div className='verify-now-button'>
                <Button
                  onClick={navigateToEditProfile}
                  className='verify-now-text'
                  sx={{ fontFamily: 'Sharp Sans' }}
                >
                  {t('VERIFY-NOW')}
                </Button>
              </div>
              <div className='cancel-button'>
                <Button
                  variant='outlined'
                  onClick={() => setShowEmailVerifiedModal(false)}
                  className='cancel-button-text'
                  sx={{ fontFamily: 'Sharp Sans' }}
                >
                  {t('CLOSE')}
                </Button>
              </div>
            </Grid>
          </div>
        </ModalBox>
      </Box>
    </>
  );
};

export default NotificationSettings;
