import classes from './HorizontalMenuBar.module.css';
import {
  useState,
  useEffect,
  // useContext
} from 'react';
import React from 'react';
import {
  NavLink,
  // useParams
} from 'react-router-dom';
// import { v4 } from 'uuid';
import {
  // Divider,
  Typography,
  Box,
  List,
} from '@mui/material';
import DistrictSearch from '../DistrictSearch/Districts';
import { LaptopMax, DesktopMin } from '../../responsive/breakpoints';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
// import { UserDetailsProvider } from '../../store/user-details-context';
import { MaxWidth, viewPort, MinWidth } from '../../responsive/viewport.util';
// import { AppContext } from '../../store/app-context';
import Topics from '../Topic/Topics';
import { MINMINI_V3 } from '../../themes/color.constant';

export default function HorizontalMenuBar(props) {
  const {
    newsCategories,
    // isBackRequired,
    navBarFilters,
    // title,
    // filter,
    // category,
    districts,
  } = props;
  const { t } = useTranslation();
  const [showCity, setShowCity] = useState(false);
  const [showTopics, setShowTopics] = useState(false);

  const history = useLocation();
  const navigate = useNavigate();
  // const [isActiveSearchUrl, setIsActiveSearchUrl] = useState(false);
  // const { state: userState } = useContext(UserDetailsProvider);
  const [districtActive, setDistrictActive] = useState(false);
  // const userProfileId = userState?.userProfileId;
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  // const isSmallScreen = MaxWidth(viewPort.mobileMax);
  const isDesktop = MinWidth(viewPort.dekstopView);

  // const { districtDetail } = useParams();
  // const isPresent = history.pathname.includes(userProfileId);

  // const districtName = districtDetail && districtDetail.split('-')[0];
  // const urlPath = window.location.pathname.split('/');
  // const urlSegment = urlPath[1];
  // const { dispatch: appCtxDispatch } = useContext(AppContext);
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  useEffect(() => {
    const srcLink = history.pathname;
    const convertedSrcLink = srcLink.toLowerCase();
    if (/districts/g.test(convertedSrcLink)) {
      setDistrictActive(true);
    } else {
      setDistrictActive(false);
    }
  }, [history]);

  // const handleSearchHistory = () => {
  //   setIsActiveSearchUrl(false);
  // };

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const closeCityModal = () => {
    navigate(history.pathname);
    setShowCity(false);
  };

  const closeTopicsModal = () => {
    navigate(history.pathname);
    setShowTopics(false);
  };

  /**
   * Function to Navigate previous page
   */
  // const handleBackButton = () => {
  //   districtName || category ? navigate(-2) : navigate(-1);
  // };

  // const menu = (
  //   <div className={classes.menuItems}>
  //     {navBarFilters?.map((filterObj) => (
  //       <NavLink
  //         to={`${filterObj?.url}`}
  //         key={v4()}
  //         className={({ isActive }) =>
  //           isActive ? classes.activeNavLink : classes.navLink
  //         }
  //         onClick={() => handleClose()}
  //       >
  //         {({ isActive }) => (
  //           <>
  //             <img
  //               src={filterObj?.icon}
  //               style={{ width: '30px', height: '30px' }}
  //               alt='filter'
  //             />
  //             <Typography
  //               sx={{
  //                 color: isActive ? (theme) => theme.palette.primary.main : '',
  //                 fontWeight: isActive ? 700 : 400,
  //               }}
  //               variant='span'
  //             >
  //               {isTamil ? filterObj?.name_ta : filterObj?.name}
  //             </Typography>
  //           </>
  //         )}
  //       </NavLink>
  //     ))}
  //     <Divider sx={{ height: 2, width: '100%', borderColor: 'unset', mb: 1 }} />
  //     <NavLink
  //       to='/districts-list'
  //       className={({ isActive }) =>
  //         isActive && districtActive ? classes.activeNavLink : classes.navLink
  //       }
  //       onClick={() => {
  //         setShowCity(true);
  //         handleClose();
  //       }}
  //     >
  //       {({ isActive }) => (
  //         <Typography variant='body1'> {t('DISTRICTS')}</Typography>
  //       )}
  //     </NavLink>
  //     <NavLink
  //       to='/topic-list'
  //       className={({ isActive }) =>
  //         isActive && districtActive ? classes.activeNavLink : classes.navLink
  //       }
  //       onClick={() => {
  //         setShowTopics(true);
  //         handleClose();
  //       }}
  //     >
  //       {({ isActive }) => (
  //         <Typography variant='body1'> {t('topics')}</Typography>
  //       )}
  //     </NavLink>
  //   </div>
  // );

  // const displayHeading = () => {
  //   let heading;
  //   if (category) {
  //     heading = isTamil ? category?.name_tamil : category?.name;
  //   } else if (urlSegment === 'districts-list') {
  //     heading = t('DISTRICTS');
  //   } else if (urlSegment === 'topic-list') {
  //     heading = t('topics');
  //   } else if (districtName) {
  //     heading = districtName;
  //   }

  //   if (filter) {
  //     heading = isTamil ? filter?.name_ta || filter?.name : filter?.name;
  //   }

  //   return heading;
  // };

  /**
   * Function to open Profile Modal
   */
  // const handleProfile = () => {
  //   appCtxDispatch({ type: 'SHOW_PROFILE' });
  // };

  return (
    <>
      <LaptopMax>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            overflowX: 'auto',
            flexWrap: 'nowrap',
            height: '3rem',
            '&::-webkit-scrollbar': {
              height: '0.3rem',
              cursor: 'pointer',
            },
            '&::-webkit-scrollbar-track': {
              background: isDarkMode
                ? MINMINI_V3.primary.black
                : MINMINI_V3.grey.lightGrey,
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: isDarkMode
                ? MINMINI_V3.grey.darkGrey
                : MINMINI_V3.primary.offWhite,
              borderRadius: '1.25rem',
            },
            width: '100%',
            px: isDesktop ? '3.25rem' : '6.25vw',
            zIndex: 2,
            position: 'sticky',
            top: '4.313rem',
            background: isDarkMode
              ? MINMINI_V3.grey.bgDark
              : MINMINI_V3.primary.white,
          }}
        >
          {navBarFilters?.map((filter) => (
            <NavLink
              key={filter?.url}
              data-targetid={filter?.url}
              to={`${filter?.url}`}
              className={({ isActive }) =>
                isActive ? classes.activeNavLink : classes.navLink
              }
            >
              {({ isActive }) => (
                <div>
                  <img
                    src={filter?.icon}
                    style={{
                      width: '30px',
                      height: '30px',
                      marginRight: '0.25rem',
                    }}
                    alt='filter'
                  />
                  <Typography
                    sx={{
                      color: isActive
                        ? (theme) => theme.palette.primary.main
                        : isDarkMode
                        ? MINMINI_V3.primary.white
                        : MINMINI_V3.secondary.brownHue,
                      fontWeight: 700,
                      fontSize: '0.75rem',
                    }}
                    variant='span'
                  >
                    {isTamil ? filter?.name_ta : filter?.name}
                  </Typography>
                </div>
              )}
            </NavLink>
          ))}

          <Box
            sx={{
              borderColor: isDarkMode
                ? (theme) => theme.palette.grey[300]
                : (theme) => theme.palette.grey[200],
            }}
            className={classes.verticalRule}
          ></Box>
          <Box sx={{ display: 'flex' }}>
            <NavLink
              to={`${history.pathname}`}
              className={({ isActive }) =>
                isActive && districtActive
                  ? classes.activeNavLink
                  : classes.navLink
              }
              onClick={() => setShowCity(true)}
            >
              {({ isActive }) => (
                <Typography
                  variant='body1'
                  sx={{
                    fontWeight: 700,
                    fontSize: '0.75rem',
                  }}
                >
                  {t('DISTRICTS')}
                </Typography>
              )}
            </NavLink>
            <DistrictSearch
              showModal={showCity}
              closeModal={closeCityModal}
              modalHeader={t('DISTRICTS')}
              districts={districts}
            />
            <NavLink
              to={`${history.pathname}`}
              className={({ isActive }) =>
                isActive && districtActive
                  ? classes.activeNavLink
                  : classes.navLink
              }
              onClick={() => setShowTopics(true)}
            >
              {({ isActive }) => (
                <Typography
                  variant='body1'
                  sx={{
                    fontWeight: 700,
                    fontSize: '0.75rem',
                  }}
                >
                  {t('topics')}
                </Typography>
              )}
            </NavLink>
            <Topics
              showModal={showTopics}
              topic={newsCategories}
              closeModal={closeTopicsModal}
              modalHeader={t('topics')}
            />
          </Box>
        </Box>
      </LaptopMax>
      <DesktopMin>
        <Box
          sx={{
            position: 'sticky',
            top: aboveLap
              ? '6.25rem'
              : belowLap & aboveMobile
              ? '5.5rem'
              : 'none',
            zIndex: 2,
            overflowY: 'auto',
            height: `calc(100vh - ${
              aboveLap ? '6.25rem' : belowLap & aboveMobile ? '5.5rem' : 'none'
            })`,
            width: '100%',
            pt: '2.063rem',
            pl: '3.25rem',
            '&::-webkit-scrollbar': {
              width: '0.3rem',
              cursor: 'pointer',
            },
            '&::-webkit-scrollbar-track': {
              background: isDarkMode
                ? MINMINI_V3.primary.black
                : MINMINI_V3.grey.lightGrey,
            },

            '&::-webkit-scrollbar-thumb': {
              backgroundColor: isDarkMode
                ? MINMINI_V3.grey.darkGrey
                : MINMINI_V3.primary.offWhite,
              borderRadius: '1.25rem',
            },
          }}
        >
          <List
            sx={{
              borderRight: '1px solid',
              borderColor: isDarkMode
                ? MINMINI_V3.grey.darkGrey
                : MINMINI_V3.primary.offWhite,
              width: '100%',
            }}
          >
            {navBarFilters?.map((filter) => (
              <NavLink
                key={filter?.url}
                data-targetid={filter?.url}
                to={`${filter?.url}`}
                className={({ isActive }) =>
                  isActive ? classes.activeNavLink : classes.navLink
                }
              >
                {({ isActive }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={filter?.icon}
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '0.5rem',
                      }}
                      alt='filter'
                    />
                    <Typography
                      sx={{
                        color: isActive
                          ? (theme) => theme.palette.primary.main
                          : isDarkMode
                          ? MINMINI_V3.primary.white
                          : MINMINI_V3.secondary.brownHue,
                        fontWeight: isActive ? 700 : 400,
                        fontSize: aboveLap
                          ? '1.125rem'
                          : belowLap & aboveMobile
                          ? '1rem'
                          : 'none',
                      }}
                      variant='span'
                    >
                      {isTamil ? filter?.name_ta : filter?.name}
                    </Typography>
                  </Box>
                )}
              </NavLink>
            ))}
            <NavLink
              to={`${history.pathname}`}
              className={({ isActive }) =>
                isActive && districtActive
                  ? classes.activeNavLink
                  : classes.navLink
              }
              onClick={() => setShowCity(true)}
            >
              {({ isActive }) => (
                <Typography variant='body1'> {t('DISTRICTS')}</Typography>
              )}
            </NavLink>
            <DistrictSearch
              showModal={showCity}
              closeModal={closeCityModal}
              modalHeader={t('DISTRICTS')}
              districts={districts}
            />
            <NavLink
              to={`${history.pathname}`}
              className={({ isActive }) =>
                isActive && districtActive
                  ? classes.activeNavLink
                  : classes.navLink
              }
              onClick={() => setShowTopics(true)}
            >
              {({ isActive }) => (
                <Typography variant='body1'> {t('topics')}</Typography>
              )}
            </NavLink>
            <Topics
              showModal={showTopics}
              topic={newsCategories}
              closeModal={closeTopicsModal}
              modalHeader={t('topics')}
            />
          </List>
        </Box>
      </DesktopMin>
    </>
  );
}
