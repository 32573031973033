import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import './Notifications.css';
// import bluecolorsettingsIcon from '../../assets/icons/BlueColorSettings.svg';
import backIcon from '../../assets/icons/arrow-left.svg';
import backIconDark from '../../assets/icons/arrow-left-white.svg';
import { useTranslation } from 'react-i18next';
// import settingsIcon from '../../assets/icons/Settings.svg';
// import settingsDarkIcon from '../../assets/icons/settings-dark.svg';
import { useNavigate } from 'react-router-dom';
import { TEN } from '../../Constants/Constants';
import notificationService from '../../services/notification.service';
import {
  NotificationListSkeleton,
  MobileListsSkeleton,
} from '../Skeleton/PublisherSkeleton';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import NotificationsData from './NotificationsData';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const Notifications = () => {
  const [activeButton, setActiveButton] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [sources, setSources] = useState('');
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const isMobile = MaxWidth(viewPort.tablet);
  const below579 = MinWidth(579);
  const Mobile = MaxWidth(viewPort.mobile);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  /**
   * component unmount way
   */

  useEffect(() => {
    return () => {
      updateNotificationStatus();
    };
  }, []);

  useEffect(() => {
    getNotifications();
  }, [sources]);

  /**
   * Getting the Search Result List from the api
   */
  const getNotifications = async (source) => {
    let notificationParams;

    if (sources !== '' && lastEvaluatedKey === null) {
      notificationParams = {
        pageSize: `${pageSize}`,
        source: `${sources}`,
      };
    } else if (sources === '' && lastEvaluatedKey != null) {
      notificationParams = {
        pageSize: `${pageSize}`,
        lastEvaluatedKey: lastEvaluatedKey,
      };
    } else if (sources !== '' && lastEvaluatedKey != null) {
      notificationParams = {
        pageSize: `${pageSize}`,
        source: `${sources}`,
        lastEvaluatedKey: lastEvaluatedKey,
      };
    } else {
      notificationParams = {
        pageSize: `${pageSize}`,
      };
    }

    try {
      if (notificationParams) {
        const response = await notificationService.fetchNotificationsList(
          notificationParams,
        );

        if (response) {
          setNotifications((notifications) => [
            ...notifications,
            ...(response?.notificationList || []),
          ]);
          if (response?.lastEvaluatedKey !== null) {
            setLastEvaluatedKey(response?.lastEvaluatedKey);
          } else {
            setLastEvaluatedKey(null);
          }
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  /*
   * On Filter change, we change the active button, source, pagesize, lastEvaluatedkey to null and loader to true.
   */
  const handleButton = (source) => {
    setActiveButton(source);
    setSources(source);
    setLastEvaluatedKey(null);
    setNotifications([]);
    setPageSize(10);
    setIsLoading(true);
  };

  const updateNotificationStatus = async () => {
    const readAvailable = setNotifications.find(
      (list) => list.status === 'UNREAD',
    );
    if (readAvailable) {
      try {
        const response = await notificationService.updateNotificaitionStatus();
        if (response) {
          notificationService.fetchNotificationsUnreadCount();
        }
      } catch (error) {}
    }
  };

  /*
   * On Update, we call this method to fetch the latest status of the notifications
   */
  const onUpdate = (id) => {
    const notifObj = notifications?.find((obj) => obj.id === id);
    if (notifObj) {
      notifObj.status = 'READ';
      setNotifications([...notifications]);
    }
  };

  /*
   * On Delete, we call this method to show the updated Notifications after deletion
   */
  const onDelete = (id) => {
    const notifObj = notifications?.filter((obj) => obj.id !== id);
    setNotifications([...notifObj]);
  };

  /**
   * Default notifications count upon click of Load More Button
   */
  const handleLoadMore = (event) => {
    event.preventDefault();
    setPageSize(TEN);
    getNotifications();
  };

  return (
    <>
      {!below579 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            background: isDarkMode
              ? DARK_COLOR.grey[200]
              : LIGHT_COLOR.white[100],
          }}
        >
          <Box
            className='notificationNavbar'
            sx={{
              background: isDarkMode
                ? DARK_COLOR.grey[200]
                : LIGHT_COLOR.white[100],
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Box
              className='notificationNavbarTitleContainer'
              sx={{
                background: isDarkMode
                  ? DARK_COLOR.grey[200]
                  : LIGHT_COLOR.white[100],
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <img
                src={isDarkMode ? backIconDark : backIcon}
                className='back-icon'
                alt='back icon'
                onClick={handleBackButton}
              />
              <Typography
                className='notificationNavbarTitle'
                sx={{
                  color: isDarkMode
                    ? DARK_COLOR.white[100]
                    : LIGHT_COLOR.grey[600],
                  fontWeight: '600',
                }}
              >
                {t('L_NOTIFICATIONS')}
              </Typography>
            </Box>
            {/* TODO: SHOW NOTIFICATIONS SETTINGS */}
            {/* <img
              src={isDarkMode ? settingsDarkIcon : settingsIcon}
              alt='settings icon'
              onClick={() => navigate('/notification-settings')}
            /> */}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: Mobile ? '0' : '1.5rem 6.25vw',
          width: '100%',
          minHeight: '44rem',
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Box className='innerContainer'>
          <div className='notificationsContainer'>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: '600',
                marginBottom: '0.625rem',
                color: isDarkMode
                  ? DARK_COLOR.white[100]
                  : LIGHT_COLOR.grey[600],
              }}
            >
              {t('L_NOTIFICATIONS')}
            </Typography>
            {/* TODO: SHOW NOTIFICATIONS SETTINGS */}
            {/* <div
              className='viewSettingsContainer'
              onClick={() => navigate('/notification-settings')}
            >
              <img
                className='settingsIcon'
                alt='settings icon'
                src={bluecolorsettingsIcon}
              />
              <Typography
                sx={{
                  fontWeight: '600',
                  textTransform: 'uppercase',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {t('NOTIFICATION_SETTINGS')}
              </Typography>
            </div> */}
          </div>
          <div className='buttonsContainer'>
            <div className='buttonsList'>
              <Button
                sx={{
                  background: isDarkMode
                    ? activeButton === ''
                      ? DARK_COLOR.primary[25]
                      : DARK_COLOR.white[25]
                    : activeButton === ''
                    ? LIGHT_COLOR.primary[15]
                    : LIGHT_COLOR.white[5],
                  border: isDarkMode
                    ? activeButton === ''
                      ? `0.063rem solid ${DARK_COLOR.primary[100]}`
                      : `0.063rem solid ${DARK_COLOR.grey[400]}`
                    : activeButton !== ''
                    ? `0.063rem solid ${LIGHT_COLOR.grey[300]}`
                    : `0.063rem solid ${LIGHT_COLOR.primary[100]}`,
                  borderRadius: '8px',
                  marginRight: '0.625rem',
                  padding: '0.25rem 1rem',
                }}
                onClick={() => {
                  handleButton('');
                }}
              >
                <Typography
                  sx={{
                    color: isDarkMode
                      ? activeButton === ''
                        ? DARK_COLOR.primary[100]
                        : DARK_COLOR.grey[600]
                      : activeButton !== ''
                      ? LIGHT_COLOR.grey[400]
                      : LIGHT_COLOR.primary[100],
                    textTransform: 'none',
                  }}
                >
                  {t('all')}
                </Typography>
              </Button>
              <Button
                sx={{
                  background: isDarkMode
                    ? activeButton === 'CMS'
                      ? DARK_COLOR.primary[25]
                      : DARK_COLOR.white[25]
                    : activeButton === 'CMS'
                    ? LIGHT_COLOR.primary[15]
                    : LIGHT_COLOR.white[5],
                  border: isDarkMode
                    ? activeButton === 'CMS'
                      ? `0.063rem solid ${DARK_COLOR.primary[100]}`
                      : `0.063rem solid ${DARK_COLOR.grey[400]}`
                    : activeButton !== 'CMS'
                    ? `0.063rem solid ${LIGHT_COLOR.grey[300]}`
                    : `0.063rem solid ${LIGHT_COLOR.primary[100]}`,
                  borderRadius: '8px',
                  marginRight: '0.625rem',
                  padding: '0.25rem 1rem',
                }}
                onClick={() => {
                  handleButton('CMS');
                }}
              >
                <Typography
                  sx={{
                    color: isDarkMode
                      ? activeButton === 'CMS'
                        ? DARK_COLOR.primary[100]
                        : DARK_COLOR.grey[600]
                      : activeButton !== 'CMS'
                      ? LIGHT_COLOR.grey[400]
                      : LIGHT_COLOR.primary[100],
                    textTransform: 'none',
                  }}
                >
                  {t('news')}
                </Typography>
              </Button>
              {/* <Button
                sx={{
                  background: isDarkMode
                    ? activeButton === 'COMMUNITY'
                      ? DARK_COLOR.primary[25]
                      : DARK_COLOR.white[25]
                    : activeButton === 'COMMUNITY'
                    ? LIGHT_COLOR.primary[15]
                    : LIGHT_COLOR.white[5],
                  border: isDarkMode
                    ? activeButton === 'COMMUNITY'
                      ? `0.063rem solid ${DARK_COLOR.primary[100]}`
                      : `0.063rem solid ${DARK_COLOR.grey[400]}`
                    : activeButton !== 'COMMUNITY'
                    ? `0.063rem solid ${LIGHT_COLOR.grey[300]}`
                    : `0.063rem solid ${LIGHT_COLOR.primary[100]}`,
                  borderRadius: '8px',
                  marginRight: '0.625rem',
                  padding: '0.25rem 1rem',
                }}
                onClick={() => {
                  handleButton('COMMUNITY');
                }}
              >
                <Typography
                  sx={{
                    color: isDarkMode
                      ? activeButton === 'COMMUNITY'
                        ? DARK_COLOR.primary[100]
                        : DARK_COLOR.grey[600]
                      : activeButton !== 'COMMUNITY'
                      ? LIGHT_COLOR.grey[400]
                      : LIGHT_COLOR.primary[100],
                    textTransform: 'none',
                  }}
                >
                  {t('community')}
                </Typography>
              </Button> */}
            </div>
          </div>

          {!notifications?.length && !isLoading ? (
            <EmptyScreen message={t('no_notifications')} heights='250px' />
          ) : (
            <div className='notificationListContainer'>
              {isLoading ? (
                isMobile ? (
                  <MobileListsSkeleton cards={4} />
                ) : (
                  <NotificationListSkeleton cards={2} />
                )
              ) : (
                <>
                  <NotificationsData
                    notifications={notifications}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                  {lastEvaluatedKey !== null && (
                    <div className='load-more-notifications'>
                      <Button
                        onClick={handleLoadMore}
                        color='primary'
                        variant='contained'
                        className='load-more-text'
                        sx={{ fontFamily: 'Sharp Sans' }}
                      >
                        {t('LOAD_MORE')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Notifications;
