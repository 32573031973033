import React from 'react';
import NotificationsList from '../Notifications/NotificationsList';

const NotificationsData = ({ notifications, onUpdate, onDelete }) => {
  /**
   * Getting the notification responses from notifications API to display Notifications List
   */
  return (
    <div>
      {notifications?.map((notificationsData) => (
        <div key={notificationsData.id}>
          <NotificationsList
            id={notificationsData.id}
            source={notificationsData.source}
            timestamp={notificationsData.timestamp}
            owner_id={notificationsData.owner_id}
            news_story_id={notificationsData.news_story_id}
            news_story_thumbnail={notificationsData.news_story_thumbnail}
            post_id={notificationsData.post_id}
            user_action={notificationsData.user_action}
            user_action_id={notificationsData.user_action_id}
            message_en={notificationsData.message_en}
            message_tn={notificationsData.message_tamil}
            status={notificationsData.status}
            redirect_required={notificationsData.redirect_required}
            news_url={notificationsData.news_url}
            channel_id={notificationsData.channel_id}
            group_id={notificationsData.group_id}
            post_type={notificationsData.post_type}
            user_id={notificationsData.userName}
            notifications={notifications}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </div>
      ))}
    </div>
  );
};

export default NotificationsData;
