import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styles } from './trainingStyles';
import JWPlayer from '@jwplayer/jwplayer-react';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';
import { TRAINING_VIDEO_STATUS } from '../../../Constants/Constants';
import { EnvironmentProvider } from '../../../store/firebase-context';

/**
 * Mobile Player View component
 */
function MobilePlayerView({
  open,
  onClose,
  onPlaylistItem,
  index,
  playlistId,
  onPrevious,
  onNext,
  playlistLength,
  playlistItem,
  onUpdateData,
  currentPlayListState,
}) {
  const { t } = useTranslation();

  const { environment } = useContext(EnvironmentProvider);

  const [player, setPlayer] = useState();
  const [seekValue, setSeekValue] = useState({
    currentPosition: null,
    offSetTime: null,
  });
  const [maxPositionTime, setMaxPositionTime] = useState(0);

  /**
   * playerMountedCallback will call once the video is set and going to start
   * @param{object} it will have player detail of current id
   */
  const playerMountedCallback = ({ player }) => {
    // if there is seek position we will start video from there
    if (
      currentPlayListState &&
      parseInt(currentPlayListState.seek_time) &&
      currentPlayListState.seek_time < playlistItem.duration - 10
    ) {
      let seekTime = parseInt(currentPlayListState.seek_time);
      player.seek(seekTime);
      setMaxPositionTime(seekTime);
    }
    //if video is not completed then we will make it as in progress
    if (currentPlayListState.status !== TRAINING_VIDEO_STATUS.COMPLETED) {
      const payload = {
        ...currentPlayListState,
        status: TRAINING_VIDEO_STATUS.IN_PROGRESS,
      };
      onUpdateData(payload, false);
    }
    setPlayer(player);
  };

  /**
   * if seek value get change on the basis of that we rewind or forward the screen
   */
  useEffect(() => {
    //current postion time is greater then watch time we will update maximum watched time
    if (
      seekValue &&
      maxPositionTime < seekValue.offSetTime &&
      seekValue.currentPosition < seekValue.offSetTime
    ) {
      player.seek(seekValue.currentPosition);
    }
  }, [seekValue]);

  /**
   * when user seek then this event triggered
   * @param {*} event as per jw player
   */
  const onSeek = (event) => {
    setSeekValue({
      currentPosition: event.currentTime,
      offSetTime: event.offset,
    });
  };

  /**
   * once the video is ended then this event is tiggered
   */
  const onCompleted = () => {
    const payload = {
      ...currentPlayListState,
      status: TRAINING_VIDEO_STATUS.COMPLETED,
    };
    onUpdateData(payload, true);
  };

  // need to update backend on every 10 sec so seektime wil persist
  useEffect(() => {
    const interval = setInterval(() => {
      // video is not completed then only updating video seek position status
      if (
        currentPlayListState.status !== TRAINING_VIDEO_STATUS.COMPLETED &&
        maxPositionTime < parseInt(player.getPosition())
      ) {
        const payload = {
          ...currentPlayListState,
          seek_time: Math.trunc(player.getPosition()),
          status:
            Math.trunc(player.getPosition()) > 0 &&
            currentPlayListState.status === TRAINING_VIDEO_STATUS.NOT_STARTED
              ? TRAINING_VIDEO_STATUS.IN_PROGRESS
              : currentPlayListState.status,
        };
        onUpdateData(payload, false);
        setMaxPositionTime(() => payload.seek_time);
      }
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [player, maxPositionTime, currentPlayListState]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby='mobile-player-dialog'
    >
      <DialogTitle
        sx={{
          fontSize: '1rem',
          fontWeight: '600',
          lineHeight: '1.25rem',
          borderBottom: '1px solid ',
          borderColor: 'divider',
          padding: '1.25rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        {playlistItem?.title}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '1.25rem !important' }}>
        <Typography sx={styles.videoDescription}>
          {playlistItem?.description}
        </Typography>
        <Box sx={styles.playerContainer}>
          {playlistItem && Object.keys(currentPlayListState).length ? (
            <JWPlayer
              key={index}
              onPlaylistItem={onPlaylistItem}
              didMountCallback={playerMountedCallback}
              playlistIndex={index}
              onComplete={onCompleted}
              onSeek={onSeek}
              config={{
                responsive: true,
                aspectratio: '16:9',
                width: '100%',
                stretching: 'uniform',
                volume: 25,
                autostart: 'viewable',
                autoPause: {
                  viewability: true,
                  pauseAds: true,
                },
              }}
              playlist={`${environment.web_jwp_playlist_url}${playlistId}`}
              library={environment.web_jwp_library_url}
            />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            borderTop: '1px solid ',
            borderColor: 'divider',
            width: '100%',
          }}
        >
          <Box sx={[styles.prevNextContainer, styles.mobilePrevNextContainer]}>
            <Button
              disabled={!index}
              style={
                !index
                  ? { fontWeight: '600', fontSize: '.875rem' }
                  : {
                      color: ' #8E3BD9',
                      fontWeight: '600',
                      fontSize: '.875rem',
                    }
              }
              onClick={onPrevious}
              startIcon={<ArrowBackIosNew />}
            >
              {t('previous')}
            </Button>
            <Button
              disabled={index === playlistLength - 1}
              style={
                index === playlistLength - 1
                  ? { fontWeight: '600', fontSize: '.875rem' }
                  : {
                      color: ' #8E3BD9',
                      fontWeight: '600',
                      fontSize: '.875rem',
                    }
              }
              onClick={onNext}
              endIcon={<ArrowForwardIos />}
            >
              {t('next')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default MobilePlayerView;
