import React, { useContext } from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';
import classes from './Ads.module.css';
import DesktopSideAdSmall from '../../assets/images/ads/Ad_228x190.png';
import DesktopSideAdLarge from '../../assets/images/ads/Ad_228x456.png';
import { EnvironmentProvider } from '../../store/firebase-context';
import MostRead from '../MostRead/MostRead';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserDetailsProvider } from '../../store/user-details-context';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import {
  TRUE,
  FALSE,
  CITIZEN_REPORTER_CAPITAL,
  UGC_REPORTER,
} from '../../Constants/Constants';

const adsData = [
  {
    id: 1,
    image: DesktopSideAdSmall,
  },
  {
    id: 2,
    image: DesktopSideAdSmall,
  },
  {
    id: 3,
    image: DesktopSideAdLarge,
  },
];

export default function Ads() {
  const { t } = useTranslation();
  const { state: userState } = useContext(UserDetailsProvider);
  const { environment } = useContext(EnvironmentProvider);
  const isCitizenReporter =
    userState?.userDetails?.userType === CITIZEN_REPORTER_CAPITAL
      ? TRUE
      : FALSE;
  const isDTVerifiedReporter =
    userState?.userDetails.userType === UGC_REPORTER ? TRUE : FALSE;
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  let navigate = useNavigate();

  const goToCreateAReport = () => {
    navigate('/create-a-report');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '18.75rem',
        width: '100%',
        pt: '2.063rem',
        pr: '3.25rem',
      }}
    >
      {(isCitizenReporter === TRUE || isDTVerifiedReporter === TRUE) && (
        <Box
          sx={{
            background: isDarkMode
              ? DARK_COLOR.grey[200]
              : LIGHT_COLOR.white[100],
            border: isDarkMode
              ? `0.063rem solid ${DARK_COLOR.grey[300]}`
              : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
            maxWidth: '18.75rem',
            width: '100%',
            borderRadius: '10px',
            marginBottom: '1.25rem',
            minHeight: '9.375rem',
          }}
        >
          <Box>
            <Typography
              sx={{
                color: isDarkMode
                  ? DARK_COLOR.white[100]
                  : LIGHT_COLOR.grey[600],
                fontSize: '1.125rem',
                fontWeight: '700',
                padding: '1.25rem 0 1.25rem 0.875rem',
              }}
            >
              {t('anything-interesting-to-share?')}
            </Typography>
          </Box>
          <Divider fullWidth sx={{ borderWidth: '0.063rem' }} />
          <Box sx={{ padding: '1.25rem' }}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              sx={{ fontWeight: '600' }}
              onClick={() => goToCreateAReport()}
            >
              {t('create-a-report')}
            </Button>
          </Box>
        </Box>
      )}
      {environment?.web_ad_enable === TRUE &&
        adsData
          ?.filter((item, index) => index < 2)
          .map((ad) => (
            <img
              key={ad.id}
              src={ad.image}
              style={{
                marginBottom: '1.25rem',
              }}
              alt='ads'
            />
          ))}
      <MostRead />
      {environment?.web_ad_enable === TRUE &&
        adsData
          ?.filter((item, index) => index > 1)
          .map((ad) => (
            <img
              key={ad.id}
              src={ad.image}
              className={classes.adsImage}
              alt='ads'
            />
          ))}
    </Box>
  );
}
