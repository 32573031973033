/**
 * via react-share ui package we are not able to achive our data preview requirment in whatsapp
 */
import assert from 'react-share/es/utils/assert';
import objectToGetParams from 'react-share/es/utils/objectToGetParams';
import createShareButton from './createShareButton';

/**
 * we check here whether share functionality happen from mobile or dekstop
 */
function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

/**
 * to generate and share via whatsapp link
 * @param {url}  url  to be share
 * @param {title,separator,mobileLink} title of share url seperate the message identify link or other thing mobile link will be the app link
 * @returns {string} return string value in order to tiltle link then mobile link
 */
function whatsappLink(url, { title, separator, mobileLink }) {
  assert(url, 'whatsapp.url');
  return (
    'https://' +
    (isMobileOrTablet() ? 'api' : 'web') +
    '.whatsapp.com/send' +
    objectToGetParams({
      text: title + separator + url + separator + mobileLink,
    })
  );
}

const WhatsappShareButton = createShareButton(
  'whatsapp',
  whatsappLink,
  (props) => ({
    title: props.title,
    separator: props.separator || ' ',
    mobileLink: props.mobileLink || '',
  }),
  {
    windowWidth: 800,
    windowHeight: 800,
  },
);

export default WhatsappShareButton;
