import { SvgIcon } from '@mui/material';
/**
 * custom uncheckbox
 * @param {*} props  fill stroke color string
 * @returns
 */
const CalenderIconDark = () => {
  return (
    <SvgIcon>
      <svg width='24' height='24' fill='none'>
        <path
          d='M4 8H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V8Z'
          fill='#8E8E8E'
        />
        <path
          d='M4 4H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V4Z'
          stroke='#fffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M4 8H20'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M8 12H12'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M16 3V5'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M8 3V5'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M4 8H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V8Z'
          fill='white'
          fill-opacity='0.25'
        />
        <path
          d='M4 4H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V4Z'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M4 8H20'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M8 12H12'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M16 3V5'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M8 3V5'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};
export default CalenderIconDark;
