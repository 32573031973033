// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minmini-blue-tick-icon-training {
  width: 1.125rem;
  height: 1.125rem;
  margin-left: -0.7rem;
  margin-top: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Reporter/Training/training.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":[".minmini-blue-tick-icon-training {\n  width: 1.125rem;\n  height: 1.125rem;\n  margin-left: -0.7rem;\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
