import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

/**
 * Make api call for downloading certificate
 * @param {object} data
 * @returns promise
 */
const downloadCertificate = (data) => {
  return apiRequest(
    api.downloadCertificate,
    METHOD.POST,
    false,
    data,
    true,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to get assesment questions and its options
 * @param {object} data
 * @returns promise
 */
const getAssessment = (data) => {
  return apiRequest(api.getAssessment, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to get assesment start
 * @param {object} data
 * @returns promise
 */
const saveAnswer = (data) => {
  return apiRequest(api.saveAnswer, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to submit assessment
 * @param {object} data
 * @returns promise
 */
const submitAssessment = (data) => {
  return apiRequest(api.submitAssessment, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for downloading certificate
 * @param {object} data
 * @returns promise
 */
const downloadReporterID = () => {
  return apiRequest(
    api.downloadReporterID,
    METHOD.GET,
    false,
    false,
    true,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const assessmentService = {
  downloadCertificate,
  getAssessment,
  saveAnswer,
  submitAssessment,
  downloadReporterID,
};

export default assessmentService;
