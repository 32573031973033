import { SvgIcon } from '@mui/material';

/**
 * custom checkbox
 * @param {*} props  fill stroke color string
 * @returns
 */
const CheckedboxIcon = (props) => {
  const { fill, stroke } = props;
  return (
    <SvgIcon viewBox='0 0 20 20' fontSize='small'>
      <rect
        x='0.5'
        y='0.5'
        width='19'
        height='19'
        rx='4.5'
        fill={fill}
        stroke={stroke}
      />
      <path
        d='M13 8L9 12L7 10'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIcon>
  );
};

export default CheckedboxIcon;
