import React, { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Carousel.css';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
import noImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import JWPlayer from '@jwplayer/jwplayer-react';
import { Box } from '@mui/material';
import { EnvironmentProvider } from '../../store/firebase-context';
import recombeeService from '../../services/recombee.service';

const Carousel = ({ media, userID, newsID, isAuthenticated }) => {
  const { environment } = useContext(EnvironmentProvider);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [player, setPlayer] = useState(null);

  const onJWPMount = ({ player }) => {
    setPlayer(player);
  };

  const onJWPPause = () => {
    isAuthenticated && captureRecombeeEvents(player);
  };

  const onJWPUnmount = ({ player }) => {
    isAuthenticated && captureRecombeeEvents(player);
  };

  /**
   * Captures a Recombee interaction event
   * @async
   * @function captureRecombeeEvents
   * @throws {Error} If an error occurs while capturing the interaction.
   * @returns {Promise<void>}
   */
  const captureRecombeeEvents = async (playerInstance) => {
    try {
      const playerPlaybackPercent = (
        playerInstance.getPosition() / playerInstance.getDuration()
      ).toFixed(1);
      const payload = {
        eventName: 'viewPortion',
        userId: userID,
        itemId: parseInt(newsID),
        portion: playerPlaybackPercent,
      };

      await recombeeService.captureRecombeeInteraction(payload);
    } catch (error) {}
  };

  return (
    <Swiper
      cssMode={true}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      className='mySwiper'
    >
      {media &&
        media?.map((item, index) => (
          <SwiperSlide key={index}>
            {(!item?.type || item?.type === 'image') && (
              <>
                {!item?.url ? (
                  <img
                    src={isDarkMode ? noImageDark : noImage}
                    alt='No Image Found'
                    style={{ objectFit: 'cover' }}
                  />
                ) : (
                  <Box
                    sx={{
                      backgroundColor: isDarkMode
                        ? (theme) => theme.palette.grey[300]
                        : (theme) => theme.palette.grey[600],
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <img
                      src={item?.url}
                      alt='image'
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = isDarkMode ? noImageDark : noImage;
                        currentTarget.style.objectFit = 'cover';
                      }}
                    />
                  </Box>
                )}
              </>
            )}

            {item?.type === 'video' &&
              (item?.jwp_supported ||
                !item?.hasOwnProperty('jwp_supported')) && (
                <>
                  <JWPlayer
                    config={{
                      responsive: true,
                      aspectratio: '16:9',
                      width: '100%',
                      autostart: 'viewable',
                      stretching: 'uniform',
                      allowFullScreen: true,
                      volume: 25,
                      autoPause: {
                        viewability: true,
                        pauseAds: true,
                      },
                    }}
                    didMountCallback={onJWPMount}
                    willUnmountCallback={onJWPUnmount}
                    onPause={onJWPPause}
                    playlist={
                      item?.url?.includes('/manifests/')
                        ? `${environment.web_jwp_media_url}${item?.mediaId}`
                        : [{ file: item?.url }]
                    }
                    library={environment.web_jwp_library_url}
                  />
                </>
              )}
            {item?.type === 'video' && item?.jwp_supported === false && (
              <Box
                sx={{
                  overflow: 'hidden',
                }}
              >
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  src={`https://www.youtube.com/embed/${item?.mediaId}`}
                  title='videoFrame'
                  allowFullScreen
                ></iframe>
              </Box>
            )}
          </SwiperSlide>
        ))}
      {!media && (
        <SwiperSlide>
          <img
            src={isDarkMode ? noImageDark : noImage}
            alt='No Image Found'
            style={{ objectFit: 'cover' }}
          />
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default Carousel;
