import useMediaQuery from '@mui/material/useMediaQuery';

export const viewPort = {
  mobile: 479,
  mobileMax: 600,
  tablet: 767,
  dekstopView: 1199,
  laptop: 991,
  bigLaptop: 1440,
  smallLaptop: 1281,
};

export function MaxWidth(width) {
  return useMediaQuery(`(max-width:${width}px)`);
}

export function MinWidth(width) {
  return useMediaQuery(`(min-width:${width}px)`);
}

export function breakPointbasedValue(maxValue, midValue = '', smallValue = '') {
  const belowLap = useMediaQuery(`(max-width:${viewPort.smallLaptop}px)`);
  const aboveLap = useMediaQuery(`(min-width:${viewPort.smallLaptop}px)`);
  const aboveMobile = useMediaQuery(`(min-width:${viewPort.mobileMax}px)`);
  if (aboveLap) {
    return maxValue;
  } else if (belowLap && aboveMobile) {
    return midValue || maxValue;
  } else {
    return smallValue || midValue;
  }
}
