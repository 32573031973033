import React, { useContext } from 'react';
import './SearchReportersCard.css';
import { CITIZEN_REPORTER_CAPITAL } from '../../Constants/Constants';
import { AUTHOR_NEWS } from '../../Constants/Constants';

import purpletickIcon from '../../assets/icons/purple-tick.svg';
import bluetickIcon from '../../assets/icons/blue-influencer-tick-icon.svg';
import ellipseIcon from '../../assets/icons/ellipse.svg';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Avatar } from '@mui/material';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { EnvironmentProvider } from '../../store/firebase-context';

const SearchUsersCard = (props) => {
  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { environment } = useContext(EnvironmentProvider);

  const handleAuthor = (event) => {
    event.stopPropagation();
    if (props.userId)
      navigate(`/${AUTHOR_NEWS}/${props.userName}`, {
        state: {
          trackingId: props.userId,
        },
      });
  };

  return (
    <Box className='reporterMainContainer'>
      <Box
        sx={{
          background: isDarkMode
            ? DARK_COLOR.grey[200]
            : LIGHT_COLOR.white[100],
          border: isDarkMode
            ? `0.063rem solid ${DARK_COLOR.grey[300]}`
            : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
        }}
        className='reporterSubContainer'
      >
        <div className='reporterInfoSection' onClick={handleAuthor}>
          <div className='reporterProfileImage'>
            <Avatar
              alt='reporter avatar'
              src={`${environment.userprofile_prefix_url}${props.userId}`}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                width: '3rem',
                height: '3rem',
                borderRadius: '50%',
                mr: '0.813rem',
                color: (theme) => theme.palette.white[100],
                backgroundColor: (theme) => theme.palette.avatar[100],
              }}
            >
              {`${(props?.userName?.[0] || '').toUpperCase()}`}
            </Avatar>
          </div>
          <div className='reporterinfo'>
            <Typography
              sx={{
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[500],
                fontWeight: '600',
                marginBottom: '0.625rem',
              }}
            >
              {props.userName ? props.userName : ''}

              {props.userType !== 'USER' && (
                <Box
                  component='img'
                  src={
                    props.userType === CITIZEN_REPORTER_CAPITAL
                      ? purpletickIcon
                      : props.userType !== 'USER'
                      ? bluetickIcon
                      : null
                  }
                  alt='tickIcon'
                  width={15}
                  sx={{ height: 'auto', marginLeft: '2px' }}
                ></Box>
              )}
            </Typography>

            <p className='reporterFollowersCount'>
              <span className='reportersfollowers'>
                <Typography
                  sx={{
                    color: isDarkMode
                      ? DARK_COLOR.grey[500]
                      : LIGHT_COLOR.grey[400],
                    fontSize: '0.75rem',
                  }}
                  component='scan'
                >
                  {props.userType}
                </Typography>
                <span style={{ margin: '0.313rem' }}>
                  <img
                    style={{ marginTop: '-0.1rem' }}
                    src={ellipseIcon}
                    width={2}
                    height={2}
                    alt='ellipsis icon'
                  />
                </span>
              </span>
              <Typography
                sx={{
                  color: isDarkMode
                    ? DARK_COLOR.grey[500]
                    : LIGHT_COLOR.grey[400],
                  fontSize: '0.75rem',
                }}
                component='scan'
              >
                {props.followersCount} followers
              </Typography>
            </p>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default SearchUsersCard;
