import CheckboxBlankIcon from '../Components/icons/checkbox/CheckboxBlankIcon';
import CheckedboxIcon from '../Components/icons/checkbox/CheckedboxIcon';
import { DARK_COLOR, MINMINI_V2, MINMINI_V3 } from './color.constant';
import IndeterminateCheckBoxIcon from '../Components/icons/checkbox/IndeterminateCheckBoxIcon';
import SharpSansMedium from '../assets/fonts/HelveticaNeueMedium.ttf';

export const DtDark = {
  palette: {
    mode: 'dark',
    black: {
      100: '#000000',
    },
    custom: {
      dark: DARK_COLOR.white[100],
    },

    text: {
      primary: DARK_COLOR.grey[600],
    },

    primary: {
      main: MINMINI_V2.primary.purple,
    },

    success: {
      main: MINMINI_V2.primary.green,
    },

    error: {
      main: MINMINI_V2.primary.errorRed,
      secondary: DARK_COLOR.error[100],
    },

    background: {
      default: DARK_COLOR.grey.bgDark,

      paper: DARK_COLOR.grey[200],
    },
    minmini_grey: {
      grey: MINMINI_V2.grey.grey,
    },

    grey: {
      bgDark: MINMINI_V2.grey.bgDark,
      100: DARK_COLOR.grey[100],
      200: DARK_COLOR.grey[200],
      300: DARK_COLOR.grey[300],
      400: DARK_COLOR.grey[400],
      500: DARK_COLOR.grey[500],
      600: DARK_COLOR.grey[600],
    },
    white: {
      100: DARK_COLOR.white[100],
      50: DARK_COLOR.white[50],
      25: DARK_COLOR.white[25],
    },
    toaster: {
      error: DARK_COLOR.toaster.error,
      success: DARK_COLOR.toaster.success,
      warning: DARK_COLOR.toaster.warning,
    },
    avatar: {
      100: DARK_COLOR.avatar[100],
    },
    gradient: {
      flare: DARK_COLOR.gradient.flare,
    },
    shadow: {
      100: DARK_COLOR.shadow[100],
    },
  },
  typography: {
    fontFamily: 'Sharp Sans',
    color: DARK_COLOR.grey[600],
    h8: {
      color: MINMINI_V2.DarkModeText.font1,
      fontWeight: '700',
      lineHeight: '1.25rem',
      fontSize: '1.25rem',
    },
    h8_mobile: {
      color: MINMINI_V2.DarkModeText.font1,
      fontWeight: '700',
      lineHeight: '1.25rem',
      fontSize: '0.75rem',
    },
    h7: {
      color: MINMINI_V2.DarkModeText.font1,
      fontWeight: '700',
      lineHeight: '1.5rem',
    },
    h5: {
      color: MINMINI_V2.DarkModeText.font1,
      fontWeight: '600',
      lineHeight: '1.5rem',
    },
    h6: {
      fontWeight: '600',
      color: MINMINI_V2.DarkModeText.font1,
    },
    body1: {
      // its font size is 1 rem color in light its  font gray 600
      color: MINMINI_V2.DarkModeText.font1,
    },
    body3: {
      // its font size is 1 rem color in light its  font gray 500
      fontSize: '1rem',
      color: MINMINI_V2.DarkModeText.font2,
    },
    body2: {
      color: DARK_COLOR.grey[500],
    },
    subtitle1: {
      color: MINMINI_V2.DarkModeText.font2,
    },
    subtitle3: {
      //its font size is 1 rem color in  light its font grey500
      color: MINMINI_V2.DarkModeText.font2,
    },
    subtitle4: {
      //its font size is 1 rem color in white is gry400
      fontSize: '1rem',
      color: MINMINI_V2.DarkModeText.font2,
    },
    subtitle5: {
      //its font size is 1 rem color in white is gry400
      fontSize: '1rem',
      color: DARK_COLOR.white[100],
    },
    subtitle6: {
      //its font size is 1 rem color in white is gry400
      fontSize: '1rem',
      color: MINMINI_V2.DarkModeText.font2,
    },
    subtitle2: {
      //its font size is 0.875 rem color in Dark is white
      color: DARK_COLOR.grey[600],
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: MINMINI_V3.grey.bgDark,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          borderColor: MINMINI_V2.secondary.brownHue,
          color: MINMINI_V2.DarkModeText.font1,
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sharp Sans';
          src: url(${SharpSansMedium});
        }
      `,
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: 0.9,
          borderColor: DARK_COLOR.grey[300],
          borderBottomWidth: '0.063rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: DARK_COLOR.grey[300],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: DARK_COLOR.grey[300],
          borderRadius: '10px',
        },
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': 'unset',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: DARK_COLOR.grey[400],
        },
        shrink: {
          color: DARK_COLOR.grey[500],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          fontSize: '1rem',
          padding: '0.75rem',
          lineHeight: '1.25rem',
          borderRadius: '0.75rem',
        },
        contained: {
          color: MINMINI_V2.DarkModeText.font1,
        },
        outlined: {
          border: `2px solid ${MINMINI_V2.primary.purple}`,
          color: MINMINI_V2.primary.purple,
          '&:hover': {
            border: `2px solid ${MINMINI_V2.primary.purple}`,
          },
          '&.Mui-disabled': {
            color: DARK_COLOR.primary[50],
            borderColor: DARK_COLOR.grey[300],
          },
        },
        minminiContained: {
          background: MINMINI_V2.primary.purple,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          color: MINMINI_V2.DarkModeText.font1,
          borderRadius: '12px',
          fontWeight: '700',
          '&:hover': {
            backgroundColor: MINMINI_V2.primary.purple,
          },
        },
        minminiOutlined: {
          border: `2px solid ${MINMINI_V2.primary.purple}`,
          boxShadow: DARK_COLOR.shadow[100],
          color: MINMINI_V2.primary.purple,
          borderRadius: '12px',
          fontWeight: '700',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <CheckedboxIcon
            fill={DARK_COLOR.primary[100]}
            stroke={DARK_COLOR.primary[100]}
          />
        ),
        icon: (
          <CheckboxBlankIcon
            fill={DARK_COLOR.grey[300]}
            stroke={DARK_COLOR.grey[400]}
          />
        ),
        indeterminateIcon: (
          <IndeterminateCheckBoxIcon
            fill={DARK_COLOR.primary[100]}
            stroke={DARK_COLOR.primary[100]}
          />
        ),
      },
    },
  },
};
