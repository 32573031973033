import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const FollowingTabBackdrop = ({ isLoading }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: (theme) => theme.palette.white[100],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isDarkMode
          ? 'rgba(251, 251, 251, 0.1)'
          : 'rgba(0, 0, 0, 0.1)',
      }}
      open={isLoading}
    >
      <CircularProgress color='primary' />
    </Backdrop>
  );
};

export default FollowingTabBackdrop;
