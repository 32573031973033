import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import './Search.css';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { INITIAL_VALUE } from '../../Constants/Constants';
import Skeleton from 'react-loading-skeleton';
import searchService from '../../services/search.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import { AppContext } from '../../store/app-context';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import SearchResult from './SearchResult';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import { META_TAG_TITLE } from '../../Constants/meta.title.constant';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Search UI Component
 */
const Search = ({ navBarCategories, navBarFilters }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatchMetaAction = useMetaStore()[1];

  const [value, setValue] = useState('');

  const [isCountLoading, setIsCountLoading] = useState(true);
  const [isActiveTotalCount, setActiveTotalCount] = useState(0);
  const [newsCount, setNewsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSearch, setActiveSearch] = useState('');
  const { state: userState } = useContext(UserDetailsProvider);
  const { state: appCtxState } = useContext(AppContext);
  const newsSessionID = userState?.sessionId;
  const [startIndex, setStartIndex] = useState(0);
  const isMobile = MaxWidth(viewPort.tablet);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  /**
   * side effect to call newsdetails count api when there is a change in input search
   */
  useEffect(() => {
    if (value) {
      setIsLoading(true);
      getNewsDetails(value);
      searchUsername(value);
    }
  }, [value]);

  useEffect(() => {
    setValue(appCtxState?.searchTerm);
  }, [appCtxState?.searchTerm]);

  /**
   * side effect to update activesearch based on the count
   */
  useEffect(() => {
    if (usersCount > 0) {
      setActiveSearch('USERS');
      const metaObj = {
        title: META_TAG_TITLE.serchNews,
        description: META_TAG_TITLE.serchNews,
        keywords: '',
        canonical_url: location.pathname,
      };
      dispatchMetaAction(metaAction.updateMeta, metaObj);
      setActiveTotalCount(usersCount);
    }
    setIsCountLoading(false);
  }, [usersCount, newsCount, value]);
  /**
   * Getting the Search Result count from the api
   */
  const getNewsDetails = async (value) => {
    try {
      const response = await searchService.fetchSearchResultList({
        search: `${value}`,
        sessionId: `${newsSessionID}`,
      });
      setNewsCount(response?.count_news);
      setIsCountLoading(false);
      setIsLoading(false);
    } catch (error) {}
  };

  const searchUsername = () => {
    const payload = {
      username: value,
    };
    searchService
      .searchUsername(payload)
      .then((response) => {
        if (response?.authors?.length) {
          setActiveSearch('USERS');
          setUsersCount(response?.authors?.length);
          setIsLoading(false);
        } else {
          setActiveSearch('POSTS');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          background: (theme) => theme.palette.background.default,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          minHeight: '44rem',
        }}
      >
        <Box className='searchContainer'>
          {value && (newsCount || usersCount !== INITIAL_VALUE) ? (
            <div className='searchSelection'>
              <div className='searchResultsContainer'>
                <Box
                  sx={{
                    p: isMobile
                      ? '1rem 1.32rem 1rem 1.32rem'
                      : '1rem 0.563rem 1.375rem 2.563rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: aboveLap
                        ? '1.25rem'
                        : belowLap && aboveMobile
                        ? '1rem'
                        : '0.75rem',
                    }}
                    variant='h8'
                  >
                    &lt; &nbsp;{t('back')}
                  </Typography>
                </Box>
                <div className='resultsContainer'>
                  {isLoading ? (
                    <Skeleton
                      height={20}
                      width={250}
                      style={{ marginBottom: '1.25rem' }}
                    />
                  ) : (
                    <>
                      <Typography sx={{ marginBottom: '1.25rem' }}>
                        <Typography
                          component='span'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[500],
                          }}
                        >
                          {t('RESULTS_MATCHING')}
                        </Typography>
                        <Typography
                          component='span'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.white[100]
                              : LIGHT_COLOR.grey[600],
                            marginLeft: '0.313rem',
                            fontWeight: '600',
                          }}
                        >
                          {`"${value}"`}
                        </Typography>
                      </Typography>
                    </>
                  )}
                </div>
                <div className='searchMenuList'>
                  {isCountLoading ? (
                    <Skeleton height={20} width={800} />
                  ) : (
                    !isCountLoading && (
                      <>
                        {usersCount !== INITIAL_VALUE && (
                          <Button
                            sx={{
                              background: isDarkMode
                                ? activeSearch === 'USERS'
                                  ? DARK_COLOR.primary[25]
                                  : DARK_COLOR.white[25]
                                : activeSearch === 'USERS'
                                ? LIGHT_COLOR.primary[15]
                                : LIGHT_COLOR.white[5],
                              border: isDarkMode
                                ? activeSearch === 'USERS'
                                  ? `0.063rem solid ${DARK_COLOR.primary[100]}`
                                  : `0.063rem solid ${DARK_COLOR.grey[400]}`
                                : activeSearch !== 'USERS'
                                ? `0.063rem solid ${LIGHT_COLOR.grey[300]}`
                                : `0.063rem solid ${LIGHT_COLOR.primary[100]}`,
                              borderRadius: '8px',
                              marginRight: '0.625rem',
                              padding: '0.25rem 1rem',
                              textAlign: 'center',
                              whiteSpace: { xs: 'nowrap', md: 'normal' },
                              textOverflow: { xs: 'ellipsis', md: 'clip' },
                              marginBottom: '0.3rem',
                            }}
                            onClick={() => {
                              const metaObj = {
                                title: META_TAG_TITLE.serchNews,
                                description: META_TAG_TITLE.serchNews,
                                keywords: '',
                                canonical_url: location.pathname,
                              };
                              dispatchMetaAction(
                                metaAction.updateMeta,
                                metaObj,
                              );
                              setActiveSearch('USERS');
                              setActiveTotalCount(usersCount);
                              setStartIndex(0);
                              setIsLoading(true);
                            }}
                          >
                            <Typography
                              sx={{
                                color: isDarkMode
                                  ? activeSearch === 'USERS'
                                    ? DARK_COLOR.primary[100]
                                    : DARK_COLOR.grey[600]
                                  : activeSearch !== 'USERS'
                                  ? LIGHT_COLOR.grey[400]
                                  : LIGHT_COLOR.primary[100],
                                textTransform: 'none',
                              }}
                            >
                              {t('users') + ' (' + usersCount + ')'}
                            </Typography>
                          </Button>
                        )}
                        {newsCount !== INITIAL_VALUE && (
                          <Button
                            sx={{
                              background: isDarkMode
                                ? activeSearch === 'POSTS'
                                  ? DARK_COLOR.primary[25]
                                  : DARK_COLOR.white[25]
                                : activeSearch === 'POSTS'
                                ? LIGHT_COLOR.primary[15]
                                : LIGHT_COLOR.white[5],
                              border: isDarkMode
                                ? activeSearch === 'POSTS'
                                  ? `0.063rem solid ${DARK_COLOR.primary[100]}`
                                  : `0.063rem solid ${DARK_COLOR.grey[400]}`
                                : activeSearch !== 'POSTS'
                                ? `0.063rem solid ${LIGHT_COLOR.grey[300]}`
                                : `0.063rem solid ${LIGHT_COLOR.primary[100]}`,
                              borderRadius: '8px',
                              marginRight: '0.625rem',
                              padding: '0.25rem 1rem',
                              textAlign: 'center',
                              whiteSpace: { xs: 'nowrap', md: 'normal' },
                              textOverflow: { xs: 'ellipsis', md: 'clip' },
                              marginBottom: '0.3rem',
                            }}
                            onClick={() => {
                              const metaObj = {
                                title: META_TAG_TITLE.serchNews,
                                description: META_TAG_TITLE.serchNews,
                                keywords: '',
                                canonical_url: location.pathname,
                              };
                              dispatchMetaAction(
                                metaAction.updateMeta,
                                metaObj,
                              );
                              setActiveSearch('POSTS');
                              setActiveTotalCount(newsCount);
                              setStartIndex(0);
                              setIsLoading(true);
                            }}
                          >
                            <Typography
                              sx={{
                                color: isDarkMode
                                  ? activeSearch === 'POSTS'
                                    ? DARK_COLOR.primary[100]
                                    : DARK_COLOR.grey[600]
                                  : activeSearch !== 'POSTS'
                                  ? LIGHT_COLOR.grey[400]
                                  : LIGHT_COLOR.primary[100],
                                textTransform: 'none',
                              }}
                            >
                              {t('posts') + ' (' + newsCount + ')'}
                            </Typography>
                          </Button>
                        )}
                      </>
                    )
                  )}
                </div>
                <SearchResult
                  newsType={activeSearch}
                  totalCount={isActiveTotalCount}
                  searchValue={value}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  startIndex={startIndex}
                  setStartIndex={setStartIndex}
                />
              </div>
            </div>
          ) : (
            value &&
            newsCount === INITIAL_VALUE &&
            usersCount === INITIAL_VALUE &&
            !isLoading && (
              <div className='no-results-search'>
                <EmptyScreen message={`No matching results for ${value}`} />
              </div>
            )
          )}
        </Box>
      </Box>
    </>
  );
};

export default Search;
