import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import classes from './PromotionalNewsGrid.module.css';
import inactiveThumbUpIcon from '../../assets/icons/inactive-thumb-up-white.svg';
import activeThumbUpIcon from '../../assets/icons/active-thumb-up.svg';
import inactiveThumbDownIcon from '../../assets/icons/inactive-thumb-down-white.svg';
import activeThumbDownIcon from '../../assets/icons/activeHeartDislikeIconDarkMode.svg';
import moreOptionsWhiteIcon from '../../assets/icons/more-options-white.svg';
import commentIcon from '../../assets/icons/comment-white.svg';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import { PROMOTION_LABEL } from '../../Constants/Constants';
import { LIKE, DISLIKE, NONE } from '../../Constants/Constants';
import { UserDetailsProvider } from '../../store/user-details-context';
import newsService from '../../services/news.service';
import { AppContext } from '../../store/app-context';

export default function PromotionalNewsGrid(props) {
  const navigate = useNavigate();
  const { dispatch: appCtxDispatch } = useContext(AppContext);
  const { state: userState } = useContext(UserDetailsProvider);
  const isAuthenticated = userState?.isAuthenticated;

  const [isImageError, setIsImageError] = useState(false);
  const [isReactionClicked, setIsReactionClicked] = useState(false);
  const [reaction, setReaction] = useState('');
  const [thumbState, setThumbState] = useState({
    thumbUpCount: props.likeCount,
    thumbDownCount: props.dislikeCount,
    thumbUpActive: false,
    thumbDownActive: false,
  });

  const [firstImage] = props.image;
  const newsSessionID = userState?.sessionId;
  const newsID = props.newsId;
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  const handleThumbUp = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.thumbUpActive || props.isLiked
      ? setReaction(NONE)
      : setReaction(LIKE);
  };

  const handleThumbDown = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.thumbDownActive || props.isDisliked
      ? setReaction(NONE)
      : setReaction(DISLIKE);
  };

  const handleComments = (event) => {
    event.stopPropagation();
  };

  const handleMoreOptions = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setThumbState((prev) => ({
      ...prev,
      thumbUpCount: props.likeCount,
      thumbDownCount: props.dislikeCount,
      thumbUpActive: props.isLiked,
      thumbDownActive: props.isDisliked,
    }));
  }, [props.isLiked, props.isLiked]);

  useEffect(() => {
    if (isReactionClicked) {
      updateUserNews();
    }
  }, [isReactionClicked, newsID, reaction]);

  const updateUserNews = async () => {
    try {
      const response = await newsService.updateUserNews({
        sessionId: newsSessionID,
        newsId: newsID,
        type: reaction,
      });

      response?.news?.forEach((updatedNews) => {
        setThumbState({
          thumbUpCount: updatedNews.news_like_count,
          thumbUpActive: updatedNews.is_liked,
          thumbDownCount: updatedNews.news_dislike_count,
          thumbDownActive: updatedNews.is_disliked,
        });
        setIsReactionClicked(false);
      });
    } catch (error) {}
  };

  const handleNewsDetail = (newsId) => {
    navigate(`/news-details/${newsId}`);
  };

  const handleSignInModal = (event) => {
    event?.stopPropagation();
    appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
  };

  return (
    <Box
      className={classes.newsCard}
      onClick={() => handleNewsDetail(props?.newsId)}
    >
      <Box className={classes.newsCardMedia}>
        {isImageError ? (
          <img
            src={isDarkMode ? noImageDark : noImage}
            alt='No Image Found'
            className={classes.newsCardImage}
            style={{ height: '13.25rem' }}
          />
        ) : (
          <img
            src={firstImage}
            onError={() => setIsImageError(true)}
            alt='image'
            className={classes.newsCardImage}
          />
        )}
        <div className={classes.imageLabel}>
          <p className={classes.imageLabelText}>{PROMOTION_LABEL}</p>
          <img
            src={moreOptionsWhiteIcon}
            alt='more-options-icon'
            className={classes.moreOptions}
            onClick={(event) => {
              isAuthenticated
                ? handleMoreOptions(event)
                : handleSignInModal(event);
            }}
          />
        </div>
        <Box className={classes.newsCardActions}>
          <Box className={classes.newsCardReactionsBox}>
            <div className={classes.thumb}>
              <div
                aria-label='thumbs up'
                onClick={(event) => {
                  isAuthenticated
                    ? handleThumbUp(event)
                    : handleSignInModal(event);
                }}
              >
                {thumbState.thumbUpActive ? (
                  <img
                    src={activeThumbUpIcon}
                    alt='active-thumb-up-icon'
                    className={classes.thumb}
                  />
                ) : (
                  <img
                    src={inactiveThumbUpIcon}
                    alt='inactive-thumb-up-icon'
                    className={classes.thumb}
                  />
                )}
              </div>

              <div className={classes.newsCardReactions}>
                {thumbState.thumbUpCount ? thumbState.thumbUpCount : ''}
              </div>
            </div>

            <div className={classes.thumb}>
              <div
                aria-label='thumbs down'
                onClick={(event) => {
                  isAuthenticated
                    ? handleThumbDown(event)
                    : handleSignInModal(event);
                }}
              >
                {thumbState.thumbDownActive ? (
                  <img
                    src={activeThumbDownIcon}
                    alt='active-thumb-down-icon'
                    className={classes.thumb}
                  />
                ) : (
                  <img
                    src={inactiveThumbDownIcon}
                    alt='inactive-thumb-down-icon'
                    className={classes.thumb}
                  />
                )}
              </div>
              <div className={classes.newsCardReactions}>
                {thumbState.thumbDownCount ? thumbState.thumbDownCount : ''}
              </div>
            </div>

            <div className={classes.thumb}>
              <div
                aria-label='comment'
                onClick={(event) => {
                  isAuthenticated
                    ? handleComments(event)
                    : handleSignInModal(event);
                }}
              >
                <img
                  src={commentIcon}
                  alt='comment-icon'
                  className={classes.thumb}
                />
              </div>
              <div className={classes.newsCardReactions}></div>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
