import React, { useState, useEffect } from 'react';
import './Profile.css';
import { Grid, Box } from '@mui/material';
import editIcon from '../../assets/icons/edit.svg';
import editDarkIcon from '../../assets/icons/editDark.svg';
import deleteIcon from '../../assets/icons/Delete.svg';
import deleteDarkIcon from '../../assets/icons/delete-dark.svg';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const EditProfileMobile = ({
  isImageDeleted,
  userProfilePic,
  deleteProfilePic,
}) => {
  const [gridSize, setGridSize] = useState(12);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.mobile);

  useEffect(() => {
    isImageDeleted && setGridSize(6);
  }, [isImageDeleted]);

  return (
    <Box
      className='editIconsSection'
      sx={{
        background: isDarkMode
          ? isMobile
            ? DARK_COLOR.grey[300]
            : ''
          : isMobile
          ? LIGHT_COLOR.white[100]
          : '',
        border: isDarkMode
          ? isMobile
            ? `0.063rem solid ${DARK_COLOR.grey[400]}`
            : ''
          : isMobile
          ? `0.063rem solid ${LIGHT_COLOR.grey[200]}`
          : '',
      }}
    >
      <Grid container item xs={12} justifyContent='center'>
        <Grid
          xs={gridSize}
          sx={{ borderRight: 1, borderColor: 'divider', height: '1.8rem' }}
        >
          <Box
            onClick={() => userProfilePic.current.click()}
            className='editIconsMobile'
          >
            <img
              src={isDarkMode ? editDarkIcon : editIcon}
              className='mobileEditIcons'
              alt='edit icon'
            />
          </Box>
        </Grid>
        {isImageDeleted && (
          <Grid xs={6}>
            <div
              onClick={() => deleteProfilePic(userProfilePic.current)}
              className='deleteIconsMobile'
            >
              <img
                src={isDarkMode ? deleteDarkIcon : deleteIcon}
                className='mobileEditIcons'
                alt='edit icon'
              />
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EditProfileMobile;
