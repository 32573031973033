import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import classes from './share.module.css';
import {
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
} from 'react-share';
import WhatsappShareButton from './WhatsApp';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import CopyIcon from '../../assets/icons/copyClipBoard.svg';
import Email from '../../assets/icons/Email.svg';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import { toast } from 'react-toastify';
import NoImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import { EnvironmentProvider } from '../../store/firebase-context';
import { useTranslation } from 'react-i18next';
import { convert } from '../../utils/html-to-string.util';

/**
 * These function is for styling
 * @param {children,onclose} childern is dynamic component and onclose will be function
 *
 */
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

/**
 * ShareDialog
 * we are usinf for sharing via social media
 */
export default function ShareDialog(props) {
  const { media, headline, url, handleClose, open, authorName } = props;
  const convertedHeadline = convert(headline);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { t } = useTranslation();
  const mobileView = MaxWidth(viewPort.mobileMax);
  const dispatchMetaAction = useMetaStore()[1];
  const { environment } = useContext(EnvironmentProvider);
  const imageUrl =
    media && media[0]
      ? media[0]?.type === 'video'
        ? media[0]?.thumbUrl
        : media[0]?.url
      : isDarkMode
      ? noImageDark
      : NoImage;

  /**
   * side effect to update og tags
   */
  useEffect(() => {
    const OgTag = {
      title: convertedHeadline,
      img: media && media[0] && media[0].url ? media[0].url : '',

      url: window.location.origin,
    };
    dispatchMetaAction(metaAction.updateOgTag, OgTag);
  }, []);

  /**
   * used to copy the url link
   */
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    toast.success('link copied successfully', {
      toastId: 'clipboard',
    });
  };

  return (
    <Dialog
      fullScreen={mobileView}
      fullWidth
      maxWidth='sm'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      sx={{ backgroundImage: 'unset' }}
    >
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        Share
      </BootstrapDialogTitle>
      <DialogContent
        sx={
          mobileView
            ? { display: 'flex', marginY: 'auto' }
            : { padding: '1.2rem' }
        }
        dividers
      >
        <>
          <Card
            sx={{
              backgroundColor: (theme) => theme.palette.grey[100],
              borderRadius: '10px',
              padding: '1rem',
              border: isDarkMode ? '1px solid #484848' : '1px solid #E0E0E0',
            }}
          >
            <Box
              sx={
                mobileView
                  ? {}
                  : {
                      display: 'flex',
                    }
              }
            >
              <img
                src={imageUrl}
                alt={'no title'}
                loading='lazy'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = isDarkMode ? noImageDark : NoImage;
                }}
                style={{
                  borderRadius: '8px',
                  width: mobileView ? '100%' : '11.875rem',
                  objectFit: 'cover',
                }}
              />

              <Box
                sx={{
                  paddingLeft: '0.625rem',
                  paddingRight: '1rem',
                }}
              >
                <Typography
                  variant='subtitle2'
                  sx={{ fontWeight: 600 }}
                  color='#808080'
                  gutterBottom
                >
                  {authorName}
                </Typography>
                <Typography variant='h6' sx={{ fontWeight: 700 }} gutterBottom>
                  {convertedHeadline}
                </Typography>
              </Box>
            </Box>
          </Card>
        </>
      </DialogContent>
      <Box
        sx={{
          backgroundImage: 'unset',
        }}
      >
        <Typography
          variant='subtitle1'
          gutterBottom
          sx={{ textAlign: 'center' }}
        >
          Share via
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: mobileView
              ? 'auto auto auto'
              : 'auto auto auto auto auto ',

            marginX: mobileView ? '2.188rem' : '4.375rem',
            marginBottom: mobileView ? '1.38rem' : '1.8rem',
            marginTop: mobileView ? '1.38rem' : '',
            flexWrap: 'wrap',
          }}
          onClick={handleClose}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#808080',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                width: '3.75rem',
                height: '3.75rem',
              }}
              className={classes.borderRadius}
              onClick={copyToClipboard}
            >
              <img src={CopyIcon} alt='completed icon' />
            </Box>
            <Typography>Copy link</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <WhatsappShareButton
              title={`${convertedHeadline}\n`}
              separator=''
              mobileLink={`\n${t('minmini-share')}: ${
                environment.minmini_share_url
              }`}
              url={url}
            >
              <WhatsappIcon className={classes.borderRadius} size={60} />
            </WhatsappShareButton>
            <Typography>Whatsapp</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <FacebookShareButton url={url} quote={convertedHeadline}>
              <FacebookIcon className={classes.borderRadius} size={60} />
            </FacebookShareButton>
            <Typography>Facebook</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TwitterShareButton url={url} quote={convertedHeadline}>
              <TwitterIcon className={classes.borderRadius} size={60} />
            </TwitterShareButton>
            <Typography>Twitter</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <EmailShareButton url={url} quote={convertedHeadline}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#808080',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: '3.75rem',
                    height: '3.75rem',
                  }}
                  className={classes.borderRadius}
                  onClick={copyToClipboard}
                >
                  <img src={Email} alt='completed icon' />
                </Box>
              </Box>
            </EmailShareButton>
            <Typography>Email</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
