import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { METHOD } from '../enums/api.enums';
import { api } from '../Constants/api.constants';
import { getEnv, defaultConfigurations } from '../firebase/firebaseConfig';

const BASE_URL =
  getEnv('new_api_host')?._value ||
  defaultConfigurations[process.env.REACT_APP_ENV]?.new_api_host;

const signUp = (data) => Auth.signUp(data);

const resendSignUp = (data) => Auth.resendSignUp(data);

const signIn = ({ userName, password }) => Auth.signIn(userName, password);

const signout = () => Auth.signOut();

const forgotPassword = (username) => Auth.forgotPassword(username);

const forgotPasswordSubmit = ({ username, code, password }) =>
  Auth.forgotPasswordSubmit(username, code, password);

const currentAuthenticatedUser = () => Auth.currentAuthenticatedUser();

const confirmSignUp = ({ username, code }) =>
  Auth.confirmSignUp(username, code, {
    forceAliasCreation: false,
  });

const completeNewPassword = ({ user, password }) =>
  Auth.completeNewPassword(user, password);

const googleSignIn = () =>
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });

const facebookSignIn = () =>
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Facebook,
  });

const appleSignIn = () =>
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });

const currentUserInfo = () => Auth.currentUserInfo();

const verifyUser = (userAttribute) =>
  Auth.verifyCurrentUserAttribute(userAttribute);

const verifyUserSubmit = ({ userAttribute, otp }) =>
  Auth.verifyCurrentUserAttributeSubmit(userAttribute, otp);

const verifyCaptcha = () => {
  return window.AwsWafIntegration.fetch(
    `${BASE_URL}${api.verifyCaptcha}`,
    METHOD.GET,
  );
};

const authService = {
  signUp,
  signIn,
  signout,
  forgotPassword,
  forgotPasswordSubmit,
  currentAuthenticatedUser,
  confirmSignUp,
  completeNewPassword,
  googleSignIn,
  facebookSignIn,
  appleSignIn,
  currentUserInfo,
  verifyUser,
  verifyUserSubmit,
  resendSignUp,
  verifyCaptcha,
};

export default authService;
