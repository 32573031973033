import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';
import { api } from '../Constants/api.constants';
import { getEnv } from '../firebase/firebaseConfig';

/**
 * Make api call for retrieving Navbar Category List
 * @returns promise
 */
const fetchNavBarCategories = () => {
  return apiRequest(api.getConfig, METHOD.GET, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Category List
 * @returns promise
 */
const fetchNewsCategories = () => {
  return apiRequest(api.getCategories, METHOD.GET, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Navbar Filters List
 * @returns promise
 */
const fetchNavBarFilters = (data) => {
  return apiRequest(api.getFilters, METHOD.GET, false, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for register user
 * @returns promise
 */
const registerUser = (data) => {
  return apiRequest(api.register, METHOD.POST, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Bookmarks
 * @returns promise
 */
const districtList = () => {
  return apiRequest(api.getLocations, METHOD.GET, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const fetchDistricts = () => {
  return apiRequest(api.getLocations, METHOD.GET, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const fetchAreas = (data) => {
  return apiRequest(api.getLocations, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to get TrackingId of the user
 * @returns promise
 */
const getTrackingId = (data) => {
  return apiRequest(api.getTrackingId, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to get UserId of the user
 * @returns promise
 */
const getUserId = (data) => {
  return apiRequest(api.getUserId, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getCurrentCity = (data) => {
  const openStreet = getEnv('web_open_street_map_url')?._value;
  return fetch(`${openStreet}?format=json&lat=${data.lat}&lon=${data.long}`)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for deleting User Account
 * @returns promise
 */
const deleteUserAccount = () => {
  return apiRequest(`${api.deleteAccount}`, METHOD.DELETE, false, null, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving profile of PUBLISHER/ REPORTER/ CJ/ USER/ INFLUENCER
 * @returns promise
 */
const getUserProfile = (data) => {
  return apiRequest(
    api.getUserProfile,
    METHOD.GET,
    false,
    data,
    true,
    false,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const validateUserAvailabilty = (data) => {
  return apiRequest(api.userCheck, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const appService = {
  fetchNewsCategories,
  fetchNavBarCategories,
  fetchNavBarFilters,
  registerUser,
  districtList,
  getTrackingId,
  getCurrentCity,
  deleteUserAccount,
  fetchDistricts,
  fetchAreas,
  getUserProfile,
  validateUserAvailabilty,
  getUserId,
};

export default appService;
