import { initStore } from '.';
import { metaAction } from './metaAction';
import logo from '../../assets/images/minmini-logo.png';
import { convert } from '../../utils/html-to-string.util';

const ConfigurationMetaStore = () => {
  /**
   * This method will configure store to your file after intializing that we can use it in our module
   */
  const actions = {
    [metaAction.updateMeta]: (state, meta) => ({
      meta: {
        title: convert(meta?.title),
        keywords: meta?.keywords,
        description: meta?.description,
        canonical_url: meta?.canonical_url,
      },
    }),
    [metaAction.updateOgTag]: (state, ogTag) => ({ ogTag: ogTag }),
  };

  initStore(actions, {
    ogTag: {
      url: window.location.origin,
      title: 'Minmini',
      img: { logo },
    },
    meta: {
      title: 'Minmini',
      keywords: '',
      description: '',
      canonical_url: '',
    },
  });
};

export default ConfigurationMetaStore;
