import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

/**
 * Make api call for retrieving Drafts
 * @param {object} data
 * @returns promise
 */
const getMedia = (data) => {
  return apiRequest(api.mediaData, METHOD.GET, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for updating post attach date
 * @param {object} data
 * @returns promise
 */
const updatePostAttachDate = (data) => {
  return apiRequest(api.updatePostAttachDate, METHOD.PUT, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getUserStats = (data) => {
  return apiRequest(api.userStats, METHOD.GET, true, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const reportService = { getMedia, updatePostAttachDate, getUserStats };

export default reportService;
