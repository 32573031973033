// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reporterID_backIcon__wYSDE {
  margin-right: 0.625rem;
  width: 24px;
  height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Reporter/ReporterID/reporterID.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd","sourcesContent":[".backIcon {\n  margin-right: 0.625rem;\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backIcon": `reporterID_backIcon__wYSDE`
};
export default ___CSS_LOADER_EXPORT___;
