import React, { useState, useContext } from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  Button,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import newsService from '../../services/news.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfoModal from '../Modal/InfoModal';
import { UserDetailsProvider } from '../../store/user-details-context';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import ButtonLoader from '../buttonLoader/ButtonLoader';

/**
 * Report UI Component
 */
const Report = ({ newsID, closeModal }) => {
  const [problemWithPost, setProblemWithPost] = useState({
    vulgar: false,
    fake: false,
    abuse: false,
    offensive: false,
    violence: false,
    prohibited: false,
    others: false,
  });

  const isMobile = MaxWidth(viewPort.mobileMax);
  const [comment, setComment] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isReportingLimitExceeded, setIsReportingLimitExceeded] =
    useState(false);
  const navigate = useNavigate();
  const { state: userState } = useContext(UserDetailsProvider);
  const newsSessionID = userState?.sessionId;
  const { t } = useTranslation();
  const mobileView = MaxWidth(viewPort.mobileMax);
  const [reportLoader, setReportLoader] = useState(false);

  const { vulgar, fake, abuse, offensive, violence, prohibited, others } =
    problemWithPost;

  /**
   * Checks the length of the array to set the value of boolean checkbox if no checkbox has been selected
   */
  const checkboxError =
    [vulgar, fake, abuse, offensive, violence, prohibited, others].filter(
      (problem) => problem,
    ).length < 1;

  /**
   * Sets the value of boolean button error if no checkbox has been selected and the length of comment exceeds 500 characters
   */
  const buttonActive = !checkboxError && comment.length <= 500;

  /**
   * Sets the selectedProblems from problemWithPost state which are true
   */
  const selectedProblems = Object.keys(problemWithPost).filter(
    (key) => problemWithPost[key],
  );

  /**
   * Sets the problemWithPost state in case a checkbox has been checked or unchecked
   */
  const handleCheckBoxChange = (event) => {
    setProblemWithPost({
      ...problemWithPost,
      [event.target.name]: event.target.checked,
    });
  };

  /**
   * Reporting the news using Flag API by passing sessionID, newsID, the checkbox values selected
   * by user and the comments added.
   */
  const handleReport = async () => {
    setReportLoader(true);
    try {
      const response = await newsService.flagNews({
        sessionId: newsSessionID,
        newsId: newsID,
        message: selectedProblems,
        description: comment,
      });
      setReportLoader(false);
      if (response.newsId) {
        setShowConfirmModal(true);
      }
      if (response?.errorMessage) {
        setIsReportingLimitExceeded(true);
      }
    } catch (error) {
      setReportLoader(false);
    }
  };

  return (
    <>
      <div className='report'>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: 600,
            marginBottom: '0.625rem',
            marginTop: mobileView ? '1.25rem' : '0.625rem',
          }}
        >
          {t('HELP_US_TITLE')}
        </Typography>
        <Typography variant='body2' sx={{ marginBottom: '1.25rem' }}>
          {t('HELP_US_DESCRIPTION')}
        </Typography>
        <Typography variant='subtitle1' sx={{ marginBottom: '0.938rem' }}>
          {t('SELECT_A_PROBLEM')}
        </Typography>
        <div className='options-container' style={{ marginLeft: '0' }}>
          <FormControl
            required
            error={checkboxError}
            component='fieldset'
            variant='standard'
          >
            <FormGroup
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'Sharp Sans',
                  fontSize: '0.875rem',
                  fontWeight: '400',
                  color: '#545454',
                  marginLeft: '0.625rem',
                  display: 'flex',
                  flexDirection: 'row',
                },
              }}
              className='options-innercontainerone'
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='vulgar'
                    checked={vulgar}
                    onChange={handleCheckBoxChange}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{ marginLeft: isMobile ? '' : '0.625rem' }}
                  >
                    {t('vulgar-content')}
                  </Typography>
                }
                sx={{ width: '10.125rem' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='fake'
                    checked={fake}
                    onChange={handleCheckBoxChange}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{ marginLeft: isMobile ? '' : '0.625rem' }}
                  >
                    {t('fake-misleading')}
                  </Typography>
                }
                sx={{ width: '10.125rem' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='abuse'
                    checked={abuse}
                    onChange={handleCheckBoxChange}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{ marginLeft: isMobile ? '' : '0.625rem' }}
                  >
                    {t('abuse')}
                  </Typography>
                }
                sx={{ width: '10.125rem' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='offensive'
                    checked={offensive}
                    onChange={handleCheckBoxChange}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{ marginLeft: isMobile ? '' : '0.625rem' }}
                  >
                    {t('offensive')}
                  </Typography>
                }
                sx={{ width: '10.125rem' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='violence'
                    checked={violence}
                    onChange={handleCheckBoxChange}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{ marginLeft: isMobile ? '' : '0.625rem' }}
                  >
                    {t('violence')}
                  </Typography>
                }
                sx={{ width: '10.125rem' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='prohibited'
                    checked={prohibited}
                    onChange={handleCheckBoxChange}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{ marginLeft: isMobile ? '' : '0.625rem' }}
                  >
                    {t('prohibited-content')}
                  </Typography>
                }
                sx={{ width: '10.125rem' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='others'
                    checked={others}
                    onChange={handleCheckBoxChange}
                  />
                }
                label={
                  <Typography
                    variant='body2'
                    sx={{ marginLeft: isMobile ? '' : '0.625rem' }}
                  >
                    {t('others')}
                  </Typography>
                }
                sx={{ width: '10.125rem' }}
              />
            </FormGroup>
          </FormControl>
        </div>
        <div className='comments-container'>
          <Typography
            variant='subtitle1'
            sx={{
              marginBottom: '0.625rem',
            }}
          >
            {t('COMMENTS_TITLE')}
          </Typography>
          <TextField
            label={
              // sx={{
              //   color: (theme) =>
              //     theme.palette.mode === 'dark' ? '#8E8E8E' : '#CCCCCC',
              // }}

              t('write-your-comment')
            }
            type='text'
            variant='outlined'
            fullWidth
            autoComplete='off'
            size='small'
            multiline
            rows={2}
            className='commentField'
            onChange={(event) => setComment(event.target.value)}
            sx={{
              '& .MuiInputBase-root': {
                height: 80,
              },
            }}
          />
        </div>

        <Divider className='report-section-horizontalline' fullWidth />
      </div>

      <div className='report-button-container'>
        <ButtonLoader
          sx={{
            width: isMobile ? '' : '5.669rem',
            height: isMobile ? '' : '2.75rem',
          }}
          fullWidth={isMobile}
          onClick={handleReport}
          variant='contained'
          disabled={!buttonActive}
          loading={reportLoader}
        >
          {t('report')}
        </ButtonLoader>
      </div>
      <InfoModal
        title={t('THANK_YOU')}
        message={t('notify-you')}
        open={showConfirmModal}
        close={() => {
          setShowConfirmModal(false);
          closeModal();
          navigate('/');
        }}
      >
        <Box className='report-ok-container'>
          <Button
            onClick={() => {
              setShowConfirmModal(false);
              closeModal();
              navigate('/');
            }}
            className='report-ok-button'
            sx={{ fontFamily: 'Sharp Sans' }}
          >
            {t('ok')}
          </Button>
        </Box>
      </InfoModal>
      <InfoModal
        message={t('you-have-exceeded-the-daily-limit')}
        open={isReportingLimitExceeded}
        close={() => {
          setIsReportingLimitExceeded(false);
          closeModal();
          navigate('/');
        }}
      >
        <Box className='report-ok-container'>
          <Button
            onClick={() => {
              setShowConfirmModal(false);
              closeModal();
              navigate('/');
            }}
            className='report-ok-button'
            sx={{ fontFamily: 'Sharp Sans' }}
          >
            {t('ok')}
          </Button>
        </Box>
      </InfoModal>
    </>
  );
};

export default Report;
