import {
  Box,
  Button,
  InputLabel,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/minmini-tamil.png';
import { PaymentTheme } from '../../themes/payment.theme';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EnvironmentProvider } from '../../store/firebase-context';
import { useEffect } from 'react';
import paymentService from '../../services/payment.service';
import { decrypt, encrypt } from '../../utils/crypto.util';
import OverlayLoader from '../Loader/OverLayLoader';
import { useState } from 'react';
import {
  DARK_COLOR,
  LIGHT_COLOR,
  MINMINI_V2,
} from '../../themes/color.constant';
import PaymentModel from '../Modal/PaymentModal';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';

const paymentTheme = createTheme(PaymentTheme);

function PaymentForm() {
  const upiRegex = /^$|^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/;
  const accountnumberRegex = /^$|^\d{9,18}$/;
  const IFSCRegex = /^$|^[a-zA-Z]{4}[a-zA-Z0-9]{7}$/;
  const alphabetWithSpace = /^$|^[a-zA-Z ]*$/;
  const today = new Date();
  const { environment } = useContext(EnvironmentProvider);
  const dd = Number(String(today.getDate()).padStart(2, '0'));
  const year = today.getFullYear();
  const mm = Number(
    String(
      today.getMonth() + (dd > environment?.payout_scheduled_day ? 1 : 0),
    ).padStart(2, '0'),
  );

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const accountTypes = [
    {
      key: 'Savings',
      value: t('savings'),
    },
    { key: 'Current', value: t('current') },
  ];

  const [loading, setloading] = useState();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [userPaymentDetails, setUserPaymentDetails] = useState();
  const [accountType, setAccountType] = useState('Savings');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (token) {
      getBankDetail();
    }
  }, [token]);

  const getBankDetail = async () => {
    setloading(true);
    try {
      const response = await paymentService.getBankDetail(token);
      if (response) {
        setUserPaymentDetails(response);
        resetForm(response);
      }
    } catch (err) {
    } finally {
      setloading(false);
    }
  };

  const resetForm = async (data) => {
    const upiId = data?.upiId ? await decrypt(data?.upiId) : '';
    const IFSC = data?.IFSC ? await decrypt(data?.IFSC) : '';
    const accountNumber = data?.accountNumber
      ? await decrypt(data?.accountNumber)
      : '';
    setAccountType(data?.accountType || 'Savings');
    reset({
      upiId: upiId,
      accountHolderName: data?.accountHolderName || '',
      accountNumber: accountNumber,
      IFSC: IFSC,
      accountType: data?.accountType || 'Savings',
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape(
        {
          upiId: Yup.string().when('accountNumber', {
            is: (accountNumberField) => !accountNumberField,
            then: () =>
              Yup.string()
                .matches(upiRegex, t('upi-validation'))
                .required(t('either-upi-bank-validation')),
            otherwise: () =>
              Yup.string().matches(upiRegex, t('upi-validation')),
          }),
          accountHolderName: Yup.string().matches(
            alphabetWithSpace,
            t('accoutholder-name-validation'),
          ),
          accountNumber: Yup.string().when(['upiId', 'IFSC'], {
            is: (upiId, IFSC) => !upiId || !!IFSC,
            then: () =>
              Yup.string()
                .matches(accountnumberRegex, t('account-number-validation'))
                .required(t('either-upi-bank-validation')),
            otherwise: () =>
              Yup.string().matches(
                accountnumberRegex,
                t('account-number-validation'),
              ),
          }),
          IFSC: Yup.string().when(['accountNumber', 'upiId'], {
            is: (accountNumberField, upiId) => !upiId || !!accountNumberField,
            then: () =>
              Yup.string()
                .required(t('either-upi-bank-validation'))
                .matches(IFSCRegex, t('valid-ifsc')),
            otherwise: () => Yup.string().matches(IFSCRegex, t('valid-ifsc')),
          }),
        },
        [
          ['accountNumber', 'upiId'],
          ['upiId', 'IFSC'],
          ['accountNumber', 'IFSC'],
        ],
      ),
    ),
    defaultValues: {
      upiId: '',
      accountHolderName: '',
      accountNumber: '',
      IFSC: '',
      accountType: 'Savings',
    },
  });
  const onSubmit = async (data) => {
    setloading(true);
    const upiId = data?.upiId && (await encrypt(data?.upiId));
    const IFSC = data?.IFSC && (await encrypt((data?.IFSC).toUpperCase()));
    const accountNumber =
      data?.accountNumber && (await encrypt(data?.accountNumber));
    const payload = {
      upiId: upiId,
      accountHolderName: data?.accountHolderName,
      accountNumber: accountNumber,
      IFSC: IFSC,
      accountType: data.accountType,
    };
    paymentService
      .updateBankDetail(payload, token)
      .then((res) => {
        setErrorMessage(res.message);
        setloading(false);
        setIsOpenPopup(true);
      })
      .catch((error) => {
        if (error.message) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(t('please-try-after-sometime'));
        }
        setloading(false);
        setIsOpenPopup(true);
      });
  };

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
    setValue('accountType', event.target.value);
  };

  return (
    <ThemeProvider theme={paymentTheme}>
      <OverlayLoader show={loading} />
      {userPaymentDetails ? (
        <Box
          sx={{
            height: '100vh',
            overflowY: 'auto',
            padding: '1.575rem 1.125rem',
          }}
        >
          <Box sx={{ minWidth: '300px', maxWidth: '600px', margin: '0 auto' }}>
            <Box
              sx={{
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src={Logo} alt='Minimi logo' width='240px' height='100px' />
            </Box>
            <Box sx={{ marginTop: '1rem', marginBottom: '2.5rem' }}>
              <Typography
                sx={{ fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}
              >
                {t('Vanakkam')},
              </Typography>
              <Typography
                sx={{ fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}
              >
                {userPaymentDetails?.userName || ''}
              </Typography>
            </Box>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Box sx={{ marginBottom: '3rem' }}>
                  <InputLabel
                    htmlFor='upi-id'
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 700,
                    }}
                  >
                    {t('upiId')}:
                  </InputLabel>
                  <TextField
                    id='upi-id'
                    name='upi-id'
                    hiddenLabel
                    fullWidth
                    onChange={() => {
                      trigger('accountNumber');
                      trigger('IFSC');
                    }}
                    placeholder={t('upi-id-used')}
                    variant='outlined'
                    inputProps={register('upiId')}
                    error={errors?.upiId ? true : false}
                    helperText={errors?.upiId?.message}
                    InputProps={{
                      style: { color: 'black', marginTop: '0.75rem' },
                    }}
                  />
                </Box>

                <Box sx={{ marginBottom: '1.25rem' }}>
                  <InputLabel
                    htmlFor='bank-account-name'
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 700,
                    }}
                  >
                    {t('bankAccountHolderName')}:
                  </InputLabel>
                  <TextField
                    id='bank-account-name'
                    name='bank-account-name'
                    hiddenLabel
                    fullWidth
                    placeholder={t('fullAccountNumber')}
                    variant='outlined'
                    inputProps={{
                      ...register('accountHolderName'),
                      maxLength: 50,
                    }}
                    error={errors?.accountHolderName ? true : false}
                    helperText={errors?.accountHolderName?.message}
                    InputProps={{
                      style: { color: 'black', marginTop: '0.75rem' },
                    }}
                  />
                </Box>
                <Box sx={{ marginBottom: '1.25rem' }}>
                  <InputLabel
                    htmlFor='bank-account-no'
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 700,
                    }}
                  >
                    {t('Bank-account-number')}
                  </InputLabel>
                  <TextField
                    id='bank-account-no'
                    name='bank-account-no'
                    hiddenLabel
                    fullWidth
                    onChange={() => {
                      trigger('upiId');
                      trigger('IFSC');
                    }}
                    placeholder={t('full-account-number')}
                    variant='outlined'
                    inputProps={register('accountNumber')}
                    error={errors?.accountNumber ? true : false}
                    helperText={errors?.accountNumber?.message}
                    InputProps={{
                      style: { color: 'black', marginTop: '0.75rem' },
                    }}
                  />
                </Box>
                <Box sx={{ marginBottom: '1.25rem' }}>
                  <InputLabel
                    htmlFor='bank-ifsc-code'
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 700,
                    }}
                  >
                    {t('IFSC-label')}
                  </InputLabel>
                  <TextField
                    id='bank-ifsc-code'
                    name='bank-ifsc-code'
                    hiddenLabel
                    fullWidth
                    placeholder='Enter IFSC Code'
                    variant='outlined'
                    onChange={() => {
                      trigger('accountNumber');
                    }}
                    inputProps={register('IFSC')}
                    error={errors?.IFSC ? true : false}
                    helperText={errors?.IFSC?.message}
                    InputProps={{
                      style: { color: 'black', marginTop: '0.75rem' },
                    }}
                  />
                </Box>
                <Box sx={{ marginBottom: '1.25rem' }}>
                  <InputLabel
                    htmlFor='account-type'
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      fontWeight: 700,
                    }}
                  >
                    {t('account-type-label')}
                  </InputLabel>
                  <Select
                    required
                    sx={{
                      width: '100%',
                      marginTop: '0.75rem',
                      textAlign: 'justify',
                    }}
                    id='account-type'
                    value={accountType}
                    inputProps={register('accountType')}
                    onChange={handleAccountTypeChange}
                  >
                    {accountTypes &&
                      Array.isArray(accountTypes) &&
                      accountTypes.map((item) => {
                        return (
                          <MenuItem value={item.key} key={item.key}>
                            <Typography sx={{ color: 'black' }}>
                              {item.value}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {errors?.category ? (
                    <FormHelperText
                      sx={{
                        color: isDarkMode
                          ? DARK_COLOR.error
                          : LIGHT_COLOR.error,
                        fontSize: '0.75rem',
                        paddingLeft: '0.625rem',
                      }}
                    >
                      {errors?.category?.message}
                    </FormHelperText>
                  ) : null}
                </Box>
                <Box sx={{ paddingBottom: '1.688rem' }}>
                  <Typography>{t('minimum-amount-eligible')}</Typography>
                  <Typography>
                    {`Next payout scheduled for ${environment?.payout_scheduled_day} ${months[mm]} ${year}.
Payout amounts shown are subject to review and are not final.
 Payment is currently supported only for Indian bank accounts. Refer app `}
                    <span
                      style={{
                        cursor: 'pointer',
                        color: MINMINI_V2.primary.salmon,
                      }}
                      onClick={() =>
                        window.open(
                          '/terms-of-use',
                          '_blank',
                          'noopener,noreferrer',
                        )
                      }
                    >
                      {'Terms of Use.'}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  disabled={!isDirty || !isValid}
                  color='primary'
                  fullWidth
                  variant='contained'
                  sx={{
                    fontWeight: '600',
                    width: '19.56rem',
                    height: '3.063rem',
                  }}
                  type='submit'
                >
                  {t('Update')}
                </Button>
              </Box>
            </form>
          </Box>
          {isOpenPopup && (
            <PaymentModel
              show={isOpenPopup}
              onClose={() => setIsOpenPopup(false)}
              message={errorMessage}
            />
          )}
        </Box>
      ) : (
        <>
          {!loading && !userPaymentDetails && (
            <Box
              sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>{t('no-user-info')}</Box>
            </Box>
          )}
        </>
      )}
    </ThemeProvider>
  );
}

export default PaymentForm;
