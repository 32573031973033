import React, { useState, useEffect } from 'react';
import './MostRead.css';
import { useTranslation } from 'react-i18next';
import newsService from '../../services/news.service';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';
import { Box, Typography, Divider, List, ListItem } from '@mui/material';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { convert } from '../../utils/html-to-string.util';
import { Ad } from 'react-ad-manager';

/**
 * MostRead Component
 */
const MostRead = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [mostReadNews, setMostReadNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  const [isEmpty, setIsEmpty] = useState(false);

  /**
   * side effect to most read news api on initialization
   */
  useEffect(() => {
    getMostReadNews();
  }, []);

  /**
   * Getting the Most Read News list from the api
   */
  const getMostReadNews = async () => {
    try {
      const response = await newsService.mostReadList({
        news_type: 'most_read',
      });
      setMostReadNews(response?.news);
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <Box
        sx={{
          background: isDarkMode
            ? DARK_COLOR.grey[200]
            : LIGHT_COLOR.white[100],
          border: isDarkMode
            ? `0.063rem solid ${DARK_COLOR.grey[300]}`
            : `0.063rem solid ${LIGHT_COLOR.grey[300]}`,
        }}
        className='mostread-container'
      >
        <Typography
          sx={{
            color: isDarkMode ? DARK_COLOR.white[100] : LIGHT_COLOR.grey[600],
          }}
          className='mostread-title'
        >
          {t('top-posts')}
        </Typography>
        <Divider variant='fullWidth' />
        {isLoading ? (
          <div className='mostread-headings'>
            <Skeleton
              height={7}
              count={10}
              style={{ marginLeft: '1rem', width: '90%' }}
            />
          </div>
        ) : (
          <List
            className='mostread-innercontainer'
            sx={{
              color: isDarkMode ? DARK_COLOR.grey[600] : LIGHT_COLOR.grey[500],
              listStyleType: 'disc',
            }}
          >
            {mostReadNews
              ?.filter((item, index) => index < 3)
              .map((mostread) => (
                <ListItem
                  className='mostread-headings title'
                  key={mostread.newsId}
                  onClick={() => {
                    if (mostread?.url) {
                      navigate(`/news${mostread?.url}`);
                    }
                  }}
                  sx={{ display: 'list-item', padding: '0' }}
                >
                  <Typography
                    sx={{
                      color: isDarkMode
                        ? DARK_COLOR.grey[600]
                        : LIGHT_COLOR.grey[500],
                      '&:hover': {
                        borderColor: isDarkMode
                          ? DARK_COLOR.grey[600]
                          : LIGHT_COLOR.grey[500],
                        paddingLeft: '0',
                      },
                    }}
                  >
                    {convert(mostread.heading)}
                  </Typography>
                </ListItem>
              ))}
          </List>
        )}
      </Box>
      {!isEmpty && (
        <Box>
          <Ad
            adUnit='/23039363529/Minmini_web_home_300x250'
            name={'div-gpt-ad-1723463235492-0'}
            size={[300, 250]}
            collapseEmptyDivs={true}
            eventSlotRenderEnded={(event) => {
              if (event?.isEmpty) {
                setIsEmpty(true);
              } else {
                setIsEmpty(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
};

export default MostRead;
