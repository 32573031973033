import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Grid, Button, Typography } from '@mui/material';
import Timer from '../Timer/Timer';
import completedIcon from '../../assets/completed.png';
import authService from '../../services/auth.service';
import './Verify.css';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

/***
 * Verify Phone / Email by OTP
 */
const Verify = ({ verificationType, smallModal }) => {
  const { t } = useTranslation();
  const inputRef = React.useRef();
  const [otp, setOtp] = useState('');
  const [resetTimer, setResetTimer] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  /**
   * side effect to focus the textbox on inital render
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  /**
   * Function to handleChange the value of OTP
   */
  const handleChange = (e) => {
    setOtp(e.target.value.replace(/[^0-9]/gi, ''));
  };

  /**
   * Function to ReSend the OTP to registered email/phone number
   */
  const resendOtp = () => {
    if (sendOtp) {
      authService
        .verifyUser(verificationType)
        .then((response) => {
          if (response) {
            setSendOtp(false);
            setResetTimer(true);
          }
        })
        .catch((err) => {});
    }
  };

  /**
   * Function to verify the enteres OTP is valid or not
   */
  const handleOtpVerification = async (e) => {
    e.preventDefault();
    let userAttribute;

    if (verificationType === 'email') {
      userAttribute = 'email';
    } else {
      userAttribute = 'phone_number';
    }
    try {
      await authService.verifyUserSubmit({ userAttribute, otp });
      await smallModal();
      setShowSuccessMsg(true);
    } catch (error) {
      if (error?.message) {
        setErrMsg(t('OTP-IS-INCORRECT_PLEASE_TRY_AGAIN'));
      }
    }
  };

  /**
   * Function to prevent increment & decrement of value by pressing arrow up/down key
   */
  const handleKeyEvent = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };

  return (
    <>
      {showSuccessMsg ? (
        <div className='verifySuccessMsg'>
          <div className='verifyContainer'>
            <img
              src={completedIcon}
              alt='completedIcon'
              className='verifySuccessIcon'
            />
          </div>
          <div className='verifyContainer'>
            <Typography
              sx={{
                fontWeight: '600',
                color: isDarkMode
                  ? DARK_COLOR.white[100]
                  : LIGHT_COLOR.grey[600],
              }}
            >
              {verificationType === 'email'
                ? t('EMAIL_VERIFIED_SUCCESSFULLY')
                : t('PHONE_VERIFIED_SUCCESSFULLY')}
            </Typography>
          </div>
        </div>
      ) : (
        <form onSubmit={handleOtpVerification} style={{ marginTop: '1.25rem' }}>
          <div className='verifyContainer'>
            <Typography
              sx={{
                marginBottom: '0.625rem',
                fontWeight: '600',
                color: isDarkMode
                  ? DARK_COLOR.white[100]
                  : LIGHT_COLOR.grey[600],
              }}
            >
              {verificationType === 'email'
                ? t('verify-your-email')
                : t('verify-your-phone')}
            </Typography>
          </div>
          <div className='verifyContainer'>
            <Typography
              sx={{
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[400],
                marginBottom: '2.5rem',
              }}
            >
              {verificationType === 'email'
                ? t('we-have-sent-a-6-digit-otp-to-your-email')
                : t('we-have-sent-a-6-digit-otp-to-your-phone')}
            </Typography>
          </div>
          <div className='verifyContainer' style={{ marginBottom: '0.625rem' }}>
            <TextField
              inputRef={inputRef}
              className='verifyTextField'
              label={
                <Typography
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === 'dark'
                        ? DARK_COLOR.grey[400]
                        : LIGHT_COLOR.grey[300],
                  }}
                >
                  {t('enter-6-digit-otp')}
                </Typography>
              }
              variant='outlined'
              name='otp'
              value={otp}
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 6);
              }}
              onKeyUp={handleKeyEvent}
              onKeyDown={handleKeyEvent}
            />
          </div>
          <div className='verifyContainer'>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <div className='verifyCounter'>
                <Timer
                  resetTimer={resetTimer}
                  resetOtp={() => setSendOtp(true)}
                  hideTimer={sendOtp}
                />
              </div>
              <div
                className={
                  sendOtp ? 'verifyResendCode' : 'disableVerifyResendCode'
                }
                disabled={true}
                onClick={resendOtp}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    color: isDarkMode
                      ? DARK_COLOR.primary[50]
                      : LIGHT_COLOR.primary[100],
                  }}
                >
                  {t('cresend-otp')}
                </Typography>
              </div>
            </Grid>
          </div>
          {errMsg && (
            <div className='signupFlexContainer'>
              <p className='error-message'>{errMsg}</p>
            </div>
          )}
          <div className='verifyContainer'>
            <div className='verifyBtn'>
              <Button
                variant='contained'
                type='submit'
                disabled={otp?.length < 6}
              >
                {t('cverify')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default Verify;
