// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mostread-container {
  max-width: 18.75rem;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1.25rem;
  min-height: 19.688rem;
}

.mostread-container > .mostread-title {
  padding: 1.25rem 0.75rem;
  height: 1.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}

.mostread-container > .mostread-innercontainer {
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-left: 1.8rem;
  padding-bottom: 0.625rem;
}

.mostread-headings.title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.mostread-container > .mostread-innercontainer > .mostread-headings {
  margin-right: 0.25rem;
}

@media screen and (max-width: 1280px) {
  .mostread-container {
    max-width: 14.25rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/MostRead/MostRead.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,oBAAoB;EACpB,wBAAwB;AAC1B;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".mostread-container {\n  max-width: 18.75rem;\n  width: 100%;\n  border-radius: 10px;\n  margin-bottom: 1.25rem;\n  min-height: 19.688rem;\n}\n\n.mostread-container > .mostread-title {\n  padding: 1.25rem 0.75rem;\n  height: 1.5rem;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 1.125rem;\n  margin-bottom: 1.25rem;\n}\n\n.mostread-container > .mostread-innercontainer {\n  padding-right: 1.25rem;\n  padding-top: 0.625rem;\n  padding-left: 1.8rem;\n  padding-bottom: 0.625rem;\n}\n\n.mostread-headings.title:hover {\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.mostread-container > .mostread-innercontainer > .mostread-headings {\n  margin-right: 0.25rem;\n}\n\n@media screen and (max-width: 1280px) {\n  .mostread-container {\n    max-width: 14.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
