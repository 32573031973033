import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

/**
 * Make api call for retrieving comments
 * @param {object} data
 * @returns promise
 */

const fetchComments = (data, accessTokenReq = false) => {
  return apiRequest(api.getComments, METHOD.GET, false, data, accessTokenReq)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving comments replies
 * @param {object} data
 * @returns promise
 */

const fetchReplyComment = (data, accessTokenReq = false) => {
  return apiRequest(
    api.getCommentReplys,
    METHOD.GET,
    false,
    data,
    accessTokenReq,
  )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to add reply on comment
 * @param {object} data
 * @returns promise
 */
const addReplyComment = (data) => {
  return apiRequest(api.addReplyComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to add comment
 * @param {object} data
 * @returns promise
 */
const addComment = (data) => {
  return apiRequest(api.addComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to edit comment
 * @param {object} data
 * @returns promise
 */
const editComment = (data) => {
  return apiRequest(api.editComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to edit reply comment
 * @param {object} data
 * @returns promise
 */
const editReplyComment = (data) => {
  return apiRequest(api.replyEditComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to delete comment
 * @param {object} data
 * @returns promise
 */
const deleteComment = (data) => {
  return apiRequest(api.deleteComment, METHOD.DELETE, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to block user
 * @param {object} data
 * @returns promise
 */
const blockUser = (data) => {
  return apiRequest(api.blockUser, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to like comment
 * @param {object} data
 * @returns promise
 */

const likeComment = (data) => {
  return apiRequest(api.likeComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to dislike comment
 * @param {object} data
 * @returns promise
 */

const dislikeComment = (data) => {
  return apiRequest(api.dislikeComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to dislike reply comment
 * @param {object} data
 * @returns promise
 */

const dislikeReplyComment = (data) => {
  return apiRequest(api.dislikeReplyComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to like reply comment
 * @param {object} data
 * @returns promise
 */

const likeReplyComment = (data) => {
  return apiRequest(api.likeReplyComment, METHOD.POST, false, data, true);
};

/**
 * Make api call to delete reply comment
 * @param {object} data
 * @returns promise
 */
const replyDeleteComment = (data) => {
  return apiRequest(api.replyDeleteComment, METHOD.DELETE, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call to report comment
 * @param {object} data
 * @returns promise
 */
const reportComment = (data) => {
  return apiRequest(api.reportComment, METHOD.POST, false, data, true)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const commentService = {
  fetchComments,
  addComment,
  deleteComment,
  addReplyComment,
  fetchReplyComment,
  likeComment,
  dislikeComment,
  likeReplyComment,
  dislikeReplyComment,
  replyDeleteComment,
  editComment,
  editReplyComment,
  blockUser,
  reportComment,
};

export default commentService;
