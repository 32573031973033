import {
  Box,
  Grid,
  styled,
  Typography,
  LinearProgress,
  Divider,
} from '@mui/material';
import { convertSecToMinSec } from '../../../utils/time-format.util';
import { styles } from './trainingStyles';
import bluetickIcon from '../../../assets/icons/trainingTick.svg';
import { TRAINING_VIDEO_STATUS } from '../../../Constants/Constants';
import { viewPort, MaxWidth } from '../../../responsive/viewport.util';
import './training.css';

const Img = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  aspectRatio: '16/9',
  borderRadius: '5px',
});

/**
 * Playist List View Component
 */
function PlaylistListView({
  playlist,
  selectedIndex,
  onChange,
  playlistState,
}) {
  /**
   * we have some video based on id we will get state detail of that media
   * @param {*} mediaId  based on mediaid it will return
   * @returns selected playlist detail
   */
  const isMobile = MaxWidth(viewPort.mobileMax);

  const selectPlaylist = (mediaId) =>
    playlistState?.find((state) => state.video_id === mediaId) || null;

  return (
    <Box>
      {isMobile && <Divider sx={{ margin: '1.1225rem 0' }} />}
      {playlist.map((item, index) => (
        <>
          <Box sx={{ margin: isMobile ? '0 6.25vw' : '' }}>
            <Grid
              key={item.mediaid}
              onClick={() => {
                onChange(item, index);
              }}
              container
              sx={{ padding: isMobile ? '' : '1.125rem', ...styles.listItem }}
            >
              <Grid item>
                <Box sx={styles.thumbnailContainer}>
                  <Img src={item.image} alt='video thumbnail' loading='lazy' />
                  {selectPlaylist(item.mediaid) && (
                    <LinearProgress
                      variant='determinate'
                      value={
                        selectPlaylist(item.mediaid).status ===
                        TRAINING_VIDEO_STATUS.COMPLETED
                          ? 100
                          : (selectPlaylist(item.mediaid).seek_time /
                              item.duration) *
                            100
                      }
                      color='error'
                      sx={{
                        marginTop: '-5px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        width: '98%',
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item direction='column' sx={{ flex: 1 }}>
                <Typography
                  variant='subtitle4'
                  component='div'
                  sx={[
                    styles.playlistItemTitle,
                    {
                      color:
                        selectedIndex === index
                          ? (theme) => theme.palette.primary.main
                          : '',
                    },
                  ]}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant='subtitle4'
                  sx={styles.playlistItemDuration}
                >
                  {convertSecToMinSec(item.duration)}
                </Typography>
              </Grid>
              <Grid>
                {selectPlaylist(item.mediaid) &&
                  selectPlaylist(item.mediaid).status ===
                    TRAINING_VIDEO_STATUS.COMPLETED && (
                    <img
                      src={bluetickIcon}
                      className='minmini-blue-tick-icon-training'
                      alt='minmini-verified-icon'
                    />
                  )}
              </Grid>
            </Grid>
          </Box>
          <Divider
            variant='fullwidth'
            sx={{ margin: isMobile ? '1.1225rem 0' : '' }}
          />
        </>
      ))}
    </Box>
  );
}

export default PlaylistListView;
