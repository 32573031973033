export const DARK_COLOR = {
  primary: {
    100: '#8E3BD9',
    50: 'rgba(142, 59, 217, 0.5)',
    25: 'rgba(142, 59, 217, 0.25)',
  },
  error: {
    100: '#FF5B5C',
    50: 'rgba(255, 91, 92, 0.5)',
    25: 'rgba(255, 91, 92, 0.25)',
  },

  success: {
    100: '#66D4CF',
    50: 'rgba(102, 212, 207, 0.5)',
    25: 'rgba(102, 212, 207, 0.25)',
  },
  grey: {
    100: '#1C1C1C',
    200: '#2C2C2C',
    300: '#484848',
    400: '#8E8E8E',
    500: '#C7C7C7',
    600: '#F2F2F2',
  },
  white: {
    100: '#FFFFFF',
    50: 'rgba(255, 255, 255, 0.5)',
    25: 'rgba(255, 255, 255, 0.25)',
  },
  toaster: {
    error: '#8E3B3C',
    success: '#417875',
    warning: '#8D601E',
  },
  avatar: {
    100: '#FFACAD',
  },

  gradient: {
    flare: 'linear-gradient(88.75deg, #A52729 0.17%, #C26602 103.28%)',
  },

  shadow: {
    100: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
};

export const LIGHT_COLOR = {
  primary: {
    100: '#8E3BD9',
    50: 'rgba(142, 59, 217, 0.5)',
    25: 'rgba(142, 59, 217, 0.25)',
    15: 'rgba(142, 59, 217, 0.15)',
  },
  error: {
    100: '#FF5B5C',
    50: 'rgba(222, 23, 23, 0.5)',
    25: 'rgba(222, 23, 23, 0.25)',
  },
  success: {
    100: '#38BB9C',
    50: 'rgba(56, 187, 156, 0.5)',
    25: 'rgba(56, 187, 156, 0.25)',
  },
  grey: {
    100: '#FBFBFB',
    200: '#E0E0E0',
    300: '#CCCCCC',
    400: '#808080',
    500: '#545454',
    600: '#323232',
  },
  white: {
    100: '#FFFFFF',
    50: 'rgba(255, 255, 255, 0.5)',
    25: 'rgba(255, 255, 255, 0.25)',
    5: 'rgba(41, 41, 41, 0.05)',
  },
  toaster: {
    errorBorder: '#FF5B5C',
    error: '#FCE8E8',
    successBorder: '#38BB9C',
    success: '#31C173',
    warningBorder: '#FEA421',
    warningText: '#CD7F32',
    warning: '#FFF6E9',
  },
  avatar: {
    100: '#FFD6D6',
  },
  gradient: {
    flare: 'linear-gradient(88.75deg, #A52729 0.17%, #C26602 103.28%)',
  },
  shadow: {
    100: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
};

export const MINMINI_V2 = {
  backgroundGradient: {
    minminiPrimaryGrade: { colorfirst: '#6308E8', colorSecond: ' #AD6FD9' },
    darkModeVarientVertical: { colorFirst: '#0F0123', colorSecond: '#1A1120' },
    darkModeVarientHorizontal: {
      colorFirst: '#CEB7EF',
      colorSecond: '#E0D1EB',
    },
    minminiSecGrade: { colorfirst: '#DA9173', colorSecond: ' #DD35AE' },
    astroYellow: { colorfirst: '#FFB800', colorSecond: '#FFF' },
    astroYellowLight: { colorfirst: '#FFB800', colorSecond: '#FFDD85' },
  },
  primary: {
    white: '#f2f2f2',
    black: '#000000',
    lighPurple: '#9F78C3',
    errorRed: '#FF5B5C',
    salmon: '#EA8C72',
    purple: '#8E3BD9',
    offWhite: '#F2E3FF',
    green: '#31C173',
  },
  secondary: {
    voilet: '#19072D',
    brownHue: '#2E2933',
    blueHue: '#20122D',
  },
  grey: {
    bgDark: '#131313',
    grey: '#7F7F7F',
    darkGrey: '#212121',
    lightGrey: '#E7E7E7',
  },
  DarkModeText: {
    font1: '#f2f2f2',
    font2: '#A9A9A9',
  },
  tick: {
    blue: '#2A8BE4',
    voilet: '#CA2AE4',
  },
};

export const MINMINI_V3 = {
  backgroundGradient: {
    minminiPrimaryGrade: { colorfirst: '#6308E8', colorSecond: ' #AD6FD9' },
    darkModeVarientVertical: { colorFirst: '#0F0123', colorSecond: '#1A1120' },
    darkModeVarientHorizontal: {
      colorFirst: '#CEB7EF',
      colorSecond: '#E0D1EB',
    },
    minminiSecGrade: { colorfirst: '#DA9173', colorSecond: ' #DD35AE' },
    astroYellow: { colorfirst: '#FFB800', colorSecond: '#FFF' },
    astroYellowLight: { colorfirst: '#FFB800', colorSecond: '#FFDD85' },
  },
  primary: {
    white: '#f2f2f2',
    black: '#000000',
    lightPurple: '#9F78C3',
    errorRed: '#FF5B5C',
    salmon: '#EA8C72',
    purple: '#8E3BD9',
    offWhite: '#C4C4C4',
    green: '#31C173',
  },
  secondary: {
    voilet: '#19072D',
    brownHue: '#2E2933',
    blueHue: '#20122D',
  },
  grey: {
    lightGrey: '#E0E0E0',
    bgDark: '#131313',
    grey: '#7F7F7F',
    darkGrey: '#212121',
    coolGray: '#111827',
    midGrey: '#555555',
  },
  DarkModeText: {
    font1: '#f2f2f2',
    font2: '#A9A9A9',
  },
  tick: {
    blue: '#2A8BE4',
    voilet: '#CA2AE4',
  },
};
