import React from 'react';
import { Navigate } from 'react-router-dom';

/**
 * PrivateRoute Function
 *  - If user is not loggedIn, Protected URL will naviagte to home page
 *  - If user LoggedIn, Protected URL will navigate to corresponding page
 **/

function PrivateRoute({ children }) {
  const authUser = localStorage.getItem('userID');

  if (!authUser) {
    return <Navigate to='/' />;
  }

  return children;
}

export default PrivateRoute;
