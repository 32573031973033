import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import { ERROR_MSG } from '../../Constants/Constants';

const userId = localStorage.getItem('userID');

const graphQLRequest = (query, variables) => {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(query, variables))
      .then((response) => {
        if (response?.errors?.length && userId) {
          toast.error(ERROR_MSG, {
            toastId: 'graphQLError',
          });
          reject();
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        if (userId) {
          toast.error(ERROR_MSG, {
            toastId: 'graphQLErrorMsg',
          });
          reject(error);
        }
      });
  });
};

export default graphQLRequest;
