import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ArrowLeft from '../../assets/icons/back-arrow.svg';
import ArrowLeftWhite from '../../assets/icons/arrow-left-white.svg';
import { MobileMax, TabletMin } from '../../responsive/breakpoints';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { UserDetailsProvider } from '../../store/user-details-context';
import { DARK_COLOR } from '../../themes/color.constant';
import './Modal.css';

const ModalBox = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const { state: userState } = useContext(UserDetailsProvider);
  const isAuthenticated = userState?.isAuthenticated;

  return ReactDOM.createPortal(
    <div className='rootModal'>
      {/* <LaptopMin>
        <Dialog
          open={props.showModal}
          onClose={props.isBackdropClick ? () => {} : props.closeModal}
          aria-labelledby='responsive-dialog-title'
          aria-describedby='dialog-description'
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '22.5rem',
                height: '100%',
                maxHeight: props.modalSize
                  ? '26.25rem'
                  : props.smallModal
                  ? '22.5rem'
                  : isAuthenticated
                  ? '33rem'
                  : props.minModal
                  ? '30rem'
                  : '35rem',
                marginTop: props.modalSize ? '-0.625rem' : '6rem',
                background: 'unset',
              },
            },
          }}
          className='modal-width'
        >
          <DialogTitle id=' responsive-dialog-title'>
            {!props.isHeaderChange ? (
              !props.backIcon ? (
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={10}>
                    <Box
                      id={isTamil ? 'modalHeaderTamil' : 'modalHeaderEnglish'}
                    >
                      <Typography>{props.modalHeader}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton aria-label='close' onClick={props.closeModal}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={1}>
                    <img
                      src={isDarkMode ? ArrowLeftWhite : ArrowLeft}
                      alt='arrow-left'
                      className='modalArrowLeft'
                      onClick={props.goBack}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <div
                      id={
                        isTamil
                          ? 'appearanceModalHeaderTamil'
                          : 'appearanceModalHeaderEnglish'
                      }
                    >
                      <Typography>{props.modalHeader}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label='close'
                      onClick={props.closeModal}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 13,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid container item xs={12} justifyContent='center'>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent='center'
                  id={isTamil ? 'modalHeaderTamil' : 'modalHeaderEnglish'}
                >
                  <Typography>{props.modalHeader}</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent='center'
                  id='modalContent'
                >
                  <Typography>{props.headerContent}</Typography>
                </Grid>
              </Grid>
            )}
          </DialogTitle>
          <Divider variant='fullWidth' />
          <Scrollbars>
            <DialogContent>
              <DialogContentText id='dialog-description'>
                {console.log('LaptopMin')}
                <Typography>{props.children}</Typography>
              </DialogContentText>
            </DialogContent>
          </Scrollbars>
        </Dialog>
      </LaptopMin> */}
      <TabletMin>
        <Dialog
          open={props.showModal}
          onClose={props.isBackdropClick ? () => {} : props.closeModal}
          aria-labelledby='responsive-dialog-title'
          aria-describedby='dialog-description'
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: props.isCaptcha ? '25rem' : '22.5rem',
                height: '100%',
                maxHeight: props.modalSize
                  ? '26.25rem'
                  : props.smallModal
                  ? '22.5rem'
                  : isAuthenticated
                  ? '33rem'
                  : props.minModal
                  ? '30rem'
                  : '35rem',
                marginTop: props.modalSize ? '-0.625rem' : '6rem',
                backgroundColor: props.isCaptcha && DARK_COLOR.white[100],
              },
            },
          }}
          className='modal-width'
        >
          <DialogTitle
            id='responsive-dialog-title'
            sx={{
              m: 0,
              p: 2,
              fontWeight: '600',
              fontSize: '1.125rem',
              lineHeight: '1.5rem',
              padding: '1.25rem',
              textTransform: 'capitalize',
              backgroundColor:
                props.isCaptcha && isDarkMode && DARK_COLOR.grey[200],
            }}
          >
            {!props.isHeaderChange ? (
              !props.backIcon ? (
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={10}>
                    <Typography
                      id={isTamil ? 'modalHeaderTamil' : 'modalHeaderEnglish'}
                    >
                      {props.modalHeader}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div>
                      <IconButton
                        aria-label='close'
                        onClick={props.closeModal}
                        sx={{ position: 'absolute', right: 8, top: 13 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction='row'>
                  <Grid item xs={1}>
                    <img
                      src={isDarkMode ? ArrowLeftWhite : ArrowLeft}
                      alt='arrow-left'
                      className='modalArrowLeft'
                      onClick={props.goBack}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      id={
                        isTamil
                          ? 'appearanceModalHeaderTamil'
                          : 'appearanceModalHeaderEnglish'
                      }
                    >
                      {props.modalHeader}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ marginLeft: '1.3rem' }}>
                      <IconButton
                        aria-label='close'
                        onClick={props.closeModal}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 13,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid container item xs={12} justifyContent='center'>
                <Grid container item xs={12} justifyContent='center'>
                  <Typography
                    id={isTamil ? 'modalHeaderTamil' : 'modalHeaderEnglish'}
                  >
                    {props.modalHeader}
                  </Typography>
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <Typography id='modalContent'>
                    {props.headerContent}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </DialogTitle>
          <Divider variant='fullWidth' />
          <Scrollbars>
            <DialogContent>
              <DialogContentText id='dialog-description'>
                {props.children}
              </DialogContentText>
            </DialogContent>
          </Scrollbars>
        </Dialog>
      </TabletMin>
      <MobileMax>
        <Dialog
          fullScreen={fullScreen}
          open={props.showModal}
          onClose={props.isBackdropClick ? () => {} : props.closeModal}
          aria-labelledby='responsive-dialog-title'
          sx={{
            background: 'unset',
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                backgroundColor: props.isCaptcha && DARK_COLOR.white[100],
              },
            },
          }}
        >
          <DialogTitle
            id='responsive-dialog-title'
            sx={{
              backgroundColor:
                props.isCaptcha && isDarkMode && DARK_COLOR.grey[200],
            }}
          >
            {!props.isHeaderChange ? (
              !props.backIcon ? (
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography
                    id={isTamil ? 'modalHeaderTamil' : 'modalHeaderEnglish'}
                  >
                    {props.modalHeader}
                  </Typography>
                  <IconButton
                    aria-label='close'
                    onClick={props.closeModal}
                    sx={{
                      position: 'absolute',
                      right: 10,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ) : (
                <Grid container direction='row'>
                  <Grid item xs={1}>
                    <img
                      src={isDarkMode ? ArrowLeftWhite : ArrowLeft}
                      alt='arrow-left'
                      className='modalArrowLeft'
                      onClick={props.goBack}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      id={
                        isTamil
                          ? 'appearanceModalHeaderTamil'
                          : 'appearanceModalHeaderEnglish'
                      }
                    >
                      {props.modalHeader}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label='close'
                      onClick={props.closeModal}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 13,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid container item xs={12} justifyContent='center'>
                <Grid container item xs={12} justifyContent='center'>
                  <Typography
                    id={isTamil ? 'modalHeaderTamil' : 'modalHeaderEnglish'}
                  >
                    {props.modalHeader}
                  </Typography>
                </Grid>
                <Typography id='modalContent'>{props.headerContent}</Typography>
              </Grid>
            )}
          </DialogTitle>
          <Divider variant='fullWidth' />
          <Scrollbars>
            <DialogContent>
              <DialogContentText>{props.children}</DialogContentText>
            </DialogContent>
          </Scrollbars>
        </Dialog>
      </MobileMax>
    </div>,
    document.getElementById('modal-root'),
  );
};

export default ModalBox;
