import { SvgIcon } from '@mui/material';

const ThumbDownIcon = ({ styleProps, fill, stroke, fontSize }) => {
  return (
    <SvgIcon
      width='13'
      height='26'
      viewBox='0 0 13 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ ...styleProps, fontSize }}
    >
      <g filter='url(#filter0_d_19097_7302)'>
        <path
          d='M6.52334 3.38883C6.07035 3.38883 5.70312 3.0259 5.70313 2.5782C5.70313 2.13051 6.07035 1.76758 6.52334 1.76758C6.97634 1.76758 7.34356 2.13051 7.34356 2.5782C7.34356 3.0259 6.97634 3.38883 6.52334 3.38883Z'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          shape-rendering='crispEdges'
          fill={fill}
          stroke={stroke}
        />
        <path
          d='M6.52334 9.87382C6.07035 9.87382 5.70312 9.51089 5.70313 9.06319C5.70313 8.6155 6.07035 8.25257 6.52334 8.25257C6.97634 8.25257 7.34356 8.6155 7.34356 9.06319C7.34356 9.51089 6.97634 9.87382 6.52334 9.87382Z'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          shape-rendering='crispEdges'
          fill={fill}
          stroke={stroke}
        />
        <path
          d='M6.52334 16.3588C6.07035 16.3588 5.70312 15.9959 5.70313 15.5482C5.70313 15.1005 6.07035 14.7376 6.52334 14.7376C6.97634 14.7376 7.34356 15.1005 7.34356 15.5482C7.34356 15.9959 6.97634 16.3588 6.52334 16.3588Z'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          shape-rendering='crispEdges'
          fill={fill}
          stroke={stroke}
        />
      </g>
      <defs>
        <filter
          id='filter0_d_19097_7302'
          x='0.703125'
          y='0.767578'
          width='11.6406'
          height='24.5908'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_19097_7302'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_19097_7302'
            result='shape'
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};
export default ThumbDownIcon;
