import React, { forwardRef } from 'react';

import ShareButton from 'react-share/es/ShareButton';

/**
 *
 * @param {string} network via which it is going to be share
 * @param {string} link of the social media app
 * @param {*} props example title separator mobile app link
 * @param {props} props width and height and other parameter of new window
 * @returns {component}
 */
function createShareButton(networkName, link, optsMap, defaultProps) {
  function CreatedButton(props, ref) {
    const opts = optsMap(props);
    const passedProps = { ...props };

    // remove keys from passed props that are passed as opts
    const optsKeys = Object.keys(opts);
    optsKeys.forEach((key) => {
      delete passedProps[key];
    });

    return (
      <ShareButton
        {...defaultProps}
        {...passedProps}
        forwardedRef={ref}
        networkName={networkName}
        networkLink={link}
        opts={optsMap(props)}
      />
    );
  }

  CreatedButton.displayName = `ShareButton-${networkName}`;

  return forwardRef(CreatedButton);
}

export default createShareButton;
