import { SvgIcon } from '@mui/material';

const CloseIcon = ({ styleProps, fill, fontSize }) => {
  return (
    <SvgIcon
      width='18'
      height='14'
      viewBox='0 0 18 14'
      fill='none'
      sx={{ ...styleProps, fontSize }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.28033 0.21967C7.57322 0.512563 7.57322 0.987437 7.28033 1.28033L2.56066 6H16.75C17.1642 6 17.5 6.33579 17.5 6.75C17.5 7.16421 17.1642 7.5 16.75 7.5H2.56066L7.28033 12.2197C7.57322 12.5126 7.57322 12.9874 7.28033 13.2803C6.98744 13.5732 6.51256 13.5732 6.21967 13.2803L0.21967 7.28033C-0.0732233 6.98744 -0.0732233 6.51256 0.21967 6.21967L6.21967 0.21967C6.51256 -0.0732233 6.98744 -0.0732233 7.28033 0.21967Z'
        fill={fill}
      />
    </SvgIcon>
  );
};

export default CloseIcon;
