import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import backIcon from '../../assets/icons/back-arrow.svg';
import backIconDark from '../../assets/icons/arrow-left-white.svg';
import radioButton from '../../assets/icons/radio-btn.svg';
import radioButtonDark from '../../assets/icons/radio-btn-dark.svg';
import ActiveRadioButton from '../../assets/icons/active-radioBtn.svg';
import ActiveRadioButtonDark from '../../assets/icons/active-radioBtn-dark.svg';
import CorrectAnswerIcon from '../../assets/icons/correct-answer.svg';
import CorrectAnswerDarkIcon from '../../assets/icons/correct-answer-dark.svg';
import WrongAnswerIcon from '../../assets/icons/wrong-answer.svg';
import WrongAnswerDarkIcon from '../../assets/icons/wrong-answer-dark.svg';
import ActivePreviousArrow from '../../assets/icons/active-previous-arrow.svg';
import InActivePreviousArrow from '../../assets/icons/inactive-previous-arrow.svg';
import ActiveNextArrow from '../../assets/icons/active-next-arrow.svg';
import InActiveNextArrow from '../../assets/icons/inactive-next-arrow.svg';
import NotAnsweredIcon from '../../assets/icons/not-answered.svg';
import NotAnsweredDarkIcon from '../../assets/icons/not-answered-dark.svg';
import CorrectIcon from '../../assets/icons/green-correct.svg';
import CorrectDarkIcon from '../../assets/icons/green-correct-dark.svg';
import InCorrectIcon from '../../assets/icons/red-incorrect.svg';
import InCorrectDarkIcon from '../../assets/icons/red-incorrect-dark.svg';
import ModalBox from '../Modal/Modal';
import {
  ONE,
  SUBMIT,
  ABORT,
  INITIAL_VALUE,
  TRUE,
  ASSESSMENT_URL,
} from '../../Constants/Constants';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import AssessmentMobile from './AssessmentMobile';
import './Assessment.css';
import { useLocation, useNavigate } from 'react-router-dom';
import userProfileService from '../../services/user-profile.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import assessmentService from '../../services/assessment.service';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { AppContext } from '../../store/app-context';
import { EnvironmentProvider } from '../../store/firebase-context';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import { META_TAG_TITLE } from '../../Constants/meta.title.constant';
import ButtonLoader from '../buttonLoader/ButtonLoader';
import AssessmentResults from './AssessmentResults';

const Assessment = () => {
  const dispatchMetaAction = useMetaStore()[1];
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const [assesmentData, setAssessmentData] = useState([]);
  const [displayOrder, setDisplayOrder] = useState(ONE);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [assessmentId, setAssessmentId] = useState('');
  const [answerCount, setAnswerCount] = useState(INITIAL_VALUE);
  const [showBadgeScreen, setShowBadgeScreen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [callUpdate, setCallUpdate] = useState(false);
  const [percentage, setPercentage] = useState();
  const [resumeOrder, setResumeOrder] = useState(true);
  const isMobileMax = MaxWidth(viewPort.mobile);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { environment } = useContext(EnvironmentProvider);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [restartLoader, setRestartLoader] = useState(false);
  const [submitAssesmentLoader, setSubmitAssesmentLoader] = useState(false);

  const { state: userState } = useContext(UserDetailsProvider);
  const userId =
    userState?.userDetails?.userId || localStorage.getItem('userID');
  const citizenJournalist = userState?.citizenJournalist;
  const { state: appCtxState, dispatch: appCtxDispatch } =
    useContext(AppContext);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    appCtxDispatch({ type: 'SHOW_EXIT_POPUP' });
  };

  /**
   * Side Effect to Call PercentCheck function after getting answer count
   */
  useEffect(() => {
    if (answerCount !== 0) {
      percentCheck();
    }
  }, [answerCount]);

  useEffect(() => {
    const metaObj = {
      title: META_TAG_TITLE.assessment,
      description: META_TAG_TITLE.assessment,
      keywords: '',
      canonical_url: location.pathname,
    };
    dispatchMetaAction(metaAction.updateMeta, metaObj);
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    assesmentAPI();
  }, []);

  useEffect(() => {
    callUpdate && UpdatePreferences();

    return () => {
      setCallUpdate(false);
    };
  }, [callUpdate]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  /**
   * Function to Check the Assessment Percentage
   */
  const percentCheck = () => {
    let totalQuestion = assesmentData?.length;
    const percentage = (answerCount / totalQuestion) * 100;
    setPercentage(percentage);
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const UpdatePreferences = async () => {
    try {
      if (percentage >= environment.assessment_pass_percentage) {
        await userProfileService.updateUserProfile({
          userId: userId ? userId : localStorage.getItem('userID'),
          citizenJournalist: JSON.stringify({
            ...citizenJournalist,
            isAssessmentDone: TRUE,
          }),
        });
      }
    } catch (error) {}
  };

  /**
   * Function to get assessment
   */
  const assesmentAPI = async (resumeOrderNotReq = false) => {
    try {
      const response = await assessmentService.getAssessment({
        userId: userId,
      });
      setAssessmentData(response?.data);
      setAssessmentId(response?.assessmentId);
      const resumeQuestion = response?.data.find(
        (resume) => resume.selectedOptionId === null,
      );

      if (!resumeOrderNotReq && resumeOrder === true) {
        setDisplayOrder(resumeQuestion?.displayOrder);
      }
    } catch (error) {}
  };

  const onPreviousClick = () => {
    setDisplayOrder((prev) => prev - ONE);
    setSelectedOptionId(null);
  };

  const onNextClick = () => {
    setDisplayOrder((prev) => prev + ONE);
    setSelectedOptionId(null);
  };

  const onOptionClick = (id) => setSelectedOptionId(id);

  /**
   * Function to Check Answer
   */
  const onAnswerSubmit = async () => {
    let answerId =
      assesmentData &&
      assesmentData
        .filter(
          (assessmentData) => assessmentData.displayOrder === displayOrder,
        )
        .map((answer) => answer.answer);
    let validateAnswer = answerId.includes(selectedOptionId);
    let questionId =
      assesmentData &&
      assesmentData
        .filter(
          (assessmentData) => assessmentData.displayOrder === displayOrder,
        )
        .map((id) => id.questionId);
    setSubmitLoader(true);
    try {
      const response = await assessmentService.saveAnswer({
        assessmentId: assessmentId,
        questionId: questionId.values().next().value,
        selectedOptionId: selectedOptionId,
        isCorrect: validateAnswer,
      });
      setSubmitLoader(false);
      response && setResumeOrder(false);
      assesmentAPI(true);
      setSelectedOptionId(null);
    } catch (error) {
      setSubmitLoader(false);
    }
  };

  /**
   * Function to Submit assessment
   */
  const submitAssesment = async () => {
    const count = assesmentData.filter(
      (assesment) => assesment.isCorrect === true,
    ).length;
    setAnswerCount(count);
    setSubmitAssesmentLoader(true);
    try {
      const response = await assessmentService.submitAssessment({
        assessmentId: assessmentId,
        status: SUBMIT,
      });
      setSubmitAssesmentLoader(false);
      response && setShowBadgeScreen(true);
      setShowConfirmModal(false);
      navigate(ASSESSMENT_URL, { state: true });
      setCallUpdate(true);
    } catch (error) {
      setSubmitAssesmentLoader(false);
    }
  };

  /**
   * Function to Restart assessment
   */
  const restartAssesment = async () => {
    setRestartLoader(true);
    try {
      const response = await assessmentService.submitAssessment({
        assessmentId: assessmentId,
        status: ABORT,
      });
      setRestartLoader(false);
      response && assesmentAPI();
      setDisplayOrder(ONE);
      appCtxDispatch({ type: 'HIDE_EXIT_POPUP' });
    } catch (error) {
      setRestartLoader(false);
    }
  };

  return (
    <div>
      {isMobileMax ? (
        <AssessmentMobile />
      ) : (
        <>
          {!showBadgeScreen ? (
            <>
              <Box sx={{ padding: '0 4.666vw' }}>
                <Box sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
                  <Box className='assesmentTitleSection'>
                    <div className='assesmentTitleMd'>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant='h5'>
                          {t('become-a-reporter')}
                        </Typography>
                      </Box>
                      <div
                        className='assesmentSubTitleMd'
                        onClick={() =>
                          appCtxDispatch({ type: 'SHOW_EXIT_POPUP' })
                        }
                      >
                        <img
                          src={isDarkMode ? backIconDark : backIcon}
                          alt='left-arrow'
                          className='backIcon'
                        />
                        <Typography
                          component='span'
                          variant='h5'
                          sx={{
                            fontSize: '1.125rem',
                            fontStyle: 'normal',
                            marginLeft: '0.625rem',
                          }}
                        >
                          {t('ASSESSMENT')}
                        </Typography>
                      </div>
                    </div>
                  </Box>
                  <Box className='assesmentContentSection'>
                    <Box
                      className='assesmentLeftBox'
                      sx={{
                        background: isDarkMode
                          ? DARK_COLOR.grey[200]
                          : LIGHT_COLOR.white[100],
                        border: isDarkMode
                          ? `0.063rem solid ${DARK_COLOR.grey[300]}`
                          : `0.063rem solid ${LIGHT_COLOR.grey[200]}`,
                      }}
                    >
                      <div className='assementQuestion'>
                        <Typography
                          variant='h5'
                          sx={{ fontSize: '0.875rem', fontStyle: 'normal' }}
                        >
                          {' '}
                          {displayOrder}.{' '}
                          {assesmentData &&
                            assesmentData
                              ?.filter(
                                (item) => item?.displayOrder === displayOrder,
                              )
                              ?.map((g) => g?.question)}
                        </Typography>
                      </div>
                      {assesmentData
                        ?.filter(
                          (assesment) =>
                            assesment?.displayOrder === displayOrder,
                        )
                        ?.map((assessment, index) => {
                          return (
                            <div
                              key={index}
                              className='assessmentAnswerSection'
                            >
                              {assessment?.options?.map(
                                (assessmentOption, index) => {
                                  return (
                                    <div
                                      className='assementOptionSection'
                                      key={index}
                                      onClick={() =>
                                        assessment?.selectedOptionId === null
                                          ? onOptionClick(
                                              assessmentOption?.optionId,
                                            )
                                          : undefined
                                      }
                                    >
                                      <img
                                        src={
                                          assessment?.isCorrect &&
                                          assessment?.selectedOptionId ===
                                            assessmentOption?.optionId
                                            ? isDarkMode
                                              ? CorrectAnswerDarkIcon
                                              : CorrectAnswerIcon
                                            : !assessment?.isCorrect &&
                                              assessment?.selectedOptionId ===
                                                assessmentOption?.optionId
                                            ? isDarkMode
                                              ? WrongAnswerDarkIcon
                                              : WrongAnswerIcon
                                            : !assessment?.isCorrect &&
                                              assessment?.selectedOptionId &&
                                              assessment.answer ===
                                                assessmentOption?.optionId
                                            ? isDarkMode
                                              ? CorrectAnswerDarkIcon
                                              : CorrectAnswerIcon
                                            : selectedOptionId ===
                                              assessmentOption?.optionId
                                            ? isDarkMode
                                              ? ActiveRadioButtonDark
                                              : ActiveRadioButton
                                            : isDarkMode
                                            ? radioButtonDark
                                            : radioButton
                                        }
                                        alt='radio-btn'
                                        className='assessmentRadioBtn'
                                      />{' '}
                                      <span
                                        className={
                                          assessment?.isCorrect &&
                                          assessment?.selectedOptionId ===
                                            assessmentOption?.optionId
                                            ? 'assementCorrectAnswer'
                                            : !assessment?.isCorrect &&
                                              assessment?.selectedOptionId ===
                                                assessmentOption?.optionId
                                            ? 'assementWrongAnswer'
                                            : !assessment?.isCorrect &&
                                              assessment?.selectedOptionId &&
                                              assessment.answer ===
                                                assessmentOption?.optionId
                                            ? 'assementCorrectAnswer'
                                            : 'assementOptions'
                                        }
                                      >
                                        <Typography
                                          component='span'
                                          sx={{
                                            color: isDarkMode
                                              ? DARK_COLOR.grey[600]
                                              : LIGHT_COLOR.grey[500],
                                          }}
                                        >
                                          {assessmentOption.optionId}
                                          {'. '}
                                          {assessmentOption?.option}
                                        </Typography>
                                      </span>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          );
                        })}
                      <div className='assementCheckBtn'>
                        <ButtonLoader
                          disabled={selectedOptionId === null ? true : false}
                          variant='outlined'
                          onClick={onAnswerSubmit}
                          loading={submitLoader}
                        >
                          {t('CHECK_ANSWER')}
                        </ButtonLoader>
                      </div>
                      <div className='assementFooter'>
                        <div className='footerContent'>
                          <div
                            className={
                              displayOrder !== ONE
                                ? 'activeAssementFooterAction'
                                : 'inactiveAssementFooterAction'
                            }
                            onClick={
                              displayOrder !== ONE ? onPreviousClick : undefined
                            }
                          >
                            <img
                              src={
                                displayOrder !== ONE
                                  ? ActivePreviousArrow
                                  : InActivePreviousArrow
                              }
                              alt='previous-btn'
                              className='previousIcon'
                            />{' '}
                            <span>{t('previous')}</span>
                          </div>
                          <div
                            className={
                              displayOrder !== assesmentData?.length
                                ? 'activeAssementFooterAction'
                                : 'inactiveAssementFooterAction'
                            }
                            onClick={
                              displayOrder !== assesmentData?.length
                                ? onNextClick
                                : undefined
                            }
                          >
                            <span>{t('next')}</span>{' '}
                            <img
                              src={
                                displayOrder !== assesmentData?.length
                                  ? ActiveNextArrow
                                  : InActiveNextArrow
                              }
                              alt='previous-btn'
                              className='nextIcon'
                            />
                          </div>
                        </div>
                      </div>
                    </Box>
                    <Box
                      className='assesmentRightBox'
                      sx={{
                        background: isDarkMode
                          ? DARK_COLOR.grey[200]
                          : LIGHT_COLOR.white[100],
                        border: isDarkMode
                          ? `0.063rem solid ${DARK_COLOR.grey[300]}`
                          : `0.063rem solid ${LIGHT_COLOR.grey[200]}`,
                      }}
                    >
                      <div>
                        <ul className='assementQuestionContainer'>
                          {assesmentData?.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className='assementQuestionsList'
                                onClick={() =>
                                  setDisplayOrder(item?.displayOrder)
                                }
                              >
                                <Box
                                  className={
                                    item?.isCorrect
                                      ? 'assesementCorrectAnswer'
                                      : item?.isCorrect === false
                                      ? 'assesementWrongAnswer'
                                      : 'assesementNotAnswered'
                                  }
                                  sx={{
                                    background: item?.isCorrect
                                      ? isDarkMode
                                        ? DARK_COLOR.success[100]
                                        : LIGHT_COLOR.success[100]
                                      : item?.isCorrect === false
                                      ? isDarkMode
                                        ? DARK_COLOR.error[100]
                                        : LIGHT_COLOR.error[100]
                                      : isDarkMode
                                      ? ''
                                      : LIGHT_COLOR.white[100],
                                  }}
                                >
                                  {item?.displayOrder}
                                </Box>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div
                        className={
                          assesmentData?.length === 20
                            ? 'assementDetails'
                            : 'assessmentDetailsTen'
                        }
                      >
                        <Grid
                          container
                          direction='row'
                          justifyContent='space-evenly'
                        >
                          <div className='assementDetailName'>
                            <img
                              src={isDarkMode ? CorrectDarkIcon : CorrectIcon}
                              alt='correct'
                            />
                            <Typography
                              component='span'
                              sx={{
                                fontSize: '0.75rem',
                                color: isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[400],
                              }}
                            >
                              {t('CORRECT')}
                            </Typography>
                          </div>
                          <div className='assementDetailName'>
                            <img
                              src={
                                isDarkMode ? InCorrectDarkIcon : InCorrectIcon
                              }
                              alt='correct'
                            />
                            <Typography
                              component='span'
                              sx={{
                                fontSize: '0.75rem',
                                color: isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[400],
                              }}
                            >
                              {t('IN_CORRECT')}
                            </Typography>
                          </div>
                          <div className='assementDetailName'>
                            <img
                              src={
                                isDarkMode
                                  ? NotAnsweredDarkIcon
                                  : NotAnsweredIcon
                              }
                              alt='correct'
                            />
                            <Typography
                              component='span'
                              sx={{
                                fontSize: '0.75rem',
                                color: isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[400],
                              }}
                            >
                              {t('NOT_ANSWERED')}
                            </Typography>
                          </div>
                        </Grid>
                      </div>
                      <div className='assementBtnSection'>
                        <div className='submitAssesment'>
                          <Button
                            variant='contained'
                            onClick={() => setShowConfirmModal(true)}
                          >
                            {t('SUBMIT_ASSESSMENT')}
                          </Button>
                        </div>
                        <div className='restartAssesment'>
                          <ButtonLoader
                            variant='outlined'
                            loading={restartLoader}
                            onClick={restartAssesment}
                          >
                            {t('RESTART_ASSESSMENT')}
                          </ButtonLoader>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <AssessmentResults
              percentage={percentage}
              answerCount={answerCount}
              assesmentData={assesmentData}
              setShowBadgeScreen={setShowBadgeScreen}
              setDisplayOrder={setDisplayOrder}
              assesmentAPI={assesmentAPI}
            />
          )}
        </>
      )}
      <ModalBox
        showModal={showConfirmModal}
        closeModal={() => setShowConfirmModal(false)}
        modalHeader={t('CONFIRM_SUBMIT')}
        modalSize={true}
        isBackdropClick={true}
      >
        <Typography
          sx={{
            color: isDarkMode ? DARK_COLOR.grey[600] : LIGHT_COLOR.grey[400],
          }}
          className='assessmentModalContent'
        >
          {t('ASSESSMENT_MODAL_CONTENT')}
        </Typography>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignContent={'center'}
          style={{ marginTop: '4rem' }}
        >
          <div className='confirmSubmitAssesment'>
            <ButtonLoader
              variant='contained'
              loading={submitAssesmentLoader}
              onClick={submitAssesment}
            >
              {t('YES_SUBMIT')}
            </ButtonLoader>
          </div>
          <div className='goBackToAssessment'>
            <Button
              variant='outlined'
              onClick={() => setShowConfirmModal(false)}
            >
              {t('NO_GO_BACK')}
            </Button>
          </div>
        </Grid>
      </ModalBox>

      <ModalBox
        showModal={appCtxState?.showExitModal}
        closeModal={() => appCtxDispatch({ type: 'HIDE_EXIT_POPUP' })}
        modalHeader={t('exit-assessment')}
        modalSize={true}
        isBackdropClick={true}
      >
        <Typography
          sx={{
            color: isDarkMode ? DARK_COLOR.grey[600] : LIGHT_COLOR.grey[400],
          }}
          className='assessmentModalContent'
        >
          {t('exit-assessment-content')}
        </Typography>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignContent={'center'}
        >
          <div className='confirmSubmitAssesment'>
            <Button
              variant='contained'
              onClick={() => appCtxDispatch({ type: 'HIDE_EXIT_POPUP' })}
            >
              {t('no-resume')}
            </Button>
          </div>
          <div className='goBackToAssessment'>
            <Button
              variant='outlined'
              onClick={() => {
                appCtxDispatch({ type: 'HIDE_EXIT_POPUP' });
                navigate('/');
              }}
            >
              {t('resume-later')}
            </Button>
          </div>
          <div className='goBackToAssessment'>
            <ButtonLoader
              variant='outlined'
              loading={restartLoader}
              onClick={restartAssesment}
            >
              {t('RESTART_ASSESSMENT')}
            </ButtonLoader>
          </div>
          <div className='goBackToAssessment'>
            <Button
              variant='outlined'
              onClick={() => {
                appCtxDispatch({ type: 'HIDE_EXIT_POPUP' });
                navigate('/training');
              }}
            >
              {t('training-material')}
            </Button>
          </div>
        </Grid>
      </ModalBox>
    </div>
  );
};

export default Assessment;
