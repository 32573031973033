import React, { useContext, useEffect, useState } from 'react';
import DraftsNewsCard from './DraftsNewsCard';
import { Box, Alert, Typography, Paper } from '@mui/material';
import { PENDING, TEN } from '../../Constants/Constants';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import { useTranslation } from 'react-i18next';
import draftService from '../../services/drafts.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import ReportForm from '../CreateAReport/ReportForm/ReportForm';
import { TabletMin } from '../../responsive/breakpoints';
import { MaxWidth, viewPort, MinWidth } from '../../responsive/viewport.util';
import RightArrowIcon from '../../assets/icons/right-arrow.svg';
import RightArrowDarkIcon from '../../assets/icons/arrow-right-white.svg';
import appService from '../../services/app.service';
import { LargeScreenMin } from '../../responsive/breakpoints';
import { EnvironmentProvider } from '../../store/firebase-context';
import { convert } from '../../utils/html-to-string.util';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Drafts Component
 */
const Drafts = ({ navBarCategories, navBarFilters }) => {
  const isMobile = MaxWidth(viewPort.mobileMax);
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  const { environment } = useContext(EnvironmentProvider);
  const [draftsData, setDraftsData] = useState([]);
  const [isEditDrafts, setIsEditDrafts] = useState(false);
  const [dataToBeEdited, setDataToBeEdited] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [draftCount, setDraftCount] = useState(0);
  const { t } = useTranslation();
  const { state: userState } = useContext(UserDetailsProvider);
  const newsSessionID = userState?.sessionId;
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [district, setDistrict] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [stopFetchDistrict, setStopFetchDistrict] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleStopFetchDistrict = () => {
    setStopFetchDistrict(true);
  };

  /**
   * use for checking location is there or not on every 5 sec
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (!(district || stopFetchDistrict)) {
        navigator.geolocation.getCurrentPosition(function (position) {
          fetchDistrict(position.coords.latitude, position.coords.longitude);
        });
      }
    }, 5 * 1000);

    return () => clearInterval(interval);
  }, [district]);

  /**
   * fterching district with third party api
   * @param {string} lat lattitude
   * @param {string} long longitutde
   */

  const fetchDistrict = (lat, long) => {
    if (lat && long) {
      appService.getCurrentCity({ lat, long }).then((data) => {
        setDistrict(
          data?.address?.city || data?.address?.state_district
            ? (data?.address?.state_district).replace('District', '').trim()
            : '',
        );
        setLatitude(lat);
        setLongitude(long);
      });
    }
  };

  useEffect(() => {
    getDrafts();
  }, [newsSessionID]);

  /**
   * Getting the Feed with pending state i.e. saved drafts
   */
  const getDrafts = async () => {
    try {
      const response = await draftService.draftList({
        sessionId: newsSessionID,
        state: PENDING,
      });
      if (response) {
        setDraftsData(response);
        setDraftCount(response.news?.length);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Setting the state for Edit Draft Component.
   */
  const displayEdit = (dataToBeEdited) => {
    setIsEditDrafts(true);
    setDataToBeEdited(dataToBeEdited);
  };

  /**
   * Storing DraftsNewsCard in draftsNewsData and passing the props
   */
  const draftsNewsData = !isLoading && draftCount && (
    <>
      <LargeScreenMin>
        <Box
          sx={{
            mb: '1rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (location.key !== 'default') {
              navigate(-1);
            } else {
              navigate('/', { replace: true });
            }
          }}
        >
          <Typography
            variant='h5'
            sx={{
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.grey[600],
              mb: '1rem',
              display: 'inline',
              fontSize: '1.125rem',
            }}
          >
            &lt;&nbsp;{t('drafts')}
          </Typography>
          <Typography
            variant='h5'
            sx={{
              color: isDarkMode
                ? (theme) => theme.palette.grey[600]
                : (theme) => theme.palette.grey[400],
              mb: '1rem',
              marginLeft: '0.5rem',
              display: 'inline',
              fontSize: '1.125rem',
            }}
          >
            ({draftCount}/{TEN})
          </Typography>
        </Box>
      </LargeScreenMin>

      {draftCount >= environment.draft_max_count ? (
        <Alert
          variant={isDarkMode ? 'filled' : 'outlined'}
          severity='error'
          icon={<WarningIcon fontSize='1.875rem' />}
          sx={{
            borderRadius: '0.625rem',
            borderColor: (theme) => theme.palette.toaster.errorBorder,
            mb: 2,
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.toaster.error,
            '& .MuiAlert-message': {
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.toaster.errorBorder,
              fontSize: '1rem',
            },
            '& .MuiAlert-icon': {
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.toaster.errorBorder,
              fontSize: '1.875rem',
            },
          }}
        >
          {t('your-drafts-are-full')}
        </Alert>
      ) : draftCount >= environment.drafts_warning_limit ? (
        <Alert
          variant={isDarkMode ? 'filled' : 'outlined'}
          severity='warning'
          icon={<ErrorIcon fontSize='1.875rem' />}
          sx={{
            borderRadius: '0.625rem',
            borderColor: (theme) => theme.palette.toaster.warningBorder,
            mb: 2,
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.toaster.warning,
            '& .MuiAlert-message': {
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.toaster.warningText,
              fontSize: '1rem',
            },
            '& .MuiAlert-icon': {
              color: isDarkMode
                ? (theme) => theme.palette.white[100]
                : (theme) => theme.palette.toaster.warningBorder,
              fontSize: '1.875rem',
            },
          }}
        >
          {t('your-drafts-are-about-to-reach-the-limit')}
        </Alert>
      ) : null}
      {draftsData?.news?.map((draft) => (
        <DraftsNewsCard
          key={draft.feedId}
          feedID={draft.feedId}
          heading={convert(draft.heading)}
          media={draft.mediaIds}
          location={draft.location}
          story={draft.story}
          categoryID={draft.categoryId}
          categoryName={draft.category_name}
          date={draft.date_created}
          onDelete={getDrafts}
          onEdit={displayEdit}
        />
      ))}
    </>
  );

  /**
   * Storing Edit Draft Component in editDraftsData and passing the props
   */
  const editDraftsData = (
    <Box>
      <TabletMin>
        <Box
          sx={{
            marginBottom: '1rem',
            display: 'flex',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: isDarkMode ? '#FFFFFF' : (theme) => theme.palette.grey[400],
          }}
        >
          <Typography
            sx={{ marginRight: '1rem', cursor: 'pointer' }}
            onClick={() => {
              setIsEditDrafts(false);
              getDrafts();
            }}
          >
            {t('drafts')}
          </Typography>
          <img
            src={isDarkMode ? RightArrowDarkIcon : RightArrowIcon}
            alt='right arrow icon'
            style={{ marginRight: '1rem' }}
          />
          <Typography>{t('edit-draft')}</Typography>
        </Box>
      </TabletMin>
      {isMobile ? (
        <ReportForm
          stopFetchDistrict={handleStopFetchDistrict}
          district={district}
          point_lat={latitude}
          point_long={longitude}
          configCategories={navBarCategories}
          isFromDraft={true}
          data={dataToBeEdited}
          goToDrafts={() => {
            setIsEditDrafts(false);
            getDrafts();
          }}
        />
      ) : (
        <Paper sx={{ flex: 1, marginBottom: '5rem' }}>
          <ReportForm
            stopFetchDistrict={handleStopFetchDistrict}
            district={district}
            point_lat={latitude}
            point_long={longitude}
            configCategories={navBarCategories}
            isFromDraft={true}
            data={dataToBeEdited}
            goToDrafts={() => {
              setIsEditDrafts(false);
              getDrafts();
            }}
          />
        </Paper>
      )}
    </Box>
  );

  /**
   * Conditionally rendering Empty screen if there are no drafts available and If available then either
   * displaying existing drafts or Edit draft component
   */
  return (
    <>
      {draftCount === 0 && !isLoading ? (
        <>
          <Box
            sx={{
              p: isMobile ? '1rem 1.32rem 0 1.32rem' : '1rem 0.563rem 0 8.5rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (location.key !== 'default') {
                navigate(-1);
              } else {
                navigate('/', { replace: true });
              }
            }}
          >
            <Typography
              variant='h8'
              sx={{
                fontSize: aboveLap
                  ? '1.1rem 1.5rem'
                  : belowLap && aboveMobile
                  ? '1.1rem 1.5rem'
                  : '1.1rem 0.875rem',
              }}
            >
              &lt;{t('back')}
            </Typography>
          </Box>
          <EmptyScreen message={t('no-drafts')} />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '1.25rem 6.25vw',
            background: (theme) => theme.palette.background.default,
            minHeight: '44rem',
            height: '100%',

            '& > .MuiBox-root': {
              maxWidth: '78.75rem',
              width: '100%',
            },
          }}
        >
          <Box>{isEditDrafts ? editDraftsData : draftsNewsData}</Box>
        </Box>
      )}
    </>
  );
};

export default Drafts;
