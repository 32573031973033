export const HOSTS = {
  API_GATEWAY: 'api-gateway',
  GRAPHQL: 'graph-ql',
};

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};
