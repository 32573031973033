import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function InfoModal(props) {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.close}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '19.5rem',
              width: '100%',
              px: 2,
            },
          },
        }}
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            textAlign: 'center',
            fontFamily: 'Sharp Sans',
            fontWeight: '800',
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center', fontFamily: 'Sharp Sans' }}>
          <DialogContentText
            id='alert-dialog-description'
            sx={{ fontFamily: 'Sharp Sans' }}
          >
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
          {props.children}
        </DialogActions>
      </Dialog>
    </>
  );
}
