import { api } from '../Constants/api.constants';
import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';

/**
 * Make api call for capturing Recombee Interaction
 * @param {object} data
 * @returns promise
 */
const captureRecombeeInteraction = (data) => {
  return apiRequest(api.recombeeInteraction, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const recombeeService = {
  captureRecombeeInteraction,
};

export default recombeeService;
