import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import DesktopStickyImage from '../../assets/images/ads/Ad_728x90.png';
import MobileStickyImage from '../../assets/images/ads/Ad_320x50.png';
import { EnvironmentProvider } from '../../store/firebase-context';
import { TRUE } from '../../Constants/Constants';

const StickyAd = () => {
  const history = useLocation();
  const { environment } = useContext(EnvironmentProvider);
  const validPaths = [
    '/search',
    '/search/:id',
    '/notifications',
    '/notification-settings',
    '/bookmarks',
    '/news-posts',
    '/drafts',
    '/reporter',
    '/training',
    '/assessment',
    '/reporter-id',
    '/author-news',
    '/create-a-report',
  ];
  const isPresent = validPaths.some((path) => history.pathname.includes(path));
  const isMobile = MaxWidth(viewPort.tablet);

  return (
    <>
      {!isPresent && environment?.web_ad_enable === TRUE && (
        <img
          src={isMobile ? MobileStickyImage : DesktopStickyImage}
          alt='sticky ad'
          style={{
            width: isMobile ? '320px' : '728px',
            height: isMobile ? '50px' : '90px',
            position: 'sticky',
            bottom: 0,
            margin: '0 auto',
            zIndex: 2,
          }}
        />
      )}
    </>
  );
};

export default StickyAd;
