import React, { useEffect, useState, useContext } from 'react';
import backgroundIcon from '../../assets/icons/reaction-background.svg';
import likeIcon from '../../assets/icons/like.svg';
import followIcon from '../../assets/icons/followIcon.svg';
import commentIcon from '../../assets/icons/commentIcon.svg';
import UserIcon from '../../assets/icons/UserIcon.svg';
import { TIME_UNITS, AUTHOR_NEWS, ENGLISH } from '../../Constants/Constants';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import userProfileService from '../../services/user-profile.service';
import notificationService from '../../services/notification.service';
import './NotificationDropdown.css';
import DtIcon from '../../assets/icons/minmini-logo.svg';
import DtIconDark from '../../assets/icons/minmini-logo.svg';
import MicIcon from '../../assets/icons/micIcon.svg';
import MicIconDark from '../../assets/icons/micIconDark.png';
import { useNavigate } from 'react-router-dom';
import { UserDetailsProvider } from '../../store/user-details-context';
import { AppContext } from '../../store/app-context';
import { Box, Typography } from '@mui/material';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const NotificationsDropdownList = ({
  id,
  source,
  timestamp,
  owner_id,
  news_story_id,
  news_story_thumbnail,
  post_id,
  user_action,
  user_action_id,
  message_en,
  message_tn,
  status,
  redirect_required,
  channel_id,
  group_id,
  user_id,
  news_url,
  post_type,
  onUpdate,
}) => {
  const [followersImage, setFollowersImage] = useState(null);
  let navigate = useNavigate();
  const { dispatch: appCtxDispatch } = useContext(AppContext);

  const { state: userState } = useContext(UserDetailsProvider);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  const timeInHours = formatDistanceToNowStrict(new Date(timestamp))
    .replace(TIME_UNITS, '')
    .split(' ')
    .join('');

  const handleNavigation = async () => {
    try {
      const response = await notificationService.fetchAuthorDetails({
        userIds: `${user_action_id}`,
      });
      if (response) {
        const [authorsdetail] = response?.authors;
        navigate(`/${AUTHOR_NEWS}/${authorsdetail.name}`);
      }
    } catch (error) {}
  };

  const handleProfile = () => {
    appCtxDispatch({ type: 'SHOW_PROFILE' });
  };

  const handleNewsDetailPost = () => {
    navigate(`/news${news_url}`);
  };

  /**
   * Side effect to calls Image API if reporter tracking ID is available
   */
  useEffect(() => {
    user_action_id && getFollowersImage();
  }, [user_action_id]);

  /**
   * Function to retrieve images using the userId of the the reporters
   */
  const getFollowersImage = () => {
    if (
      user_action === 'FOLLOW' ||
      user_action === 'LIKE' ||
      user_action === 'COMMENT'
    ) {
      userProfileService.getProfileImage(user_action_id).then((image) => {
        setFollowersImage(image);
      });
    } else {
      setFollowersImage(UserIcon);
    }
  };

  /**
   * Updating the Notification status to read by passing id
   */
  const updateNotification = async (id) => {
    try {
      const response = await notificationService.updateNotification({
        notification_id: `${id}`,
      });
      if (response) {
        onUpdate(id);
      }
    } catch (error) {}
  };

  return (
    <>
      <Box
        key={id}
        sx={{
          padding: '1.25rem',
          display: 'flex',
          flexDirection: 'row',
          borderBottom: 1,
          borderColor: 'divider',
          position: 'relative',
          cursor: 'pointer',
          background: isDarkMode
            ? DARK_COLOR.grey[200]
            : status === 'READ'
            ? LIGHT_COLOR.white[100]
            : LIGHT_COLOR.grey[100],
        }}
        onClick={() => {
          updateNotification(id);
          if (redirect_required) {
            if (source === 'CMS') {
              if (
                user_action === 'LIKE' ||
                user_action === 'POST' ||
                user_action === 'COMMENT' ||
                user_action === 'FLAG'
              ) {
                handleNewsDetailPost();
              }
            }
            if (source === 'APP') {
              user_action === 'FOLLOW' && handleNavigation();
              (user_action === 'PROFILE' || user_action === 'WELCOME') &&
                handleProfile();
            }
          }
        }}
      >
        <>
          {followersImage ? (
            <>
              <img
                className='notification-image'
                src={followersImage}
                alt='notification image'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = UserIcon;
                }}
              />
              {(user_action === 'FOLLOW' ||
                user_action === 'LIKE' ||
                user_action === 'COMMENT') && (
                <>
                  <img
                    src={backgroundIcon}
                    alt='reaction-background'
                    className='reactions-background'
                  />
                  <img
                    src={
                      user_action === 'FOLLOW'
                        ? followIcon
                        : user_action === 'LIKE'
                        ? likeIcon
                        : commentIcon
                    }
                    alt='like'
                    className='reaction'
                  />
                </>
              )}
            </>
          ) : (
            <img
              className='notification-dropdown-minmini-image'
              style={{
                border: `1px solid ${DARK_COLOR.primary[100]}`,
              }}
              src={
                user_action === 'SIGNUP' ||
                user_action === 'PROFILE' ||
                user_action === 'WELCOME'
                  ? isDarkMode
                    ? DtIconDark
                    : DtIcon
                  : user_action === 'POST' ||
                    user_action === 'REPORTER' ||
                    user_action === 'ASSESSMENT'
                  ? isDarkMode
                    ? MicIconDark
                    : MicIcon
                  : (user_action === 'EKYC' || user_action === 'FLAG') &&
                    UserIcon
              }
              alt='minmini image'
            />
          )}
          {news_story_thumbnail != null ? (
            <div style={{ display: 'flex' }}>
              <Box sx={{ marginRight: '0.625rem' }}>
                <Typography
                  sx={{
                    color: isDarkMode
                      ? DARK_COLOR.white[100]
                      : LIGHT_COLOR.grey[600],
                    cursor: 'pointer',
                    maxWidth: '15rem',
                    fontWeight: status !== 'READ' ? '600' : '400',
                  }}
                >
                  {userState?.preferences?.contentLanguage === ENGLISH
                    ? message_en
                    : message_tn}
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    fontSize: '0.875rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[500]
                      : LIGHT_COLOR.grey[400],
                  }}
                >
                  {timeInHours + ' ago'}
                  <Typography
                    component='span'
                    sx={{
                      color: isDarkMode
                        ? DARK_COLOR.grey[500]
                        : LIGHT_COLOR.grey[300],
                      margin: '0 0.313rem',
                    }}
                  >
                    &bull;
                  </Typography>
                  <Typography
                    component='span'
                    sx={{
                      fontSize: '0.875rem',
                      color: isDarkMode
                        ? DARK_COLOR.grey[500]
                        : LIGHT_COLOR.grey[400],
                    }}
                  >
                    {source}
                  </Typography>
                </Typography>
              </Box>
              <img
                width={85}
                height={48}
                className='story-thumbnail'
                src={news_story_thumbnail}
                alt='notification image'
              />
            </div>
          ) : (
            <Box sx={{ marginRight: '0.625rem' }}>
              <Typography
                sx={{
                  color: isDarkMode
                    ? DARK_COLOR.white[100]
                    : LIGHT_COLOR.grey[600],
                  cursor: 'pointer',
                  maxWidth: '15rem',
                  fontWeight: status !== 'READ' ? '600' : '400',
                }}
              >
                {userState?.preferences?.contentLanguage === ENGLISH
                  ? message_en
                  : message_tn}
              </Typography>
              <Typography
                component='span'
                sx={{
                  fontSize: '0.875rem',
                  color: isDarkMode
                    ? DARK_COLOR.grey[500]
                    : LIGHT_COLOR.grey[400],
                }}
              >
                {timeInHours + ' ago'}
                <Typography
                  component='span'
                  sx={{
                    color: isDarkMode
                      ? DARK_COLOR.grey[500]
                      : LIGHT_COLOR.grey[300],
                    margin: '0 0.313rem',
                  }}
                >
                  &bull;
                </Typography>
                <Typography
                  component='span'
                  sx={{
                    fontSize: '0.875rem',
                    color: isDarkMode
                      ? DARK_COLOR.grey[500]
                      : LIGHT_COLOR.grey[400],
                  }}
                >
                  {source}
                </Typography>
              </Typography>
            </Box>
          )}
        </>
      </Box>
    </>
  );
};

export default NotificationsDropdownList;
