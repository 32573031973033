import React from 'react';
import NewsCard from '../NewsCards/NewsCard';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { EnvironmentProvider } from '../../store/firebase-context';
import { PUBLISHER_TYPE } from '../../Constants/Constants';

const SearchNews = (props) => {
  const { environment } = useContext(EnvironmentProvider);
  return (
    <div className='relatedNews'>
      {props?.newsresult?.map((newsresult) => (
        <Box
          key={newsresult?.newsId}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <NewsCard
            key={newsresult.newsId}
            hasVideo={newsresult?.video}
            id={newsresult?.newsId}
            anonymous={newsresult?.anonymous}
            keywords={newsresult.keywords}
            url={newsresult?.url}
            maincategoryIcon={newsresult.maincategory_icon}
            mainCategory={newsresult?.maincategory_name}
            mainCategoryID={newsresult?.maincategory}
            mainCategoryUrl={newsresult?.maincategory_url}
            headline={newsresult?.heading}
            description={newsresult?.description}
            media={newsresult?.media}
            city={newsresult?.location}
            locationID={newsresult?.locationId}
            newsDate={newsresult?.date_news}
            likeCount={newsresult?.news_like_count}
            dislikeCount={newsresult?.news_dislike_count}
            comments_count={newsresult?.comments_count}
            authorName={newsresult?.authorName}
            authorID={newsresult?.authorId}
            newsID={newsresult?.newsId}
            authorImage={
              newsresult.user_type === PUBLISHER_TYPE && newsresult.authorImage
                ? newsresult.authorImage
                : `${environment.userprofile_prefix_url}${newsresult.trackingId}`
            }
            isLiked={newsresult?.is_liked}
            isDisliked={newsresult?.is_disliked}
            isBookmark={newsresult?.is_bookmark}
            isTrending={newsresult?.is_trending}
            newsType={newsresult?.news_type}
            user_type={newsresult.user_type}
          />
        </Box>
      ))}
    </div>
  );
};

export default SearchNews;
