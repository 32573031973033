import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { LaptopMin } from '../../responsive/breakpoints';
import BookmarksData from './BookmarksData';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import { useTranslation } from 'react-i18next';
import MostRead from '../MostRead/MostRead';
import {
  CardSkeleton,
  MobileCardSkeleton,
} from '../Skeleton/PublisherSkeleton';
import { INITIAL_VALUE, TEN } from '../../Constants/Constants';
import bookmarkService from '../../services/bookmark.service';
import { AppContext } from '../../store/app-context';
import { UserDetailsProvider } from '../../store/user-details-context';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import newsService from '../../services/news.service';
import { startTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackIcon from '../../assets/icons/BackIcon';
import { MINMINI_V3 } from '../../themes/color.constant';

const Bookmarks = ({ newsCategories, navBarCategories, navBarFilters }) => {
  const { state: appCtxState } = useContext(AppContext);
  const { state: userState } = useContext(UserDetailsProvider);

  const [bookmarkNews, setBookmarkNews] = useState([]);
  const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [updatedNews, setUpdatedNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = MaxWidth(viewPort.tablet);
  const isDesktop = MinWidth(viewPort.dekstopView);
  const location = useLocation();
  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { t } = useTranslation();
  const newsSessionID = userState?.sessionId;
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  useEffect(() => {
    getBookMarkNews();
    window.scrollTo(0, 0);
  }, [newsSessionID, startIndex]);

  /**
   * In case the load more button is clicked and previous news is changed then merge news function
   */
  useEffect(() => {
    isLoadMoreClicked && mergeNews();
  }, [updatedNews, isLoadMoreClicked]);

  /**
   * Default news count upon click of Load More Button
   */
  const handleLoadMore = () => {
    setStartIndex(bookmarkNews?.length);
    setIsLoadMoreClicked(true);
  };

  /**
   * Merge function to merge the previously loaded news and new news together
   */
  const mergeNews = useCallback(() => {
    setBookmarkNews([...bookmarkNews, ...updatedNews]);
    setIsLoading(false);
  }, [bookmarkNews, updatedNews]);

  /**
   * Function to Call API to retrieving Bookmarks
   */
  const getBookMarkNews = async () => {
    try {
      const response = await bookmarkService.bookmarkNewsList({
        sessionId: newsSessionID,
        startIndex: startIndex,
        count: TEN,
      });
      if (response?.news?.length) {
        isLoadMoreClicked
          ? setUpdatedNews(response?.news)
          : setBookmarkNews(response?.news);
        response?.news.length !== TEN && setIsEndOfPage(true);
        setIsLoading(false);

        const newsIds = response?.news?.map(({ newsId }) => newsId);

        if (newsIds?.length) {
          getDynamicProps(newsIds);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * function to updated dynamic props of the news
   * @param {object} newsIds
   */
  const getDynamicProps = async (newsIds) => {
    try {
      const reqObj = {
        newsIds: newsIds.join(','),
      };

      if (newsSessionID) {
        reqObj['sessionId'] = newsSessionID;
      }

      const response = await newsService.fetchNewsDynamicProps(reqObj);
      const dynamicProps = response?.news || [];

      if (dynamicProps?.length) {
        startTransition(() => {
          setBookmarkNews((prevState) => {
            return prevState?.map((news) => {
              let isAvailable = dynamicProps.find(
                (newsObj) => newsObj.newsId === news.newsId,
              );

              if (isAvailable) {
                return {
                  ...news,
                  ...isAvailable,
                };
              } else {
                return { ...news };
              }
            });
          });
        });
      }
    } catch (error) {}
  };

  /**
   * Remove Bookmark Handler
   */
  const onRemove = (newsId) => {
    setBookmarkNews((prevState) => {
      const updatedBookmarks = prevState.filter(
        (item) => item.newsId !== newsId,
      );
      return updatedBookmarks;
    });
  };

  /**
   * Handler for Card Actions (like, dislike and bookmark)
   * @param {object} newsObj news object for which action needs to be updated
   */
  const onCardAction = (newsObj) => {
    const modifiedBookmarkNewsArray = bookmarkNews?.map((newsItem) => {
      if (newsItem.newsId === newsObj.newsId) {
        return { ...newsItem, ...newsObj };
      }
      return newsItem;
    });
    setBookmarkNews([...modifiedBookmarkNewsArray]);
  };

  return (
    <>
      {!bookmarkNews?.length && !isLoading ? (
        <>
          <Box
            sx={{
              p: aboveLap
                ? '1rem 1.32rem 1rem 0rem'
                : belowLap && aboveMobile
                ? '1rem 1.32rem 1rem 0rem'
                : '0rem 0.563rem 1rem 0.5rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (location.key !== 'default') {
                navigate(-1);
              } else {
                navigate('/', { replace: true });
              }
            }}
          >
            <Typography
              variant={isMobile ? 'h8_mobile' : 'h8'}
              sx={{
                fontSize: aboveLap
                  ? '1.125rem'
                  : belowLap && aboveMobile
                  ? '1rem'
                  : '0.75rem',
              }}
            >
              &lt;&nbsp;{t('back')}
            </Typography>
          </Box>
          <EmptyScreen message={t('NO_BOOKMARKS')} />
        </>
      ) : (
        <Box
          sx={{
            background: (theme) => theme.palette.background.default,
            display: 'flex',
            justifyContent: 'center',
            padding: isDesktop ? '1.5rem 6.25vw' : '1.5rem 0',
            width: '100%',
            minHeight: '44rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '1rem',
              alignItems: 'center',
              maxWidth: '78.75rem',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                pb: '1.5rem',
                height: '2.5rem',
                maxWidth:
                  aboveLap || (belowLap && aboveMobile)
                    ? '32.5rem'
                    : '28.875rem',
                alignSelf: 'center',
              }}
            >
              <IconButton onClick={() => navigate(-1)} sx={{ mr: '0.5rem' }}>
                <BackIcon
                  fill={
                    isDarkMode
                      ? MINMINI_V3.primary.white
                      : MINMINI_V3.grey.darkGrey
                  }
                  fontSize={
                    aboveLap
                      ? '1.25rem'
                      : belowLap && aboveMobile
                      ? '1rem'
                      : '1rem'
                  }
                />
              </IconButton>

              <Typography
                variant='h8'
                sx={{
                  fontSize: aboveLap
                    ? '1.25rem'
                    : belowLap && aboveMobile
                    ? '1rem'
                    : '1rem',
                }}
              >
                {t('bookmarks')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {isLoading ? (
                  isMobile ? (
                    <MobileCardSkeleton cards={2} />
                  ) : (
                    <CardSkeleton cards={10} />
                  )
                ) : (
                  bookmarkNews?.news?.length !== INITIAL_VALUE && (
                    <BookmarksData
                      news={bookmarkNews}
                      newsCategories={newsCategories}
                      onRemove={onRemove}
                      onCardAction={onCardAction}
                    />
                  )
                )}
                {!isEndOfPage && !isLoading && (
                  <Button
                    onClick={handleLoadMore}
                    color='primary'
                    variant='contained'
                    sx={{
                      padding: '0.75rem 1.5rem',
                      lineHeight: '1.25rem',
                      maxWidth: '9.75rem',
                      width: '100%',
                      textAlign: 'center',
                      color: 'primary',
                      alignSelf: 'center',
                    }}
                  >
                    {t('LOAD_MORE')}
                  </Button>
                )}
              </Box>
              <LaptopMin>
                {appCtxState?.newsView?.activeList && (
                  <Box
                    sx={{
                      marginLeft: '1.25rem',
                      maxWidth: '13.75rem',
                      width: '100%',
                    }}
                  >
                    <MostRead />
                  </Box>
                )}
              </LaptopMin>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Bookmarks;
