import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import './Modal.css';

/**
 * Warning Modal
 */
function WarningModal({ image, message, isOpen, onClose }) {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '20rem',
          width: '100%',
          borderRadius: '8px',
          maxHeight: '10.938rem',
        },
      }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby='warning-modal'
    >
      <DialogContent sx={{ textAlign: 'center', p: '0.938rem 1.188rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton aria-label='close' onClick={onClose} sx={{ p: '0' }}>
            <CloseIcon width={10} height={10} />
          </IconButton>
        </Box>
        <Box>
          <img src={image} alt='warning' width={48} height={60} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: (theme) => theme.palette.grey[600],
              margin: '0 1.875rem',
            }}
          >
            {message}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  image: PropTypes.string,
  message: PropTypes.string,
};

export default WarningModal;
