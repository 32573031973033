// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-image {
  width: 3rem;
  height: 3rem;
  border-radius: 24px;
  margin-right: 0.625rem;
  position: relative;
  object-fit: cover;
}

.story-thumbnail {
  object-fit: cover;
  border-radius: 0.25rem;
}

.notification-dropdown-minmini-image {
  width: 3rem;
  height: 3rem;
  margin-right: 0.625rem;
  border-radius: 6px;
  padding: 0.375rem;
}

.reactions-background {
  position: absolute;
  margin-left: 1.75rem;
  margin-top: 1.75rem;
}

.reaction {
  position: absolute;
  margin-left: 2.042rem;
  margin-top: 2.042rem;
}

.notfication-dropdown-container {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.notfication-dropdown-container::-webkit-scrollbar {
  background: transparent;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.notifydropdown_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.063rem 1.25rem;
  align-items: center;
  align-content: center;
  border-bottom: 0.063rem solid var(--gray-200);
}

.notifydropdown_noImage {
  width: 2.625rem;
  height: 2.625rem;
  margin-right: 0.625rem;
  border-radius: 50%;
  background-color: var(--gray-300);
}

.notifydropdown_settings {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Notifications/NotificationDropdown.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,mBAAmB;EACnB,qBAAqB;EACrB,6CAA6C;AAC/C;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".notification-image {\n  width: 3rem;\n  height: 3rem;\n  border-radius: 24px;\n  margin-right: 0.625rem;\n  position: relative;\n  object-fit: cover;\n}\n\n.story-thumbnail {\n  object-fit: cover;\n  border-radius: 0.25rem;\n}\n\n.notification-dropdown-minmini-image {\n  width: 3rem;\n  height: 3rem;\n  margin-right: 0.625rem;\n  border-radius: 6px;\n  padding: 0.375rem;\n}\n\n.reactions-background {\n  position: absolute;\n  margin-left: 1.75rem;\n  margin-top: 1.75rem;\n}\n\n.reaction {\n  position: absolute;\n  margin-left: 2.042rem;\n  margin-top: 2.042rem;\n}\n\n.notfication-dropdown-container {\n  overflow-y: scroll;\n  scroll-behavior: smooth;\n}\n\n.notfication-dropdown-container::-webkit-scrollbar {\n  background: transparent;\n  -webkit-appearance: none;\n  width: 0;\n  height: 0;\n}\n\n.notifydropdown_header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 1.063rem 1.25rem;\n  align-items: center;\n  align-content: center;\n  border-bottom: 0.063rem solid var(--gray-200);\n}\n\n.notifydropdown_noImage {\n  width: 2.625rem;\n  height: 2.625rem;\n  margin-right: 0.625rem;\n  border-radius: 50%;\n  background-color: var(--gray-300);\n}\n\n.notifydropdown_settings {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
