import React from 'react';
import { Helmet } from 'react-helmet';
import { convert } from '../../utils/html-to-string.util';

/**
 * Meta Tag will update Og tags and title.
 * whatever content came in Html Head can be updated from here only
 */
const MetaTagWrapper = ({ meta, ogTag }) => {
  return (
    <>
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]',
          );
          const metaKeywords = document.querySelector('meta[name="keywords"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', meta?.description || '');
          }
          if (metaKeywords) {
            metaKeywords.setAttribute('content', meta?.keywords || '');
          }
        }}
      >
        <title>{convert(meta?.title)}</title>
        <meta name='description' content={meta?.description} />
        <meta name='keywords' content={meta?.keywords} />
        <meta
          id='og-image'
          property='og:image'
          width={'315px'}
          height='600px'
          content={ogTag.img}
        />
        <meta
          id='og-title'
          property='og:title'
          content={convert(ogTag.title)}
        />
        <meta id='og-url' property='og:url' content={ogTag.url} />
        <meta
          name='twitter:image'
          width={'315px'}
          height='600px'
          content={ogTag.img}
        />
        <meta name='twitter:card' content={convert(ogTag.title)}></meta>
        <meta name='twitter:title' content={convert(ogTag.title)} />
        <meta name='twitter:url' content={ogTag.url} />
        <link
          rel='canonical'
          href={`${window.location.origin}${meta.canonical_url || ''}`}
        />
      </Helmet>
    </>
  );
};

export default MetaTagWrapper;
