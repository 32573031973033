import { SvgIcon } from '@mui/material';

const TickIcon = ({ fill, fontSize }) => {
  return (
    <SvgIcon
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ fontSize }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8905 17.5779C32.2398 17.3106 35.4195 15.9936 37.9768 13.8142C43.9445 8.72858 52.7218 8.72858 58.6895 13.8142C61.2469 15.9936 64.4265 17.3106 67.7758 17.5779C75.5917 18.2016 81.7982 24.4081 82.4219 32.224C82.6892 35.5733 84.0063 38.753 86.1856 41.3103C91.2713 47.278 91.2713 56.0553 86.1856 62.023C84.0063 64.5804 82.6892 67.76 82.4219 71.1093C81.7982 78.9252 75.5917 85.1317 67.7758 85.7554C64.4265 86.0227 61.2469 87.3398 58.6895 89.5191C52.7218 94.6048 43.9445 94.6048 37.9768 89.5191C35.4195 87.3398 32.2398 86.0227 28.8905 85.7554C21.0746 85.1317 14.8681 78.9252 14.2444 71.1093C13.9771 67.76 12.6601 64.5804 10.4808 62.023C5.39509 56.0553 5.39509 47.278 10.4808 41.3103C12.6601 38.753 13.9771 35.5733 14.2444 32.224C14.8681 24.4081 21.0746 18.2016 28.8905 17.5779ZM67.641 44.9328C69.675 42.8989 69.675 39.6011 67.641 37.5672C65.607 35.5332 62.3093 35.5332 60.2753 37.5672L43.1248 54.7176L36.391 47.9838C34.357 45.9498 31.0593 45.9498 29.0253 47.9838C26.9913 50.0178 26.9913 53.3155 29.0253 55.3495L39.442 65.7662C41.476 67.8002 44.7737 67.8002 46.8077 65.7662L67.641 44.9328Z'
        fill={fill}
      />
    </SvgIcon>
  );
};
export default TickIcon;
