import { SvgIcon } from '@mui/material';

const NotificationIcon = ({ styleProps, stroke }) => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      sx={{ ...styleProps, fill: 'none' }}
    >
      <path
        d='M9.9974 31.666V12.666C9.9974 9.35231 12.6837 6.66602 15.9974 6.66602H23.9974C27.3111 6.66602 29.9974 9.35231 29.9974 12.666V31.666M9.9974 31.666H29.9974M9.9974 31.666H6.66406M29.9974 31.666H33.3307'
        stroke={stroke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3307 36.666L21.6641 36.666'
        stroke={stroke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='20.0026'
        cy='5.00065'
        r='1.66667'
        stroke={stroke}
        strokeWidth='2'
      />
    </SvgIcon>
  );
};
export default NotificationIcon;
