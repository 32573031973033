import { Button, CircularProgress } from '@mui/material';
import { MaxWidth, viewPort } from '../../responsive/viewport.util';

const ButtonLoader = (props) => {
  const isMobile = MaxWidth(viewPort.mobileMax);

  return (
    <Button
      {...props}
      disabled={props.loading || props.disabled}
      loading={props.loading}
    >
      {props.loading ? (
        <CircularProgress size={isMobile ? 20 : 30} />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default ButtonLoader;
