import React, { useReducer, useEffect, useState } from 'react';
import awsconfig from '../aws-exports';
import authService from '../services/auth.service';
import userProfileService from '../services/user-profile.service';
import {
  onUpdateDTUserProfile,
  onCreateDTUserProfile,
  onManageDTUserFollow,
} from '../services/graphql/subscriptions';
import { ModifyData, getUserName } from '../Components/Common/CommonFunctions';
import Amplify, { Auth, API, graphqlOperation, Hub } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { TAMIL } from '../Constants/Constants';
import appService from '../services/app.service';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DtDark } from '../themes/dark.theme';
import { DtLight } from '../themes/light.theme';
import DialogBox from '../Components/DialogBox/DialogBox';
import { getEnv } from '../firebase/firebaseConfig';
import { Mixpanel } from '../mixpanel/Mixpanel';

let amplifyConfigured = false;

export const UserDetailsProvider = React.createContext();

/**
 * Initial User Details before sideEffect
 */
const initialUserDetails = {
  isAuthenticated: false,
  sessionId: null,
  userProfileId: null,
  citizenJournalist: {
    draftCount: 0,
    postCount: 0,
    beAReporter: 'false',
    isKYCDone: 'false',
    isTrainingDone: 'false',
    nameInDoc: '',
    tcConsent: 'false',
    trainingLanguage: '',
    isTrainingSkipped: 'false',
    reporterID: null,
    isAssessmentDone: 'false',
  },
  following: {
    publishers: [],
    topics: [],
    reporters: [],
  },
  follows: [],
  preferences: {
    appearance: 'Light',
    autoPlayVideos: 'false',
    contentLanguage: 'English',
    emailNotifications: 'true',
    preferredNewsLocation: 'Chennai',
    textSize: 'Medium',
  },
  authInfo: {
    idToken: '',
    accessToken: '',
  },
  userDetails: {
    userId: '',
    firstName: null,
    lastName: null,
    followersCount: 0,
    followingCount: 0,
    isOnboardedReporter: false,
    ageGroup: '',
    email: '',
    isEmailVerified: false,
    isPhoneVerified: false,
    joiningDate: '',
    dob: '',
    gender: '',
    aboutMe: '',
    location: '',
    phone: '',
    pincode: '',
    userName: '',
    latitude: '',
    longitude: '',
    profileName: '',
    userType: '',
    blockedByOtherUsersCount: 0,
    blockedUserCount: 0,
    coverPostId: '',
  },
  trainingPlaylist: {
    en: null,
    ta: null,
  },
  trainingPlaylistState: {
    en: null,
    ta: null,
  },
};

/**
 * Reducer performs action to return state value
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER_DETAILS': {
      const {
        signInUserSession,
        attributes,
        sessionId,
        userProfileId,
        profileName,
      } = action?.data;
      return {
        ...state,
        isAuthenticated: true,
        sessionId: sessionId || localStorage.getItem('session_id') || null,
        userProfileId:
          userProfileId || localStorage.getItem('user_profile_id') || null,
        authInfo: {
          idToken:
            signInUserSession?.idToken?.jwtToken || state?.authInfo?.idToken,
          accessToken:
            signInUserSession?.accessToken?.jwtToken ||
            state?.authInfo?.jwtToken,
        },
        userDetails: {
          ...state.userDetails,
          email: attributes?.email || state?.userDetails?.email,
          phone: attributes?.phone_number || state?.userDetails?.phone,
          userId: attributes['custom:user_id'] || state?.userDetails?.userId,
          userName:
            attributes['custom:user_name'] || state?.userDetails?.userName,
          profileName: profileName || state?.userDetails?.profileName,
        },
      };
    }
    case 'UPDATE_VERIFY_DETAILS':
      return {
        ...state,
        userDetails: {
          ...state?.userDetails,
          isEmailVerified:
            action?.data?.attributes?.email_verified ||
            state?.userDetails?.isEmailVerified,
          isPhoneVerified:
            action?.data?.attributes?.phone_number_verified ||
            state?.userDetails?.isPhoneVerified,
        },
      };
    case 'UPDATE_PREFERENCE_DETAILS': {
      const {
        citizenJournalist,
        followersCount,
        isOnboardedReporter,
        userId,
        userDetails,
        firstName,
        lastName,
        preferences,
        following,
        follows,
        userType,
        followingCount,
      } = action?.data;

      return {
        ...state,
        citizenJournalist: {
          draftCount:
            citizenJournalist?.draftCount ||
            state?.citizenJournalist?.draftCount,
          postCount:
            citizenJournalist?.postCount || state?.citizenJournalist?.postCount,
          beAReporter:
            citizenJournalist?.beAReporter ||
            state?.citizenJournalist?.beAReporter,
          isKYCDone:
            citizenJournalist?.isKYCDone || state?.citizenJournalist?.isKYCDone,
          isTrainingDone:
            citizenJournalist?.isTrainingDone ||
            state?.citizenJournalist?.isTrainingDone,
          nameInDoc:
            citizenJournalist?.nameInDoc || state?.citizenJournalist?.nameInDoc,
          tcConsent:
            citizenJournalist?.tcConsent || state?.citizenJournalist?.tcConsent,
          isTrainingSkipped:
            citizenJournalist?.isTrainingSkipped ||
            state?.citizenJournalist?.isTrainingSkipped,
          reporterID:
            citizenJournalist?.reporterID ||
            state?.citizenJournalist?.reporterID,
          isAssessmentDone:
            citizenJournalist?.isAssessmentDone ||
            state?.citizenJournalist?.isAssessmentDone,
        },
        userDetails: {
          ...state?.userDetails,
          userId: userId || state?.userDetails?.userId,
          firstName: firstName || null,
          lastName: lastName || null,
          followersCount: followersCount || state?.userDetails?.followersCount,
          isOnboardedReporter:
            isOnboardedReporter || state?.userDetails?.isOnboardedReporter,
          ageGroup: userDetails?.ageGroup || state?.userDetails?.ageGroup,
          email: userDetails?.email || state?.userDetails?.email,
          isEmailVerified:
            userDetails?.isEmailVerified || state?.userDetails?.isEmailVerified,
          isPhoneVerified:
            userDetails?.isPhoneVerified || state?.userDetails?.isPhoneVerified,
          joiningDate:
            userDetails?.joiningDate || state?.userDetails?.joiningDate,
          dob: userDetails?.dob || state?.userDetails?.dob,
          gender: userDetails?.gender || state?.userDetails?.gender,
          aboutMe: userDetails?.aboutMe || null,
          location: userDetails?.location || null,
          latitude: userDetails?.latitude || null,
          longitude: userDetails?.longitude || null,
          phone: userDetails?.phone || state?.userDetails?.phone,
          pincode: userDetails?.pincode || state?.userDetails?.pincode,
          userName: userDetails?.userName || state?.userDetails?.userName,
          userType: userType || state?.userDetails?.userType,
          followingCount: followingCount || state?.userDetails?.followingCount,
          blockedByOtherUsersCount:
            userDetails?.blockedByOtherUsersCount ||
            state?.userDetails?.blockedByOtherUsersCount,
          blockedUserCount:
            userDetails?.blockedByOtherUsersCount ||
            state?.userDetails?.blockedByOtherUsersCount,
          coverPostId:
            userDetails?.blockedByOtherUsersCount ||
            state?.userDetails?.blockedByOtherUsersCount,
        },
        preferences: {
          ...state.preferences,
          appearance: preferences?.appearance || state?.preferences?.appearance,
          autoPlayVideos:
            preferences?.autoPlayVideos || state?.preferences?.autoPlayVideos,
          contentLanguage:
            preferences?.contentLanguage || state?.preferences?.contentLanguage,
          emailNotifications:
            preferences?.emailNotifications ||
            state?.preferences?.emailNotifications,
          preferredNewsLocation:
            preferences?.preferredNewsLocation ||
            state?.preferences?.preferredNewsLocation,
          textSize: preferences?.textSize || state?.preferences?.textSize,
        },
        following: {
          ...state?.following,
          publishers: following?.publishers || state?.following?.publishers,
          topics: following?.topics || state?.following?.topics,
          reporters: following?.reporters || state?.following?.reporters,
        },
        follows: follows || state?.follows,
      };
    }
    case 'UPDATE_FOLLOW_DATA': {
      const { follow } = action?.data;
      return { ...state, follows: JSON.parse(follow) };
    }
    case 'AUTHENTICATE_FAILED': {
      localStorage.removeItem('username');
      localStorage.removeItem('email');
      localStorage.removeItem('userID');
      localStorage.removeItem('token');
      localStorage.removeItem('community_login');
      localStorage.removeItem('phone_number');
      // localStorage.removeItem('communityLogout');
      localStorage.removeItem('session_id');
      localStorage.removeItem('user_profile_id');

      return { ...initialUserDetails };
    }
    case 'UPDATE_PLAYLIST': {
      const { lang, data } = action.payload;
      return {
        ...state,
        trainingPlaylist: {
          ...state?.trainingPlaylist,
          [lang]: data,
        },
      };
    }
    case 'UPDATE_PLAYLIST_STATE': {
      const { lang, data } = action.payload;
      return {
        ...state,
        trainingPlaylistState: {
          ...state.trainingPlaylistState,
          [lang]: data,
        },
      };
    }
    case 'UPDATE_APP_THEME': {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          appearance: localStorage.getItem('theme'),
        },
      };
    }
    default:
      return state;
  }
};

/***
 * Provides User Details to App
 */
const UserDetailsContext = (props) => {
  const [state, dispatch] = useReducer(reducer, initialUserDetails);
  const userID = localStorage.getItem('userID');
  const dtTheme = localStorage.getItem('theme');
  const { t } = useTranslation();
  const [showSignInException, setShowSignInException] = useState(false);
  const [signInExceptionMessage, setSignInExceptionMessage] = useState('');

  /**
   * create mui theme base
   */
  const theme = createTheme({
    ...(dtTheme === 'Dark' ? DtDark : DtLight),
  });

  const { i18n } = useTranslation();

  /**
   * side effect to get user details
   */
  useEffect(() => {
    if (!amplifyConfigured) {
      const graphQlEndPoint = getEnv('graphql_url')?._value;
      const newAwsconfig = {
        ...awsconfig,
        API: {
          ...awsconfig.API,
          aws_appsync_graphqlEndpoint: graphQlEndPoint,
          graphql_headers: async () => {
            const session = await Auth.currentSession();
            return {
              Authorization: session.getIdToken().getJwtToken(),
            };
          },
        },
      };
      Amplify.configure(newAwsconfig);
      amplifyConfigured = true;
    }

    authenticateUser();

    let previousEvent = null;
    const unsubscribe = Hub.listen(
      'auth',
      async ({ payload: { event, data } }) => {
        if (event === 'autoSignIn' || event === 'signIn') {
          if (previousEvent !== 'confirmSignUp') {
            await authenticateUser();
          }
        } else if (event === 'signOut' || event === 'autoSignIn_failure') {
          dispatch({ type: 'AUTHENTICATE_FAILED' });
        } else if (event.type === 'click') {
          fetchPreferences();
        } else if (event === 'signIn_failure') {
          const message = data?.message || '';

          if (message.includes('User already exists')) {
            setShowSignInException(true);
            setSignInExceptionMessage(t('USERNAME_DUPLICATE_ERROR'));
          } else if (message.includes('DuplicateEmailFound')) {
            setShowSignInException(true);
            setSignInExceptionMessage(t('EMAIL_DUPLICATE_ERROR'));
          } else if (message.includes('DuplicatePhoneFound')) {
            setShowSignInException(true);
            setSignInExceptionMessage(t('PHONE_DUPLICATE_ERROR'));
          } else {
            setSignInExceptionMessage('');
            setShowSignInException(false);
          }
        }
        previousEvent = event;
      },
    );

    return unsubscribe;
  }, []);

  /**
   * side effect to call when user id is present and get user preferences
   */
  useEffect(() => {
    userID && fetchPreferences();
  }, [userID]);

  /**
   * Function to retrieve User Details
   */
  const authenticateUser = async () => {
    try {
      const response = await authService.currentAuthenticatedUser();
      verifyUser();
      const userId = response?.attributes['custom:user_id'];
      const username = response?.attributes['custom:user_name'];
      const phoneNumber = response?.attributes?.phone_number;

      let dataToBeDispatched = {
        ...response,
        profileName: getUserName(response),
      };

      try {
        Mixpanel.identify(userId);
        Mixpanel.track('Successful login');
      } catch (error) {}

      localStorage.setItem('userID', userId);
      localStorage.setItem('email', response?.attributes?.email);
      localStorage.setItem('username', username);
      localStorage.setItem('phone_number', phoneNumber);
      localStorage.setItem(
        'token',
        response?.signInUserSession?.idToken?.jwtToken,
      );

      if (!localStorage.getItem('session_id')) {
        const registerResponse = await appService.registerUser({
          email: username,
          trackingId: userId,
          name: username,
          phone: phoneNumber,
        });

        localStorage.setItem('session_id', registerResponse?.sessionId);
        localStorage.setItem('user_profile_id', registerResponse?.userId);

        dataToBeDispatched = {
          ...response,
          profileName: getUserName(response),
          sessionId: registerResponse?.sessionId,
          userProfileId: registerResponse?.userId || null,
        };
      }

      dispatch({
        type: 'UPDATE_USER_DETAILS',
        data: dataToBeDispatched,
      });
      dispatch({ type: 'UPDATE_APP_THEME' });
    } catch (error) {
      dispatch({ type: 'AUTHENTICATE_FAILED' });
    }
  };

  /**
   * Function to Check User Email & Phone Number is verified
   */
  const verifyUser = async () => {
    try {
      const response = await authService.currentUserInfo();
      dispatch({ type: 'UPDATE_VERIFY_DETAILS', data: response });
    } catch (error) {}
  };

  /**
   * Function to retrieve user preferences
   */
  const fetchPreferences = async () => {
    try {
      const preferenceData = await userProfileService.getUserProfile({
        userId: userID,
      });
      const userFollowData = await userProfileService.getUserFollow({
        userId: userID,
      });
      if (preferenceData?.data?.getDTUserProfile) {
        const response = ModifyData(preferenceData.data.getDTUserProfile);
        response?.preferences?.contentLanguage === TAMIL
          ? i18n.changeLanguage('tn')
          : i18n.changeLanguage('en');

        if (response?.preferences?.appearance) {
          localStorage.setItem('theme', response?.preferences?.appearance);
        }

        if (response?.preferences?.contentLanguage) {
          localStorage.setItem('lang', response?.preferences?.contentLanguage);
        }
        if (userFollowData?.data?.getDTUserFollow) {
          try {
            const follow = userFollowData?.data?.getDTUserFollow?.follow;
            response['follows'] = JSON.parse(follow);
          } catch (error) {}
        }
        dispatch({ type: 'UPDATE_PREFERENCE_DETAILS', data: response });
      } else {
        await userProfileService.createUserProfile({
          userId: userID,
        });
      }
    } catch (error) {}
  };

  /**
   * Make api call for subscribing to user profile
   * @param {object} data
   * @returns promise
   */
  let userProfileSubscription;
  const subscribeToUserProfile = (data) => {
    return new Promise((resolve, reject) => {
      const observable = API.graphql(
        graphqlOperation(onUpdateDTUserProfile, data),
      );
      userProfileSubscription = observable.subscribe({
        next: ({ value }) => {
          resolve(value.data.onUpdateDTUserProfile);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  /**
   * Function to Unsubscribe to user profile
   */
  const unsubscribeToUserProfile = () => {
    userProfileSubscription?.unsubscribe();
  };

  /**
   * Make api call for subscribing to user Follow
   * @param {object} data
   * @returns promise
   */
  let userFollowSubscription;
  const subscribeToUserFollow = (data) => {
    return new Promise((resolve, reject) => {
      const observable = API.graphql(
        graphqlOperation(onManageDTUserFollow, data),
      );
      userFollowSubscription = observable.subscribe({
        next: ({ value }) => {
          resolve(value.data.onManageDTUserFollow);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  /**
   * Function to Unsubscribe to user Follow
   */
  const unsubscribeToUserFollow = () => {
    userFollowSubscription?.unsubscribe();
  };

  /**
   * Function to update realtime user preferences based upon subscription data
   */
  const UpdatePreferences = async () => {
    if (userID) {
      try {
        const updatedPreferenceData = await subscribeToUserProfile({
          userId: userID,
        });
        const modifiedData = ModifyData(updatedPreferenceData);
        dispatch({ type: 'UPDATE_PREFERENCE_DETAILS', data: modifiedData });
      } catch (error) {}
    }
  };

  /**
   * Function to update realtime user preferences based upon subscription data
   */
  const UpdateFollowData = async () => {
    if (userID) {
      try {
        const updatedFollowData = await subscribeToUserFollow({
          userId: userID,
        });
        dispatch({
          type: 'UPDATE_FOLLOW_DATA',
          data: updatedFollowData,
        });
      } catch (error) {}
    }
  };

  /**
   * SideEffect to update Follow Data and cleanup the effect
   */
  useEffect(() => {
    UpdateFollowData();
    return () => {
      unsubscribeToUserFollow();
    };
  });

  /**
   * SideEffect to update user preferences and cleanup the effect
   */
  useEffect(() => {
    UpdatePreferences();
    return () => {
      unsubscribeToUserProfile();
    };
  });

  /**
   * Make api call for subscribing to user profile.
   * @param {object} data
   * @returns promise
   */
  let createProfileSubscription;
  const subscribeToCreateProfile = (data) => {
    return new Promise((resolve, reject) => {
      const observable = API.graphql(
        graphqlOperation(onCreateDTUserProfile, data),
      );
      createProfileSubscription = observable.subscribe({
        next: ({ value }) => {
          resolve(value.data.onCreateDTUserProfile);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  /**
   * Function to Unsubscribe to user profile
   */
  const unsubscribeToCreateProfile = () => {
    createProfileSubscription?.unsubscribe();
  };

  /**
   * Function to update realtime user preferences based upon subscription data
   */
  const UpdatePreferencesOnCreateUser = async () => {
    if (userID) {
      try {
        const updatedPreferenceData = await subscribeToCreateProfile({
          userId: userID,
        });
        const modifiedData = ModifyData(updatedPreferenceData);
        dispatch({ type: 'UPDATE_PREFERENCE_DETAILS', data: modifiedData });
      } catch (error) {}
    }
  };

  /**
   * SideEffect to update user preferences and cleanup the effect
   */
  useEffect(() => {
    UpdatePreferencesOnCreateUser();
    return () => {
      unsubscribeToCreateProfile();
    };
  });

  return (
    <UserDetailsProvider.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        {props.children}
        {showSignInException && (
          <DialogBox
            isOpen={showSignInException}
            onClose={() => {
              setShowSignInException(false);
              setSignInExceptionMessage('');
            }}
            title={t('ERROR')}
            content={signInExceptionMessage}
            primaryButtonAction={() => {
              setShowSignInException(false);
              setSignInExceptionMessage('');
            }}
            primaryButtonText={t('ok')}
          />
        )}
      </ThemeProvider>
    </UserDetailsProvider.Provider>
  );
};

export default UserDetailsContext;
