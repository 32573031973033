import React, { useState, useEffect, useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ModalBox from '../Modal/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  Menu,
  IconButton,
  ListItem,
  Grid,
  Button,
  Typography,
  Box,
} from '@mui/material';
import radioButton from '../../assets/icons/radio-btn.svg';
import radioButtonDark from '../../assets/icons/radio-btn-dark.svg';
import ActiveRadioButton from '../../assets/icons/active-radioBtn.svg';
import ActiveRadioButtonDark from '../../assets/icons/active-radioBtn-dark.svg';
import CorrectAnswerIcon from '../../assets/icons/correct-answer.svg';
import CorrectAnswerDarkIcon from '../../assets/icons/correct-answer-dark.svg';
import WrongAnswerIcon from '../../assets/icons/wrong-answer.svg';
import WrongAnswerDarkIcon from '../../assets/icons/wrong-answer-dark.svg';
import ActivePreviousArrow from '../../assets/icons/active-previous-arrow.svg';
import InActivePreviousArrow from '../../assets/icons/inactive-previous-arrow.svg';
import ActiveNextArrow from '../../assets/icons/active-next-arrow.svg';
import InActiveNextArrow from '../../assets/icons/inactive-next-arrow.svg';
import { useNavigate } from 'react-router-dom';
import {
  ONE,
  INITIAL_VALUE,
  ABORT,
  SUBMIT,
  TRUE,
  OF,
  ASSESSMENT_URL,
} from '../../Constants/Constants';
import { useTranslation } from 'react-i18next';
import './AssessmentMobile.css';
import NotAnsweredIcon from '../../assets/icons/not-answered.svg';
import NotAnsweredDarkIcon from '../../assets/icons/not-answered-dark.svg';
import CorrectIcon from '../../assets/icons/green-correct.svg';
import CorrectDarkIcon from '../../assets/icons/green-correct-dark.svg';
import InCorrectIcon from '../../assets/icons/red-incorrect.svg';
import InCorrectDarkIcon from '../../assets/icons/red-incorrect-dark.svg';
import userProfileService from '../../services/user-profile.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import assessmentService from '../../services/assessment.service';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import AssessmentResults from './AssessmentResults';
import { EnvironmentProvider } from '../../store/firebase-context';

const AssessmentMobile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const [assesmentData, setAssessmentData] = useState([]);
  const [displayOrder, setDisplayOrder] = useState(ONE);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [assessmentId, setAssessmentId] = useState('');
  const [answerCount, setAnswerCount] = useState(INITIAL_VALUE);
  const [showBadgeScreen, setShowBadgeScreen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAssessment, setShowAssessment] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false);
  const [callUpdate, setCallUpdate] = useState(false);
  const [percentage, setPercentage] = useState();
  const [resumeOrder, setResumeOrder] = useState(true);
  let navigate = useNavigate();

  const { state: userState } = useContext(UserDetailsProvider);
  const { environment } = useContext(EnvironmentProvider);
  const userId = userState?.userDetails?.userId;
  const citizenJournalist = userState?.citizenJournalist;

  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setShowExitModal(true);
  };

  /**
   * Side Effect to Call PercentCheck function after getting answer count
   */
  useEffect(() => {
    if (answerCount !== 0) {
      percentCheck();
    }
  }, [answerCount]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    assesmentAPI();
  }, []);

  useEffect(() => {
    callUpdate && UpdatePreferences();

    return () => {
      setCallUpdate(false);
    };
  }, [callUpdate]);

  const UpdatePreferences = async () => {
    try {
      if (percentage >= environment.assessment_pass_percentage) {
        await userProfileService.updateUserProfile({
          userId: userId ? userId : localStorage.getItem('userID'),
          citizenJournalist: JSON.stringify({
            ...citizenJournalist,
            isAssessmentDone: TRUE,
          }),
        });
      }
    } catch (error) {}
  };

  /**
   * Function to get assessment
   */
  const assesmentAPI = async (resumeOrderNotReq = false) => {
    try {
      const response = await assessmentService.getAssessment({
        userId: userId,
      });
      setAssessmentData(response?.data);
      setAssessmentId(response?.assessmentId);
      const resumeQuestion = response?.data.find(
        (resume) => resume.selectedOptionId === null,
      );
      if (resumeOrder === true) {
        setDisplayOrder(resumeQuestion?.displayOrder);
      }
    } catch (error) {}
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleIconClose = () => {
    navigate('/');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onPreviousClick = () => {
    setDisplayOrder((prev) => prev - ONE);
    setSelectedOptionId(null);
  };

  const onNextClick = () => {
    setDisplayOrder((prev) => prev + ONE);
    setSelectedOptionId(null);
  };

  /**
   * Function to Check Answer
   */
  const onAnswerSubmit = async () => {
    let answerId =
      assesmentData &&
      assesmentData
        .filter(
          (assessmentData) => assessmentData.displayOrder === displayOrder,
        )
        .map((answer) => answer.answer);
    let validateAnswer = answerId.includes(selectedOptionId);
    let questionId =
      assesmentData &&
      assesmentData
        .filter(
          (assessmentData) => assessmentData.displayOrder === displayOrder,
        )
        .map((id) => id.questionId);
    try {
      const response = await assessmentService.saveAnswer({
        assessmentId: assessmentId,
        questionId: questionId.values().next().value,
        selectedOptionId: selectedOptionId,
        isCorrect: validateAnswer,
      });
      response && setResumeOrder(false);
      assesmentAPI(true);
      setSelectedOptionId(null);
    } catch (error) {}
  };

  const onOptionClick = (id) => setSelectedOptionId(id);

  const selectQuestion = (assessment) => {
    setDisplayOrder(assessment.displayOrder);
    setAnchorEl(null);
  };

  /**
   * Function to Submit assessment
   */
  const submitAssesment = async () => {
    const count = assesmentData.filter(
      (assesment) => assesment.isCorrect === true,
    ).length;
    setAnswerCount(count);
    try {
      const response = await assessmentService.submitAssessment({
        assessmentId: assessmentId,
        status: SUBMIT,
      });
      response && setShowBadgeScreen(true);
      setShowConfirmModal(false);
      navigate(ASSESSMENT_URL, { state: true });
      setCallUpdate(true);
    } catch (error) {}
  };

  /**
   * Function to Restart assessment
   */
  const restartAssesment = async () => {
    try {
      const response = await assessmentService.submitAssessment({
        assessmentId: assessmentId,
        status: ABORT,
      });
      response && assesmentAPI();
      setDisplayOrder(ONE);
      setAnchorEl(null);
      setShowExitModal(false);
    } catch (error) {}
  };

  /**
   * Function to Check the Assessment Percentage
   */
  const percentCheck = () => {
    let totalQuestion = assesmentData?.length;
    const percentage = (answerCount / totalQuestion) * 100;
    setPercentage(percentage);
  };

  const goBackToAssessment = () => {
    setShowConfirmModal(false);
    setShowAssessment(true);
    setAnchorEl(null);
  };

  const confirmSubmit = () => {
    setShowConfirmModal(true);
    setShowAssessment(false);
  };

  return (
    <div>
      {!showBadgeScreen ? (
        <ModalBox
          showModal={showAssessment}
          closeModal={() => setShowExitModal(true)}
          modalHeader='Assessment'
        >
          <div className='assessmentmenuItems'>
            <Grid container direction='row'>
              <IconButton>
                {open ? (
                  <CloseIcon onClick={handleIconClose} />
                ) : (
                  <MenuIcon onClick={handleClick} />
                )}
              </IconButton>
              <span>
                <ListItem className='assessmentActiveItem'>
                  <Typography sx={{ fontWeight: '600' }}>
                    {displayOrder} {OF} {assesmentData?.length}
                  </Typography>
                </ListItem>
              </span>
            </Grid>
            <hr className='assessmentMenuLine' />
            <Menu
              anchorEl={anchorEl}
              open={open}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={handleClose}
              className='assessmentMenu'
            >
              <div>
                <ul className='mobileAssementQuestionContainer'>
                  {assesmentData?.map((assessment, index) => {
                    return (
                      <li
                        key={index}
                        className='mobileAssementQuestionsList'
                        onClick={() => selectQuestion(assessment)}
                      >
                        <Box
                          className={
                            assessment?.isCorrect
                              ? 'mobileAssesementCorrectAnswer'
                              : assessment?.isCorrect === false
                              ? 'mobileAssesementWrongAnswer'
                              : 'mobileAssesementNotAnswered'
                          }
                          sx={{
                            background: assessment?.isCorrect
                              ? isDarkMode
                                ? DARK_COLOR.success[100]
                                : LIGHT_COLOR.success[100]
                              : assessment?.isCorrect === false
                              ? isDarkMode
                                ? DARK_COLOR.error[100]
                                : LIGHT_COLOR.error[100]
                              : isDarkMode
                              ? ''
                              : LIGHT_COLOR.white[100],
                          }}
                        >
                          {assessment?.displayOrder}
                        </Box>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className='mobileAssementDetails'>
                <Grid container direction='row' justifyContent='space-evenly'>
                  <div className='mobileAssementDetailName'>
                    <img
                      src={isDarkMode ? CorrectDarkIcon : CorrectIcon}
                      alt='correct'
                    />
                    <span>{t('CORRECT')}</span>
                  </div>
                  <div className='mobileAssementDetailName'>
                    <img
                      src={isDarkMode ? InCorrectDarkIcon : InCorrectIcon}
                      alt='correct'
                    />
                    <span>{t('IN_CORRECT')}</span>
                  </div>
                  <div className='mobileAssementDetailName'>
                    <img
                      src={isDarkMode ? NotAnsweredDarkIcon : NotAnsweredIcon}
                      alt='correct'
                    />
                    <span>{t('NOT_ANSWERED')}</span>
                  </div>
                </Grid>
              </div>
              <div className='mobileAssementBtnSection'>
                <Grid
                  container
                  direction='column'
                  justifyContent='space-between'
                >
                  <div className='mobileSubmitAssesment'>
                    <Button variant='contained' onClick={confirmSubmit}>
                      {t('SUBMIT_ASSESSMENT')}
                    </Button>
                  </div>
                  <div className='mobileRestartAssesment'>
                    <Button variant='outlined' onClick={restartAssesment}>
                      {t('RESTART_ASSESSMENT')}
                    </Button>
                  </div>
                </Grid>
              </div>
            </Menu>
          </div>
          <div className='mobileAssessmentQuestion'>
            <Typography
              variant='h5'
              sx={{ fontSize: '0.875rem', fontStyle: 'normal' }}
            >
              {displayOrder}.{' '}
              {assesmentData &&
                assesmentData
                  ?.filter(
                    (assesment) => assesment?.displayOrder === displayOrder,
                  )
                  ?.map((assesmentQuestion) => assesmentQuestion?.question)}
            </Typography>
          </div>
          <div>
            {assesmentData
              ?.filter((assesment) => assesment?.displayOrder === displayOrder)
              ?.map((assessment, index) => {
                return (
                  <div key={index}>
                    {assessment?.options.map((assessmentOption, index) => {
                      return (
                        <div
                          className='mobileAssessmentOptionSection'
                          key={index}
                          onClick={() =>
                            assessment?.selectedOptionId === null
                              ? onOptionClick(assessmentOption?.optionId)
                              : undefined
                          }
                        >
                          <img
                            src={
                              assessment?.isCorrect &&
                              assessment?.selectedOptionId ===
                                assessmentOption?.optionId
                                ? isDarkMode
                                  ? CorrectAnswerDarkIcon
                                  : CorrectAnswerIcon
                                : !assessment?.isCorrect &&
                                  assessment?.selectedOptionId ===
                                    assessmentOption?.optionId
                                ? isDarkMode
                                  ? WrongAnswerDarkIcon
                                  : WrongAnswerIcon
                                : !assessment?.isCorrect &&
                                  assessment?.selectedOptionId &&
                                  assessment.answer ===
                                    assessmentOption?.optionId
                                ? isDarkMode
                                  ? CorrectAnswerDarkIcon
                                  : CorrectAnswerIcon
                                : selectedOptionId ===
                                  assessmentOption?.optionId
                                ? isDarkMode
                                  ? ActiveRadioButtonDark
                                  : ActiveRadioButton
                                : isDarkMode
                                ? radioButtonDark
                                : radioButton
                            }
                            alt='radio-btn'
                            className='mobileAssessmentRadioBtn'
                          />{' '}
                          <Typography
                            component='span'
                            className={
                              assessment?.isCorrect &&
                              assessment?.selectedOptionId ===
                                assessmentOption?.optionId
                                ? 'mobileAssessmentCorrectAnswer'
                                : !assessment?.isCorrect &&
                                  assessment?.selectedOptionId ===
                                    assessmentOption?.optionId
                                ? 'mobileAssessmentWrongAnswer'
                                : !assessment?.isCorrect &&
                                  assessment?.selectedOptionId &&
                                  assessment.answer ===
                                    assessmentOption?.optionId
                                ? 'mobileAssessmentCorrectAnswer'
                                : 'mobileAssessmentOptions'
                            }
                            sx={{
                              marginLeft: '0.625rem',
                              color:
                                assessment?.isCorrect &&
                                assessment?.selectedOptionId ===
                                  assessmentOption?.optionId
                                  ? (theme) => theme.palette.primary.main
                                  : !assessment?.isCorrect &&
                                    assessment?.selectedOptionId ===
                                      assessmentOption?.optionId
                                  ? (theme) => theme.palette.error.main
                                  : !assessment?.isCorrect &&
                                    assessment?.selectedOptionId &&
                                    assessment.answer ===
                                      assessmentOption?.optionId
                                  ? (theme) => theme.palette.primary.main
                                  : isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[500],
                            }}
                          >
                            {assessmentOption.optionId}
                            {'. '}
                            {assessmentOption?.option}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
          {anchorEl === null && (
            <div
              className={
                displayOrder === assesmentData?.length
                  ? 'mobileAssessmentFooterSubmitBtn'
                  : 'mobileAssessmentFooter'
              }
            >
              <hr className='assessmentMenuLine' />
              <Grid container direction='row' justifyContent='space-between'>
                <Box
                  className={
                    displayOrder !== ONE
                      ? 'activeMobileAssementFooterAction'
                      : 'inactiveMobileAssementFooterAction'
                  }
                  sx={{
                    color:
                      displayOrder !== ONE
                        ? (theme) => theme.palette.primary.main
                        : (theme) =>
                            isDarkMode
                              ? theme.palette.grey[400]
                              : theme.palette.grey[300],
                  }}
                  onClick={displayOrder !== ONE ? onPreviousClick : undefined}
                >
                  <img
                    src={
                      displayOrder !== ONE
                        ? ActivePreviousArrow
                        : InActivePreviousArrow
                    }
                    alt='previous-btn'
                    className='mobileAssessmentpreviousIcon'
                  />{' '}
                  <span>{t('prev')}</span>
                </Box>
                <div className='mobileAssessmentCheckBtn'>
                  <Button
                    disabled={selectedOptionId === null ? true : false}
                    variant='outlined'
                    onClick={onAnswerSubmit}
                  >
                    {t('CHECK_ANSWER')}
                  </Button>
                </div>
                <Box
                  className={
                    displayOrder !== assesmentData?.length
                      ? 'activeMobileAssementFooterAction'
                      : 'inactiveMobileAssementFooterAction'
                  }
                  sx={{
                    color:
                      displayOrder !== assesmentData?.length
                        ? (theme) => theme.palette.primary.main
                        : (theme) =>
                            isDarkMode
                              ? theme.palette.grey[400]
                              : theme.palette.grey[300],
                  }}
                  onClick={
                    displayOrder !== assesmentData?.length
                      ? onNextClick
                      : undefined
                  }
                >
                  <span>{t('next')}</span>{' '}
                  <img
                    src={
                      displayOrder !== assesmentData?.length
                        ? ActiveNextArrow
                        : InActiveNextArrow
                    }
                    alt='previous-btn'
                    className='mobileAssessmentnextIcon'
                  />
                </Box>
              </Grid>
              {displayOrder === assesmentData?.length && (
                <>
                  <hr className='assessmentMenuLine' />
                  <div className='mobileFooterSubmitBtn'>
                    <Button variant='contained' onClick={confirmSubmit}>
                      {t('SUBMIT_ASSESSMENT')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </ModalBox>
      ) : (
        <AssessmentResults
          percentage={percentage}
          answerCount={answerCount}
          assesmentData={assesmentData}
          setShowBadgeScreen={setShowBadgeScreen}
          setDisplayOrder={setDisplayOrder}
          assesmentAPI={assesmentAPI}
          setShowAssessment={setShowAssessment}
          setAnchorEl={setAnchorEl}
        />
      )}
      <ModalBox
        showModal={showConfirmModal}
        closeModal={goBackToAssessment}
        modalHeader={t('CONFIRM_SUBMIT')}
        isBackdropClick={true}
      >
        <Typography
          sx={{
            color: isDarkMode ? DARK_COLOR.grey[600] : LIGHT_COLOR.grey[400],
          }}
          className='assessmentModalContent'
        >
          {t('ASSESSMENT_MODAL_CONTENT')}
        </Typography>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignContent={'center'}
        >
          <div className='confirmSubmitAssesment'>
            <Button variant='contained' onClick={submitAssesment}>
              {t('YES_SUBMIT')}
            </Button>
          </div>
          <div className='assessmentOutlineBtn'>
            <Button variant='outlined' onClick={goBackToAssessment}>
              {t('NO_GO_BACK')}
            </Button>
          </div>
        </Grid>
      </ModalBox>
      <ModalBox
        showModal={showExitModal}
        closeModal={() => setShowExitModal(false)}
        modalHeader={t('exit-assessment')}
        modalSize={true}
        isBackdropClick={true}
      >
        <Typography
          sx={{
            color: isDarkMode ? DARK_COLOR.grey[600] : LIGHT_COLOR.grey[400],
          }}
          className='assessmentModalContent'
        >
          {t('exit-assessment-content')}
        </Typography>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignContent={'center'}
        >
          <div className='confirmSubmitAssesment'>
            <Button variant='contained' onClick={() => setShowExitModal(false)}>
              {t('no-resume')}
            </Button>
          </div>
          <div className='assessmentOutlineBtn'>
            <Button variant='outlined' onClick={() => navigate('/')}>
              {t('resume-later')}
            </Button>
          </div>
          <div className='assessmentOutlineBtn'>
            <Button variant='outlined' onClick={restartAssesment}>
              {t('RESTART_ASSESSMENT')}
            </Button>
          </div>
          <div className='assessmentOutlineBtn'>
            <Button variant='outlined' onClick={() => navigate('/training')}>
              {t('training-material')}
            </Button>
          </div>
        </Grid>
      </ModalBox>
    </div>
  );
};

export default AssessmentMobile;
