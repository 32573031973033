import { Box, Paper, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReportForm from './ReportForm/ReportForm';
import { MaxWidth, viewPort, MinWidth } from '../../responsive/viewport.util';
import { useEffect, useState } from 'react';
import appService from '../../services/app.service';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import { useMetaStore } from '../../store/MetaTagHandler';
import { META_TAG_TITLE } from '../../Constants/meta.title.constant';
import BackIcon from '../../assets/icons/BackIcon';
import { MINMINI_V3 } from '../../themes/color.constant';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * CreateAReport component
 *  props
 *    - navBarCategories - main topics
 */
function CreateAReport({ navBarCategories, navBarFilters }) {
  const dispatchMetaAction = useMetaStore()[1];
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = MaxWidth(viewPort.mobileMax);
  const [district, setDistrict] = useState();
  const [latLong, setLatLong] = useState({});
  const [stopFetchDistrict, setStopFetchDistrict] = useState(false);
  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  const handleStopFetchDistrict = () => {
    setStopFetchDistrict(true);
  };

  /**
   * use for checking location is there or not on every 5 sec
   */
  useEffect(() => {
    const metaObj = {
      title: META_TAG_TITLE.CreateReport,
      description: META_TAG_TITLE.CreateReport,
      keywords: '',
      canonical_url: location.pathname,
    };
    dispatchMetaAction(metaAction.updateMeta, metaObj);
    const interval = setInterval(() => {
      if (!(district || stopFetchDistrict)) {
        navigator.geolocation.getCurrentPosition(function (position) {
          fetchDistrict(position.coords.latitude, position.coords.longitude);
          setLatLong({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        });
      }
    }, 5 * 1000);

    return () => clearInterval(interval);
  }, [district]);

  /**
   * fterching district with third party api
   * @param {string} lat lattitude
   * @param {string} long longitutde
   */

  const fetchDistrict = (lat, long) => {
    if (lat && long) {
      appService.getCurrentCity({ lat, long }).then((data) => {
        setDistrict(
          data?.address?.city || data?.address?.state_district
            ? (data?.address?.state_district).replace('District', '').trim()
            : '',
        );
      });
    }
  };
  return (
    <Box
      sx={{
        padding: '0 6.25vw',
        display: 'flex',
        flexDirection: 'column',
        background: (theme) => theme.palette.grey[100],
      }}
    >
      <Box
        sx={{
          maxWidth: '78.75rem',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            pt: '1.5rem',
            pb: !isMobile ? '1.5rem' : 'unset',
            height: '2.5rem',
            maxWidth:
              aboveLap || (belowLap && aboveMobile) ? '32.5rem' : '28.875rem',
            alignSelf: 'center',
          }}
        >
          <IconButton onClick={() => navigate(-1)} sx={{ mr: '0.5rem' }}>
            <BackIcon
              fill={
                isDarkMode ? MINMINI_V3.primary.white : MINMINI_V3.grey.darkGrey
              }
              fontSize={
                aboveLap ? '1.25rem' : belowLap && aboveMobile ? '1rem' : '1rem'
              }
            />
          </IconButton>

          <Typography
            variant='h8'
            sx={{
              fontSize: aboveLap
                ? '1.25rem'
                : belowLap && aboveMobile
                ? '1rem'
                : '1rem',
            }}
          >
            {t('create-a-report')}
          </Typography>
        </Box>
        {isMobile ? (
          <ReportForm
            stopFetchDistrict={handleStopFetchDistrict}
            point_lat={latLong.lat}
            point_long={latLong.long}
            district={district}
            configCategories={navBarCategories}
          />
        ) : (
          <Paper sx={{ flex: 1, marginBottom: '5rem' }}>
            <ReportForm
              stopFetchDistrict={handleStopFetchDistrict}
              point_lat={latLong.lat}
              point_long={latLong.long}
              district={district}
              configCategories={navBarCategories}
            />
          </Paper>
        )}
      </Box>
    </Box>
  );
}

CreateAReport.propTypes = {
  navBarCategories: PropTypes.object.isRequired,
};

export default CreateAReport;
