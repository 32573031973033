import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  Button,
  Stack,
  MenuItem,
  Grid,
  Box,
  Typography,
  Checkbox,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import completedIcon from '../../assets/completed.png';
import failedIcon from '../../assets/icons/failedIcon.svg';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalBox from '../Modal/Modal';
import './Reporter.css';
import { useTranslation } from 'react-i18next';
import KycCompletedIcon from '../../assets/kycCompleted.png';
import tickLight from '../../assets/icons/Tick.svg';
import Timer from '../Timer/Timer';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import ArrowLeftWhite from '../../assets/icons/arrow-left-white.svg';
import InfoIcon from '../../assets/icons/info-icon.svg';
import InfoIconWhite from '../../assets/icons/info-icon-white.svg';
import {
  TWO,
  ONE,
  TRUE,
  INITIAL_VALUE,
  DATE_FORMAT,
  DRIVING_LICENSE,
  AADHAR,
  PASSPORT,
  UGC_REPORTER,
  CITIZEN_REPORTER_CAPITAL,
} from '../../Constants/Constants';
import userProfileService from '../../services/user-profile.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import ekycService from '../../services/ekyc.service';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import calendarIcon from '../../assets/icons/calendar.svg';
import calendarDarkIcon from '../../assets/icons/calendar-dark.svg';
import CalenderIconDarkMui from '../icons/calendar/CalenderIconDark';
import calendarIconMui from '../icons/calendar/CalenderIconLight';
import { useMetaStore } from '../../store/MetaTagHandler';
import { META_TAG_TITLE } from '../../Constants/meta.title.constant';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import { decrypt, encrypt } from '../../utils/crypto.util';

const Reporter = () => {
  const dispatchMetaAction = useMetaStore()[1];
  const isMobileMax = MaxWidth(viewPort.mobile);
  const initialKycDetails = {
    fullname: '',
    country: 'india',
    idType: AADHAR,
    dob: '',
    docNumber: '',
    otp: '',
    transID: '',
  };
  const initialErrDetails = {
    fullname: '',
    country: '',
    idType: '',
    dob: '',
    docNumber: '',
    otp: '',
  };
  const initialCitizenDetails = {
    beAReporter: 'false',
    isKYCDone: 'false',
    isTrainingDone: 'false',
    nameInDoc: '',
    tcConsent: 'false',
    reporterID: null,
    isTrainingSkipped: 'false',
  };
  const [kycDetails, setKycDetails] = useState(initialKycDetails);
  const [errDetails, setErrDetails] = useState(initialErrDetails);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [isUpdateFunc, setIsUpdateFunc] = useState(false);
  const [citizenDetails, setCitizenDetails] = useState(initialCitizenDetails);
  const [callUpdateFunc, setCallUpdateFunc] = useState(false);
  const [showIDTypeSuccess, setShowIDTypeSuccess] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { state: userState } = useContext(UserDetailsProvider);
  const userId = userState?.userDetails?.userId;
  const isOnboardedReporter = userState?.userDetails?.isOnboardedReporter;

  const isMobile = MaxWidth(viewPort.tablet);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  let navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const inputRef = React.useRef();
  const prevIdType = React.useRef();

  useEffect(() => {
    const metaObj = {
      title: META_TAG_TITLE.kycScreen,
      description: META_TAG_TITLE.kycScreen,
      keywords: '',
      canonical_url: location.pathname,
    };
    dispatchMetaAction(metaAction.updateMeta, metaObj);
    fetchPreferences();
  }, [userState]);

  useEffect(() => {
    prevIdType.current = kycDetails;
  }, [kycDetails]);

  useEffect(() => {
    isUpdateFunc && setCallUpdateFunc(true);
    return () => {
      setIsUpdateFunc(false);
    };
  }, [isUpdateFunc]);

  useEffect(() => {
    callUpdateFunc && UpdatePreferences();
    return () => {
      setCallUpdateFunc(false);
    };
  }, [callUpdateFunc]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleChange = (e) => {
    setDisableBtn(false);
    if (
      showOtpForm === false &&
      kycDetails.idType === AADHAR &&
      e.target.value.length === 1 &&
      (e.target.value === '0' || e.target.value === '1')
    ) {
      e.preventDefault();
      return false;
    } else {
      if (kycDetails.idType === PASSPORT) {
        setKycDetails({
          ...kycDetails,
          [e.target.name]: e.target.value.replace(/[^a-z0-9]/gi, ''),
        });
      } else if (e.target.name === 'fullname') {
        setKycDetails({
          ...kycDetails,
          [e.target.name]: e.target.value.replace(/[^a-zA-Z ]/gi, ''),
        });
      } else if (e.target.name === 'otp' || kycDetails.idType === AADHAR) {
        setKycDetails({
          ...kycDetails,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, ''),
        });
      } else if (e.target.name === 'otp') {
        setKycDetails({
          ...kycDetails,
          [e.target.name]: e.target.value.replace(/[^0-9 .]/gi, ''),
        });
      } else {
        setKycDetails({
          ...kycDetails,
          [e.target.name]: e.target.value,
        });
      }
      setErrDetails({
        ...errDetails,
        [e.target.name]: '',
      });
    }
  };

  const handleDob = (newValue) => {
    setKycDetails({ ...kycDetails, dob: newValue });
    setErrDetails(initialErrDetails);
    setDisableBtn(false);
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    let errMsg = {};
    if (kycDetails.fullname.length <= 0) {
      errMsg['fullname'] = 'Please enter a valid name';
    }
    if (kycDetails.fullname.length > 0 && kycDetails.fullname.length < 3) {
      errMsg['fullname'] = 'Full Name length must be minimum 3';
    }
    if (kycDetails.country.length <= 0) {
      errMsg['country'] = 'Please select Country';
    }
    if (kycDetails.idType.length <= 0) {
      errMsg['idType'] = 'Please select ID Type';
    }
    if (kycDetails.dob.length <= 0) {
      errMsg['dob'] = 'Please select DOB';
    }
    if (kycDetails.docNumber.length <= 0) {
      errMsg['docNumber'] = 'Please enter a valid ID Number';
    }
    setErrDetails(errMsg);

    const currentYear = new Date().getFullYear();
    const year = format(kycDetails?.dob, DATE_FORMAT).split('-')[2];
    const age = currentYear - year;

    if (age < 18) {
      setErrDetails({
        ...errDetails,
        dob: 'User should be 18+',
      });
    }

    let preparedData = {
      docType: kycDetails.idType,
      docNumber: kycDetails.docNumber,
      dob: format(kycDetails.dob, DATE_FORMAT),
      name: kycDetails.fullname,
    };

    const kycData = JSON.stringify(preparedData);
    try {
      const encryptedData = await encrypt(kycData);
      const encodedData = {
        encodedInputString: encryptedData,
      };
      if (
        kycDetails?.fullname?.length >= 3 &&
        kycDetails.country &&
        kycDetails.idType &&
        kycDetails.dob &&
        errDetails?.dob?.length === 0 &&
        age >= 18
      ) {
        setDisableBtn(true);
        let response;
        if (kycDetails.idType === DRIVING_LICENSE) {
          try {
            response = await ekycService.drivingLicenseVerification(
              encodedData,
            );
          } catch (error) {}
        } else if (kycDetails.idType === AADHAR) {
          try {
            response = await ekycService.aadharOtp(encodedData);
          } catch (error) {}
        } else if (kycDetails.idType === PASSPORT) {
          try {
            response = await ekycService.passportVerification(encodedData);
          } catch (error) {}
        }

        if (response?.isVerified) {
          if (isOnboardedReporter) {
            try {
              await userProfileService.updateUserProfile({
                userId: userId,
                userType: UGC_REPORTER,
                citizenJournalist: JSON.stringify({
                  ...citizenDetails,
                  beAReporter: TRUE,
                  isKYCDone: TRUE,
                  nameInDoc: kycDetails.fullname,
                  tcConsent: TRUE,
                  reporterID: response?.reporterID || null,
                }),
              });

              setShowIDTypeSuccess(true);
            } catch (error) {}
          } else {
            setCitizenDetails({
              ...citizenDetails,
              beAReporter: TRUE,
              isKYCDone: TRUE,
              nameInDoc: kycDetails.fullname,
              tcConsent: TRUE,
            });
            setIsUpdateFunc(true);
          }
        }
        if (response?.transID) {
          setDisableBtn(false);
          const decryptedTransID = await decrypt(response?.transID);
          setKycDetails({
            ...kycDetails,
            transID: decryptedTransID,
          });
          setShowOtpForm(true);
        }
        if (response?.status === 500 && !response?.isVerified) {
          setDisableBtn(false);
          setShowErrorModal(true);
        }
        if (!response?.transID && !response?.isVerified) {
          setDisableBtn(false);
          setShowErrorModal(true);
        }
      }
    } catch (error) {}
  };

  const onHandleOtpSubmit = async (e) => {
    e.preventDefault();
    setDisableBtn(true);
    let preparedOtpData = {
      transID: kycDetails.transID,
      mobileCode: kycDetails.otp,
      dob: format(kycDetails.dob, DATE_FORMAT),
      name: kycDetails.fullname,
    };
    const kycData = JSON.stringify(preparedOtpData);

    try {
      const encryptedData = await encrypt(kycData);
      const response = await ekycService.aadharVerification({
        encodedInputString: encryptedData,
      });

      if (response?.isVerified) {
        if (isOnboardedReporter) {
          try {
            await userProfileService.updateUserProfile({
              userId: userId,
              userType: UGC_REPORTER,
              citizenJournalist: JSON.stringify({
                ...citizenDetails,
                beAReporter: TRUE,
                isKYCDone: TRUE,
                nameInDoc: kycDetails.fullname,
                tcConsent: TRUE,
                reporterID: response?.reporterID || null,
              }),
            });

            setShowIDTypeSuccess(true);
          } catch (error) {}
        } else {
          setCitizenDetails({
            ...citizenDetails,
            beAReporter: TRUE,
            isKYCDone: TRUE,
            nameInDoc: kycDetails.fullname,
            tcConsent: TRUE,
          });
          setIsUpdateFunc(true);
        }
      }
      if (!response?.isVerified) {
        setShowErrorModal(true);
        setDisableBtn(false);
      }
    } catch (error) {}
  };

  const IdTypeLabel = (id) => {
    let label = '';
    if (id === DRIVING_LICENSE) {
      label = t('driving-license-number');
    } else if (id === AADHAR) {
      label = t('aadhar-number');
    } else if (id === PASSPORT) {
      label = t('passport-file-number');
    }
    return label;
  };

  const UpdatePreferences = async () => {
    try {
      await userProfileService.updateUserProfile({
        userId: userId,
        userType: CITIZEN_REPORTER_CAPITAL,
        citizenJournalist: JSON.stringify(citizenDetails),
      });

      setShowIDTypeSuccess(true);
    } catch (error) {}
  };

  const fetchPreferences = () => {
    setCitizenDetails({
      beAReporter: userState?.citizenJournalist?.beAReporter,
      isKYCDone: userState?.citizenJournalist?.isKYCDone,
      isTrainingDone: userState?.citizenJournalist?.isTrainingDone,
      nameInDoc: userState?.citizenJournalist?.nameInDoc,
      tcConsent: userState?.citizenJournalist?.tcConsent,
    });
  };

  const resendOtp = async () => {
    if (sendOtp) {
      let preparedData = {
        docType: kycDetails.idType,
        docNumber: kycDetails.docNumber,
        dob: format(kycDetails.dob, DATE_FORMAT),
        name: kycDetails.fullname,
      };
      const kycData = JSON.stringify(preparedData);
      try {
        const encryptedData = await encrypt(kycData);
        const response = await ekycService.aadharOtp({
          encodedData: encryptedData,
        });
        response && setSendOtp(false);
        setResetTimer(true);
      } catch (error) {}
    }
  };

  const closeFailureModal = () => {
    setShowErrorModal(false);
    setShowOtpForm(false);
    setKycDetails(initialKycDetails);
    setDisableBtn(false);
    navigate(0);
  };

  return (
    <Box>
      {showOtpForm ? (
        <form onSubmit={onHandleOtpSubmit}>
          <Box sx={isMobileMax ? {} : { padding: '0 4.666vw' }}>
            <Box
              className={
                isMobileMax ? 'reporterOuterBox' : 'reprterOuterBoxLaptop'
              }
            >
              {isMobileMax ? (
                <>
                  <Box
                    sx={{ alignItems: isDarkMode ? '' : 'baseline' }}
                    className='subDisplay'
                  >
                    <span>
                      <img
                        src={isDarkMode ? ArrowLeftWhite : ArrowLeftIcon}
                        className={isDarkMode ? 'reporterHeadingIcon' : ''}
                        alt='back-icon'
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </span>
                    <div className={'reporterMobileHeading'}>
                      {t('become-a-reporter')}
                    </div>
                  </Box>
                  <hr className='reporterHeadingLine' />
                  <div className='kycMobileSteps'>
                    <Grid container direction='row' justifyContent={'center'}>
                      <div
                        className={'activeKycStep'}
                        style={{ marginRight: '0.5rem' }}
                      >
                        {ONE}
                      </div>
                      <Box
                        className='inactiveKycStep'
                        sx={{
                          background: isDarkMode
                            ? DARK_COLOR.grey[400]
                            : LIGHT_COLOR.grey[300],
                        }}
                      >
                        {TWO}
                      </Box>
                    </Grid>
                    <div className='activeMobileKycStepName'>
                      {t('kyc-verification')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='subDisplay'>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '1.125rem',
                          color: isDarkMode
                            ? DARK_COLOR.white[100]
                            : LIGHT_COLOR.grey[600],
                        }}
                      >
                        {t('become-a-reporter')}
                      </Typography>
                    </Box>
                  </div>
                  <div className='kycSteps'>
                    <Box
                      className={
                        citizenDetails.isKYCDone === TRUE
                          ? 'successfullKyc'
                          : 'activeKycStep'
                      }
                      sx={{
                        background:
                          citizenDetails.isKYCDone === TRUE
                            ? isDarkMode
                              ? LIGHT_COLOR.primary[25]
                              : LIGHT_COLOR.primary[25]
                            : isDarkMode
                            ? DARK_COLOR.primary[100]
                            : LIGHT_COLOR.primary[100],
                      }}
                    >
                      {citizenDetails.isKYCDone === TRUE ? (
                        <img src={KycCompletedIcon} alt='kyc' />
                      ) : (
                        ONE
                      )}
                    </Box>
                    <div
                      className={
                        citizenDetails.isKYCDone === TRUE
                          ? 'successfullKycName'
                          : 'activeKycStepName'
                      }
                    >
                      {t('kyc-verification')}
                    </div>
                    <Box
                      className='inactiveKycStep'
                      sx={{
                        background: isDarkMode
                          ? DARK_COLOR.grey[400]
                          : LIGHT_COLOR.grey[300],
                      }}
                    >
                      {TWO}
                    </Box>
                    <div className='inactiveKycStepName'>
                      {isOnboardedReporter
                        ? t('training')
                        : t('training-and-assessment')}
                    </div>
                  </div>
                </>
              )}
              <Box className={isMobileMax ? '' : 'reporterBoxLaptop'}>
                <Box
                  className={!isMobileMax ? 'reporterDetailedBox' : ''}
                  sx={{
                    background: isDarkMode
                      ? !isMobileMax && DARK_COLOR.grey[200]
                      : !isMobileMax && LIGHT_COLOR.white[100],
                  }}
                >
                  <div className='otpSection'>
                    <Stack spacing={2}>
                      <div className={'reporterKycContainer'}>
                        <TextField
                          className='ReporterTextField'
                          label={t('full-name')}
                          variant='outlined'
                          name='fullname'
                          value={kycDetails.fullname}
                          disabled
                        />
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <TextField
                          className='ReporterTextField'
                          label={t('country')}
                          variant='outlined'
                          name='country'
                          value={t('india')}
                          disabled
                        ></TextField>
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <TextField
                          className='ReporterTextField'
                          label={t('id-type')}
                          variant='outlined'
                          name='idType'
                          value={t('aadhar-card')}
                          disabled
                        ></TextField>
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <DatePicker
                          components={
                            !isMobile
                              ? {
                                  OpenPickerIcon: isDarkMode
                                    ? CalenderIconDarkMui
                                    : calendarIconMui,
                                }
                              : {}
                          }
                          label={t('date-of-birth')}
                          className='reporterTextField'
                          value={kycDetails.dob}
                          disabled
                          InputProps={
                            isMobile
                              ? {
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <img
                                        src={
                                          isDarkMode
                                            ? calendarDarkIcon
                                            : calendarIcon
                                        }
                                        alt='calendar icon'
                                      />
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                          renderInput={(params) => <TextField {...params} />}
                          views={['year', 'month', 'day']}
                        />
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <TextField
                          error={errDetails.otp?.length ? true : false}
                          helperText={
                            errDetails.otp?.length ? errDetails.otp : ''
                          }
                          inputRef={inputRef}
                          className='ReporterTextField'
                          label={t('6-digit-otp')}
                          variant='outlined'
                          name='otp'
                          value={kycDetails.otp}
                          onInput={(e) => {
                            e.target.value = e.target.value.slice(0, 6);
                          }}
                          onChange={handleChange}
                        />
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className='reporterKycContainer'>
                        <div className='reporterCounter'>
                          <Timer
                            resetTimer={resetTimer}
                            resetOtp={() => setSendOtp(true)}
                            hideTimer={sendOtp}
                          />
                        </div>
                        <div
                          className={
                            sendOtp
                              ? 'reporterResendCode'
                              : 'disableReporterResendCode'
                          }
                          disabled={true}
                          onClick={resendOtp}
                        >
                          {t('resend-otp')}
                        </div>
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <Grid item xs={2}>
                          <Checkbox
                            checked={isTerms}
                            onChange={(_, checked) => {
                              setIsTerms(checked);
                            }}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <div className='reporterTerms'>
                            <span className='space'>{t('i-agree-to')}</span>
                            <span
                              className='reporterNavLink'
                              onClick={() =>
                                window.open(
                                  '/citizen-journalism-guidelines',
                                  '_blank',
                                  'noopener,noreferrer',
                                )
                              }
                            >
                              {t('citizen-journalism-guidelines')}
                            </span>
                          </div>
                        </Grid>
                      </div>
                    </Stack>
                    <Stack>
                      <div className={'reporterKycContainer'}>
                        <Box className='reporterBtn'>
                          <Button
                            disabled={
                              !isTerms ||
                              kycDetails.otp.length <= 0 ||
                              disableBtn
                            }
                            variant='contained'
                            type='submit'
                          >
                            {t('submit')}
                          </Button>
                        </Box>
                      </div>
                    </Stack>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            background: isDarkMode
              ? isMobile && '#2c2c2c'
              : isMobile && '#FFFFFF',
          }}
        >
          <form onSubmit={onHandleSubmit}>
            <Box sx={isMobileMax ? {} : { padding: '0 4.666vw' }}>
              <Box
                className={
                  isMobileMax ? 'reporterOuterBox' : 'reprterOuterBoxLaptop'
                }
              >
                {isMobileMax ? (
                  <>
                    <Box
                      sx={{ alignItems: isDarkMode ? '' : 'baseline' }}
                      className='subDisplay'
                    >
                      <span>
                        <img
                          src={isDarkMode ? ArrowLeftWhite : ArrowLeftIcon}
                          className={isDarkMode ? 'reporterHeadingIcon' : ''}
                          alt='back-icon'
                          onClick={() => {
                            navigate(-1);
                          }}
                        />
                      </span>{' '}
                      <div className={'reporterMobileHeading'}>
                        <Typography
                          variant={isMobile ? 'subtitle1' : 'h5'}
                          sx={{ fontWeight: 600 }}
                        >
                          {t('become-a-reporter')}
                        </Typography>
                      </div>
                    </Box>
                    <hr className='reporterHeadingLine' />
                    <div className='kycMobileSteps'>
                      <Grid
                        container
                        direction='row'
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <img
                          src={tickLight}
                          alt='completed-training-icon'
                          style={{
                            width: '2.5rem',
                            marginRight: '1.25rem',
                          }}
                        />
                        <Box
                          className='activeKycStep'
                          sx={{
                            background: isDarkMode
                              ? DARK_COLOR.primary[100]
                              : LIGHT_COLOR.primary[100],
                          }}
                        >
                          {TWO}
                        </Box>
                      </Grid>
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '1.125rem',
                          margin: '20px 0 40px',
                          color: (theme) => theme.palette.primary.main,
                        }}
                      >
                        {t('kyc-verification')}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='subDisplay'>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: '600',
                            fontSize: '1.125rem',
                            color: isDarkMode
                              ? DARK_COLOR.white[100]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {t('become-a-reporter')}
                        </Typography>
                      </Box>
                    </div>
                    <div className='kycSteps'>
                      <img
                        src={tickLight}
                        alt='completed-training-icon'
                        style={{
                          width: '2.5rem',
                        }}
                      />
                      <div className='inactiveKycStepName'>
                        {isOnboardedReporter
                          ? t('training')
                          : t('training-and-assessment')}
                      </div>
                      <Box
                        className='activeKycStep'
                        sx={{
                          background: isDarkMode
                            ? DARK_COLOR.primary[100]
                            : LIGHT_COLOR.primary[100],
                        }}
                      >
                        {TWO}
                      </Box>
                      <div className='activeKycStepName'>
                        {t('kyc-verification')}
                      </div>
                    </div>
                  </>
                )}

                <Box
                  className={isMobileMax ? 'reporterBox' : 'reporterBoxLaptop'}
                >
                  <Box
                    className={!isMobileMax ? 'reporterDetailedBox' : ''}
                    sx={{
                      background: isDarkMode
                        ? !isMobileMax && DARK_COLOR.grey[200]
                        : !isMobileMax && LIGHT_COLOR.white[100],
                    }}
                  >
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <TextField
                          error={errDetails.fullname?.length ? true : false}
                          helperText={
                            errDetails.fullname?.length
                              ? errDetails.fullname
                              : ''
                          }
                          inputRef={inputRef}
                          className={
                            errDetails.fullname
                              ? 'errReporterTextField'
                              : 'ReporterTextField'
                          }
                          label={
                            <Typography
                              variant='subtitle1'
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[400]
                                  : LIGHT_COLOR.grey[300],
                              }}
                            >
                              {t('full-name')}
                            </Typography>
                          }
                          variant='outlined'
                          name='fullname'
                          value={kycDetails.fullname}
                          onChange={handleChange}
                        />
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <TextField
                          select
                          error={errDetails.country?.length ? true : false}
                          helperText={
                            errDetails.country?.length ? errDetails.country : ''
                          }
                          className={
                            errDetails.country
                              ? 'errReporterTextField'
                              : 'ReporterTextField'
                          }
                          label={
                            <Typography
                              variant='subtitle1'
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[400]
                                  : LIGHT_COLOR.grey[300],
                              }}
                            >
                              Country
                            </Typography>
                          }
                          variant='outlined'
                          name='country'
                          value={kycDetails.country}
                          onChange={handleChange}
                        >
                          <MenuItem value={'india'}>{t('india')}</MenuItem>
                        </TextField>
                      </div>
                    </Stack>
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <TextField
                          select
                          error={errDetails.idType?.length ? true : false}
                          helperText={
                            errDetails.idType?.length ? errDetails.idType : ''
                          }
                          className={
                            errDetails.idType
                              ? 'errReporterTextField'
                              : 'ReporterTextField'
                          }
                          label={
                            <Typography
                              variant='subtitle1'
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[400]
                                  : LIGHT_COLOR.grey[300],
                              }}
                            >
                              {t('id-type')}
                            </Typography>
                          }
                          variant='outlined'
                          name='idType'
                          value={kycDetails.idType}
                          onChange={handleChange}
                        >
                          <MenuItem value={AADHAR}>{t('aadhar-card')}</MenuItem>
                          <MenuItem value={DRIVING_LICENSE}>
                            {t('driving-license')}
                          </MenuItem>
                          <MenuItem value={PASSPORT}>{t('passport')}</MenuItem>
                        </TextField>
                      </div>
                    </Stack>
                    {kycDetails.idType && (
                      <Stack spacing={4}>
                        <div className={'reporterKycContainer'}>
                          <TextField
                            error={errDetails.docNumber?.length ? true : false}
                            helperText={
                              errDetails.docNumber?.length
                                ? errDetails.docNumber
                                : ''
                            }
                            onInput={(e) => {
                              kycDetails.idType === AADHAR &&
                                (e.target.value = e.target.value.slice(
                                  INITIAL_VALUE,
                                  12,
                                ));

                              kycDetails.idType === PASSPORT &&
                                (e.target.value = e.target.value.slice(
                                  INITIAL_VALUE,
                                  15,
                                ));

                              kycDetails.idType === DRIVING_LICENSE &&
                                (e.target.value = e.target.value.slice(
                                  INITIAL_VALUE,
                                  16,
                                ));
                            }}
                            className={
                              errDetails.docNumber
                                ? 'errReporterTextField'
                                : 'ReporterTextField'
                            }
                            label={
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[400]
                                    : LIGHT_COLOR.grey[300],
                                }}
                              >
                                {IdTypeLabel(kycDetails.idType)}
                              </Typography>
                            }
                            variant='outlined'
                            name='docNumber'
                            value={
                              prevIdType?.current?.idType !== kycDetails.idType
                                ? ''
                                : kycDetails.docNumber
                            }
                            onChange={handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  {kycDetails.idType === PASSPORT && (
                                    <Tooltip
                                      placement='top'
                                      title={t('file-number')}
                                    >
                                      <IconButton>
                                        <img
                                          src={
                                            isDarkMode
                                              ? InfoIconWhite
                                              : InfoIcon
                                          }
                                          alt='info-icon'
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </InputAdornment>
                              ),
                              style: {
                                paddingRight: '0',
                              },
                            }}
                          />
                        </div>
                      </Stack>
                    )}
                    <Stack spacing={4}>
                      <div className={'reporterKycContainer'}>
                        <DatePicker
                          components={
                            !isMobile
                              ? {
                                  OpenPickerIcon: isDarkMode
                                    ? CalenderIconDarkMui
                                    : calendarIconMui,
                                }
                              : {}
                          }
                          dateFormat='dd-mm-yyyy'
                          maxDate={new Date()}
                          label={
                            <Typography
                              variant='subtitle1'
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[400]
                                  : LIGHT_COLOR.grey[300],
                              }}
                            >
                              {t('date-of-birth')}
                            </Typography>
                          }
                          className={
                            errDetails.dob
                              ? 'errreporterTextField'
                              : 'reporterTextField'
                          }
                          value={kycDetails.dob}
                          helperText={
                            errDetails.dob?.length ? errDetails.dob : ''
                          }
                          onChange={handleDob}
                          InputProps={
                            isMobile
                              ? {
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <img
                                        src={
                                          isDarkMode
                                            ? calendarDarkIcon
                                            : calendarIcon
                                        }
                                        alt='calendar icon'
                                      />
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={errDetails.dob?.length ? true : false}
                              helperText={
                                errDetails.dob?.length ? errDetails.dob : ''
                              }
                            />
                          )}
                          views={['year', 'month', 'day']}
                        />
                      </div>
                    </Stack>
                    {kycDetails.idType && kycDetails.idType !== AADHAR && (
                      <Stack spacing={4}>
                        <div className={'reporterKycContainer'}>
                          <Grid item xs={2}>
                            <Checkbox
                              checked={isTerms}
                              onChange={(_, checked) => {
                                setIsTerms(checked);
                              }}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <div>
                              <Typography
                                component='span'
                                sx={{
                                  marginRight: '0.25rem',
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[500]
                                    : LIGHT_COLOR.grey[400],
                                  fontSize: '0.875rem',
                                  textAlign: 'left',
                                }}
                              >
                                {t('i-agree-to')}
                              </Typography>
                              <Typography
                                component='span'
                                sx={{
                                  textAlign: 'left',
                                  cursor: 'pointer',
                                  fontSize: '0.875rem',
                                  color: (theme) => theme.palette.primary.main,
                                }}
                                onClick={() =>
                                  window.open(
                                    '/citizen-journalism-guidelines',
                                    '_blank',
                                    'noopener,noreferrer',
                                  )
                                }
                              >
                                {t('citizen-journalism-guidelines')}
                              </Typography>
                            </div>
                          </Grid>
                        </div>
                      </Stack>
                    )}
                    <Stack>
                      <div className={'reporterKycContainer'}>
                        <Box
                          className={
                            kycDetails.idType !== AADHAR
                              ? 'reporterBtn'
                              : 'reporterOutlineBtn'
                          }
                        >
                          <Button
                            variant={
                              kycDetails.idType !== AADHAR
                                ? 'contained'
                                : 'outlined'
                            }
                            disabled={
                              disableBtn ||
                              (kycDetails.idType !== AADHAR && !isTerms)
                            }
                            type='submit'
                            sx={{
                              background:
                                kycDetails.idType !== AADHAR
                                  ? isTerms
                                    ? isDarkMode
                                      ? DARK_COLOR.primary[100]
                                      : LIGHT_COLOR.primary[100]
                                    : !isDarkMode
                                    ? LIGHT_COLOR.grey[300]
                                    : DARK_COLOR.grey[300]
                                  : '',
                            }}
                          >
                            {kycDetails.idType === AADHAR
                              ? t('send-otp')
                              : t('submit')}
                          </Button>
                        </Box>
                      </div>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      )}

      <ModalBox
        showModal={showIDTypeSuccess || showErrorModal}
        closeModal={
          showIDTypeSuccess
            ? () => navigate('/congratulations')
            : closeFailureModal
        }
        modalHeader={t('kyc-verification')}
        modalSize={true}
      >
        <Stack>
          <div className='passwordFlexContainer'>
            <img
              src={showIDTypeSuccess ? completedIcon : failedIcon}
              alt='completedIcon'
              className='forgotCompleteIcon'
            />
          </div>
        </Stack>
        <Stack>
          <div className='reporterKycContainer'>
            <Typography variant='h5'>
              {showIDTypeSuccess ? t('verified') : `${t('failed')}!`}
            </Typography>
          </div>
        </Stack>
        <Stack>
          <div className='reporterKycContainer'>
            <Typography
              sx={{
                textAlign: 'center',
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[400],
              }}
            >
              {showIDTypeSuccess
                ? t('awesome-you-have-successfully-completed-kyc-verification')
                : kycDetails.idType === AADHAR
                ? t('UIDAI-ERR-MSG')
                : t('oops-your-kyc-verification-failed-please-try-again')}
            </Typography>
          </div>
        </Stack>
        <Stack>
          <div className='reporterKycContainer'>
            <div className='reporterBtn'>
              <Button
                variant='contained'
                onClick={
                  showIDTypeSuccess
                    ? () => navigate('/congratulations')
                    : closeFailureModal
                }
              >
                {showIDTypeSuccess ? t('continue') : t('try-again')}
              </Button>
            </div>
          </div>
        </Stack>
      </ModalBox>
    </Box>
  );
};

export default Reporter;
