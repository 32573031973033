import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IndeterminateCheckedBoxIcon = (props) => {
  const { fill, stroke } = props;
  return (
    <SvgIcon
      viewBox='0 0 20 20'
      fill='none'
      width='20'
      height='20'
      rx='4.5'
      fontSize='small'
    >
      <rect width='20' height='20' rx='5' fill={fill} stroke={stroke} />
      <rect x='6' y='9' width='8' height='2' rx='1' fill='white' />
    </SvgIcon>
  );
};

export default IndeterminateCheckedBoxIcon;
