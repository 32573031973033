import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';
import { api } from '../Constants/api.constants';

/**
 * Make api call for updating Notification Push/Email Preferences
 * @returns promise
 */
const savePreferences = (data) => {
  return apiRequest(api.notificationPreferences, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Notification Push/Email Preferences
 * @returns promise
 */
const fetchPreferencesList = (data) => {
  return apiRequest(api.notificationPreferences, METHOD.GET, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Notification List
 * @returns promise
 */
const fetchNotificationsList = (data) => {
  return apiRequest(api.notification, METHOD.POST, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for deleting Notification
 * @returns promise
 */
const deleteNotification = (data) => {
  return apiRequest(
    `${api.notification}/${data.notification_id}`,
    METHOD.DELETE,
    false,
    null,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for updating Notification status to read
 * @returns promise
 */
const updateNotification = (data) => {
  return apiRequest(
    `${api.notification}/${data.notification_id}`,
    METHOD.PUT,
    false,
    null,
    true,
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for fetching Author Details
 * @param {object} data
 * @returns promise
 */
const fetchAuthorDetails = (data) => {
  return apiRequest(api.authorDetails, METHOD.GET, true, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Notification Unread Count
 * @returns promise
 */
const fetchNotificationsUnreadCount = (data) => {
  return apiRequest(api.notificationUnreadCount, METHOD.GET, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for update notification status for read
 * @returns promise
 */

const updateNotificaitionStatus = (data = null) => {
  return apiRequest(api.notificationReadUpdate, METHOD.PUT, false, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const notificationService = {
  fetchPreferencesList,
  savePreferences,
  fetchNotificationsList,
  deleteNotification,
  updateNotification,
  fetchAuthorDetails,
  fetchNotificationsUnreadCount,
  updateNotificaitionStatus,
};

export default notificationService;
