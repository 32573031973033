import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  Divider,
  Box,
  InputAdornment,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UserDetailsProvider } from '../../store/user-details-context';
import completedIcon from '../../assets/completed.png';
import editIcon from '../../assets/icons/edit.svg';
import editDarkIcon from '../../assets/icons/editDark.svg';
import CalenderIconDarkMui from '../icons/calendar/CalenderIconDark';
import calendarIconMui from '../icons/calendar/CalenderIconLight';
import calendarIcon from '../../assets/icons/calendar.svg';
import calendarDarkIcon from '../../assets/icons/calendar-dark.svg';
import './Profile.css';
import avatarIcon from '../../assets/defaultProfilePic.png';
import verifiedIcon from '../../assets/icons/success-tick.svg';
import { useTranslation } from 'react-i18next';
import {
  NAMEREG,
  MALE,
  FEMALE,
  OTHERS,
  AGE_TILL_TWENTYFIVE,
  AGE_TILL_FORTY,
  AGE_TILL_SIXTY,
  AGE_ABOVE_SIXTY,
  DATE_FORMAT,
  ZERO,
  CHARACTER_LIMIT,
} from '../../Constants/Constants';
import EditProfile from './EditProfile';
import EditProfileMobile from './EditProfileMobile';
import userProfileService from '../../services/user-profile.service';
import authService from '../../services/auth.service';
import { format } from 'date-fns';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { validateProfileName } from '../../utils/profile-util';
import ButtonLoader from '../buttonLoader/ButtonLoader';

const Profile = (props) => {
  const {
    changeHeight,
    closeModal,
    navigateToHome,
    handleVerification,
    showSuccessMsg,
    setShowSuccessMsg,
  } = props;
  const userProfilePic = useRef(null);
  const initialUserValue = {
    email: '',
    phone: '',
    username: '',
    gender: '',
    aboutMe: '',
    dob: '',
    ageGroup: '',
    location: '',
    joiningDate: '',
  };
  const initialErrMsg = {
    firstName: '',
    lastName: '',
    email: '',
    validateEmail: '',
    validateUsername: '',
    phone: '',
    validatePhone: '',
    validateLocation: '',
  };
  const initailFullName = {
    firstName: '',
    lastName: '',
  };
  const [userDetail, setUserDetail] = useState(initialUserValue);
  const [errMsg, setErrMsg] = useState('');
  const [editProfileErr, setEditProfileUpErr] = useState(initialErrMsg);
  const [updateProfileImg, setUpdateProfileImg] = useState(false);
  const [isCreateFollowing, setCreateFollowing] = useState(false);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [fullname, setFullname] = useState(initailFullName);
  const [userFullName, setUserFullName] = useState('');
  const [isPhoneNumberVerified, setPhoneNumberVerified] = useState(false);
  const [profileUrl, setProfileUrl] = useState('');
  const [dateOfBirth, setDobOfBirth] = useState('');

  const { state: userState } = useContext(UserDetailsProvider);
  const isPhoneVerified = userState?.userDetails?.isPhoneVerified;
  const userId = userState?.userDetails?.userId;
  const userName = userState?.userDetails?.userName;
  const email = userState?.userDetails?.email;
  const phoneNumber = userState?.userDetails?.phone;
  const isEmailVerified = userState?.userDetails?.isEmailVerified;
  const gender = userState?.userDetails?.gender;
  const aboutMe = userState?.userDetails?.aboutMe;
  const dob = userState?.userDetails?.dob;
  const ageGroup = userState?.userDetails?.ageGroup;
  const pincode = userState?.userDetails?.pincode;
  const joiningDate = userState?.userDetails?.joiningDate;
  const location = userState?.userDetails?.location;
  const firstName = userState?.userDetails?.firstName;
  const lastName = userState?.userDetails?.lastName;
  const { t } = useTranslation();
  const below821 = MinWidth(990);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.mobile);
  const [saveLoader, setSaveLoader] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState({
    url: null,
    isUpload: false,
    isRemove: false,
  });

  useEffect(() => {
    isCreateFollowing && CreatePreference();
    updateProfileImg && UpdatePreferences();
  }, [isCreateFollowing, updateProfileImg]);

  useEffect(() => {
    if (isPhoneVerified === true) {
      setPhoneNumberVerified(true);
    }
  }, [isPhoneVerified]);

  useEffect(() => {
    if (firstName || lastName) {
      setUserFullName(fullname?.firstName + ' ' + fullname?.lastName);
    } else {
      setUserFullName('');
    }
  }, [fullname]);

  useEffect(() => {
    if (profileUrl) {
      setIsImageDeleted(true);
    }
  }, [profileUrl]);

  /**
   * Side Effect to Fetch Profile Picture with userId as dependency
   */
  useEffect(() => {
    fetchUserProfile();
  }, [userId]);

  const onHandleChange = (e) => {
    const file = e.target.files[0];
    let url = {
      fileUrl: URL.createObjectURL(file),
      filename: file.name,
      file: file,
    };
    setSelectedAvatar((prev) => {
      return { ...prev, url, isUpload: true, isRemove: false };
    });
    setIsImageDeleted(true);
  };

  const onAvatarRemove = (url) => {
    setSelectedAvatar((prev) => {
      return { ...prev, url, isUpload: false, isRemove: true };
    });
    setProfileUrl('');
    setIsImageDeleted(false);
  };

  /**
   * Function to Fetch Profile Picture of Current user
   */
  const fetchUserProfile = async () => {
    try {
      const response = await userProfileService.getProfileImage(userId);
      setProfileUrl(response);
    } catch (error) {}
  };

  const uploadProfileImage = async (url) => {
    await userProfileService
      .uploadProfileImage({
        userId: userId,
        image: url.file,
      })
      .then((data) => {
        userProfileService.getProfileImage(data.key).then((response) => {
          setProfileUrl(response);
        });
      });
  };

  const replaceImage = (error) => {
    userFullName.length === 0
      ? (error.target.src = avatarIcon)
      : setProfileUrl('');
    setIsImageDeleted(false);
  };

  const handleChange = (e) => {
    setFullname({ ...fullname, [e.target.name]: e.target.value });
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
    setEditProfileUpErr({ ...editProfileErr, [e.target.name]: '' });
  };
  useEffect(() => {
    setUserDetail({
      ...userDetail,
      username: userName,
      email: email,
    });
  }, [userState]);

  useEffect(() => {
    fetchPreferences();
  }, [userState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errMsg = {};

    if (
      !NAMEREG.test(fullname.firstName) ||
      !(fullname.firstName && fullname.firstName.trim())
    ) {
      errMsg['firstName'] = t('ENTER_VALID_FIRSTNAME');
    }
    if (
      !NAMEREG.test(fullname.lastName) ||
      !(fullname.lastName && fullname.lastName.trim())
    ) {
      errMsg['lastName'] = t('ENTER_VALID_LASTNAME');
    }
    if (!NAMEREG.test(userDetail.location)) {
      errMsg['validateLocation'] = t('ENTER_VALID_LOCATION');
    }
    setEditProfileUpErr(errMsg);
    if (
      NAMEREG.test(fullname.firstName) &&
      NAMEREG.test(fullname.lastName) &&
      NAMEREG.test(userDetail.location) &&
      fullname.firstName &&
      fullname.firstName.trim() &&
      fullname.lastName &&
      fullname.lastName.trim()
    ) {
      UpdatePreferences();
    }
  };

  const fetchPreferences = () => {
    setFullname({
      firstName: firstName,
      lastName: lastName,
    });
    setUserDetail({
      email:
        email !== 'undefined'
          ? email
          : localStorage.getItem('email')
          ? localStorage.getItem('email')
          : '',
      phone:
        phoneNumber !== 'undefined'
          ? phoneNumber
          : localStorage.getItem('phone_number')
          ? localStorage.getItem('phone_number')
          : '',
      gender: gender,
      aboutMe: aboutMe,
      dob: dob,
      ageGroup: ageGroup,
      location: location,
      username: userName ? userName : localStorage.getItem('username'),
      pincode: pincode,
      joiningDate: joiningDate,
    });
    setDobOfBirth(
      new Date(dob?.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')),
    );
  };

  const CreatePreference = async () => {
    try {
      await userProfileService.createUserProfile({
        userId: userId,
        userDetails: JSON.stringify(userDetail),
      });

      setCreateFollowing(false);
      fetchPreferences();
    } catch (err) {
      setErrMsg(err);
    }
  };

  const UpdatePreferences = async () => {
    setSaveLoader(true);
    try {
      await userProfileService.updateUserProfile({
        userId: userId ? userId : localStorage.getItem('userID'),
        firstName: fullname?.firstName || null,
        lastName: fullname?.lastName || null,
        userDetails: JSON.stringify({
          ...userDetail,
          dob: dateOfBirth ? dateOfBirth : format(userDetail.dob, DATE_FORMAT),
        }),
      });
      if (selectedAvatar?.isUpload) {
        await uploadProfileImage(selectedAvatar.url);
      }
      if (selectedAvatar?.isRemove) {
        await deleteProfilePic(selectedAvatar.url);
      }
      setSaveLoader(false);
      changeHeight(true);
      setShowSuccessMsg(true);
      setUpdateProfileImg(false);
      errMsg('');
      setSelectedAvatar({
        url: null,
        isUpload: false,
        isRemove: false,
      });
    } catch (error) {
      setSaveLoader(false);
    }
  };

  const deleteProfilePic = async (url) => {
    await userProfileService
      .deleteProfileImage({
        userId: userId,
        image: url.file,
      })
      .then(() => {
        setProfileUrl('');
        setIsImageDeleted(false);
      });
  };

  const handleUserVerification = async (type) => {
    let userAttribute;
    if (type === 'email') {
      userAttribute = 'email';
    } else {
      userAttribute = 'phone_number';
    }

    await authService
      .verifyUser(userAttribute)
      .then((response) => {
        if (response) {
          handleVerification(userAttribute);
        }
      })
      .catch((err) => {});
  };

  /**
   * Function to format the onChange value of DOB
   */
  const handleDob = (dateOfBirth) => {
    setUserDetail({
      ...userDetail,
      dob: dateOfBirth,
    });
    setDobOfBirth('');
  };

  return (
    <>
      {showSuccessMsg ? (
        <form>
          <Grid container spacing={2}>
            <Grid container item xs={12} justifyContent='center'>
              <img src={completedIcon} alt='user-icon' />
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
              {t('PROFILE_SUCCESSFULLY_UPDATED')}
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
              <div className='profileBtn'>
                <Button
                  variant='contained'
                  className='save-text'
                  onClick={() => {
                    navigateToHome();
                    closeModal();
                  }}
                >
                  {t('continue')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      ) : (
        <>
          {below821 ? (
            <form onSubmit={handleSubmit}>
              <div className='desktopEditProfileMainContainer'>
                <div className='desktopEditProfileSubContainer'>
                  <div className='desktopEditProfileSectionOne'>
                    <div>
                      <Grid container item xs={12} justifyContent='center'>
                        <input
                          type='file'
                          accept='image/*'
                          ref={userProfilePic}
                          onChange={onHandleChange}
                          hidden={true}
                        />
                        <Grid>
                          {selectedAvatar ||
                          profileUrl ||
                          userFullName?.length === 0 ? (
                            <img
                              onError={replaceImage}
                              src={
                                selectedAvatar?.url?.fileUrl
                                  ? selectedAvatar?.url?.fileUrl
                                  : profileUrl
                                  ? profileUrl
                                  : avatarIcon
                              }
                              alt='user-icon'
                              className='profileImage'
                              width={80}
                              height={80}
                            />
                          ) : (
                            <Box
                              component='span'
                              className='profileLetterAvatar'
                              sx={{
                                backgroundColor: (theme) =>
                                  theme.palette.avatar[100],
                                fontSize: '3rem',
                                color: (theme) => theme.palette.white[100],
                                width: '5rem',
                                height: '5rem',
                                py: '1.8rem',
                              }}
                            >
                              {fullname?.firstName?.length &&
                              fullname?.lastName?.length
                                ? fullname.firstName[0].toUpperCase() +
                                  fullname.lastName[0].toUpperCase()
                                : ''}
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    <EditProfile
                      isImageDeleted={isImageDeleted}
                      userProfilePic={userProfilePic}
                      setUpdateProfileImg={setUpdateProfileImg}
                      deleteProfilePic={onAvatarRemove}
                    />
                  </div>
                  <div className='desktopEditSectionTwo'>
                    <Grid container spacing={2}>
                      <Grid container item xs={12} justifyContent='center'>
                        <TextField
                          className='profileTextField'
                          error={editProfileErr.firstName ? true : false}
                          helperText={
                            editProfileErr.firstName
                              ? editProfileErr.firstName
                              : ''
                          }
                          required={false}
                          label={t('first-name')}
                          variant='outlined'
                          name='firstName'
                          value={fullname.firstName || ''}
                          onChange={(e) => {
                            if (e.target.value.length < 30) {
                              const capitalizeValue = validateProfileName(
                                e.target.value,
                              )
                                ? e.target.value.charAt(0).toUpperCase() +
                                  e.target.value.slice(1)
                                : fullname?.firstName || '';
                              const nameValue = e.target.name;
                              handleChange({
                                target: {
                                  name: nameValue,
                                  value: capitalizeValue,
                                },
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12} justifyContent='center'>
                        <TextField
                          disabled
                          className='profileTextField'
                          label={t('email-address')}
                          variant='outlined'
                          name='email'
                          value={userDetail?.email}
                          InputProps={{
                            endAdornment: userDetail?.email && (
                              <>
                                {isEmailVerified === true ? (
                                  <p className='verified-button'>
                                    <img
                                      alt='verified icon'
                                      className='verified-icon'
                                      src={verifiedIcon}
                                    />
                                    {t('VERIFIED')}
                                  </p>
                                ) : (
                                  <Typography
                                    onClick={() =>
                                      handleUserVerification('email')
                                    }
                                    sx={{
                                      color: isDarkMode
                                        ? DARK_COLOR.primary[100]
                                        : LIGHT_COLOR.primary[100],
                                      fontWeight: '600',
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      marginLeft: ' 0.375rem',
                                    }}
                                  >
                                    {t('cverify')}
                                  </Typography>
                                )}
                              </>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12} justifyContent='center'>
                        <TextField
                          disabled
                          className='profileTextField'
                          label={t('username')}
                          variant='outlined'
                          name='username'
                          value={userDetail?.username}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid container item justifyContent='center'>
                        <TextField
                          label={t('aboutme')}
                          type='text'
                          variant='outlined'
                          fullWidth
                          autoComplete='off'
                          size='small'
                          multiline
                          rows={2}
                          className='profileTextField'
                          name='aboutMe'
                          value={userDetail?.aboutMe}
                          onChange={handleChange}
                          inputProps={{
                            maxlength: CHARACTER_LIMIT,
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.slice(0, 150);
                          }}
                          helperText={`${
                            userDetail?.aboutMe
                              ? userDetail?.aboutMe.length
                              : ZERO
                          }/${CHARACTER_LIMIT}`}
                          required={false}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 80,
                              width: 620,
                            },
                            '& .MuiFormHelperText-root': {
                              marginLeft: '37rem',
                            },
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12} justifyContent='center'>
                        <DatePicker
                          components={
                            isMobile
                              ? {}
                              : {
                                  OpenPickerIcon: isDarkMode
                                    ? CalenderIconDarkMui
                                    : calendarIconMui,
                                }
                          }
                          maxDate={new Date()}
                          label={t('date-of-birth')}
                          className='profileTextField'
                          value={dateOfBirth ? dateOfBirth : userDetail?.dob}
                          onChange={handleDob}
                          InputProps={
                            isMobile
                              ? {
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <img
                                        src={
                                          isDarkMode
                                            ? calendarDarkIcon
                                            : calendarIcon
                                        }
                                        alt='calendar icon'
                                      />
                                    </InputAdornment>
                                  ),
                                }
                              : {}
                          }
                          renderInput={(params) => (
                            <TextField {...params} error={false} />
                          )}
                          views={['year', 'month', 'day']}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className='desktopEditSectionThree'>
                    <Grid container spacing={2}>
                      <Grid container item xs={12} justifyContent='center'>
                        <TextField
                          className='profileTextField'
                          error={editProfileErr.lastName ? true : false}
                          helperText={
                            editProfileErr.lastName
                              ? editProfileErr.lastName
                              : ''
                          }
                          required={false}
                          label={t('last-name')}
                          variant='outlined'
                          name='lastName'
                          value={fullname?.lastName}
                          onChange={(e) => {
                            if (e.target.value.length < 30) {
                              const capitalizeValue = validateProfileName(
                                e.target.value,
                              )
                                ? e.target.value.charAt(0).toUpperCase() +
                                  e.target.value.slice(1)
                                : fullname.lastName || '';
                              const nameValue = e.target.name;
                              handleChange({
                                target: {
                                  name: nameValue,
                                  value: capitalizeValue,
                                },
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12} justifyContent='center'>
                        <TextField
                          disabled
                          className='profileTextField'
                          label={t('phone-number')}
                          variant='outlined'
                          name='phone'
                          value={
                            userDetail?.phone ? userDetail?.phone.slice(3) : ''
                          }
                          InputProps={{
                            endAdornment: (
                              <>
                                {userDetail?.phone &&
                                  (isPhoneNumberVerified === true ? (
                                    <p className='verified-button'>
                                      <img
                                        alt='verified icon'
                                        className='verified-icon'
                                        src={verifiedIcon}
                                      />
                                      {t('VERIFIED')}
                                    </p>
                                  ) : (
                                    <Typography
                                      onClick={() =>
                                        handleUserVerification('phone')
                                      }
                                      sx={{
                                        color: isDarkMode
                                          ? DARK_COLOR.primary[100]
                                          : LIGHT_COLOR.primary[100],
                                        fontWeight: '600',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        marginLeft: ' 0.375rem',
                                      }}
                                    >
                                      {t('cverify')}
                                    </Typography>
                                  ))}
                              </>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12} justifyContent='center'>
                        <div>
                          <TextField
                            select
                            label={t('gender')}
                            variant='outlined'
                            name='gender'
                            className='selectField'
                            value={userDetail.gender}
                            onChange={handleChange}
                          >
                            <MenuItem value='Male'>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[600],
                                }}
                              >
                                {MALE}
                              </Typography>
                            </MenuItem>
                            <MenuItem value='Female'>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[600],
                                }}
                              >
                                {FEMALE}
                              </Typography>
                            </MenuItem>
                            <MenuItem value='Others'>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[600],
                                }}
                              >
                                {OTHERS}
                              </Typography>
                            </MenuItem>
                          </TextField>
                        </div>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent='center'
                        sx={{ marginTop: '7.5rem' }}
                      >
                        <div>
                          <TextField
                            select
                            label={t('age-group')}
                            variant='outlined'
                            name='ageGroup'
                            className='selectField'
                            value={userDetail?.ageGroup}
                            onChange={handleChange}
                          >
                            <MenuItem value='13-25'>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[600],
                                }}
                              >
                                {AGE_TILL_TWENTYFIVE}
                              </Typography>
                            </MenuItem>
                            <MenuItem value='26-40'>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[600],
                                }}
                              >
                                {AGE_TILL_FORTY}
                              </Typography>
                            </MenuItem>
                            <MenuItem value='41-60'>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[600],
                                }}
                              >
                                {AGE_TILL_SIXTY}
                              </Typography>
                            </MenuItem>
                            <MenuItem value='60+'>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[600],
                                }}
                              >
                                {AGE_ABOVE_SIXTY}
                              </Typography>
                            </MenuItem>
                          </TextField>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Divider sx={{ margin: '0 -22px' }} variant='fullWidth' />
                <div className='desktopEditProfileFooter'>
                  <div className='footerMainContainer'>
                    <Box
                      className='desktopProfileBtn'
                      sx={{ marginTop: '20px' }}
                    >
                      <ButtonLoader
                        loading={saveLoader}
                        variant='contained'
                        type='submit'
                      >
                        {t('save')}
                      </ButtonLoader>
                    </Box>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent='center'
                  marginBottom={1}
                >
                  <input
                    type='file'
                    accept='image/*'
                    ref={userProfilePic}
                    onChange={onHandleChange}
                    hidden={true}
                  />
                  <Grid>
                    {selectedAvatar ||
                    profileUrl ||
                    userFullName.length === 0 ? (
                      <img
                        onError={replaceImage}
                        src={
                          selectedAvatar?.url?.fileUrl
                            ? selectedAvatar?.url?.fileUrl
                            : profileUrl
                            ? profileUrl
                            : avatarIcon
                        }
                        alt='user-icon'
                        className='profileImage'
                        width={96}
                        height={96}
                      />
                    ) : (
                      <Box
                        component='span'
                        className='profileLetterAvatar'
                        sx={{
                          backgroundColor: (theme) => theme.palette.avatar[100],
                          fontSize: '3.5rem',
                          color: (theme) => theme.palette.white[100],
                          width: '6rem',
                          height: '6rem',
                          py: '2.2rem',
                        }}
                      >
                        {fullname?.firstName?.length &&
                        fullname?.lastName?.length
                          ? fullname.firstName[0].toUpperCase() +
                            fullname.lastName[0].toUpperCase()
                          : ''}
                      </Box>
                    )}
                  </Grid>

                  {!isImageDeleted && (
                    <div className='userEditIconImage'>
                      <Box
                        className='editIconContainer'
                        sx={{
                          background: isDarkMode
                            ? isMobile
                              ? DARK_COLOR.grey[300]
                              : ''
                            : isMobile
                            ? LIGHT_COLOR.white[100]
                            : '',
                          border: isDarkMode
                            ? isMobile
                              ? `1px solid ${DARK_COLOR.grey[400]}`
                              : ''
                            : isMobile
                            ? `1px solid ${LIGHT_COLOR.grey[200]}`
                            : '',
                        }}
                      >
                        <img
                          alt='edit icon'
                          src={isDarkMode ? editDarkIcon : editIcon}
                          className='editIconButton'
                          onClick={() => userProfilePic.current.click()}
                        />
                      </Box>
                    </div>
                  )}

                  {isImageDeleted && (
                    <div className='userEditIconsImage'>
                      <div className=''>
                        <EditProfileMobile
                          isImageDeleted={isImageDeleted}
                          userProfilePic={userProfilePic}
                          setUpdateProfileImg={setUpdateProfileImg}
                          deleteProfilePic={onAvatarRemove}
                        />
                      </div>
                    </div>
                  )}
                </Grid>

                <Grid container item xs={12} justifyContent='center'>
                  <TextField
                    className='profileTextField'
                    error={editProfileErr.firstName ? true : false}
                    helperText={
                      editProfileErr.firstName ? editProfileErr.firstName : ''
                    }
                    required={false}
                    label={t('first-name')}
                    variant='outlined'
                    name='firstName'
                    value={fullname.firstName}
                    onChange={(e) => {
                      if (e.target.value.length < 30) {
                        const capitalizeValue = validateProfileName(
                          e.target.value,
                        )
                          ? e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1)
                          : fullname.firstName || '';
                        const nameValue = e.target.name;
                        handleChange({
                          target: {
                            name: nameValue,
                            value: capitalizeValue,
                          },
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <TextField
                    className='profileTextField'
                    error={editProfileErr.lastName ? true : false}
                    helperText={
                      editProfileErr.lastName ? editProfileErr.lastName : ''
                    }
                    required={false}
                    label={t('last-name')}
                    variant='outlined'
                    name='lastName'
                    value={fullname.lastName}
                    onChange={(e) => {
                      if (e.target.value.length < 30) {
                        const capitalizeValue = validateProfileName(
                          e.target.value,
                        )
                          ? e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1)
                          : fullname.lastName || '';
                        const nameValue = e.target.name;
                        handleChange({
                          target: {
                            name: nameValue,
                            value: capitalizeValue,
                          },
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <TextField
                    disabled
                    className='profileTextField'
                    label={t('email-address')}
                    variant='outlined'
                    name='email'
                    value={userDetail?.email}
                    InputProps={{
                      endAdornment: userDetail?.email && (
                        <>
                          {isEmailVerified === true ? (
                            <p className='verified-button'>
                              <img
                                alt='verified icon'
                                className='verified-icon'
                                src={verifiedIcon}
                              />
                              {t('VERIFIED')}
                            </p>
                          ) : (
                            <Typography
                              onClick={() => handleUserVerification('email')}
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.primary[100]
                                  : LIGHT_COLOR.primary[100],
                                fontWeight: '600',
                                cursor: 'pointer',
                                textAlign: 'center',
                                marginLeft: ' 0.375rem',
                              }}
                            >
                              {t('cverify')}
                            </Typography>
                          )}
                        </>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <TextField
                    disabled
                    className='profileTextField'
                    label={t('phone-number')}
                    variant='outlined'
                    name='phone'
                    value={userDetail?.phone ? userDetail?.phone.slice(3) : ''}
                    InputProps={{
                      endAdornment: (
                        <>
                          {userDetail?.phone &&
                            (isPhoneVerified === true ? (
                              <p className='verified-button'>
                                <img
                                  alt='verified icon'
                                  className='verified-icon'
                                  src={verifiedIcon}
                                />
                                {t('VERIFIED')}
                              </p>
                            ) : (
                              <Typography
                                sx={{
                                  color: isDarkMode
                                    ? DARK_COLOR.primary[100]
                                    : LIGHT_COLOR.primary[100],
                                  fontWeight: '600',
                                  cursor: 'pointer',
                                  textAlign: 'center',
                                  marginLeft: ' 0.375rem',
                                }}
                              >
                                {t('cverify')}
                              </Typography>
                            ))}
                        </>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <TextField
                    disabled
                    className='profileTextField'
                    label={t('username')}
                    variant='outlined'
                    name='username'
                    value={userDetail.username}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid container item justifyContent='center'>
                  <TextField
                    label={t('aboutme')}
                    type='text'
                    variant='outlined'
                    fullWidth
                    autoComplete='off'
                    size='small'
                    multiline
                    rows={2}
                    className='profileTextField'
                    name='aboutMe'
                    value={userDetail?.aboutMe}
                    onChange={handleChange}
                    inputProps={{
                      maxlength: CHARACTER_LIMIT,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 150);
                    }}
                    helperText={`${
                      userDetail?.aboutMe ? userDetail?.aboutMe.length : ZERO
                    }/${CHARACTER_LIMIT}`}
                    required={false}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: 80,
                      },
                      '& .MuiFormHelperText-root': {
                        marginRight: 0,
                        display: 'flex',
                        justifyContent: 'end',
                      },
                    }}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <>
                    <TextField
                      select
                      label={t('gender')}
                      variant='outlined'
                      name='gender'
                      className='selectField'
                      value={userDetail.gender}
                      onChange={handleChange}
                    >
                      <MenuItem value='Male'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {MALE}
                        </Typography>
                      </MenuItem>
                      <MenuItem value='Female'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {FEMALE}
                        </Typography>
                      </MenuItem>
                      <MenuItem value='Others'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {OTHERS}
                        </Typography>
                      </MenuItem>
                    </TextField>
                  </>
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <>
                    <TextField
                      select
                      label={t('age-group')}
                      variant='outlined'
                      name='ageGroup'
                      className='selectField'
                      value={userDetail.ageGroup}
                      onChange={handleChange}
                    >
                      <MenuItem value='13-25'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {AGE_TILL_TWENTYFIVE}
                        </Typography>
                      </MenuItem>
                      <MenuItem value='26-40'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {AGE_TILL_FORTY}
                        </Typography>
                      </MenuItem>
                      <MenuItem value='41-60'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {AGE_TILL_SIXTY}
                        </Typography>
                      </MenuItem>
                      <MenuItem value='60+'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: isDarkMode
                              ? DARK_COLOR.grey[600]
                              : LIGHT_COLOR.grey[600],
                          }}
                        >
                          {AGE_ABOVE_SIXTY}
                        </Typography>
                      </MenuItem>
                    </TextField>
                  </>
                </Grid>
                <Grid container item xs={12} justifyContent='center'>
                  <DatePicker
                    components={
                      isMobile
                        ? {}
                        : {
                            OpenPickerIcon: isDarkMode
                              ? CalenderIconDarkMui
                              : calendarIconMui,
                          }
                    }
                    maxDate={new Date()}
                    label={t('date-of-birth')}
                    className='profileTextField'
                    value={dateOfBirth ? dateOfBirth : userDetail?.dob}
                    onChange={handleDob}
                    InputProps={
                      isMobile
                        ? {
                            endAdornment: (
                              <InputAdornment position='end'>
                                <img
                                  src={
                                    isDarkMode ? calendarDarkIcon : calendarIcon
                                  }
                                  alt='calendar icon'
                                />
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                    renderInput={(params) => (
                      <TextField {...params} error={false} />
                    )}
                    views={['year', 'month', 'day']}
                  />
                </Grid>
                {errMsg && (
                  <Grid container item xs={12} justifyContent='center'>
                    <Typography variant='caption' className='error-message'>
                      {errMsg}
                    </Typography>
                  </Grid>
                )}
                <Grid container item xs={12} justifyContent='center'>
                  <div className='profileBtn'>
                    <ButtonLoader
                      variant='contained'
                      type='submit'
                      loading={saveLoader}
                      className='save-text'
                    >
                      {t('save')}
                    </ButtonLoader>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default Profile;
