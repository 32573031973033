import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Grid, Box } from '@mui/material';
import './Skeleton.css';
import { MINMINI_V3 } from '../../themes/color.constant';
import { MaxWidth, viewPort, MinWidth } from '../../responsive/viewport.util';

const PublisherSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div key={i}>
        <Skeleton
          height={45}
          count={2}
          style={{ margin: '1.5rem 0 1rem 0.3rem' }}
        />
      </div>
    ));
};
export default PublisherSkeleton;

export const TopicsSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div className='TopicsSkeleton' key={i}>
        <Skeleton height={5} width={'50rem'} count={1} />
      </div>
    ));
};

export const CardSkeleton = ({ cards }) => {
  const isMobile = MaxWidth(viewPort.tablet);
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Box
          sx={{
            backgroundColor: isDarkMode
              ? (theme) => theme.palette.grey.bgDark
              : (theme) => theme.palette.background.white,
            border: ' 1px solid',
            borderColor: isDarkMode
              ? MINMINI_V3.grey.darkGrey
              : MINMINI_V3.primary.offWhite,

            maxWidth:
              aboveLap || (belowLap && aboveMobile) ? '32.5rem' : '28.875rem',
          }}
          className='cardSkeleton'
          key={i}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: aboveLap
                ? '1.1rem 1.5rem'
                : belowLap && aboveMobile
                ? '1.1rem 1.5rem'
                : '1.1rem 0.875rem',
              height: aboveLap
                ? '4.438rem'
                : belowLap && aboveMobile
                ? '4.188rem'
                : '2.75rem',
            }}
          >
            <Skeleton
              circle={true}
              width={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.3rem'
              }
              height={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.3rem'
              }
            />
            <Box sx={{ ml: '0.525rem' }}>
              <Skeleton
                width={
                  aboveLap || (belowLap && aboveMobile) ? '12.5rem' : '7rem'
                }
                height={
                  aboveLap || (belowLap && aboveMobile) ? '2.313rem' : '1.3rem'
                }
              />
            </Box>
          </Box>
          <Box>
            <Skeleton
              style={{
                width: '100%',
                height: isMobile ? '8rem' : '21rem',
                position: 'relative',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              padding: aboveLap
                ? '1.1rem 1.5rem'
                : belowLap && aboveMobile
                ? '1.1rem 1.5rem'
                : '0.5rem 0rem 0.5rem 0.875rem',
            }}
          >
            <Skeleton
              style={{
                width: '9rem',
                height: '0.5rem',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              padding: aboveLap
                ? '1.1rem 1.5rem'
                : belowLap && aboveMobile
                ? '1.1rem 1.5rem'
                : '0rem 0.875rem',
              justifyContent: 'space-between',
              width: '16rem',
            }}
          >
            <Skeleton
              style={{
                width: '5rem',
                height: '1rem',
              }}
            />
            <Skeleton
              style={{
                width: '5rem',
                height: '1rem',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              padding: isMobile
                ? '0.5rem 0.875rem 1rem 0.875rem'
                : '1.1rem 1.5rem',
              justifyContent: 'space-between',
              width:
                aboveLap || (belowLap && aboveMobile)
                  ? '21.25rem'
                  : '12.375rem',
            }}
          >
            <Skeleton
              width={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
              height={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
            />
            <Skeleton
              width={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
              height={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
            />
            <Skeleton
              width={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
              height={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
            />
            <Skeleton
              width={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
              height={
                aboveLap || (belowLap && aboveMobile) ? '2.25rem' : '1.37rem'
              }
            />
          </Box>
        </Box>
      </Box>
    ));
};

export const CardSkeletonGrid = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}
    >
      {Array(cards)
        .fill(0)
        .map((_, i) => (
          <Box
            sx={{
              backgroundColor: isDarkMode
                ? (theme) => theme.palette.grey[200]
                : (theme) => theme.palette.white[100],
              border: '0.063rem solid',
              borderColor: isDarkMode
                ? (theme) => theme.palette.grey[300]
                : (theme) => theme.palette.grey[200],
              maxWidth: '18.75rem',
              maxHeight: '26.5rem',
            }}
            className='cardSkeleton'
            key={i}
          >
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: '.5rem',
                }}
              >
                <Skeleton
                  width={'3.5rem'}
                  height={'1rem'}
                  className='skeleton-logo'
                />
                <Skeleton
                  width={'1rem'}
                  height={'1rem'}
                  className='skeleton-logo'
                />
              </Box>
              <Skeleton
                width={'100%'}
                height={'6rem'}
                className='skeleton-logo'
              />
              <Skeleton
                width={'100%'}
                height={'1rem'}
                className='skeleton-logo'
                style={{ marginTop: '.5rem' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Skeleton
                  width={'5rem'}
                  height={'1rem'}
                  className='skeleton-logo'
                />
                <Skeleton
                  width={'4rem'}
                  height={'1rem'}
                  className='skeleton-logo'
                />
              </Box>
              <Skeleton
                width={'100%'}
                height={'1rem'}
                className='skeleton-logo'
                style={{ marginTop: '.5rem' }}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export const MobileCardSkeleton = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '0.063rem solid',
          borderColor: isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
        className='mobile-card-skeleton'
        key={i}
      >
        <Grid container spacing={1} direction='column'>
          <Grid item>
            <Grid container spacing={0.75} justifyContent='space-between'>
              <Grid item>
                <Skeleton
                  width={'6.5rem'}
                  height={'2.25rem'}
                  className='skeleton-logo'
                />
              </Grid>
              <Grid item>
                <Grid container spacing={0.75}>
                  <Grid item>
                    <Skeleton
                      width={'2rem'}
                      height={'2rem'}
                      className='skeleton-logo'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      width={'2rem'}
                      height={'2rem'}
                      className='skeleton-logo'
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      width={'2rem'}
                      height={'2rem'}
                      className='skeleton-logo'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item spacing={0.75}>
            <Skeleton
              count={1}
              borderRadius={'0.625rem'}
              className='mobile-skeleton-img-column'
            />
          </Grid>
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <Skeleton
                  count={2}
                  style={{ flexGrow: 1 }}
                  className='card-content'
                />
              </Grid>
              <Grid item>
                <Skeleton
                  style={{ flexGrow: 0.75, width: '75%' }}
                  className='card-content'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={1.5}>
              <Grid item>
                <Skeleton
                  width={'6rem'}
                  height={'1.875rem'}
                  className='skeleton-logo'
                />
              </Grid>
              <Grid item>
                <Skeleton
                  width={'6rem'}
                  height={'1.875rem'}
                  className='skeleton-logo'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Skeleton style={{ flexGrow: 1 }} height={'3.375rem'} />
          </Grid>
        </Grid>
      </Box>
    ));
};

export const DetailNewsSkeleton = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '0.063rem solid',
          borderColor: isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
        className='detailNewsSkeleton'
        key={i}
      >
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Skeleton circle width={40} height={40} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton count={1} width={'10rem'} style={{ marginTop: '1rem' }} />
          </Grid>
          <Grid item xs={12}>
            <div className='img-col'>
              <Skeleton count={1} height={'13rem'} />
            </div>
            <Skeleton count={4} width={'40rem'} style={{ marginTop: '1rem' }} />
          </Grid>
        </Grid>
      </Box>
    ));
};

export const NewsPostsSkeleton = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '0.063rem solid',
          borderColor: isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
        className='newsposts-skeleton'
        key={i}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='img-col'>
              <Skeleton count={1} height={'13rem'} />
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className='card-logo'>
              <Skeleton circle width={40} height={40} />
            </div>
            <div className='card-content'>
              <Skeleton
                count={5}
                width={'37rem'}
                style={{ marginBottom: '.6rem' }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    ));
};

export const AuthorNewsCardSkeleton = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '0.063rem solid',
          borderColor: isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
        className='author-news-card-skeleton'
        key={i}
      >
        <Grid container spacing={2}>
          <Grid item xs={4.5}>
            <div className='img-col' style={{ maxWidth: '32rem' }}>
              <Skeleton count={1} height={'14rem'} borderRadius={'0.625rem'} />
            </div>
          </Grid>
          <Grid item xs={7.5}>
            <Grid container spacing={0.75} justifyContent='space-between'>
              <Grid item>
                <div className='card-logo'>
                  <Skeleton width={'12.5rem'} height={'2.25rem'} />
                </div>
              </Grid>
              <Grid item>
                <Grid container spacing={0.75}>
                  <Grid item>
                    <div className='card-logo'>
                      <Skeleton width={'2rem'} height={'2rem'} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className='card-logo'>
                      <Skeleton width={'2rem'} height={'2rem'} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className='card-logo'>
                      <Skeleton width={'2rem'} height={'2rem'} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className='card-content' style={{ marginBottom: '.3rem' }}>
              <Skeleton count={3} style={{ flexGrow: 1 }} />
            </div>
            <Grid container spacing={1.5}>
              <Grid item>
                <Skeleton
                  width={'6rem'}
                  height={'1.875rem'}
                  style={{ marginBottom: '.6rem' }}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  width={'6rem'}
                  height={'1.875rem'}
                  style={{ marginBottom: '.6rem' }}
                />
              </Grid>
            </Grid>
            <div className='card-logo'>
              <Skeleton
                height={'3.375rem'}
                borderRadius={'0.625rem'}
                style={{ flexGrow: 1 }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    ));
};

export const NotificationListSkeleton = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '0.063rem solid',
          borderColor: isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
        className='notification-list-skeleton '
        key={i}
      >
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <div className='card-logo'>
              <Skeleton circle width={40} height={40} />
            </div>
          </Grid>
          <Grid item xs={10}>
            <div className='notification-card-content'>
              <Skeleton
                count={2}
                width={'60rem'}
                style={{ marginBottom: '.6rem' }}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className='notification-option-card-content'>
              <Skeleton
                count={1}
                width={'1.5rem'}
                height={'1.5rem'}
                style={{ marginBottom: '.6rem' }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    ));
};

export const NotificationDropdownSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div className='notification-list-skeleton ' key={i}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <div className='card-logo'>
              <Skeleton circle width={40} height={40} />
            </div>
          </Grid>
          <Grid item xs={11}>
            <div className='notification-card-content'>
              <Skeleton
                count={2}
                width={'17rem'}
                style={{ marginBottom: '.6rem', marginLeft: '3.5rem' }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    ));
};

export const NotificationSettingskeleton = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '0.063rem solid',
          borderColor: isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
        className='notification-settings-list-skeleton '
        key={i}
      >
        <Grid container spacing={2} count={3}>
          <Grid item xs={10}>
            <div className='notification-settings-content'>
              <Skeleton
                count={1}
                width={'34rem'}
                style={{ marginLeft: '1.75rem' }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className='notification-settings-option'>
              <Skeleton count={1} width={'2.188rem'} height={'1.5rem'} />
            </div>
          </Grid>
        </Grid>
      </Box>
    ));
};

export const DistrictsListSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div className='author-news-card-skeleton' key={i}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <div className='card-logo'>
              <Skeleton circle width={40} height={40} />
            </div>
          </Grid>
          <Grid item xs={10}>
            <div className='notification-card-content'>
              <Skeleton
                count={2}
                width={'50rem'}
                style={{ marginBottom: '.6rem' }}
              />
            </div>
          </Grid>
          <Grid item xs={1}>
            <div className='notification-option-card-content'>
              <Skeleton
                count={1}
                width={'7.5rem'}
                height={'2.5rem'}
                style={{ marginBottom: '.6rem' }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    ));
};

export const MobileListsSkeleton = ({ cards }) => {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '0.063rem solid',
          borderColor: isDarkMode
            ? (theme) => theme.palette.grey[300]
            : (theme) => theme.palette.grey[200],
        }}
        className='mobile-districts-skeleton'
        key={i}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className='card-logo'>
              <Skeleton circle width={35} height={35} />
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className='notification-card-content'>
              <Skeleton
                count={1}
                width={'12rem'}
                height={'2rem'}
                style={{ marginBottom: '.6rem' }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className='notification-option-card-content'>
              <Skeleton
                count={1}
                width={'2rem'}
                height={'2rem'}
                style={{ marginBottom: '.6rem' }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    ));
};

export const AuthorSkeletonDesktop = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        marginY: '1rem',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton circle={true} width={64} height={64} />
        <Box sx={{ height: '56px', marginLeft: '24px' }}>
          <Skeleton height={20} width={184} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton height={20} width={70} />
            <Skeleton height={20} width={70} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginY: 'auto' }}>
        <Skeleton height={40} width={170} />
      </Box>
    </Box>
  );
};

export const AuthorSkeletonMobile = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginY: '1rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          paddingRight: '7rem',
          width: '100%',
        }}
      >
        <Skeleton circle={true} width={64} height={64} />
        <Skeleton height={20} width={40} />
        <Skeleton height={20} width={40} />
      </Box>

      <Box sx={{ marginBottom: '1rem', width: '100%' }}>
        <Skeleton height={15} width={170} />
      </Box>
      <Box sx={{ marginY: 'auto', width: '100%' }}>
        <Skeleton height={30} width={'100%'} />
      </Box>
    </Box>
  );
};
