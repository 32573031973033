import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Box, Checkbox, Avatar } from '@mui/material';
import defaultImageIcon from '../../assets/defaultProfilePic.png';
import { useTranslation } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import followersService from '../../services/followers.service';
import userProfileService from '../../services/user-profile.service';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import AntSwitch from './AntSwitch';
import FollowingTabBackdrop from './FollowingTabBackdrop';
import bluetickIcon from '../../assets/icons/blue-influencer-tick-icon.svg';
import { PUBLISHER_TYPE, INFLUENCER } from '../../Constants/Constants';
import { UserDetailsProvider } from '../../store/user-details-context';
import { EnvironmentProvider } from '../../store/firebase-context';
import classes from './publisher.module.css';

const Publishers = () => {
  const { state: userState } = useContext(UserDetailsProvider);
  const follows = userState?.follows;
  const userID = userState?.userDetails?.userId;
  const [publishers, setPublishers] = useState([]);
  const [publishersList, setPublishersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [publishersFollowCount, setPublishersFollowCount] = useState(null);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { t } = useTranslation();
  const isMobile = MaxWidth(viewPort.tablet);
  const { environment } = useContext(EnvironmentProvider);

  /**
   * side effect to calls publisher listing api on initialization
   */
  useEffect(() => {
    fetchPublishersList();
  }, []);

  /**
   * Side Effect to update the followed publishers list once any publisher has been followed/unfollowed
   */
  useEffect(() => {
    updateFollowedPublisher();
  }, [publishersList, follows]);

  /**
   * Follow/Unfollow Action Handler
   * @param {array of objects} publisherData - consisting of isFollow, publisherID key value pairs
   */
  const followUnfollowPublisher = async (publisherId, isFollowedByUser) => {
    try {
      setIsLoading(true);
      if (!Array.isArray(publisherId)) {
        publisherId = [publisherId];
      }
      const followData = {
        userId: userID,
      };
      if (isFollowedByUser) {
        followData['unfollow'] = JSON.stringify({ IDs: publisherId });
      } else {
        followData['follow'] = JSON.stringify({ IDs: publisherId });
      }
      await userProfileService.updateUserFollow(followData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Function to retrieve Followed Publishers
   */
  const fetchPublishersList = async () => {
    setIsLoading(true);

    try {
      const payload = {
        userType: `${PUBLISHER_TYPE},${INFLUENCER}`,
        limit: 1000,
      };
      const response = await followersService.getUsersList(payload);
      if (response) {
        setPublishersList(response.followersList);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateFollowedPublisher = () => {
    const followedList = publishersList?.map((user) => {
      const isFollowedByUser = follows.includes(user.userId);
      return { ...user, isFollowedByUser };
    });

    setPublishers(followedList);
    setPublishersFollowCount(
      followedList.filter((user) => user.isFollowedByUser).length,
    );
  };

  const handleCheckboxChange = (_, checked) => {
    const publishersToUpdate = publishers.map((publisher) => publisher.userId);
    followUnfollowPublisher(publishersToUpdate, !checked);
  };

  return (
    <Box
      sx={{
        maxHeight: isMobile ? '28rem' : '24rem',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          background: 'transparent',
          appearance: 'none',
          width: 0,
          height: 0,
        },
        paddingBottom: '1rem',
      }}
    >
      <FollowingTabBackdrop isLoading={isLoading} />
      <Box
        sx={{
          py: '0.1875rem',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Checkbox
          checked={
            publishersFollowCount !== 0 &&
            publishers?.length === publishersFollowCount
          }
          indeterminate={
            publishersFollowCount !== 0 &&
            publishers?.length !== publishersFollowCount
          }
          onChange={(event, checked) => {
            handleCheckboxChange(event, checked);
          }}
        />{' '}
        <Box
          component='span'
          sx={{
            fontWeight: 600,
            fontSize: '0.875rem',
            color: (theme) =>
              isDarkMode ? theme.palette.grey[600] : theme.palette.grey[400],
            textTransform: 'uppercase',
          }}
        >
          {t('FOLLOW_ALL_CREATORS')}
        </Box>
      </Box>
      {publishers?.map((publisher) => {
        return (
          <Grid
            container
            key={publisher.userId}
            alignItems='center'
            spacing={2.5}
            sx={{
              py: 2,
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Grid item xs={2}>
              <Avatar
                src={
                  publisher?.profile_image
                    ? publisher?.profile_image
                    : `${environment.userprofile_prefix_url}${publisher.userId}`
                }
                className={classes.authorImgCircle}
                alt='author-img'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = defaultImageIcon;
                }}
                sx={{
                  cursor: 'pointer',
                  // position: 'relative',
                  borderRadius: '50%',
                  color: (theme) => theme.palette.white[100],
                  fontSize: '1rem',
                  fontWeight: '600',
                  backgroundColor:
                    publisher?.userName || publisher?.name
                      ? (theme) => theme.palette.avatar[100]
                      : 'unset',
                }}
              >
                {(
                  publisher.userType === INFLUENCER
                    ? publisher?.userName
                    : publisher?.name
                ) ? (
                  `${(
                    (publisher.userType === INFLUENCER
                      ? publisher?.userName
                      : publisher?.name
                    )?.charAt(0) || ''
                  ).toUpperCase()}`
                ) : (
                  <img src={defaultImageIcon} alt='reporters-logo' />
                )}
              </Avatar>
              {/* <img
                src={
                  publisher?.profile_image
                    ? publisher?.profile_image
                    : `${environment.userprofile_prefix_url}${publisher.userId}`
                }
                alt='publisherLogo'
                style={{
                  maxWidth: '2.25rem',
                  maxHeight: '2.25rem',
                  overflow: 'hidden',
                  borderRadius: '0.625rem',
                }}
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null;
                //   currentTarget.src = isDarkMode
                //     ? defaultImageIcon
                //     : defaultImageIcon;
                // }}
              /> */}
            </Grid>
            <Grid item xs={8}>
              <Typography
                sx={{
                  color: (theme) =>
                    isDarkMode
                      ? theme.palette.grey[600]
                      : theme.palette.grey[500],
                }}
              >
                {publisher.userType === INFLUENCER
                  ? publisher?.userName
                  : publisher?.name}
                {
                  <Box
                    component='img'
                    src={bluetickIcon}
                    alt='blue-tick'
                    width={15}
                    sx={{ height: 'auto', marginLeft: '2px' }}
                  ></Box>
                }
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <AntSwitch
                checked={publisher.isFollowedByUser}
                onChange={() =>
                  followUnfollowPublisher(
                    publisher.userId,
                    publisher.isFollowedByUser ? true : false,
                  )
                }
                inputProps={{ 'aria-label': 'publish-switch' }}
                color='primary'
              />
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default Publishers;
