import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Publishers from './Publishers';
import Topics from './Topics';
import Reporters from './Reporters';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`following-tabpanel-${index}`}
      aria-labelledby={`following-tab-${index}`}
      {...other}
      sx={{
        '& .MuiBox-root': {
          px: 0,
        },
      }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const FollowingTab = ({ setTopicsFollowCount }) => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const TABS = [
    { label: 'creators' },
    { label: 'topics' },
    { label: 'reporters' },
  ];

  /**
   * Function to pass topics follow count as props to Navbar
   */
  const handleTopicFollowCount = (topicsFollowCount) => {
    setTopicsFollowCount(topicsFollowCount);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%', mt: '-1.3rem' }}>
      <Tabs
        onChange={handleChange}
        aria-label='Following Tab'
        textColor='primary'
        indicatorColor='primary'
        variant='fullWidth'
        value={value}
        sx={{
          mx: -3,
          borderBottom: 1,
          borderColor: 'divider',
          '& .Mui-selected': {
            fontWeight: 600,
          },
        }}
      >
        {TABS.map((tab, index) => (
          <Tab
            key={tab.label}
            label={t(tab.label)}
            id={`following-tabpanel-${index}`}
            sx={{
              textTransform: 'capitalize',
              fontWeight: 400,
              fontSize: '1rem',
              '& .MuiTabs-fixed ': {
                background: 'rgba(255, 255, 255, 1)',
              },
            }}
          />
        ))}
      </Tabs>
      <TabPanel value={value} index={0}>
        <Publishers />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Topics handleTopicFollowCount={handleTopicFollowCount} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Reporters />
      </TabPanel>
    </Box>
  );
};

export default FollowingTab;
