export const NON_CMS_PATH = 'api/v1';
export const CMS_PATH = 'dev/h-api';
export const ASSESSMENT_PATH = `${NON_CMS_PATH}/cj/assessment`;
export const KYC_PATH = `${NON_CMS_PATH}/user/kyc`;
export const PUBLISHER_PATH = `${NON_CMS_PATH}/publisher`;
export const REPORTER_PATH = `${NON_CMS_PATH}/reporter`;
export const TRAINING_PATH = `${NON_CMS_PATH}/cj/training`;
export const MEDIA_PATH = `${NON_CMS_PATH}/media`;
export const USER_PATH = `${NON_CMS_PATH}/user`;

export const api = {
  // REPORTER
  getDtReporters: `${REPORTER_PATH}/dtreporters`,
  followUnfollowReporter: `${REPORTER_PATH}/follow`,
  getFollowedReporters: `${REPORTER_PATH}/followed`,
  getUnfollowedReporters: `${REPORTER_PATH}/unfollowed`,
  // reporterFollowers: 'reporters/followers',
  getTrackingId: `${NON_CMS_PATH}/user/userId`,

  // PUBLISHER
  getDtPublishers: `${PUBLISHER_PATH}/dtpublisher`,
  followUnfollowPublisher: `${PUBLISHER_PATH}/follow`,
  getFollowedPublishers: `${PUBLISHER_PATH}/followed`,

  // KYC
  verifyDrivingLicense: `${KYC_PATH}/dl`,
  verifyAadhar: `${KYC_PATH}/aadhar`,
  getAadharToken: `${KYC_PATH}/aadhar/token`,
  verifyPassport: `${KYC_PATH}/passportfl`,

  // ASSESSMENT
  getAssessment: `${ASSESSMENT_PATH}/get-assessment`,
  saveAnswer: `${ASSESSMENT_PATH}/save-answer`,
  submitAssessment: `${ASSESSMENT_PATH}/submit`,
  downloadCertificate: `${NON_CMS_PATH}/cj/certificate`,
  downloadReporterID: `${NON_CMS_PATH}/cj/reporterid`,

  // NEWS
  newsV2: `${CMS_PATH}/newsv2`,
  getMyNews: `${CMS_PATH}/getMyNews`,
  getNewsByUser: `${CMS_PATH}/getNewsByUser`,
  getNewsById: `${CMS_PATH}/getNewsById`,
  newsDynamicProps: `${CMS_PATH}/getNewsDynamicProps`,
  updateUserNews: `${CMS_PATH}/updateUserNews`,
  bookmarkNews: `${CMS_PATH}/bookmarkNews`,
  getBookmarkNews: `${CMS_PATH}/getBookmarkNews`,
  newsAgrigator: `${CMS_PATH}/newsAgrigator`,
  trendingSearch: `${CMS_PATH}/trendingSearch`,
  trendingNews: `${CMS_PATH}/trendingNews`,
  reportNews: `${CMS_PATH}/flagNews`,
  forYou: `${CMS_PATH}/getMyHome`,
  similarNews: `${CMS_PATH}/similarNews`,

  // CONFIGS
  getConfig: `${CMS_PATH}/config`,
  getCategories: `${CMS_PATH}/category`,
  getLocations: `${CMS_PATH}/locations`,
  getAuthors: `${CMS_PATH}/authors`,
  register: `${CMS_PATH}/register`,
  getFilters: `${NON_CMS_PATH}/minmini/home/filter`,

  // CITIZEN NEWS
  uploadFile: `${CMS_PATH}/fileUpload`,
  deleteFile: `${CMS_PATH}/deleteFile`,
  createFeed: `${CMS_PATH}/createFeedV2`,
  getFeeds: `${CMS_PATH}/feeds`,
  deleteFeed: `${CMS_PATH}/deleteFeed`,
  uploadToJWP: `${NON_CMS_PATH}/cj/video/publish`,
  userStats: `${CMS_PATH}/userstats`,
  updatePostAttachDate: `${MEDIA_PATH}/metadata/postattachdate`,

  // NOTIFICATIONS
  notification: `${NON_CMS_PATH}/notification`,
  notificationPreferences: `${NON_CMS_PATH}/notification/pref`,
  notificationUnreadCount: `${NON_CMS_PATH}/notification/count`,
  authorDetails: `${CMS_PATH}/authors`,
  notificationReadUpdate: `${NON_CMS_PATH}/notification/status?id=`,

  // TRAINING
  videoTrackingData: `${TRAINING_PATH}/playlist`,
  getTrainingPlaylist: `${TRAINING_PATH}/jwp/playlists`,
  trainingCompleted: `${TRAINING_PATH}/complete`,

  // GALLERY
  mediaData: `${MEDIA_PATH}/metadata`,

  // ACCOUNT
  deleteAccount: `${USER_PATH}/delete`,
  getUserProfile: `${USER_PATH}/profile`,
  getUsersList: `${USER_PATH}/list`,
  userCheck: `${NON_CMS_PATH}/user/username`,

  //COMMENTS:
  getComments: `${NON_CMS_PATH}/post/fetchcomments`,
  getCommentReplys: `${NON_CMS_PATH}/post/fetchcommentreplies`,
  addComment: `${NON_CMS_PATH}/post/addcomment`,
  addReplyComment: `${NON_CMS_PATH}/post/addcommentreply`,
  deleteComment: `${NON_CMS_PATH}/post/deletecomment`,
  likeComment: `${NON_CMS_PATH}/post/likecomment`,
  dislikeComment: `${NON_CMS_PATH}/post/dislikecomment`,
  likeReplyComment: `${NON_CMS_PATH}/post/likecommentreply`,
  dislikeReplyComment: `${NON_CMS_PATH}/post/dislikecommentreply`,
  replyDeleteComment: `${NON_CMS_PATH}/post/deletecommentreply`,
  editComment: `${NON_CMS_PATH}/post/editcomment`,
  replyEditComment: `${NON_CMS_PATH}/post/editcommentreply`,
  blockUser: `${NON_CMS_PATH}/user/block`,
  reportComment: `${NON_CMS_PATH}/post/reportcomment`,

  //payment
  updateBankDetail: `${NON_CMS_PATH}/user/banking`,
  getBankDetail: `${NON_CMS_PATH}/user/banking`,

  //search username
  searchUsername: `${NON_CMS_PATH}/user/search`,
  getUserId: `${NON_CMS_PATH}/user/userId`,

  verifyCaptcha: `${NON_CMS_PATH}/user/verifycaptcha`,

  //recombee
  recombeeInteraction: `${NON_CMS_PATH}/recommendation/interaction`,
};
