import React from 'react';
import ghostIcon from '../../assets/icons/ghost-icon.svg';
import ghostIconDark from '../../assets/icons/ghostIconDark.svg';
import { Box, Typography } from '@mui/material';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import { viewPort, MinWidth } from '../../responsive/viewport.util';

/**
 * EmptyScreen Component
 */
const EmptyScreen = ({ message }) => {
  const aboveMobile = MinWidth(viewPort.mobileMax);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  /**
   * Displays a ghost icon along with the message passed as a prop
   */
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: (theme) => theme.palette.background.default,
      }}
    >
      <img src={isDarkMode ? ghostIconDark : ghostIcon} alt='ghost-icon' />
      <Typography
        sx={
          aboveMobile
            ? {
                color: isDarkMode ? DARK_COLOR.grey[500] : LIGHT_COLOR[400],
              }
            : {
                color: isDarkMode ? DARK_COLOR.grey[500] : LIGHT_COLOR[400],
                px: '0.625rem',
                fontSize: '0.875rem',
              }
        }
      >
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyScreen;
