// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RelatedNews_relatedNews__qN5EF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RelatedNews_relatedNewsTypography__V5eX4 {
  font-family: var(--font-family-source_sans_pro);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
  align-self: flex-start;
}

.RelatedNews_relatedNewsCardContainer__j0QsT {
  max-width: 58.75rem;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/News/RelatedNews.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,+CAA+C;EAC/C,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".relatedNews {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.relatedNewsTypography {\n  font-family: var(--font-family-source_sans_pro);\n  font-size: var(--font-size-xl);\n  font-style: normal;\n  font-weight: 600;\n  align-self: flex-start;\n}\n\n.relatedNewsCardContainer {\n  max-width: 58.75rem;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relatedNews": `RelatedNews_relatedNews__qN5EF`,
	"relatedNewsTypography": `RelatedNews_relatedNewsTypography__V5eX4`,
	"relatedNewsCardContainer": `RelatedNews_relatedNewsCardContainer__j0QsT`
};
export default ___CSS_LOADER_EXPORT___;
