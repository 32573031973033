import { METHOD } from '../enums/api.enums';
import apiRequest from './fetch';
import { api } from '../Constants/api.constants';

/**
 * Make api call for retrieving News Posts List
 * @param {object} data
 * @returns promise
 */
const fetchNewsPostsList = (data) => {
  return apiRequest(api.newsV2, METHOD.GET, true, data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Detailed News
 * @param {object} data
 * @returns promise
 */
const fetchDetailedNews = (data) => {
  return apiRequest(api.newsV2, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving news dynamic props
 * @param {object} data
 * @returns promise
 */
const fetchNewsDynamicProps = (data) => {
  return apiRequest(api.newsDynamicProps, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Trending News
 * @param {object} data
 * @returns promise
 */
const fetchTrendingNews = (data) => {
  return apiRequest(api.trendingNews, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving For You News
 * @param {object} data
 * @returns promise
 */
const fetchForYouNews = (data) => {
  return apiRequest(api.forYou, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for Updating User News
 * @param {object} data
 * @returns promise
 */
const updateUserNews = (data) => {
  return apiRequest(api.updateUserNews, METHOD.POST, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for reporting inappropriate News
 * @returns promise
 */
const flagNews = (data) => {
  return apiRequest(api.reportNews, METHOD.POST, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving Most Read News List
 * @returns promise
 */
const mostReadList = (data) => {
  return apiRequest(api.trendingNews, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving similar news based on newsId
 * @returns promise
 */
const similarNews = (data) => {
  return apiRequest(api.similarNews, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getFilterNews = (url, data) => {
  return apiRequest(url, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving News of logged in User
 * @param {object} data
 * @returns promise
 */
const fetchMyNews = (data) => {
  return apiRequest(api.getMyNews, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving News of particular User
 * @param {object} data
 * @returns promise
 */
const fetchNewsByUser = (data) => {
  return apiRequest(api.getNewsByUser, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

/**
 * Make api call for retrieving News of particular User
 * @param {object} data
 * @returns promise
 */
const fetchNewsById = (data) => {
  return apiRequest(api.getNewsById, METHOD.GET, true, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const newsService = {
  fetchNewsPostsList,
  fetchDetailedNews,
  fetchNewsDynamicProps,
  updateUserNews,
  fetchTrendingNews,
  fetchForYouNews,
  flagNews,
  mostReadList,
  similarNews,
  getFilterNews,
  fetchMyNews,
  fetchNewsByUser,
  fetchNewsById,
};

export default newsService;
