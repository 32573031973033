import { Box, Typography, Skeleton, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { UserDetailsProvider } from '../../../store/user-details-context';
import trainingService from '../../../services/training.service';
import { styles } from './trainingStyles';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg';
import ArrowLeftWhite from '../../../assets/icons/arrow-left-white.svg';
import StartAssessment from './StartAssement';
import PlayerView from './PlayerView';
import PlaylistListView from './PlaylistListView';
import { viewPort, MaxWidth } from '../../../responsive/viewport.util';
import { useLocation, useNavigate } from 'react-router-dom';
import MobilePlayerView from './MobilePlayerView';
import { ONE, TWO } from '../../../Constants/Constants';
import { TRAINING_VIDEO_STATUS, FALSE } from '../../../Constants/Constants';
import { EnvironmentProvider } from '../../../store/firebase-context';
import { useMetaStore } from '../../../store/MetaTagHandler';
import { metaAction } from '../../../store/MetaTagHandler/metaAction';
import { META_TAG_TITLE } from '../../../Constants/meta.title.constant';

/**
 * Training Component
 */
function Training() {
  const dispatchMetaAction = useMetaStore()[1];
  const location = useLocation();
  let mobileDetected = null;
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    mobileDetected = true;
  }
  const isMobile = MaxWidth(viewPort.mobileMax) || mobileDetected;
  const { state: userState, dispatch } = useContext(UserDetailsProvider);

  const { environment } = useContext(EnvironmentProvider);
  const {
    trainingPlaylist,
    trainingPlaylistState,
    citizenJournalist,
    userDetails,
  } = userState;

  const language = 'ta';

  const [playlist, setPlaylist] = useState([]);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentPlaylistItem, setCurrentPlaylistItem] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openPlayer, setOpenPlayer] = useState(false);
  const [autoPlaylistSetReq, SetAutoPlaylistSetReq] = useState(true);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  // Fetch training playlist  based on language selection and changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const fetchPlaylist = async (lang) => {
        const playlistID =
          lang === 'ta'
            ? environment?.training_assessment_tamil
            : environment?.training_assessment_english;

        if (trainingPlaylist && !trainingPlaylist[language]) {
          trainingService.fetchPlaylist(playlistID).then((data) => {
            dispatch({
              type: 'UPDATE_PLAYLIST',
              payload: {
                lang,
                data: data,
              },
            });
          });
        }
      };

      if (
        language &&
        trainingPlaylist?.[language] &&
        trainingPlaylistState &&
        trainingPlaylistState?.[language]
      ) {
        setPlaylist(trainingPlaylist[language]?.playlist);
        let id = [];
        trainingPlaylistState[language].forEach((video) => {
          if (
            video.status === TRAINING_VIDEO_STATUS.NOT_STARTED ||
            video.status === TRAINING_VIDEO_STATUS.IN_PROGRESS
          ) {
            id.push(video.video_id);
          }
        });
        const index = id.length
          ? trainingPlaylist[language]?.playlist.findIndex((video) =>
              id.includes(video.mediaid),
            )
          : 0;
        if (!isMobile && autoPlaylistSetReq) {
          setCurrentIndex(index);
          setCurrentPlaylistItem(trainingPlaylist[language]?.playlist[index]);
          SetAutoPlaylistSetReq(false);
        }
      } else {
        if (language) {
          fetchPlaylist(language);
        }
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [language, trainingPlaylist, isMobile, trainingPlaylistState]);

  // fetch training state on based training playlist
  useEffect(() => {
    const metaObj = {
      title: META_TAG_TITLE.trainingVideos,
      description: META_TAG_TITLE.trainingVideos,
      keywords: '',
      canonical_url: location.pathname,
    };
    dispatchMetaAction(metaAction.updateMeta, metaObj);
    const timeoutId = setTimeout(() => {
      if (trainingPlaylist?.[language]) {
        getPlaylistStateCall(trainingPlaylist?.[language]);
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [trainingPlaylist]);

  //call api for assisment once training playliststate of all videos is completed
  useEffect(() => {
    const completeTraining = async () => {
      try {
        await trainingService.trainingCompleted(language);
      } catch (error) {}
    };
    if (
      trainingPlaylistState &&
      trainingPlaylistState[language] &&
      trainingPlaylistState[language].length
    ) {
      let completed =
        trainingPlaylistState[language]?.every(
          (list) => list.status === TRAINING_VIDEO_STATUS.COMPLETED,
        ) || false;

      if (
        completed &&
        citizenJournalist &&
        citizenJournalist.isTrainingDone === FALSE
      ) {
        completeTraining();
      }
    }
  }, [trainingPlaylistState]);
  /**
   * get Api call for getting playlist state
   * @param {*} data
   */
  const getPlaylistStateCall = async (playlist) => {
    const playlistID =
      language === 'ta'
        ? environment?.training_assessment_tamil
        : environment?.training_assessment_english;
    if (!(trainingPlaylistState && trainingPlaylistState[language])) {
      const lang = language;
      const playlistState = await trainingService.getPlayListStatusData({
        playlist_id: playlistID,
      });
      if (playlistState) {
        dispatch({
          type: 'UPDATE_PLAYLIST_STATE',
          payload: {
            lang,
            data: playlistState.videoList,
          },
        });

        addVideoData(
          trainingPlaylist[language].playlist,
          playlistState.videoList,
        );
      }
    }
  };

  /**
   * add video data is used to intialise video in the backend server
   * @param {*} playlistState detail of all video in array format to upload
   */
  const addVideoData = async (playlist, playlistState) => {
    const videoIdArray = playlistState.map((playlist) => {
      return playlist.video_id;
    });

    for (const detail of playlist) {
      if (!videoIdArray.includes(detail.mediaid)) {
        const payload = {
          playlist_id: detail.feedid,
          video_id: detail.mediaid,
          user_id:
            userState && userState.details && userState.details.userId
              ? userState.details.userId
              : localStorage.getItem('userID'),
          seek_time: '0',
          status: TRAINING_VIDEO_STATUS.NOT_STARTED,
          language: language,
        };
        await trainingService.addPlayListStatusData(payload);
      }
    }
    const playlistID =
      language === 'ta'
        ? environment?.training_assessment_tamil
        : environment?.training_assessment_english;
    const playlistStates = await trainingService.getPlayListStatusData({
      playlist_id: playlistID,
    });
    if (playlistState) {
      dispatch({
        type: 'UPDATE_PLAYLIST_STATE',
        payload: {
          lang: language,
          data: playlistStates.videoList,
        },
      });
    }
  };

  /**
   * It will update data on the video data on backend and state  as well  base on condition of stateUpdatereq or not
   * @param {object} detail it contail detail information of the video object
   * @param {boolean}stateUpdateReq it will inform as whether(True) we need to update local as well server state or (false)only server
   *
   *  */
  const updateVideoData = (detail, stateUpdateReq) => {
    const selectVideo = trainingPlaylistState[language].find(
      (video) => video.id === detail.id,
    );
    if (selectVideo.status !== TRAINING_VIDEO_STATUS.COMPLETED) {
      const payload = {
        playlist_id: detail.playlist_id,
        video_id: detail.video_id,
        user_id: detail.user_id,
        seek_time: `${detail.seek_time}`,
        status: detail.status,
        language: language,
      };
      if (detail.id) {
        trainingService.updatePlayListStatusData(detail.video_id, payload).then(
          (results) => {
            const updatePlaylistState = trainingPlaylistState[language].map(
              (state) => (state.id === detail.id ? detail : state),
            );
            dispatch({
              type: 'UPDATE_PLAYLIST_STATE',
              payload: {
                lang: language,
                data: updatePlaylistState,
              },
            });
          },
          (err) => {},
        );
      }
    }
  };

  /**
   * Handler for language change
   * @param {object} event onChange event object
   */
  // const handleLanguageChange = (event) => {
  //   const changeLang = event.target.value;
  //   setLanguage(changeLang);
  //   setCurrentPlaylistItem(null);

  //   SetAutoPlaylistSetReq(true);
  // };

  /**
   * Handler for Play Previous Action
   */
  const playPrevious = () => {
    setCurrentPlaylistItem(playlist[currentIndex - 1]);
    setCurrentIndex((prevState) => prevState - 1);
  };

  /**
   * Handler for Play Next Action
   */
  const playNext = () => {
    setCurrentPlaylistItem(playlist[currentIndex + 1]);
    setCurrentIndex((prevState) => prevState + 1);
  };

  /**
   * onPlayListItem callback from JWPlayer
   * @param {object} obj object with item, index, type
   */
  const playlistItemCallback = ({ index, item, type }) => {
    setCurrentIndex(index);
    setCurrentPlaylistItem(item);
  };

  /**
   * Handler for playlist item selection
   * @param {object} item current playlist item
   * @param {number} index current playlist index
   */
  const onPlaylistItemClick = (item, index) => {
    setCurrentIndex(index);
    setCurrentPlaylistItem(item);
    if (isMobile) {
      setTimeout(() => {
        setOpenPlayer(true);
      }, 0);
    }
  };

  /**
   * Handle Close of the Mobile View Player Dialog
   */
  const handleClose = () => {
    setOpenPlayer(false);
  };

  return (
    <Box
      sx={{
        padding: isMobile ? '' : '0 6.25vw',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: isMobile
          ? (theme) =>
              isDarkMode ? theme.palette.grey[200] : theme.palette.white[100]
          : (theme) => theme.palette.background.default,
      }}
    >
      <Box sx={styles.trainingWrapper}>
        {isMobile ? (
          <>
            <Box
              sx={{
                margin: '0 6.25vw',
                ...styles.mobileTrainingHeading,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  src={isDarkMode ? ArrowLeftWhite : ArrowLeftIcon}
                  alt='left-arrow'
                />
                <Typography sx={{ fontWeight: 600 }} variant='subtitle1'>
                  {t('become-a-reporter')}
                </Typography>
              </Box>
            </Box>
            <Divider variant='fullWidth' />
          </>
        ) : (
          <Typography variant='h5' sx={styles.trainingHeading}>
            {t('become-a-reporter')}
          </Typography>
        )}
        <Box sx={{ margin: isMobile ? '0 6.25vw' : '', ...styles.stepper }}>
          <Box
            sx={[
              styles.stepperItem,
              { marginRight: { xs: '1.25rem', sm: '5rem' } },
            ]}
          >
            <Box
              sx={{
                ...styles.stepperStep,
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
            >
              {ONE}
            </Box>
            {!isMobile && (
              <Typography
                sx={[
                  styles.stepperText,
                  {
                    fontWeight: 600,
                    color: (theme) => theme.palette.primary.main,
                  },
                ]}
              >
                {userDetails?.isOnboardedReporter
                  ? t('training')
                  : t('training_and_assessment')}
              </Typography>
            )}
          </Box>
          <Box sx={[styles.stepperItem, { marginRight: 0 }]}>
            <Box
              sx={{
                ...styles.stepperStep,
                backgroundColor: (theme) => theme.palette.grey[300],
              }}
            >
              {TWO}
            </Box>
            {!isMobile && (
              <Typography
                variant='subtitle4'
                sx={{
                  ...styles.stepperText,
                  color: (theme) => theme.palette.grey[300],
                }}
              >
                {t('kyc-verification')}
              </Typography>
            )}
          </Box>
        </Box>
        {isMobile && (
          <Typography
            sx={[
              styles.stepperText,
              {
                textAlign: 'center',
                marginBottom: 0,
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
              },
            ]}
          >
            {userDetails?.isOnboardedReporter
              ? t('training')
              : t('training_and_assessment')}
          </Typography>
        )}
        <Box
          sx={{
            ...styles.trainingContainer,
            background: isMobile
              ? isDarkMode
                ? (theme) => theme.palette.grey[200]
                : (theme) => theme.palette.white[100]
              : '',
          }}
        >
          <Box
            sx={{
              background: isDarkMode
                ? (theme) => theme.palette.grey[200]
                : (theme) => theme.palette.white[100],

              ...styles.listWrapper,
              border: isMobile ? 'none' : '1px solid',
              borderColor: isMobile
                ? ''
                : isDarkMode
                ? (theme) => theme.palette.grey[300]
                : (theme) => theme.palette.grey[200],
            }}
          >
            <PlaylistListView
              playlist={playlist}
              selectedIndex={currentIndex}
              onChange={onPlaylistItemClick}
              playlistState={trainingPlaylistState?.[language]}
            />
            {/* we need to update true false on the basis of state */}
            {isMobile && (
              <StartAssessment
                language={language}
                trainingCompleted={
                  trainingPlaylistState?.[language]?.every(
                    (list) => list.status === TRAINING_VIDEO_STATUS.COMPLETED,
                  ) || false
                }
              />
            )}
          </Box>
          {!isMobile && (
            <Box sx={styles.videoWrapper}>
              <StartAssessment
                trainingCompleted={
                  trainingPlaylistState?.[language]?.every(
                    (list) => list.status === TRAINING_VIDEO_STATUS.COMPLETED,
                  ) || false
                }
              />
              {currentPlaylistItem ? (
                <PlayerView
                  key={currentIndex}
                  playlistId={
                    language === 'ta'
                      ? environment?.training_assessment_tamil
                      : environment?.training_assessment_english
                  }
                  playlistItem={currentPlaylistItem}
                  currentPlayListState={
                    trainingPlaylistState?.[language]?.find((video) => {
                      return video.video_id === currentPlaylistItem.mediaid;
                    }) || {}
                  }
                  index={currentIndex}
                  onPlaylistItem={playlistItemCallback}
                  playlistLength={playlist?.length || 0}
                  onPrevious={playPrevious}
                  onUpdateData={updateVideoData}
                  onNext={playNext}
                />
              ) : (
                <Box
                  sx={{
                    backgroundColor: isDarkMode
                      ? (theme) => theme.palette.grey[200]
                      : (theme) => theme.palette.white[100],
                    gap: '10px',
                    display: 'grid',
                  }}
                >
                  <Skeleton
                    animation='wave'
                    variant='rectangular'
                    width={100}
                    height={20}
                  />
                  <Skeleton
                    animation='wave'
                    variant='rectangular'
                    width={'auto'}
                    height={20}
                  />
                  <Skeleton
                    animation='wave'
                    variant='rectangular'
                    width={'auto'}
                    height={400}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
        {isMobile && openPlayer && currentPlaylistItem && (
          <MobilePlayerView
            open={openPlayer}
            onClose={handleClose}
            playlistId={
              language === 'ta'
                ? environment?.training_assessment_tamil
                : environment?.training_assessment_english
            }
            currentPlayListState={
              trainingPlaylistState?.[language]?.find((video) => {
                return video.video_id === currentPlaylistItem.mediaid;
              }) || {}
            }
            playlistItem={currentPlaylistItem}
            index={currentIndex}
            onPlaylistItem={playlistItemCallback}
            playlistLength={playlist?.length || 0}
            onPrevious={playPrevious}
            onUpdateData={updateVideoData}
            onNext={playNext}
          />
        )}
      </Box>
    </Box>
  );
}

export default Training;
