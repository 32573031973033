import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import DetailedNews from './Components/News/DetailedNews';
import NewsLayout from './Components/NewsLayout/index';
import NavBar from './Components/NavBar/NavBar';
import Bookmarks from './Components/Bookmarks/Bookmarks';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Reporter from './Components/Reporter/Reporter';
import { SkeletonTheme } from 'react-loading-skeleton';
import './App.css';
import DistrictsMobile from '../src/Components/DistrictSearch/DistrictsMobile';
import Search from './Components/Search/Search';
import Assessment from '../src/Components/Reporter/Assessment';
import AuthorNews from './Components/AuthorNews/AuthorNews';
import Notifications from './Components/Notifications/Notifications';
import Drafts from './Components/Drafts/Drafts';
import NotificationSettings from './Components/NotificationSettings/NotificationSettings';
import appService from './services/app.service';
import Layout from './Components/Layout/Layout';
import { ThreeDots } from 'react-loader-spinner';
import PrivateRoute from './PrivateRoute';
import NewsPosts from './Components/NewsPosts/NewsPosts';
import UserDetailsContext from './store/user-details-context';
import ConfigurationMetaStore from './store/MetaTagHandler/metaStore';
import MetaTagWrapper from './Components/MetaTagWrapper/MetaTagWrapper';
import { useMetaStore } from './store/MetaTagHandler';
import Training from './Components/Reporter/Training/Training';
import { AppContextProvider } from './store/app-context';
import ReporterID from './Components/Reporter/ReporterID/ReporterID';
import NotFound from './Components/NotFound/NotFound';
import Footer from './Components/Footer/Footer';
import StickyAd from './Components/Ads/StickyAd';
import { Box, CssBaseline } from '@mui/material';
import CreateAReport from './Components/CreateAReport/CreateAReport';
import { RemoteConfig } from './store/firebase-context';
import Congratulations from './Components/Reporter/Congratulations/Congratulations';
import mixpanel from 'mixpanel-browser';
import PolicyDocument from './Components/policy-document/PolicyDocument';
import TopicMobile from '../src/Components/Topic/TopicMobile';
import PaymentForm from '../src/Components/payment-form/PaymentForm';
import { init } from '@elastic/apm-rum';

ConfigurationMetaStore();
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

const App = () => {
  const globalMetaState = useMetaStore()[0];
  const [navBarFilters, setNavBarFilters] = useState();
  const [newsCategories, setNewsCategories] = useState();
  const [navBarCategories, setNavBarCategories] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [districts, setDistricts] = useState([]);
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;

  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  const location = useLocation();

  const validPaths = [
    '/privacy-policy',
    '/terms-of-use',
    '/community-guidelines',
    '/citizen-journalism-guidelines',
    '/manage-info',
  ];

  const isPolicyDocumentsPath = validPaths.some((path) =>
    location.pathname.includes(path),
  );

  useEffect(() => {
    if (!isPolicyDocumentsPath) {
      fetchNavBarCateogry();
      getDistrictNames();
      localStorage.setItem('theme', 'Dark');
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      loadElastic();
    }
  }, []);

  const getDistrictNames = async () => {
    try {
      const response = await appService.districtList();
      setDistricts(response?.locations);
    } catch (error) {}
  };

  const fetchNavBarCateogry = async () => {
    try {
      const results = await Promise.allSettled([
        appService.fetchNewsCategories(),
        appService.fetchNavBarCategories(),
        appService.fetchNavBarFilters({ showMetaInfo: true }),
      ]);

      setNavBarCategories(
        results[1]?.status === 'fulfilled'
          ? results[1]?.value?.cat_list_unified
          : [],
      );
      setNewsCategories(
        results[0]?.status === 'fulfilled' ? results[0]?.value?.categories : [],
      );
      setNavBarFilters(
        results[2]?.status === 'fulfilled' ? results[2]?.value?.data : [],
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadElastic = () => {
    init({
      serviceName: 'web-prod',
      serverUrl: process.env.REACT_APP_ELASTIC_SERVER_URL,
      secretKey: process.env.REACT_APP_ELASTIC_SECRET_KEY,
    });
  };

  return (
    <>
      <RemoteConfig>
        {isPolicyDocumentsPath ? (
          <div style={{ height: '100vh', overFlowY: 'auto' }}>
            <Routes>
              <Route
                path='/privacy-policy'
                element={<PolicyDocument documentName='privacy-policy' />}
              />
              <Route
                path='/terms-of-use'
                element={<PolicyDocument documentName='terms-of-use' />}
              />
              <Route
                path='/community-guidelines'
                element={<PolicyDocument documentName='community-guidelines' />}
              />
              <Route
                path='/citizen-journalism-guidelines'
                element={
                  <PolicyDocument documentName='citizen-journalism-guidelines' />
                }
              />
              <Route path='/manage-info' element={<PaymentForm />} />
            </Routes>
          </div>
        ) : (
          <div className='app-container'>
            <MetaTagWrapper
              meta={globalMetaState.meta}
              ogTag={globalMetaState.ogTag}
            />
            {isLoading ? (
              <Box
                sx={{
                  backgroundColor: isDarkMode ? '#2C2C2C' : '#FFFFFF',
                }}
                className='loader'
              >
                <ThreeDots
                  height='80'
                  width='80'
                  radius='9'
                  color={isDarkMode ? '#484848' : '#D9D9D9'}
                  ariaLabel='loading'
                  wrapperStyle
                  wrapperClass
                />
              </Box>
            ) : (
              <SkeletonTheme
                baseColor={isDarkMode ? '#484848' : '#D9D9D9'}
                highlightColor={isDarkMode ? '#323232' : '#878383'}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <UserDetailsContext>
                    <AppContextProvider>
                      <CssBaseline enableColorScheme />
                      <NavBar
                        navBarCategories={navBarCategories}
                        navBarFilters={navBarFilters}
                      />
                      <Box
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.background.default,
                        }}
                        className='route-content'
                      >
                        <Routes>
                          <Route
                            exact
                            path='/'
                            element={
                              <Navigate to={navBarFilters[0]?.url} replace />
                            }
                          />
                          {/* <Route
                            path='/for-you'
                            element={
                              <PrivateRoute>
                                <NewsLayout
                                  navBarFilters={navBarFilters}
                                  navBarCategories={navBarCategories}
                                  newsCategories={newsCategories}
                                />
                              </PrivateRoute>
                            }
                          /> */}

                          {/* <Route
                            path='/top'
                            element={
                              <NewsLayout
                                navBarFilters={navBarFilters}
                                navBarCategories={navBarCategories}
                                newsCategories={newsCategories}
                              />
                            }
                          /> */}

                          {/* <Route
                          path='/citizen-reports'
                          element={
                            <NewsLayout
                              navBarCategories={navBarCategories}
                              newsCategories={newsCategories}
                            />
                          }
                        /> */}

                          {/* <Route
                            path='/latest'
                            element={
                              <NewsLayout
                                navBarFilters={navBarFilters}
                                navBarCategories={navBarCategories}
                                newsCategories={newsCategories}
                              />
                            }
                          /> */}

                          <Route path='/search' element={<Search />} />

                          <Route
                            path='/notifications'
                            element={
                              <PrivateRoute>
                                <Notifications />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/notification-settings'
                            element={
                              <PrivateRoute>
                                <NotificationSettings />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/author-news/:authorDetail'
                            element={<AuthorNews />}
                          />
                          <Route
                            path='/topic-list/'
                            element={
                              <TopicMobile
                                navBarCategories={navBarCategories}
                                navBarFilters={navBarFilters}
                                categories={newsCategories}
                              />
                            }
                          />
                          {/* <Route path='/social' element={<Community />} /> */}
                          <Route
                            path='/districts-list/'
                            element={
                              <DistrictsMobile
                                navBarCategories={navBarCategories}
                                navBarFilters={navBarFilters}
                                districts={districts}
                              />
                            }
                          />
                          <Route
                            path='/districts/:districtDetail'
                            element={
                              <NewsLayout
                                navBarFilters={navBarFilters}
                                navBarCategories={navBarCategories}
                                newsCategories={newsCategories}
                                districts={districts}
                                isDistrict={true}
                              />
                            }
                          />
                          {navBarFilters?.map((filter) => (
                            <Route element={<Layout />} key={filter.url}>
                              <Route
                                path={`${filter?.url}`}
                                element={
                                  <NewsLayout
                                    navBarFilters={navBarFilters}
                                    navBarCategories={navBarCategories}
                                    filter={filter}
                                    newsCategories={newsCategories}
                                    districts={districts}
                                  />
                                }
                              />
                            </Route>
                          ))}
                          {newsCategories?.map((category) => (
                            <>
                              <Route element={<Layout />} key={category.catId}>
                                <Route
                                  path={`${category?.url}`}
                                  element={
                                    <NewsLayout
                                      navBarFilters={navBarFilters}
                                      navBarCategories={navBarCategories}
                                      category={category}
                                      newsCategories={newsCategories}
                                      districts={districts}
                                    />
                                  }
                                />
                                <Route
                                  path={`/news${category?.url}/:id`}
                                  element={
                                    <DetailedNews
                                      category={{
                                        path: category?.url,
                                        name: isTamil
                                          ? category?.name_tamil ||
                                            category?.name
                                          : category?.name,
                                      }}
                                      newsCategories={newsCategories}
                                      navBarCategories={navBarCategories}
                                      navBarFilters={navBarFilters}
                                      districts={districts}
                                    />
                                  }
                                />
                                {category?.childs?.map((childCategory) => (
                                  <>
                                    <Route
                                      path={`${childCategory?.url}`}
                                      element={
                                        <NewsLayout
                                          navBarCategories={navBarCategories}
                                          isFromChildCategory={true}
                                          childCategory={childCategory}
                                          category={category}
                                          newsCategories={newsCategories}
                                          navBarFilters={navBarFilters}
                                          districts={districts}
                                        />
                                      }
                                    />
                                    <Route
                                      path={`/news${childCategory?.url}/:id`}
                                      element={
                                        <DetailedNews
                                          category={{
                                            path: category?.url,
                                            name: isTamil
                                              ? category?.name_tamil ||
                                                category?.name
                                              : category?.name,
                                          }}
                                          subCategory={{
                                            path: childCategory?.url,
                                            name: isTamil
                                              ? childCategory?.name_tamil ||
                                                childCategory?.name
                                              : childCategory?.name,
                                          }}
                                          newsCategories={newsCategories}
                                          navBarCategories={navBarCategories}
                                          navBarFilters={navBarFilters}
                                          districts={districts}
                                        />
                                      }
                                    />
                                  </>
                                ))}
                              </Route>
                            </>
                          ))}
                          <Route
                            path='/bookmarks'
                            element={
                              <PrivateRoute>
                                <Bookmarks
                                  newsCategories={newsCategories}
                                  navBarCategories={navBarCategories}
                                  navBarFilters={navBarFilters}
                                />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/news-posts'
                            element={
                              <PrivateRoute>
                                <NewsPosts
                                  newsCategories={newsCategories}
                                  navBarCategories={navBarCategories}
                                  navBarFilters={navBarFilters}
                                />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/drafts'
                            element={
                              <PrivateRoute>
                                <Drafts
                                  navBarCategories={navBarCategories}
                                  navBarFilters={navBarFilters}
                                />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/reporter'
                            element={
                              <PrivateRoute>
                                <Reporter />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/training'
                            element={
                              <PrivateRoute>
                                <Training />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/assessment'
                            element={
                              <PrivateRoute>
                                <Assessment />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/reporter-id'
                            element={
                              <PrivateRoute>
                                <ReporterID />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/congratulations'
                            element={
                              <PrivateRoute>
                                <Congratulations />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='/create-a-report'
                            element={
                              <PrivateRoute>
                                <CreateAReport
                                  navBarCategories={navBarCategories}
                                  navBarFilters={navBarFilters}
                                />
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path='*'
                            element={
                              <NotFound
                                navBarCategories={navBarCategories}
                                navBarFilters={navBarFilters}
                                districts={districts}
                              />
                            }
                          />
                        </Routes>
                      </Box>
                      <Footer />
                      <StickyAd />
                    </AppContextProvider>
                  </UserDetailsContext>
                </LocalizationProvider>
              </SkeletonTheme>
            )}
          </div>
        )}
      </RemoteConfig>
    </>
  );
};

export default App;
