import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import settingsIcon from '../../assets/icons/Settings.svg';
// import settingsDarkIcon from '../../assets/icons/settings-dark.svg';
import './NotificationDropdown.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ghostIcon from '../../assets/icons/ghost-icon.svg';
import ghostIconDark from '../../assets/icons/ghostIconDark.svg';
import NotificationsDropdownData from './NotificationsDropdownData';
import { NotificationDropdownSkeleton } from '../Skeleton/PublisherSkeleton';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const NotificationDropdown = ({ open, notifications, onUpdate, isLoading }) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '22.625rem',
        maxHeight: '26.563rem',
        background: isDarkMode ? DARK_COLOR.grey[100] : LIGHT_COLOR.white[100],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '1.063rem 1.25rem',
          alignItems: 'center',
          alignContent: 'center',
          borderBottom: 1,
          borderColor: 'divider',
          background: isDarkMode
            ? DARK_COLOR.grey[200]
            : LIGHT_COLOR.white[100],
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            marginBottom: '0',
            color: isDarkMode ? DARK_COLOR.white[100] : LIGHT_COLOR.grey[600],
          }}
        >
          {t('NOTIFICATIONS')}
        </Typography>
        {/* TODO: SHOW NOTIFICATIONS SETTINGS */}
        {/*<img
          src={isDarkMode ? settingsDarkIcon : settingsIcon}
          className='notifydropdown_settings'
          alt='settings icon'
          onClick={() => {
            navigate('/notification-settings');
            open(false);
          }}
        />*/}
      </Box>
      {!notifications?.length && !isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '15.625rem',
            height: '100%',
            background: isDarkMode
              ? DARK_COLOR.grey[100]
              : LIGHT_COLOR.grey[100],
          }}
        >
          <img src={isDarkMode ? ghostIconDark : ghostIcon} alt='ghost-icon' />
          <Typography
            sx={{
              color: isDarkMode ? DARK_COLOR.grey[500] : LIGHT_COLOR.grey[400],
              textAlign: 'center',
            }}
          >
            {t('no_notifications')}
          </Typography>
        </Box>
      ) : (
        <div className='notfication-dropdown-container'>
          {isLoading ? (
            <NotificationDropdownSkeleton cards={2} />
          ) : (
            <NotificationsDropdownData
              notifications={notifications}
              onUpdate={onUpdate}
            />
          )}
        </div>
      )}
      <Divider fullWidth />
      <Box
        sx={{
          background: isDarkMode
            ? DARK_COLOR.grey[200]
            : LIGHT_COLOR.white[100],
        }}
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: '600',
            color: isDarkMode
              ? DARK_COLOR.primary[100]
              : LIGHT_COLOR.primary[100],
            background: isDarkMode
              ? DARK_COLOR.grey[200]
              : LIGHT_COLOR.white[100],
            margin: '0.938rem 0',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/notifications');
            open(false);
          }}
        >
          {t('VIEW_ALL_NOTIFICATIONS')}
        </Typography>
      </Box>
    </Box>
  );
};

export default NotificationDropdown;
