import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Box, Button, Typography, Avatar, Divider, Grid } from '@mui/material';
import NewsCard from '../NewsCards/NewsCard';
import PromotionalNewsList from '../NewsCards/PromotionalNewsList';
import { useParams } from 'react-router-dom';
import newsService from '../../services/news.service';
import { TabletMin, MobileMax } from '../../responsive/breakpoints';
import locationIcon from '../../assets/icons/Location.svg';
import calendarIcon from '../../assets/icons/calendar.svg';
import locationIconDark from '../../assets/icons/location-dark.svg';
import calendarIconDark from '../../assets/icons/calendar-dark.svg';
import {
  DESCRIPTION_FALLBACK,
  PUBLISHER_TYPE,
  UGC_REPORTER,
  INFLUENCER,
  FOLLOW,
  FOLLOWING,
  NO_LOCATION,
  NO_JOINING_DATE,
  INITIAL_VALUE,
  TEN,
  DOT,
  USER,
  ZERO,
  CITIZEN_REPORTER_CAPITAL,
} from '../../Constants/Constants';
import avatarIcon from '../../assets/defaultProfilePic.png';
import activeEditIcon from '../../assets/icons/active-edit.svg';
import format from 'date-fns/format';
import { UserDetailsProvider } from '../../store/user-details-context';
import {
  CardSkeleton,
  AuthorSkeletonMobile,
  AuthorSkeletonDesktop,
} from '../Skeleton/PublisherSkeleton';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import { useLocation, useNavigate } from 'react-router-dom';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import { useTranslation } from 'react-i18next';
import userProfileService from '../../services/user-profile.service';
import { viewPort, MaxWidth, MinWidth } from '../../responsive/viewport.util';
import { AppContext } from '../../store/app-context';
import purpletickIcon from '../../assets/icons/purple-tick.svg';
import bluetickIcon from '../../assets/icons/blue-influencer-tick-icon.svg';
import { startTransition } from 'react';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import appService from '../../services/app.service';
import { LaptopMin } from '../../responsive/breakpoints';
import { EnvironmentProvider } from '../../store/firebase-context';

const AuthorNews = ({ newsCategories, navBarCategories, navBarFilters }) => {
  const dispatchMetaAction = useMetaStore()[1];
  const location = useLocation();
  const [authorNews, setAuthorNews] = useState([]);
  const [authorDetails, setAuthorDetails] = useState(null);
  const [firstPost, setFirstPost] = useState({});
  const [isFollowedByUser, setIsFollowedByUser] = useState(false);
  const [isAuthorNewsLoading, setIsAuthorNewsLoading] = useState(true);
  const [isAuthorDetailsLoading, setIsAuthorDetailsLoading] = useState(false);
  const [reportersImage, setReportersImage] = useState(null);
  const [isReporterImageLoading, setIsReporterImageLoading] = useState(true);
  const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [updatedNews, setUpdatedNews] = useState([]);
  const [trackingID, setTrackingID] = useState('');
  const navigate = useNavigate();
  const { state: userState } = useContext(UserDetailsProvider);
  const { dispatch: appCtxDispatch } = useContext(AppContext);
  const isAuthenticated = userState?.isAuthenticated;
  const follows = userState?.follows;
  const newsSessionID = userState?.sessionId;
  const userID = userState?.userDetails?.userId;
  const isMobile = MaxWidth(viewPort.tablet);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);

  const { environment } = useContext(EnvironmentProvider);

  let { authorDetail } = useParams();
  const { t } = useTranslation();

  let authorID = authorDetail || null;

  /**
   * SideEffect to run getAuthorNews which will return the news posts in case authorID or newsSessionID
   * gets updated if the user logins to the account
   */
  useEffect(() => {
    if (authorID) {
      getTrackingID();
    } else {
      getUserID();
    }

    const metaObj = {
      title: authorDetail,
      description: authorDetail,
      keywords: '',
      canonical_url: location.pathname,
    };
    dispatchMetaAction(metaAction.updateMeta, metaObj);

    startIndex === 0 && window.scrollTo(0, 0);
  }, [authorDetail, newsSessionID, startIndex]);

  /**
   * SideEffect to run update the follow/unfollow button based upon the updated data by graphQL subscription
   */
  useEffect(() => {
    trackingID && follows?.length && updateFollowButton();
  }, [follows, trackingID]);

  /**
   * Side effect to calls Image API if reporter tracking ID is available
   */
  useEffect(() => {
    trackingID && getReportersImage();
  }, [trackingID]);

  /**
   * Handler to update the follow/unfollow of publisher/reporter based upon the state set by
   * GraphQL subscription or getPublisherByID and getReporterByID based upon the userType
   */
  const handleFollowUnfollowAuthor = async () => {
    const followData = {
      userId: userID,
    };

    if (isFollowedByUser) {
      followData['unfollow'] = JSON.stringify({ IDs: [trackingID] });
    } else {
      followData['follow'] = JSON.stringify({ IDs: [trackingID] });
    }

    try {
      await userProfileService.updateUserFollow(followData);
    } catch (error) {}
  };

  /**
   * In case the load more button is clicked and previous news is changed then merge news function
   */
  useEffect(() => {
    isLoadMoreClicked && mergeNews();
  }, [updatedNews, isLoadMoreClicked]);

  /**
   * Default news count upon click of Load More Button
   */
  const handleLoadMore = () => {
    setStartIndex((prev) => prev + TEN);
    setIsLoadMoreClicked(true);
  };

  /**
   * Merge function to merge the previously loaded news and new news together
   */
  const mergeNews = useCallback(() => {
    setAuthorNews([...authorNews, ...updatedNews]);
    setIsAuthorNewsLoading(false);
  }, [authorNews, updatedNews]);

  const getTrackingID = async () => {
    try {
      const response = await appService.getTrackingId({ userName: authorID });

      if (response?.userId) {
        getUserProfile(response?.userId);
        setTrackingID(response?.userId);
      }

      getAuthorNews(response?.userId);
    } catch (error) {}
  };

  const getUserID = async () => {
    try {
      const response = await appService.getUserId({
        userName: authorDetail,
      });

      if (response?.userId) {
        getUserProfile(response?.userId);
        setTrackingID(response?.userId);
      }

      getAuthorNews(response?.userId);
    } catch (error) {}
  };
  /**
   * Function to get the news posts/feed received of the author
   *
   */
  const getAuthorNews = async (trackingId) => {
    try {
      const req = {
        startIndex: startIndex,
        count: TEN,
      };

      let response = null;

      if (trackingId && trackingId !== userID) {
        req['userId'] = trackingId;
        response = await newsService.fetchNewsByUser(req);
      } else {
        req['sessionId'] = newsSessionID;
        response = await newsService.fetchMyNews(req);
      }

      if (response?.news?.length) {
        isLoadMoreClicked
          ? setUpdatedNews(response?.news)
          : setAuthorNews(response?.news);
        response?.news.length !== TEN && setIsEndOfPage(true);
        if (startIndex === 0) {
          const [firstPost] = response?.news;
          setFirstPost(firstPost);
        }
        setIsAuthorNewsLoading(false);
        const newsIds = response?.news.map(({ newsId }) => newsId);

        if (newsIds?.length) {
          getDynamicProps(newsIds);
        }
      }
    } catch (error) {
    } finally {
      setIsAuthorNewsLoading(false);
    }
  };

  /**
   * function to updated dynamic props of the news
   * @param {object} newsIds
   */
  const getDynamicProps = async (newsIds) => {
    try {
      const reqObj = {
        newsIds: newsIds.join(','),
      };

      if (newsSessionID) {
        reqObj['sessionId'] = newsSessionID;
      }

      const response = await newsService.fetchNewsDynamicProps(reqObj);
      const dynamicProps = response?.news || [];

      if (authorNews?.length && dynamicProps?.length) {
        startTransition(() => {
          setAuthorNews((prevState) => {
            return prevState?.map((news) => {
              let isAvailable = dynamicProps.find(
                (newsObj) => newsObj.newsId === news.newsId,
              );

              if (isAvailable) {
                return {
                  ...news,
                  ...isAvailable,
                };
              } else {
                return { ...news };
              }
            });
          });
        });
      }
    } catch (error) {}
  };

  /**
   * Updates the state of the follow button based on whether the user is already following the author.
   *
   * @function
   * @name updateFollowButton
   * @returns {void}
   *
   * @description
   * This function checks if the author (identified by the trackingID) is present in the follows array,
   * and accordingly updates the state variable isFollowedByUser to control the state of the follow button.
   *
   */
  const updateFollowButton = () => {
    const isExistingAuthor = follows?.some((author) => author === trackingID);

    isExistingAuthor ? setIsFollowedByUser(true) : setIsFollowedByUser(false);
  };

  /**
   * Function to retrieve images using the userId of the the reporters
   */
  const getReportersImage = () => {
    userProfileService.getProfileImage(trackingID).then((image) => {
      setReportersImage(image);
      setIsReporterImageLoading(false);
    });
  };

  const handleProfile = () => {
    appCtxDispatch({ type: 'SHOW_PROFILE' });
  };

  /**
   * Fetches user profile information using the provided tracking ID.
   *
   * @async
   * @function
   * @param {string} trackingID - The tracking ID of the user.
   * @returns {Promise<void>}
   */
  const getUserProfile = async (trackingID) => {
    setIsAuthorDetailsLoading(true);
    try {
      const payload = { id: trackingID };
      const response = await appService.getUserProfile(payload);
      if (response) {
        setAuthorDetails(response);
        setIsAuthorDetailsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsAuthorDetailsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: (theme) => theme.palette.background.default,
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '78.75rem',
            width: '100%',
            background: isDarkMode ? isMobile && DARK_COLOR.grey[200] : '',
            padding: isMobile ? '1.25rem 1.25rem 0' : '0',
          }}
        >
          {isAuthorDetailsLoading ? (
            isMobile ? (
              <AuthorSkeletonMobile />
            ) : (
              <AuthorSkeletonDesktop />
            )
          ) : authorDetails ? (
            <>
              <Box
                sx={{
                  p: aboveLap
                    ? '1rem 1.32rem 1rem 0rem'
                    : belowLap && aboveMobile
                    ? '1rem 1.32rem 1rem 0rem'
                    : '0rem 0.563rem 1rem 0.5rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (location.key !== 'default') {
                    navigate(-1);
                  } else {
                    navigate('/', { replace: true });
                  }
                }}
              >
                <Typography
                  variant={isMobile ? 'h8_mobile' : 'h8'}
                  sx={{
                    fontSize: aboveLap
                      ? '1.125rem'
                      : belowLap && aboveMobile
                      ? '1rem'
                      : '0.75rem',
                  }}
                >
                  &lt;&nbsp;{t('back')}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: '17rem',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '4rem',
                      height: '4rem',
                      borderRadius: '50%',
                      position: 'relative',
                      objectFit: 'cover',
                    }}
                  >
                    {authorDetails?.userType === PUBLISHER_TYPE && (
                      <Box
                        component='img'
                        src={
                          firstPost.authorImage
                            ? firstPost.authorImage
                            : avatarIcon
                        }
                        sx={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                        }}
                        alt='author-img'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = avatarIcon;
                        }}
                      />
                    )}
                    {(authorDetails?.userType === UGC_REPORTER ||
                      authorDetails?.userType === USER ||
                      authorDetails?.userType === INFLUENCER ||
                      authorDetails?.userType === CITIZEN_REPORTER_CAPITAL) &&
                      (firstPost?.authorImage
                        ? firstPost.authorImage
                        : !isReporterImageLoading) && (
                        <Avatar
                          alt='reporter avatar'
                          src={
                            reportersImage
                              ? reportersImage
                              : firstPost.authorImage
                              ? firstPost.authorImage
                              : `${environment.userprofile_prefix_url}${trackingID}`
                          }
                          sx={{
                            cursor: 'pointer',
                            position: 'relative',
                            width: '4rem',
                            height: '4rem',
                            borderRadius: '50%',
                            color: (theme) => theme.palette.white[100],
                            fontSize: '2.25rem',
                            fontWeight: '600',
                            backgroundColor: (theme) =>
                              theme.palette.avatar[100],
                          }}
                        >
                          {`${(
                            authorDetails?.firstName?.[0] || ''
                          ).toUpperCase()}${(
                            authorDetails?.lastName?.[0] || ''
                          ).toUpperCase()}`}
                        </Avatar>
                      )}
                  </Box>
                  <Box
                    sx={{
                      maxWidth: '11.5rem',
                      width: '100%',
                      color: isDarkMode
                        ? (theme) => theme.palette.white[100]
                        : (theme) => theme.palette.grey[600],
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        mb: isMobile ? '0.313rem' : '0.5rem',
                      }}
                    >
                      <Typography
                        variant='h5'
                        sx={{ fontSize: isMobile ? '1.125rem' : '1.5rem' }}
                      >
                        {authorDetails?.userType === PUBLISHER_TYPE &&
                          authorDetails?.name}
                        {(authorDetails?.userType === UGC_REPORTER ||
                          authorDetails?.userType === USER ||
                          authorDetails?.userType === INFLUENCER ||
                          authorDetails?.userType ===
                            CITIZEN_REPORTER_CAPITAL) &&
                          authorDetails?.firstName}
                      </Typography>
                      <Typography
                        variant='h5'
                        sx={{
                          marginLeft: '0.5rem',
                          fontSize: isMobile ? '1.125rem' : '1.5rem',
                        }}
                      >
                        {(authorDetails?.userType === UGC_REPORTER ||
                          authorDetails?.userType === USER ||
                          authorDetails?.userType === INFLUENCER ||
                          authorDetails?.userType ===
                            CITIZEN_REPORTER_CAPITAL) &&
                          authorDetails?.lastName}
                      </Typography>
                      {(authorDetails?.userType === PUBLISHER_TYPE ||
                        authorDetails?.userType === UGC_REPORTER ||
                        authorDetails?.userType === INFLUENCER) && (
                        <Box
                          component='img'
                          src={bluetickIcon}
                          alt='blue-tick'
                          width={15}
                          sx={{ height: 'auto' }}
                        ></Box>
                      )}

                      {authorDetails?.userType === CITIZEN_REPORTER_CAPITAL && (
                        <Box
                          component='img'
                          src={purpletickIcon}
                          alt='reporter'
                          width={15}
                          sx={{ height: 'auto', marginLeft: '2px' }}
                        ></Box>
                      )}
                      <TabletMin>
                        {(authorDetails?.userType === UGC_REPORTER ||
                          authorDetails?.userType === USER ||
                          authorDetails?.userType === INFLUENCER ||
                          authorDetails?.userType ===
                            CITIZEN_REPORTER_CAPITAL) && (
                          <>
                            {(authorDetails?.userName ||
                              authorDetails?.reporterID) && (
                              <Divider
                                orientation='vertical'
                                sx={{
                                  borderColor: isDarkMode
                                    ? DARK_COLOR.grey[600]
                                    : LIGHT_COLOR.grey[200],
                                  height: '1.25rem',
                                  margin: '0.125rem 0.625rem 0',
                                }}
                              />
                            )}
                            <Typography
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[400],
                              }}
                            >
                              {authorDetails?.userName || ''}
                            </Typography>
                            {authorDetails?.userName &&
                              authorDetails?.reporterID && (
                                <Box
                                  component='span'
                                  sx={{
                                    margin: '0 0.625rem',
                                    color: isDarkMode
                                      ? DARK_COLOR.grey[600]
                                      : LIGHT_COLOR.grey[200],
                                  }}
                                >
                                  {DOT}
                                </Box>
                              )}
                            <Typography
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[400],
                              }}
                            >
                              {authorDetails?.reporterID || ''}
                            </Typography>
                          </>
                        )}
                      </TabletMin>
                    </Box>

                    <TabletMin>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          color: isDarkMode
                            ? (theme) => theme.palette.grey[500]
                            : (theme) => theme.palette.grey[400],
                        }}
                      >
                        <Box sx={{ marginRight: '1.875rem' }}>
                          <Box
                            component='span'
                            sx={{
                              color: isDarkMode
                                ? (theme) => theme.palette.white[100]
                                : (theme) => theme.palette.grey[500],
                              fontWeight: 600,
                              mr: '0.2rem',
                            }}
                          >
                            {authorDetails?.userType === PUBLISHER_TYPE &&
                              (authorDetails?.news_count
                                ? authorDetails?.news_count
                                : INITIAL_VALUE)}
                            {(authorDetails?.userType === UGC_REPORTER ||
                              authorDetails?.userType === USER ||
                              authorDetails?.userType === INFLUENCER ||
                              authorDetails?.userType ===
                                CITIZEN_REPORTER_CAPITAL) &&
                              (authorDetails?.newsPosts
                                ? authorDetails?.newsPosts
                                : INITIAL_VALUE)}
                          </Box>
                          {t('posts')}
                        </Box>
                        <Box>
                          <Box
                            component='span'
                            sx={{
                              color: isDarkMode
                                ? (theme) => theme.palette.white[100]
                                : (theme) => theme.palette.grey[500],
                              fontWeight: 600,
                              mr: '0.2rem',
                            }}
                          >
                            {authorDetails.followersCount
                              ? authorDetails.followersCount
                              : ZERO}
                          </Box>
                          {t('followers')}
                        </Box>
                      </Box>
                    </TabletMin>
                    <MobileMax>
                      {authorDetails?.userType === PUBLISHER_TYPE && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: isDarkMode
                              ? (theme) => theme.palette.grey[500]
                              : (theme) => theme.palette.grey[400],
                          }}
                        >
                          <Box>
                            <Box
                              component='span'
                              sx={{
                                color: isDarkMode
                                  ? (theme) => theme.palette.white[100]
                                  : (theme) => theme.palette.grey[500],
                                fontWeight: 600,
                                mr: '0.2rem',
                              }}
                            >
                              {authorDetails?.userType === PUBLISHER_TYPE &&
                                (authorDetails?.news_count
                                  ? authorDetails?.news_count
                                  : INITIAL_VALUE)}
                              {(authorDetails?.userType === UGC_REPORTER ||
                                authorDetails?.userType === USER ||
                                authorDetails?.userType === INFLUENCER ||
                                authorDetails?.userType ===
                                  CITIZEN_REPORTER_CAPITAL) &&
                                (authorDetails?.newsPosts
                                  ? authorDetails?.newsPosts
                                  : INITIAL_VALUE)}
                            </Box>
                            {t('posts')}
                          </Box>
                          <Box>
                            <Box
                              component='span'
                              sx={{
                                color: isDarkMode
                                  ? (theme) => theme.palette.white[100]
                                  : (theme) => theme.palette.grey[500],
                                fontWeight: 600,
                                mr: '0.2rem',
                              }}
                            >
                              {authorDetails?.followersCount
                                ? authorDetails?.followersCount
                                : ZERO}
                            </Box>
                            {t('followers')}
                          </Box>
                        </Box>
                      )}
                      {(authorDetails?.userType === UGC_REPORTER ||
                        authorDetails?.userType === USER ||
                        authorDetails?.userType === INFLUENCER ||
                        authorDetails?.userType ===
                          CITIZEN_REPORTER_CAPITAL) && (
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[400],
                              }}
                            >
                              {authorDetails?.userName || ''}
                            </Typography>
                            {authorDetails?.userName &&
                              authorDetails?.reporterID && (
                                <Box
                                  component='span'
                                  sx={{
                                    margin: '0 0.625rem',
                                    color: isDarkMode
                                      ? DARK_COLOR.grey[600]
                                      : LIGHT_COLOR.grey[400],
                                  }}
                                >
                                  {DOT}
                                </Box>
                              )}
                            <Typography
                              sx={{
                                color: isDarkMode
                                  ? DARK_COLOR.grey[600]
                                  : LIGHT_COLOR.grey[400],
                              }}
                            >
                              {authorDetails?.reporterID || ''}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </MobileMax>
                  </Box>
                </Box>
                <TabletMin>
                  {trackingID !== userID && isAuthenticated && (
                    <Button
                      variant={isFollowedByUser ? 'outlined' : 'contained'}
                      onClick={
                        isAuthenticated
                          ? handleFollowUnfollowAuthor
                          : () =>
                              appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' })
                      }
                      sx={{
                        px: '1.6rem',
                      }}
                    >
                      {isFollowedByUser ? FOLLOWING : FOLLOW}
                    </Button>
                  )}
                </TabletMin>
                <LaptopMin>
                  {trackingID === userID && isAuthenticated && (
                    <Button
                      variant='outlined'
                      onClick={handleProfile}
                      sx={{
                        px: '1.6rem',
                        width: isTamil ? '12.5rem' : '12rem',
                      }}
                    >
                      <img
                        src={activeEditIcon}
                        alt='edit-icon'
                        style={{ marginRight: '0.625rem' }}
                      />
                      {t('cedit-profile')}
                    </Button>
                  )}
                </LaptopMin>
              </Box>
              {authorDetails?.userType === UGC_REPORTER ||
              authorDetails?.userType === USER ||
              authorDetails?.userType === INFLUENCER ||
              authorDetails?.userType === CITIZEN_REPORTER_CAPITAL ? (
                <>
                  <Box
                    sx={{
                      margin: '1.5rem 0 0.875rem 0',
                      display: 'flex',
                      width: '100%',
                      color: isDarkMode
                        ? (theme) => theme.palette.white[100]
                        : (theme) => theme.palette.grey[400],
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '1.5rem',
                      }}
                    >
                      <Box
                        component='img'
                        src={isDarkMode ? locationIconDark : locationIcon}
                        alt='location-icon'
                        sx={{ marginRight: '0.4rem' }}
                      />
                      {authorDetails?.userDetails?.location
                        ? authorDetails?.userDetails.location
                        : NO_LOCATION}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        component='img'
                        src={isDarkMode ? calendarIconDark : calendarIcon}
                        alt='calendar-icon'
                        sx={{ marginRight: '0.4rem' }}
                      />
                      {authorDetails?.userDetails?.joiningDate ? (
                        <Box>
                          <Typography
                            component='span'
                            sx={{ marginRight: '0.313rem' }}
                          >
                            {t('joined')}
                          </Typography>
                          <Typography component='span'>
                            {format(
                              new Date(authorDetails?.userDetails?.joiningDate),
                              'MMM yyyy',
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography>{NO_JOINING_DATE}</Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '1.875rem',
                    }}
                  >
                    {authorDetails?.userDetails?.aboutMe ? (
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          color: isDarkMode
                            ? DARK_COLOR.white[100]
                            : LIGHT_COLOR.grey[500],
                          overflowX: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {authorDetails?.userDetails?.aboutMe}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          fontStyle: 'italic',
                          color: isDarkMode
                            ? DARK_COLOR.grey[400]
                            : LIGHT_COLOR.grey[300],
                        }}
                      >
                        {t('aboutme')}
                      </Typography>
                    )}
                  </Box>
                  <MobileMax>
                    <Box>
                      <Divider fullWidth sx={{ margin: '0 -1.25rem' }} />
                      <Grid container>
                        <Grid item xs sx={{ textAlign: 'center' }}>
                          <Typography
                            variant='h5'
                            sx={{ margin: '0.438rem 0 0.25rem' }}
                          >
                            {authorDetails?.newsPosts
                              ? authorDetails?.newsPosts
                              : INITIAL_VALUE}
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{
                              color: isDarkMode
                                ? DARK_COLOR.grey[600]
                                : LIGHT_COLOR.grey[400],
                              textTransform: 'lowercase',
                            }}
                          >
                            {t('news')}
                          </Typography>
                        </Grid>
                        <Divider
                          orientation='vertical'
                          sx={{ height: '3.813rem' }}
                        />
                        <Grid item xs sx={{ textAlign: 'center' }}>
                          <Typography
                            variant='h5'
                            sx={{ margin: '0.438rem 0 0.25rem' }}
                          >
                            {authorDetails?.followersCount
                              ? authorDetails?.followersCount
                              : ZERO}
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{
                              color: isDarkMode
                                ? DARK_COLOR.grey[600]
                                : LIGHT_COLOR.grey[400],
                              textTransform: 'lowercase',
                            }}
                          >
                            {t('followers')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider fullWidth sx={{ margin: '0 -1.25rem' }} />
                    </Box>
                  </MobileMax>
                </>
              ) : (
                <Box
                  sx={{
                    my: '1.25rem',
                    color: isDarkMode
                      ? (theme) => theme.palette.grey[600]
                      : (theme) => theme.palette.grey[500],
                  }}
                >
                  {authorDetails?.description
                    ? authorDetails?.description
                    : DESCRIPTION_FALLBACK}
                </Box>
              )}
              <MobileMax>
                {trackingID !== userID && isAuthenticated && (
                  <Button
                    variant={isFollowedByUser ? 'outlined' : 'contained'}
                    onClick={
                      isAuthenticated
                        ? handleFollowUnfollowAuthor
                        : () => appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' })
                    }
                    sx={{
                      height: '2.25rem',
                      px: '1.6rem',
                      maxWidth: '20.625rem',
                      width: '100%',
                      mb: '1.25rem',
                      alignSelf: 'center',
                      mt: '1.25rem',
                    }}
                  >
                    {isFollowedByUser ? FOLLOWING : FOLLOW}
                  </Button>
                )}
              </MobileMax>
            </>
          ) : null}
        </Box>
      </Box>
      {trackingID !== userID && (
        <MobileMax>
          <Divider fullWidth />
        </MobileMax>
      )}
      <Box
        sx={{
          padding: '1.25rem 0',
        }}
      >
        {!authorNews?.length && !isAuthorNewsLoading ? (
          <EmptyScreen message={t('NO_NEWS_CITIZEN_REPORTER')} />
        ) : isAuthorNewsLoading ? (
          isMobile ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CardSkeleton cards={2} />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CardSkeleton cards={2} />
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {authorNews?.map((news) =>
              news.is_promotional ? (
                <Box
                  key={news.newsId}
                  sx={{
                    maxWidth: '78.75rem',
                    width: '100%',

                    '@media screen and (max-width: 1099px)': {
                      maxWidth: 'unset',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    },
                  }}
                >
                  <PromotionalNewsList
                    key={news.newsId}
                    id={news.newsId}
                    image={news.media?.map((image) => image?.url)}
                    city={news.location}
                    likeCount={news.news_like_count}
                    dislikeCount={news.news_dislike_count}
                    newsID={news.newsId}
                    isLiked={news.is_liked}
                    isDisliked={news.is_disliked}
                    isBookmark={news.is_bookmark}
                  />
                </Box>
              ) : (
                <Box
                  key={news.newsId}
                  sx={{
                    maxWidth: '78.75rem',
                    width: '100%',

                    '@media screen and (max-width: 1099px)': {
                      maxWidth: 'unset',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    },
                  }}
                >
                  <NewsCard
                    key={news.newsId}
                    hasVideo={news.video}
                    id={news.newsId}
                    anonymous={news.anonymous}
                    description={news.heading}
                    keywords={news.keywords}
                    url={news.url}
                    maincategoryIcon={news.maincategory_icon}
                    mainCategory={news.maincategory_name}
                    mainCategoryID={news.maincategory}
                    mainCategoryUrl={news.maincategory_url}
                    headline={news.heading}
                    media={news?.media}
                    city={news.location}
                    locationID={news.locationId}
                    newsDate={news.date_news}
                    likeCount={news.news_like_count}
                    dislikeCount={news.news_dislike_count}
                    authorName={news.authorName}
                    authorID={news.authorId}
                    newsID={news.newsId}
                    authorImage={
                      news.user_type === PUBLISHER_TYPE && news.authorImage
                        ? news.authorImage
                        : `${environment.userprofile_prefix_url}${news.trackingId}`
                    }
                    isLiked={news.is_liked}
                    isDisliked={news.is_disliked}
                    isBookmark={news.is_bookmark}
                    trackingID={news.trackingId}
                    isTrending={news.is_trending}
                    newsType={news.news_type}
                    commentsCount={news.news_comments_count}
                    verifiedByDT={news.param_isVerifiedByDT}
                    newsCategories={newsCategories}
                    user_type={news.user_type}
                  />
                </Box>
              ),
            )}
            {!isEndOfPage && (
              <Button
                onClick={handleLoadMore}
                color='primary'
                variant='contained'
                sx={{
                  padding: '0.75rem 1.5rem',
                  lineHeight: '1.25rem',
                  maxWidth: isMobile ? '7.75rem' : '9.75rem',
                  height: isMobile ? '2rem' : '3rem',
                  fontSize: isMobile ? '0.75rem' : '1rem',
                  width: '100%',
                  textAlign: 'center',
                  color: 'primary',
                  alignSelf: 'center',
                }}
              >
                {t('LOAD_MORE')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default AuthorNews;
