import { useEffect, useState } from 'react';

let actions = {}; // This variable will have all actions required in meta tag related changes
let globalMetaState = {}; // this will hold all state of meta tag
let listeners = [];

export const useMetaStore = () => {
  // eslint-disable-next-line no-unused-vars
  const [metaState, setMetaState] = useState(globalMetaState);

  const dispatchMetaAction = (identifier, withPayload) => {
    /**
     * This is reducer which dispatch all action to state
     */
    const newState = actions[identifier](globalMetaState, withPayload);
    globalMetaState = { ...globalMetaState, ...newState };
    for (const listener of listeners) {
      listener(globalMetaState);
    }
  };

  useEffect(() => {
    listeners.push(setMetaState);
    return () => {
      listeners = listeners.filter((keepIf) => keepIf !== setMetaState);
    };
  }, [setMetaState]);
  return [globalMetaState, dispatchMetaAction];
};

export const initStore = (userAction, initialState) => {
  /**
   * it will store the intial confiuguration and add to our action and store
   */
  if (initialState) globalMetaState = { ...globalMetaState, ...initialState };

  actions = { ...actions, ...userAction };
};
