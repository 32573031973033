import { viewPort, MaxWidth, MinWidth } from './viewport.util';

const LargeScreenMin = ({ children }) => {
  const isLargeScreenMin = MinWidth(viewPort.dekstopView);
  return isLargeScreenMin ? children : null;
};

const DesktopMax = ({ children }) => {
  const isDesktopMax = MaxWidth(viewPort.dekstopView);
  return isDesktopMax ? children : null;
};

const DesktopMin = ({ children }) => {
  const isDesktopMin = MinWidth(viewPort.laptop);
  return isDesktopMin ? children : null;
};
const LaptopMax = ({ children }) => {
  const isLaptopMax = MaxWidth(viewPort.laptop);
  return isLaptopMax ? children : null;
};
const LaptopMin = ({ children }) => {
  const isLaptopMin = MinWidth(viewPort.tablet);
  return isLaptopMin ? children : null;
};

const TabletMax = ({ children }) => {
  const isTabletMax = MaxWidth(viewPort.tablet);
  return isTabletMax ? children : null;
};

const TabletMin = ({ children }) => {
  const isTabletMin = MinWidth(viewPort.mobile);
  return isTabletMin ? children : null;
};

const MobileMax = ({ children }) => {
  const isMobileMax = MaxWidth(viewPort.mobile);
  return isMobileMax ? children : null;
};

export {
  LargeScreenMin,
  DesktopMax,
  DesktopMin,
  LaptopMax,
  LaptopMin,
  TabletMin,
  TabletMax,
  MobileMax,
};
