import React, { useEffect } from 'react';
import HeadlinesSection from '../NewsSections/HeadlinesSection';
import HorizontalMenuBar from '../HorizontalMenuBar/HorizontalMenuBar';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Ads from '../Ads/Ads';
import { MinWidth, viewPort } from '../../responsive/viewport.util';
const NewsLayout = ({
  category,
  childCategory,
  isFromChildCategory,
  newsCategories,
  navBarCategories,
  navBarFilters,
  filter,
  districts,
  isDistrict,
}) => {
  const dispatchMetaAction = useMetaStore()[1];
  const location = useLocation();
  const isLaptop = MinWidth(viewPort.laptop);
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;

  useEffect(() => {
    //we updating title on the basis of the path and update in Meta tags
    let rawTitle = location.pathname;

    //To remove the location ID from the url
    if (rawTitle.startsWith('/districts/')) {
      rawTitle = rawTitle.split('-')[0];
    }

    const titleArray = rawTitle.substring(1).split('/');
    let titleString =
      titleArray[titleArray.length - 1].charAt(0).toUpperCase() +
      titleArray[titleArray.length - 1].slice(1);
    let keywords = '';
    let description = '';
    let canonicalUrl = '';

    // const removeNewsLabel = LEFT_ROUTE_NEWS_LABEL.find((element) =>
    //   titleString.toLowerCase().includes(element),
    // );

    // TODO: Need to refactor
    // titleString = removeNewsLabel
    //   ? titleString.replace('-news', '').replace('-you', ' You')
    //   : `${titleString.replaceAll('-', ' ')}`;

    if (category) {
      const foundCategory = newsCategories.find(
        (category) => category.url === rawTitle,
      );
      titleString = isTamil ? category?.name_tamil : category?.name;
      description = foundCategory?.description || '';
      keywords = foundCategory?.keywords || '';
      canonicalUrl = foundCategory?.url || '';
    }

    if (childCategory) {
      titleString = isTamil ? childCategory?.name_tamil : childCategory?.name;
      description = childCategory?.description || '';
      keywords = childCategory?.keywords || '';
      canonicalUrl = childCategory?.url || '';
    }

    if (filter) {
      titleString = filter?.MetaData?.Title
        ? filter?.MetaData?.Title
        : isTamil
        ? filter?.name_ta
        : filter?.name;
      keywords = filter?.MetaData?.Keywords || '';
      description = filter?.MetaData?.Description || '';
      canonicalUrl = filter?.url || '';
    }

    if (isDistrict) {
      const foundDistrict = districts.find(
        (district) => `/districts${district.url}` === rawTitle,
      );
      keywords = foundDistrict?.keywords || '';
      description = foundDistrict?.description || '';
      canonicalUrl = `${foundDistrict?.url}-${foundDistrict?.locationId}` || '';
    }

    const metaObj = {
      title: titleString,
      description: description,
      keywords: keywords,
      canonical_url: canonicalUrl,
    };

    dispatchMetaAction(metaAction.updateMeta, metaObj);
  }, [location.pathname]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: isLaptop ? 'row' : 'column',
      }}
    >
      {!isLaptop ? (
        <HorizontalMenuBar
          newsCategories={navBarCategories}
          isBackRequired={false}
          category={category}
          navBarFilters={navBarFilters}
          filter={filter}
          districts={districts}
        />
      ) : (
        <Box sx={{ width: !isLaptop ? '100%' : '22%' }}>
          <HorizontalMenuBar
            newsCategories={navBarCategories}
            isBackRequired={false}
            category={category}
            navBarFilters={navBarFilters}
            filter={filter}
            districts={districts}
          />
        </Box>
      )}

      <Box
        sx={{
          width: !isLaptop ? '100%' : '50%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <HeadlinesSection
          isFromChildCategory={isFromChildCategory}
          childCategory={childCategory}
          category={category}
          newsCategories={newsCategories}
          filter={filter}
          navBarFilters={navBarFilters}
          districts={districts}
        />
      </Box>

      {isLaptop && (
        <Box
          sx={{
            width: !isLaptop ? '100%' : '22%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Ads />
        </Box>
      )}
    </Box>
  );
};

export default NewsLayout;
