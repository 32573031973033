import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import './Topics.css';
import { Close } from '@mui/icons-material';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        marginLeft: '0.5rem',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TopicModal = (props) => {
  const { showModal, modalHeader, closeModal, topic } = props;
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;
  const isMobile = MaxWidth(viewPort.mobileMax);
  const dispatchMetaAction = useMetaStore()[1];
  const location = useLocation();

  const handleMetaTag = (meta) => {
    const metaObj = {
      title: isTamil ? meta?.name : meta?.name_tamil,
      description: meta?.description,
      keywords: meta?.keywords,
      canonical_url: location.pathname,
    };

    dispatchMetaAction(metaAction.updateMeta, metaObj);
    closeModal();
  };

  return ReactDOM.createPortal(
    <div className='rootModal'>
      <Dialog
        open={showModal}
        aria-labelledby='responsive-dialog-title'
        aria-describedby='dialog-description'
        scroll='paper'
        fullScreen={isMobile ? true : false}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '50.5rem',
              marginTop: isMobile ? 0 : '5rem',
            },
          },
          '& .MuiDialog-paper': {
            margin: 'unset',
          },
        }}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={closeModal}>
          {modalHeader}
        </BootstrapDialogTitle>
        <Divider variant='fullWidth' />
        <DialogContent
          className='dailogContent'
          sx={{
            pt: 0,
          }}
        >
          <div className='topicContent'>
            <Box className='topicList'>
              {topic?.map((item, index) => {
                return (
                  <Link
                    to={`${item.url}`}
                    className='topicNameList'
                    state={{ data: item }}
                    key={index}
                    onClick={() => handleMetaTag(item)}
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant='body1' sx={{ my: '1rem' }}>
                      {isTamil ? item.name_tamil : item.name}
                    </Typography>
                    {isMobile && <Divider />}
                  </Link>
                );
              })}
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>,
    document.getElementById('modal-root'),
  );
};

export default TopicModal;
