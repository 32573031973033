import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyScreen from '../EmptyScreen/EmptyScreen';
import HorizontalMenuBar from '../HorizontalMenuBar/HorizontalMenuBar';

/**
 * NotFound Component
 *  - to show 404 page
 */
function NotFound({ navBarCategories, navBarFilters, districts }) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HorizontalMenuBar
        newsCategories={navBarCategories}
        navBarFilters={navBarFilters}
        isBackRequired={false}
        districts={districts}
      />
      <EmptyScreen message={t('page-not-found')} />
    </div>
  );
}

export default NotFound;
