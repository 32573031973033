import React from 'react';
import DetailedNewsCard from '../NewsCards/NewsCard';
import { EnvironmentProvider } from '../../store/firebase-context';
import { useContext } from 'react';
import { PUBLISHER_TYPE } from '../../Constants/Constants';

const DetailedNewsData = ({ news, newsCategories }) => {
  const { environment } = useContext(EnvironmentProvider);
  return (
    <DetailedNewsCard
      key={news.newsId}
      newsID={news.newsId}
      headline={news.heading}
      description={news.heading}
      keywords={news.keywords}
      anonymous={news.anonymous}
      story={news.story}
      media={news.media}
      city={news.location}
      locationID={news.locationId}
      newsDate={news.date_news}
      likeCount={news.news_like_count}
      dislikeCount={news.news_dislike_count}
      authorImage={
        news.user_type === PUBLISHER_TYPE && news.authorImage
          ? news.authorImage
          : `${environment.userprofile_prefix_url}${news.trackingId}`
      }
      authorName={news.authorName}
      authorID={news.authorId}
      trackingID={news.trackingId}
      isLiked={news.is_liked}
      isDisliked={news.is_disliked}
      isBookmark={news.is_bookmark}
      maincategoryIcon={news.maincategory_icon}
      mainCategory={news.maincategory_name}
      mainCategoryID={news.maincategory}
      mainCategoryUrl={news.maincategory_url}
      isTrending={news.is_trending}
      newsType={news.news_type}
      commentsCount={news.news_comments_count}
      newsCategories={newsCategories}
      verifiedByDT={news.param_isVerifiedByDT}
      url={news.url}
      user_type={news.user_type}
      can_flag={news.can_flag}
      id={news.newsId}
      isDetailPage={true}
    />
  );
};

export default DetailedNewsData;
