import React, { useState, useEffect } from 'react';
import './Profile.css';
import { Grid, Box } from '@mui/material';
import editIcon from '../../assets/icons/edit.svg';
import editDarkIcon from '../../assets/icons/editDark.svg';
import deleteIcon from '../../assets/icons/Delete.svg';
import deleteDarkIcon from '../../assets/icons/delete-dark.svg';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';

const EditProfile = ({ isImageDeleted, userProfilePic, deleteProfilePic }) => {
  const [gridSize, setGridSize] = useState(12);
  const [hideIcon, setHideIcon] = useState(true);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.mobile);

  useEffect(() => {
    setTimeout(() => {
      setHideIcon(false);
    }, 1500);
    isImageDeleted && setGridSize(6);
  }, [isImageDeleted]);

  return (
    <Box
      className='editIconsSection'
      sx={{
        background: isDarkMode
          ? isMobile
            ? DARK_COLOR.grey[300]
            : ''
          : isMobile
          ? LIGHT_COLOR.white[100]
          : '',
      }}
    >
      <Grid container item xs={12} justifyContent='center'>
        <Grid xs={gridSize}>
          <div
            onClick={() => userProfilePic.current.click()}
            className={isImageDeleted ? 'editProfileIcon' : 'editIcons'}
          >
            {!hideIcon && (
              <img src={isDarkMode ? editDarkIcon : editIcon} alt='edit icon' />
            )}
          </div>
        </Grid>
        {isImageDeleted && (
          <Grid xs={6}>
            <div
              onClick={() => deleteProfilePic(userProfilePic.current)}
              className='deleteIcons'
            >
              {!hideIcon && (
                <img
                  src={isDarkMode ? deleteDarkIcon : deleteIcon}
                  alt='delete icon'
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EditProfile;
