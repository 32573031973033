import React, { useState, useEffect } from 'react';
import { TextField, Stack, Button, Grid, Typography, Box } from '@mui/material';
import completedIcon from '../../assets/completed.png';
import './ForgotPassword.css';
import { useTranslation } from 'react-i18next';
import Timer from '../Timer/Timer';
import authService from '../../services/auth.service';
import { DARK_COLOR, LIGHT_COLOR } from '../../themes/color.constant';
import ButtonLoader from '../buttonLoader/ButtonLoader';

const ForgotPassword = (props) => {
  const { openSignIn, changeHeight, closeModal } = props;
  const initialUserValue = {
    phoneNumber: '',
    code: '',
    password: '',
    confirmPassword: '',
  };
  const initialErr = {
    phoneNumber: '',
    code: '',
    password: '',
    confirmPassword: '',
  };
  const [user, setUser] = useState(initialUserValue);
  const [errMsg, setErrMsg] = useState('');
  const [showNewPasswordForm, setNewPasswordForm] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [pwdErr, setPwdErr] = useState(initialErr);
  const [sendOtp, setSendOtp] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [userSubmitLoading, setUserSubmitLoading] = useState(false);
  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const inputRef = React.useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const handleChange = (e) => {
    if (e.target.name === 'code' || e.target.name === 'phoneNumber') {
      setUser({
        ...user,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ''),
      });
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
    setPwdErr({ ...pwdErr, [e.target.name]: '' });
    setErrMsg('');
  };
  const showSignIn = () => {
    closeModal();
    openSignIn();
  };

  const handleUserNameSubmit = async (e) => {
    e.preventDefault();
    let errMsg = {};
    if (user.phoneNumber <= 0) {
      errMsg['phoneNumber'] = t('phone-number-required');
    }
    setPwdErr(errMsg);
    let phoneNumber;

    if (user?.phoneNumber?.length === 10 && /^\d+$/.test(user?.phoneNumber)) {
      phoneNumber = `+91${user?.phoneNumber}`;
    } else {
      errMsg['phoneNumber'] = t('enter-valid-10-digit-number');
    }

    if (phoneNumber) {
      try {
        await authService.forgotPassword(phoneNumber);
        setUserSubmitLoading(false);
        changeHeight(false);
        setNewPasswordForm(true);
        setErrMsg('');
      } catch (error) {
        setUserSubmitLoading(false);
        setErrMsg(error.message);
      }
    }
  };

  const handleVerificationCodeSubmit = async (e) => {
    e.preventDefault();
    let errMsg = {};
    if (user.code.length <= 0) {
      errMsg['code'] = 'code required';
    }
    if (user.code.length > 1 && user.code.length < 6) {
      errMsg['code'] = t('enter-6-digit-otp');
    }
    if (user.password.length <= 0) {
      errMsg['password'] = t('password-required');
    }
    if (user.password.length > 1 && user.password.length < 6) {
      errMsg['password'] = t('PASSWORD_LENGTH_ERR_MSG');
    }
    if (user.confirmPassword <= 0) {
      errMsg['confirmPassword'] = t('confirm-password-required');
    }
    if (user.confirmPassword.length > 1 && user.confirmPassword.length < 6) {
      errMsg['confirmPassword'] = t('CONFIRM_PASSWORD_ERR');
    }
    setPwdErr(errMsg);
    if (
      user.code &&
      user.password &&
      user.confirmPassword &&
      user.password.length >= 6 &&
      user.confirmPassword.length >= 6
    ) {
      if (user.password === user.confirmPassword) {
        setVerificationLoading(true);
        try {
          authService
            .forgotPasswordSubmit({
              username: `+91${user?.phoneNumber}`,
              code: user?.code,
              password: user?.password,
            })
            .then((res) => {
              setVerificationLoading(false);
              if (res === 'SUCCESS') {
                setShowSuccessMsg(true);
                changeHeight(true);
              }
            })
            .catch((err) => {
              setVerificationLoading(false);
              if (
                err?.message?.includes('Invalid verification code provided')
              ) {
                setPwdErr({
                  code: t('OTP-IS-INCORRECT_PLEASE_TRY_AGAIN'),
                });
              } else {
                setErrMsg(err.message);
              }
            });
        } catch (error) {
          setVerificationLoading(false);
          setErrMsg(error.message);
        }
      } else {
        setErrMsg(t('passwords-do-not-match'));
      }
    }
  };

  const resendOtp = async () => {
    if (sendOtp) {
      await authService
        .forgotPassword(user?.phoneNumber)
        .then(setSendOtp(false), setResetTimer(!resetTimer));
    }
  };

  /**
   * Function to prevent increment & decrement of value by pressing arrow up/down key
   */
  const handleKeyEvent = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };

  return (
    <>
      {!showNewPasswordForm ? (
        <form onSubmit={handleUserNameSubmit}>
          <Stack>
            <div className='passwordFlexContainer'>
              <Typography
                sx={{
                  color: isDarkMode
                    ? (theme) => theme.palette.grey[600]
                    : (theme) => theme.palette.grey[400],
                  textAlign: 'center',
                  marginTop: '-1rem',
                  maxWidth: '18.75rem',
                  width: '100%',
                }}
              >
                {t(
                  'enter-your-email-or-phone-number-and-we-will-send-you-an-otp-to-reset-password',
                )}
              </Typography>
            </div>
          </Stack>
          <Stack>
            <div className='passwordFlexContainer'>
              <TextField
                error={pwdErr.phoneNumber ? true : false}
                inputRef={inputRef}
                className='passwordTextField'
                label={t('phone-number')}
                variant='outlined'
                name='phoneNumber'
                inputProps={{ maxLength: 10 }}
                value={user.phoneNumber}
                onChange={handleChange}
              />
            </div>
          </Stack>
          <Stack>
            {errMsg && (
              <div className='passwordFlexContainer'>
                <p className='errorMessage'>{errMsg}</p>
              </div>
            )}
          </Stack>
          <Typography
            sx={{
              margin: ' 0.45rem',
              marginLeft: '1.3rem',
              fontSize: '0.75rem',
              color: (t) =>
                isDarkMode
                  ? t.palette.minmini_grey.grey
                  : t.palette.minmini_grey.grey,
            }}
          >
            {t('outside-country-phone')}
          </Typography>
          <Stack>
            <div className='passwordFlexContainer'>
              <div className='passwordBtn'>
                <ButtonLoader
                  disabled={user.phoneNumber?.length < 10}
                  variant='contained'
                  type='submit'
                  loading={userSubmitLoading}
                >
                  {t('cproceed')}
                </ButtonLoader>
              </div>
            </div>
          </Stack>
          <Stack>
            <div className='passwordFlexContainer'>
              <div className='passwordOutlineBtn'>
                <Button
                  onClick={showSignIn}
                  variant='outlined'
                  sx={{
                    padding: '0.75rem 1.5rem',
                    lineHeight: '1.25rem',
                    width: '18.75rem',
                    textAlign: 'center',
                    color: 'primary',
                    alignSelf: 'center',
                  }}
                >
                  {t('cback-to-login')}
                </Button>
              </div>
            </div>
          </Stack>
        </form>
      ) : (
        <>
          {' '}
          {!showSuccessMsg ? (
            <form onSubmit={handleVerificationCodeSubmit}>
              <Stack>
                <div className='passwordFlexContainer'>
                  <Typography
                    sx={{
                      color: isDarkMode
                        ? (theme) => theme.palette.grey[600]
                        : (theme) => theme.palette.grey[400],
                      textAlign: 'center',
                      marginTop: '-1rem',
                      maxWidth: '18.75rem',
                      width: '100%',
                    }}
                  >
                    {t('we-have-sent-a-6-digit-otp-to-your-phone')}
                  </Typography>
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <TextField
                    onKeyUp={handleKeyEvent}
                    onKeyDown={handleKeyEvent}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 6);
                    }}
                    error={pwdErr.code ? true : false}
                    helperText={pwdErr.code ? pwdErr.code : ''}
                    inputRef={inputRef}
                    className='passwordTextField'
                    label={t('6-digit-otp')}
                    variant='outlined'
                    name='code'
                    value={user.code}
                    onChange={handleChange}
                  />
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ maxWidth: '18.75rem' }}
                  >
                    <Box
                      className='forgotPasswordCounter'
                      sx={{ color: LIGHT_COLOR.grey[400], marginRight: '10px' }}
                    >
                      <Timer
                        resetTimer={resetTimer}
                        resetOtp={() => setSendOtp(true)}
                        hideTimer={sendOtp}
                      />
                    </Box>
                    <Box onClick={resendOtp}>
                      <Typography
                        variant='subtitle2'
                        sx={{
                          fontWeight: '600',
                          color: sendOtp
                            ? isDarkMode
                              ? DARK_COLOR.primary[100]
                              : LIGHT_COLOR.primary[100]
                            : LIGHT_COLOR.primary[300],
                          cursor: sendOtp ? 'pointer' : 'none',
                        }}
                      >
                        {t('cresend-otp')}
                      </Typography>
                    </Box>
                  </Grid>
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <TextField
                    error={pwdErr.password || errMsg ? true : false}
                    helperText={pwdErr.password ? pwdErr.password : ''}
                    className='passwordTextField'
                    label={t('password')}
                    variant='outlined'
                    name='password'
                    value={user.password}
                    onChange={handleChange}
                    type='password'
                  />
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <TextField
                    error={pwdErr.confirmPassword || errMsg ? true : false}
                    helperText={
                      pwdErr.confirmPassword ? pwdErr.confirmPassword : ''
                    }
                    className='passwordTextField'
                    label={t('confirm-password')}
                    variant='outlined'
                    name='confirmPassword'
                    value={user.confirmPassword}
                    onChange={handleChange}
                    type='password'
                  />
                </div>
              </Stack>
              <Stack>
                {errMsg && (
                  <div className='passwordFlexContainer'>
                    <p className='errorMessage'>{errMsg}</p>
                  </div>
                )}
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <div className='passwordBtn'>
                    <ButtonLoader
                      disabled={user.code === ''}
                      variant='contained'
                      type='submit'
                      loading={verificationLoading}
                    >
                      {t('cproceed')}
                    </ButtonLoader>
                  </div>
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <div className='passwordOutlineBtn'>
                    <Button
                      onClick={showSignIn}
                      variant='outlined'
                      sx={{
                        padding: '0.75rem 1.5rem',
                        lineHeight: '1.25rem',
                        width: '18.75rem',
                        textAlign: 'center',
                        color: 'primary',
                        alignSelf: 'center',
                      }}
                    >
                      {t('cback-to-login')}
                    </Button>
                  </div>
                </div>
              </Stack>
            </form>
          ) : (
            <>
              <Stack>
                <div className='passwordFlexContainer'>
                  <img
                    src={completedIcon}
                    alt='completedIcon'
                    className='forgotCompleteIcon'
                  />
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <h6 className='passwordHeader'>{t('password-changed')}</h6>
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <Typography
                    sx={{
                      color: isDarkMode
                        ? (theme) => theme.palette.grey[600]
                        : (theme) => theme.palette.grey[400],
                      textAlign: 'center',
                      marginTop: '-1rem',
                      maxWidth: '18.75rem',
                      width: '100%',
                    }}
                  >
                    {t(
                      'awesome-you-have-successfully-reset-your-password-you-can-now-login-using-your-new-password',
                    )}
                  </Typography>
                </div>
              </Stack>
              <Stack>
                <div className='passwordFlexContainer'>
                  <div className='passwordBtn'>
                    <Button variant='contained' onClick={showSignIn}>
                      {t('clogin')}
                    </Button>
                  </div>
                </div>
              </Stack>
            </>
          )}{' '}
        </>
      )}
    </>
  );
};

export default ForgotPassword;
