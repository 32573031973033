import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Box, Checkbox } from '@mui/material';
import { ModifyData } from '../Common/CommonFunctions';
import 'react-loading-skeleton/dist/skeleton.css';
import { ONE } from '../../Constants/Constants';
import { useTranslation } from 'react-i18next';
import userProfileService from '../../services/user-profile.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import followersService from '../../services/followers.service';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import AntSwitch from './AntSwitch';
import FollowingTabBackdrop from './FollowingTabBackdrop';

const Topics = ({ handleTopicFollowCount }) => {
  const initialUserDetails = {
    email: '',
    phone: '',
    username: '',
  };
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [callValue, setCallValue] = useState(0);
  const [isTopicName, setIsTopicName] = useState(false);
  const [callSelectedValue, setCallSelectedValue] = useState(0);
  const [defaultPublishers, setDefaultPublihsers] = useState([]);
  const [defaultTopics, setDefaultTopics] = useState([]);
  const [defaultReporters, setDefaultReporters] = useState([]);
  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [isCreateFollowing, setCreateFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { state: userState } = useContext(UserDetailsProvider);
  const userName = userState?.userDetails?.userName;
  const email = userState?.userDetails?.email;
  const userId = userState?.userDetails?.userId;
  const phoneNumber = userState?.userDetails?.phone;
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const { t } = useTranslation();
  const isMobile = MaxWidth(viewPort.tablet);
  const isTamil = localStorage.getItem('lang') === 'Tamil' ? true : false;

  useEffect(() => {
    setUserDetails({
      email: email,
      phone: phoneNumber,
      username: userName,
    });
  }, []);

  useEffect(() => {
    getPreferencesData();
  }, []);

  useEffect(() => {
    callSelectedValue !== 0 && getSelectedTopics();
  }, [callSelectedValue]);

  useEffect(() => {
    isTopicName && getSelectedTopicsName();
  }, [callValue]);

  /**
   * side effect to set topics follow Count for warning modals if the publisher list has already loaded
   */
  useEffect(() => {
    !isLoading && handleTopicFollowCount(selectedTopics?.length);
  }, [selectedTopics, isLoading]);

  const getPreferencesData = async () => {
    try {
      const response = await followersService.getTopicsAuthorsList();
      setTopics(response);
      fetchPreferences();
      setIsLoading(false);
    } catch (error) {}
  };

  const handleSwitchChange = (catId) => {
    const isFound = selectedTopics?.some((e) => e.catId === catId);
    if (isFound) {
      let newData = selectedTopics.filter((l) => l.catId !== catId);
      setSelectedTopics(newData);
    } else {
      setSelectedTopics([...selectedTopics, { catId }]);
    }
    setIsTopicName(true);
    setCallValue((prev) => prev + ONE);
  };

  const getSelectedTopicsName = () => {
    const topicId = selectedTopics.map((object) => object.catId);
    isCreateFollowing ? CreateFollowing(topicId) : UpdateFollowing(topicId);
  };

  const fetchPreferences = async () => {
    try {
      const preferenceData = await userProfileService.getUserProfile({
        userId: userId,
      });
      const modifiedData = ModifyData(preferenceData.data.getDTUserProfile);
      setDefaultPublihsers(modifiedData?.following?.publishers);
      setDefaultTopics(modifiedData?.following?.topics);
      setDefaultReporters(modifiedData?.following?.reporters);
      setCallSelectedValue((prev) => prev + ONE);
    } catch (error) {
      setCreateFollowing(true);
    }
  };

  const getSelectedTopics = async () => {
    const props = ['catId'];
    const result =
      (await topics) &&
      topics?.cat_list_unified
        ?.filter(function (o1) {
          return defaultTopics?.some(function (o2) {
            return o1.catId === o2;
          });
        })
        .map(function (o) {
          return props.reduce(function (newo, catId) {
            newo[catId] = o[catId];
            return newo;
          }, {});
        });
    setSelectedTopics(result);
  };

  const UpdateFollowing = async (topicId) => {
    let preparedFollowingsData = {
      publishers: defaultPublishers,
      topics: topicId,
      reporters: defaultReporters,
    };
    try {
      await userProfileService.updateUserProfile({
        userId: userId,
        following: JSON.stringify(preparedFollowingsData),
      });
    } catch (error) {}
  };

  const CreateFollowing = async (topicId) => {
    let preparedFollowingsData = {
      topics: topicId,
    };
    try {
      await userProfileService.createUserProfile({
        userId: userId,
        userDetails: JSON.stringify(userDetails),
        following: JSON.stringify(preparedFollowingsData),
      });

      setCreateFollowing(false);
      fetchPreferences();
    } catch (error) {}
  };

  const selectAllTopics = () => {
    if (topics?.cat_list_unified?.length !== selectedTopics?.length) {
      setSelectedTopics(
        topics && topics?.cat_list_unified?.map((topic) => topic),
      );
    } else {
      setSelectedTopics([]);
    }
    setIsTopicName(true);
    setCallValue((prev) => prev + ONE);
  };

  return (
    <Box
      sx={{
        maxHeight: isMobile ? '28rem' : '24rem',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          background: 'transparent',
          appearance: 'none',
          width: 0,
          height: 0,
        },
        paddingBottom: '1rem',
      }}
    >
      <FollowingTabBackdrop isLoading={isLoading} />
      <Box
        sx={{
          py: '0.1875rem',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Checkbox
          checked={selectedTopics?.length === topics?.cat_list_unified?.length}
          indeterminate={
            selectedTopics?.length !== 0 &&
            selectedTopics?.length !== topics?.cat_list_unified?.length
          }
          onChange={selectAllTopics}
        />{' '}
        <Box
          component='span'
          sx={{
            fontWeight: 600,
            fontSize: '0.875rem',
            color: isDarkMode
              ? (theme) => theme.palette.grey[600]
              : (theme) => theme.palette.grey[400],
            textTransform: 'uppercase',
          }}
        >
          {t('FOLLOW_ALL_TOPICS')}
        </Box>
      </Box>
      {topics &&
        topics?.cat_list_unified?.map((topic) => {
          return (
            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              key={topic.catId}
              sx={{
                py: 3,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: isDarkMode
                      ? (theme) => theme.palette.grey[600]
                      : (theme) => theme.palette.grey[500],
                  }}
                >
                  {isTamil ? topic.name_tamil : topic.name}
                </Typography>
              </Box>
              <Box>
                <AntSwitch
                  checked={selectedTopics?.some((e) => e.catId === topic.catId)}
                  onChange={() => handleSwitchChange(topic.catId)}
                  inputProps={{ 'aria-label': 'publish-switch' }}
                  color='primary'
                />
              </Box>
            </Grid>
          );
        })}
    </Box>
  );
};

export default Topics;
