/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDTUserProfile = /* GraphQL */ `
  mutation CreateDTUserProfile($input: CreateDTUserProfileInput!) {
    createDTUserProfile(input: $input) {
      userId
      firstName
      lastName
      userName
      followersCount
      userDetails
      preferences
      following
      citizenJournalist
      isOnboardedReporter
      isCitizenReporter
      isDTVerifiedReporter
      isInfluencer
      followingCount
      userType
      blockedByOtherUsersCount
      blockedUserCount
      coverPostId
      phone
      __typename
    }
  }
`;
export const updateDTUserProfile = /* GraphQL */ `
  mutation UpdateDTUserProfile($input: UpdateDTUserProfileInput!) {
    updateDTUserProfile(input: $input) {
      userId
      firstName
      lastName
      userName
      followersCount
      userDetails
      preferences
      following
      citizenJournalist
      isOnboardedReporter
      isCitizenReporter
      isDTVerifiedReporter
      isInfluencer
      followingCount
      userType
      blockedByOtherUsersCount
      blockedUserCount
      coverPostId
      phone
      __typename
    }
  }
`;
export const deleteDTUserProfile = /* GraphQL */ `
  mutation DeleteDTUserProfile($input: DeleteDTUserProfileInput!) {
    deleteDTUserProfile(input: $input) {
      userId
      firstName
      lastName
      userName
      followersCount
      userDetails
      preferences
      following
      citizenJournalist
      isOnboardedReporter
      isCitizenReporter
      isDTVerifiedReporter
      isInfluencer
      followingCount
      userType
      blockedByOtherUsersCount
      blockedUserCount
      coverPostId
      phone
      __typename
    }
  }
`;
export const createSearchItems = /* GraphQL */ `
  mutation CreateSearchItems($input: CreateDTSearchInput!) {
    createSearchItems(input: $input) {
      userId
      searchHistory
      __typename
    }
  }
`;
export const updateSearchItems = /* GraphQL */ `
  mutation UpdateSearchItems($input: UpdateDTSearchInput!) {
    updateSearchItems(input: $input) {
      userId
      searchHistory
      __typename
    }
  }
`;
export const manageDTUserFollow = /* GraphQL */ `
  mutation ManageDTUserFollow($input: ManageFollowersInput!) {
    manageDTUserFollow(input: $input) {
      userId
      follow
      unfollow
      __typename
    }
  }
`;
export const manageDTUserBlock = /* GraphQL */ `
  mutation ManageDTUserBlock($input: ManageBlockUserInput!) {
    manageDTUserBlock(input: $input) {
      userId
      block
      unblock
      __typename
    }
  }
`;
