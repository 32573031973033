/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDTUserProfile = /* GraphQL */ `
  subscription OnCreateDTUserProfile($userId: String) {
    onCreateDTUserProfile(userId: $userId) {
      userId
      firstName
      lastName
      userName
      followersCount
      userDetails
      preferences
      following
      citizenJournalist
      isOnboardedReporter
      isCitizenReporter
      isDTVerifiedReporter
      isInfluencer
      followingCount
      userType
      blockedByOtherUsersCount
      blockedUserCount
      coverPostId
      phone
      __typename
    }
  }
`;
export const onUpdateDTUserProfile = /* GraphQL */ `
  subscription OnUpdateDTUserProfile($userId: String) {
    onUpdateDTUserProfile(userId: $userId) {
      userId
      firstName
      lastName
      userName
      followersCount
      userDetails
      preferences
      following
      citizenJournalist
      isOnboardedReporter
      isCitizenReporter
      isDTVerifiedReporter
      isInfluencer
      followingCount
      userType
      blockedByOtherUsersCount
      blockedUserCount
      coverPostId
      phone
      __typename
    }
  }
`;
export const onDeleteDTUserProfile = /* GraphQL */ `
  subscription OnDeleteDTUserProfile($userId: String) {
    onDeleteDTUserProfile(userId: $userId) {
      userId
      firstName
      lastName
      userName
      followersCount
      userDetails
      preferences
      following
      citizenJournalist
      isOnboardedReporter
      isCitizenReporter
      isDTVerifiedReporter
      isInfluencer
      followingCount
      userType
      blockedByOtherUsersCount
      blockedUserCount
      coverPostId
      phone
      __typename
    }
  }
`;
export const onManageDTUserFollow = /* GraphQL */ `
  subscription OnManageDTUserFollow($userId: String) {
    onManageDTUserFollow(userId: $userId) {
      userId
      follow
      unfollow
      __typename
    }
  }
`;
export const onManageDTUserBlock = /* GraphQL */ `
  subscription OnManageDTUserBlock($userId: String) {
    onManageDTUserBlock(userId: $userId) {
      userId
      block
      unblock
      __typename
    }
  }
`;
