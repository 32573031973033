import React from 'react';
import { useContext } from 'react';
import NewsCard from '../NewsCards/NewsCard';
import NewsCardGrid from '../NewsCards/NewsCardGrid';
import PromotionalNewsList from '../NewsCards/PromotionalNewsList';
import PromotionalNewsGrid from '../NewsCards/PromotionalNewsGrid';
import { Grid } from '@mui/material';
import { AppContext } from '../../store/app-context';
import { EnvironmentProvider } from '../../store/firebase-context';
import { PUBLISHER_TYPE } from '../../Constants/Constants';

/**
 * NewsPostsData Component
 */
const NewsPostsData = (props) => {
  const { state: appCtxState } = useContext(AppContext);
  const { environment } = useContext(EnvironmentProvider);

  return (
    <>
      {appCtxState?.newsView?.activeList &&
        props.news?.map((news) =>
          news.is_promotional ? (
            <div key={news.newsId}>
              <PromotionalNewsList
                key={news.newsId}
                id={news.newsId}
                image={news.media?.map((image) => image?.url)}
                likeCount={news.news_like_count}
                dislikeCount={news.news_dislike_count}
                newsId={news.newsId}
                isLiked={news.is_liked}
                isDisliked={news.is_disliked}
                isBookmark={news.is_bookmark}
              />
            </div>
          ) : (
            <div key={news.newsId}>
              <NewsCard
                key={news.newsId}
                hasVideo={news.video}
                id={news.newsId}
                anonymous={news.anonymous}
                keywords={news.keywords}
                url={news.url}
                description={news.heading}
                maincategoryIcon={news.maincategory_icon}
                mainCategory={news.maincategory_name}
                mainCategoryID={news.maincategory}
                mainCategoryUrl={news.maincategory_url}
                headline={news.heading}
                media={news?.media}
                city={news.location}
                locationID={news.locationId}
                newsDate={news.date_news}
                likeCount={news.news_like_count}
                dislikeCount={news.news_dislike_count}
                authorName={news.authorName}
                authorID={news.authorId}
                trackingID={news.trackingId}
                newsID={news.newsId}
                authorImage={
                  news.user_type === PUBLISHER_TYPE && news.authorImage
                    ? news.authorImage
                    : `${environment.userprofile_prefix_url}${news.trackingId}`
                }
                isLiked={news.is_liked}
                isDisliked={news.is_disliked}
                isBookmark={news.is_bookmark}
                isTrending={news.is_trending}
                newsType={news.news_type}
                verifiedByDT={news.param_isVerifiedByDT}
                newsCategories={props.newsCategories}
                onCardAction={props.onCardAction}
                user_type={news.user_type}
              />
            </div>
          ),
        )}

      {appCtxState?.newsView?.activeGrid && (
        <Grid
          container
          spacing={{ xs: 2 }}
          columns={{ xs: 'auto', sm: 6, md: 9, lg: 12 }}
          justifyContent='center'
        >
          {props.news?.map((news) =>
            news.is_promotional ? (
              <Grid item xs={3} key={news.newsId}>
                <PromotionalNewsGrid
                  key={news.newsId}
                  id={news.newsId}
                  image={news.media?.map((image) => image?.url)}
                  likeCount={news.news_like_count}
                  dislikeCount={news.news_dislike_count}
                  newsId={news.newsId}
                  isLiked={news.is_liked}
                  isDisliked={news.is_disliked}
                  isBookmark={news.is_bookmark}
                />
              </Grid>
            ) : (
              <Grid item xs={3} key={news.newsId}>
                <NewsCardGrid
                  key={news.newsId}
                  id={news.newsId}
                  url={news.url}
                  anonymous={news.anonymous}
                  hasVideo={news.video}
                  maincategoryIcon={news.maincategory_icon}
                  mainCategory={news.maincategory_name}
                  mainCategoryID={news.maincategory}
                  mainCategoryUrl={news.maincategory_url}
                  headline={news.heading}
                  media={news?.media}
                  city={news.location}
                  locationID={news.locationId}
                  newsDate={news.date_news}
                  likeCount={news.news_like_count}
                  dislikeCount={news.news_dislike_count}
                  authorName={news.authorName}
                  authorID={news.authorId}
                  trackingID={news.trackingId}
                  newsID={news.newsId}
                  authorImage={
                    news.user_type === PUBLISHER_TYPE && news.authorImage
                      ? news.authorImage
                      : `${environment.userprofile_prefix_url}${news.trackingId}`
                  }
                  isLiked={news.is_liked}
                  isDisliked={news.is_disliked}
                  isBookmark={news.is_bookmark}
                  isTrending={news.is_trending}
                  newsType={news.news_type}
                  newsCategories={props.newsCategories}
                  onCardAction={props.onCardAction}
                  user_type={news.user_type}
                />
              </Grid>
            ),
          )}
        </Grid>
      )}
    </>
  );
};

export default NewsPostsData;
