import React, { useState, useEffect, useContext } from 'react';
import { Divider, Grid, Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Arrow from '../../assets/icons/arrow-right.svg';
import DarkThemeArrow from '../../assets/icons/arrow-white-right.svg';
import Tick from '../../assets/icons/Tick.svg';
import './SettingPrivacy.css';
import { DARK, LIGHT, TAMIL, ENGLISH } from '../../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import userProfileService from '../../services/user-profile.service';
import { UserDetailsProvider } from '../../store/user-details-context';
import { AppContext } from '../../store/app-context';
import lightModeLightIcon from '../../assets/icons/light-mode-light.svg';
import lightModeDarkIcon from '../../assets/icons/light-mode-dark.svg';
import darkModeLightIcon from '../../assets/icons/dark-mode-light.svg';
import darkModeDarkIcon from '../../assets/icons/dark-mode-dark.svg';
import InfoModal from '../Modal/InfoModal';
import authService from '../../services/auth.service';
import appService from '../../services/app.service';
import OverlayLoader from '../Loader/OverLayLoader';

const SettingPrivacy = ({
  showOptions,
  closeModal,
  showLang,
  showDelete,
  setBackIcon,
  showAppearance,
}) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [showOptionModal, setShowOptionModal] = useState(false);

  const dtTheme = localStorage.getItem('theme');
  const dtLanguage = localStorage.getItem('lang');

  const initialUserValue = {
    contentLanguage: 'English',
    textSize: 'Medium',
    appearance: 'Light',
    preferredNewsLocation: '',
  };

  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [preference, setPreference] = useState(initialUserValue);
  const [showAppearanceContent, setshowAppearanceContent] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const { state: userState, dispatch: themeDispatch } =
    useContext(UserDetailsProvider);
  const userID = userState?.userDetails?.userId;
  const isAuthenticated = userState?.isAuthenticated;
  const preferences = userState?.preferences;
  const newsSessionID = userState?.sessionId;

  const { dispatch: appCtxDispatch } = useContext(AppContext);

  useEffect(() => {
    setUserId(userID);
    fetchPreferences();
  }, []);

  useEffect(() => {
    !showOptions && setShowOptionModal(showOptions);
  }, [showOptions]);

  useEffect(() => {
    if (!isAuthenticated) {
      setPreference({
        ...preference,
        contentLanguage: dtLanguage ? dtLanguage : ENGLISH,
        appearance: dtTheme ? dtTheme : LIGHT,
      });
    }
  }, []);

  const showLangContent = () => {
    setShowOptionModal(true);
    setshowAppearanceContent(false);
    showLang();
  };

  const displayAppearanceContent = () => {
    setShowOptionModal(true);
    showAppearance();
    setshowAppearanceContent(true);
  };

  const showDeleteConfirmModal = () => {
    setshowDeleteConfirm(true);
  };

  const setLight = () => {
    setPreference({ ...preference, appearance: LIGHT });
    localStorage.setItem('theme', LIGHT);
    themeDispatch({ type: 'UPDATE_APP_THEME' });

    if (isAuthenticated) {
      UpdatePreferences({
        ...preference,
        contentLanguage: dtLanguage,
        appearance: LIGHT,
      });
    }
  };

  const setDark = () => {
    setPreference({ ...preference, appearance: DARK });
    localStorage.setItem('theme', DARK);
    themeDispatch({ type: 'UPDATE_APP_THEME' });

    if (isAuthenticated) {
      UpdatePreferences({
        ...preference,
        contentLanguage: dtLanguage,
        appearance: DARK,
      });
    }
  };

  const setEnglish = () => {
    setPreference({ ...preference, contentLanguage: ENGLISH });
    i18n.changeLanguage('en');
    localStorage.setItem('lang', ENGLISH);

    if (isAuthenticated) {
      UpdatePreferences({
        ...preference,
        appearance: dtTheme,
        contentLanguage: ENGLISH,
      });
    }
  };

  const setTamil = () => {
    setPreference({ ...preference, contentLanguage: TAMIL });
    i18n.changeLanguage('tn');
    localStorage.setItem('lang', TAMIL);

    if (isAuthenticated) {
      UpdatePreferences({
        ...preference,
        appearance: dtTheme,
        contentLanguage: TAMIL,
      });
    }
  };

  const UpdatePreferences = async (pref) => {
    try {
      await userProfileService.updateUserProfile({
        userId: userId,
        preferences: JSON.stringify(pref),
      });
      setTimeout(() => {
        fetchPreferences();
      }, 1000);
    } catch (error) {}
  };

  const fetchPreferences = () => {
    setPreference({
      contentLanguage: dtLanguage ? dtLanguage : ENGLISH,
      textSize: preferences?.textSize ? preferences?.textSize : '',
      appearance: dtTheme ? dtTheme : LIGHT,
      preferredNewsLocation: preferences?.preferredNewsLocation
        ? preferences?.preferredNewsLocation
        : '',
    });
  };

  const signOutUser = async () => {
    try {
      await authService.signout();
      appCtxDispatch({ type: 'AUTHENTICATE_FAILED' });
    } catch (error) {}
  };

  /**
   * Deleting the User Account
   */
  const deleteAccount = async () => {
    try {
      setLoading(true);
      const response = await appService.deleteUserAccount({
        Sessionid: `${newsSessionID}`,
      });
      setLoading(false);
      if (response) {
        closeModal();
        signOutUser();
        navigate('/');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='settingContainer'>
        {showOptionModal ? (
          showAppearanceContent ? (
            <>
              <Grid
                container
                alignItems='center'
                spacing={2}
                className='settingsThemePreferences'
                onClick={setLight}
              >
                <Grid item xs={1.3}>
                  <img
                    src={
                      dtTheme !== DARK ? lightModeLightIcon : lightModeDarkIcon
                    }
                    alt='light-mode-icon'
                    className='arrowIcon'
                  />
                </Grid>
                <Grid item xs={8.9}>
                  <Typography variant='body3' className='settingsNameWithIcon'>
                    {t('light')}
                  </Typography>
                </Grid>

                <Grid item xs={1.8}>
                  {dtTheme !== DARK && (
                    <img src={Tick} alt='arrow-right' className='arrowIcon' />
                  )}
                </Grid>
              </Grid>
              <Divider
                variant='fullWidth'
                sx={{ marginTop: '1.25rem', width: '95%' }}
              />
              <Grid
                container
                alignItems='center'
                spacing={2}
                className='settingsThemePreferences'
                onClick={setDark}
              >
                <Grid item xs={1.3}>
                  <img
                    src={
                      dtTheme === DARK ? darkModeDarkIcon : darkModeLightIcon
                    }
                    alt='dark-mode-dark'
                    className='arrowIcon'
                  />
                </Grid>
                <Grid item xs={8.9}>
                  <Typography variant='body3' className='settingsNameWithIcon'>
                    {t('dark')}
                  </Typography>
                </Grid>

                <Grid item xs={1.8}>
                  {dtTheme === DARK && (
                    <img src={Tick} alt='arrow-right' className='arrowIcon' />
                  )}
                </Grid>
              </Grid>
              <Divider
                variant='fullWidth'
                sx={{ marginTop: '1.25rem', width: '95%' }}
              />
            </>
          ) : (
            <>
              <Grid
                container
                alignItems='center'
                spacing={2}
                className='settingsPreferences'
                onClick={setEnglish}
              >
                <Grid item xs={10.3} container direction='column' spacing={0}>
                  <Grid item>
                    <Typography
                      variant='h6'
                      sx={{ fontWeight: 700, fontSize: '1.125rem' }}
                      className='LanguageTitle'
                    >
                      Welcome, Have a nice day{' '}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 400, fontSize: '1.125rem' }}
                      className='settingsName'
                    >
                      English
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={1.7}>
                  {dtLanguage !== TAMIL && (
                    <img src={Tick} alt='arrow-right' className='arrowIcon' />
                  )}
                </Grid>
              </Grid>
              <Divider
                variant='fullWidth'
                sx={{ marginTop: '1.25rem', width: '95%' }}
              />
              <Grid
                container
                alignItems='center'
                spacing={2}
                className='settingsPreferences'
                onClick={setTamil}
              >
                <Grid item xs={10.3} container direction='column' spacing={0}>
                  <Grid item>
                    <Typography
                      variant='h6'
                      sx={{ fontWeight: 700, fontSize: '1.125rem' }}
                      className='LanguageTitle'
                    >
                      வணக்கம், இனிய நாள்
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 400, fontSize: '1.125rem' }}
                      className='settingsName'
                    >
                      Tamil
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1.7}>
                  {dtLanguage === TAMIL && (
                    <img src={Tick} alt='arrow-right' className='arrowIcon' />
                  )}
                </Grid>
              </Grid>
              <Divider
                variant='fullWidth'
                sx={{ marginTop: '1.25rem', width: '95%' }}
              />
            </>
          )
        ) : (
          <>
            {/* <Grid container spacing={2} className='settings'>
            <Grid item xs={10}>
              <p className='settingsName'>{t('videos-only-feed')}</p>
              <p className='settingsNameContent'>
                {t('ACTIVATE_VIDEO_CONTENT')}
              </p>
            </Grid>
            <Grid item xs={2}>
              <img
                src={inactiveSwitch}
                alt='arrow-right'
                className='arrowIcon'
              />
            </Grid>
          </Grid> */}
            <Grid
              container
              alignItems='center'
              spacing={2}
              className='settings'
            >
              <Grid item xs={8}>
                <Typography variant='body1' className='settingsName'>
                  {t('content-language')}
                </Typography>
              </Grid>
              <Grid item xs={2} onClick={showLangContent}>
                <Typography
                  variant='subtitle1'
                  sx={{ fontWeight: 600 }}
                  className='settingsOptions'
                >
                  {dtLanguage ? dtLanguage : preference.contentLanguage}
                </Typography>
              </Grid>
              <Grid item xs={2} onClick={showLangContent}>
                <img
                  src={isDarkMode ? DarkThemeArrow : Arrow}
                  alt='arrow-right'
                  className='arrowIcon'
                />
              </Grid>
            </Grid>
            <Divider
              variant='fullWidth'
              sx={{
                marginTop: '1.25rem',
                width: '95%',
              }}
            />
            <Grid
              container
              alignItems='center'
              spacing={2}
              className='settings'
            >
              <Grid item xs={7}>
                <Typography variant='body1' className='settingsName'>
                  {t('appearance')}
                </Typography>
              </Grid>
              <Grid item xs={1.3}>
                <img
                  src={dtTheme === DARK ? darkModeDarkIcon : lightModeLightIcon}
                  alt='light-mode-light-icon'
                  className='arrowIcon'
                />
              </Grid>

              <Grid item xs={1.7} onClick={displayAppearanceContent}>
                <Typography
                  variant='subtitle1'
                  sx={{ fontWeight: 600 }}
                  className='settingsOptions'
                >
                  {dtTheme ? dtTheme : preference.appearance}
                </Typography>
              </Grid>
              <Grid item xs={2} onClick={displayAppearanceContent}>
                <img
                  src={isDarkMode ? DarkThemeArrow : Arrow}
                  alt='arrow-right'
                  className='arrowIcon'
                />
              </Grid>
            </Grid>
            <Divider
              variant='fullWidth'
              sx={{
                marginTop: '1.25rem',
                width: '95%',
              }}
            />
            {/* TODO: SHOW NOTIFICATIONS SETTINGS */}
            {/* <Grid
              container
              alignItems='center'
              spacing={2}
              className='settings notif-settings'
              onClick={() => {
                closeModal();
                if (userID) {
                  userID && navigate('/notification-settings');
                } else {
                  appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
                }
              }}
            >
              <Grid item xs={10}>
                <Typography variant='body1' className='settingsName'>
                  {t('NOTIFICATION_SETTINGS')}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <img
                  src={isDarkMode ? DarkThemeArrow : Arrow}
                  alt='arrow-right'
                  className='arrowIcon'
                />
              </Grid>
            </Grid>
            <Divider
              variant='fullWidth'
              sx={{
                marginTop: '1.25rem',
                width: '95%',
              }}
            /> */}
            {userID && (
              <Grid
                container
                alignItems='center'
                className='settings notif-settings'
                onClick={() => setShowDeleteModal(true)}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    sx={{ color: (theme) => theme.palette.error.main }}
                    className='settingsName'
                  >
                    {t('delete-account')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
      <InfoModal
        title={t('delete-account')}
        message={
          showDeleteConfirm
            ? t('are-you-sure-you-want-to-continue-to-delete')
            : t('delete-your-account')
        }
        open={showDeleteModal}
        close={() => setShowDeleteModal(false)}
      >
        <OverlayLoader show={loading} />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItem: 'center',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={showDeleteConfirm ? deleteAccount : showDeleteConfirmModal}
            sx={{
              maxWidth: '18.75rem',
              width: '100%',
              textTransform: 'none',
              marginBottom: '1.25rem',
            }}
          >
            {t('yes')}
          </Button>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => {
              closeModal();
              setShowOptionModal(false);
            }}
            sx={{
              marginBottom: '0',
              maxWidth: '18.75rem',
              width: '100%',
              textTransform: 'capitalize',
            }}
          >
            {t('cancel')}
          </Button>
        </Box>
      </InfoModal>
    </>
  );
};

export default SettingPrivacy;
