// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jw-display-icon-rewind {
  display: none;
}

.jw-icon-rewind {
  display: none !important;
}

.jw-icon-next {
  display: none !important;
}
.jw-text-elapsed {
  display: none !important;
}
.jw-text-duration {
  display: none !important;
}
.jw-related-btn {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Reporter/Training/skin.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".jw-display-icon-rewind {\n  display: none;\n}\n\n.jw-icon-rewind {\n  display: none !important;\n}\n\n.jw-icon-next {\n  display: none !important;\n}\n.jw-text-elapsed {\n  display: none !important;\n}\n.jw-text-duration {\n  display: none !important;\n}\n.jw-related-btn {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
