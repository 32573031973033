import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import './Modal.css';
import { InputAdornment } from '@mui/material';

/**
 * DialogBox
 */
function CommentsModal({
  heading,
  content,
  commentText,
  setCommentText,
  largeTextArea,
  smallTextArea,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonAction,
  secondaryButtonAction,
  isOpen,
  onClose,
  replyToUser,
}) {
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.mobile);
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          maxWidth:
            content || smallTextArea
              ? '20rem'
              : isMobile
              ? '100%'
              : '29.188rem',
          width: '100%',
          borderRadius:
            content || smallTextArea ? '8px' : isMobile ? '0' : '8px',
          height:
            content || smallTextArea
              ? '18.625rem'
              : isMobile
              ? '100%'
              : '23.75rem',
          maxHeight:
            content || smallTextArea
              ? '18.625rem'
              : isMobile
              ? '100%'
              : '23.75rem',
          m: largeTextArea && isMobile && '0',
        },
      }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby='comments'
    >
      <DialogContent
        sx={{
          padding: '2rem 1.75rem 1.688rem 1.5rem',
        }}
      >
        {heading && (
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: '700',
              fontSize: '1.125rem',
              marginBottom: '1.063rem',
              textTransform: 'uppercase',
            }}
          >
            {heading}
          </Typography>
        )}
        {(largeTextArea || smallTextArea) && (
          <Box
            sx={{
              mb: '1.25rem',
            }}
          >
            <TextField
              type='text'
              variant='filled'
              fullWidth
              autoComplete='off'
              size='small'
              multiline
              rows={smallTextArea ? 2 : isMobile ? 11 : 6}
              value={commentText}
              onChange={(event) => setCommentText(event.target.value)}
              className='commentField'
              sx={{
                color: (theme) => theme.palette.grey[600],
                borderRadius: '12px 12px 2px 2px',
                background: isDarkMode
                  ? 'rgb(72, 72, 74, 0.5)'
                  : 'rgb(183, 183, 183, 0.5)',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                backdropFilter: 'blur(75px)',
                '& .MuiInputBase-root': {
                  alignItems: 'baseline',
                  height: smallTextArea ? 60 : isMobile ? 278 : 144,
                },
                '& .MuiFilledInput-root': {
                  paddingTop: '0.75rem',
                  borderRadius: '12px 12px 2px 2px',
                },
              }}
              InputProps={
                replyToUser
                  ? {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Typography
                            sx={{ color: '#008EE3' }}
                          >{`@${replyToUser}`}</Typography>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {content && (
            <Typography
              variant='subTitle2'
              sx={{
                textAlign: 'center',
                mb: '2.063rem',
                maxWidth: '17.5rem',
                width: '100%',
              }}
            >
              {content}
            </Typography>
          )}
          {primaryButtonText && (
            <Button
              variant='minminiContained'
              sx={{
                mb: secondaryButtonText ? '1rem' : '0',
              }}
              className='comment-buttons'
              onClick={primaryButtonAction}
            >
              {primaryButtonText}
            </Button>
          )}
          {secondaryButtonText && (
            <Button
              variant='minminiOutlined'
              onClick={secondaryButtonAction}
              className='comment-buttons'
            >
              {secondaryButtonText}
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

CommentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string,
  content: PropTypes.string,
  largeTextArea: PropTypes.bool,
  smallTextArea: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  primaryButtonAction: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
};

export default CommentsModal;
