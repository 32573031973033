export const SIGN_IN_WITH_APPLE = 'SignInWithApple_';
// export const COMMUNITY_URL = '/social';
export const SEARCH_URL = '/search';
export const HOMEPAGE_URL = '/';
export const EMAILREG =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const PHONEREG = /^[6-9]\d{9}$/;
export const NAMEREG = /^($|(?=.{1,30}$)[A-Za-z ]*)$/;
export const DARK = 'Dark';
export const LIGHT = 'Light';
export const ENGLISH = 'English';
export const TAMIL = 'Tamil';
export const FIVE_MINUTES = 300000;
export const TWO = 2;
export const FIVE = 5;
export const ASSESSMENT_URL = '/assessment';
export const TRAINING_URL = '/training';
export const ONE = 1;
export const SUBMIT = 'SUBMIT';
export const ABORT = 'ABORT';
export const PDF_FILE_NAME = 'minmini-certificate.pdf';
export const REPORTER_ID_FILE_NAME = 'minmini-reporter-id.pdf';
export const TOTAL_NUMBER_OF_QUESTIONS = 20;
export const MINIMUM_PASS_MARK = 12;
export const INITIAL_VALUE = 0;
export const LATEST_NEWS = 'Latest News';
export const HEADLINES = 'Headline';
export const TOP_STORIES = 'Top';
export const DISTRICTS = 'Districts';
export const HEADLINE_FALLBACK = 'No headline Available';
export const DESCRIPTION_FALLBACK = 'No Description Available';
export const MORE_LABEL = 'More';
export const EKYC_URL = '/reporter';
export const PROMOTION_LABEL = 'Promotion';
export const NO_BOOKMARK = 'No Bookmarks';
export const NO_NEWSPOSTS = 'No News Posts';
export const VALUEONE = '1';
export const VALUETWO = '2';
export const VALUETHREE = '3';
export const ALL = 'All';
export const NEWS = 'News';
export const PUBLISHER = 'Publisher';
export const PUBLISHER_TYPE = 'PUBLISHER';
export const PUBLISHERS = 'Publishers';
export const VIEW_ALL_PUBLISHERS = 'VIEW ALL PUBLISHERS';
export const VIEW_ALL_REPORTERS = 'VIEW ALL REPORTERS';
export const CITIZEN_REPORTER = 'Citizen Reporter';
export const LOCATION = 'Location';
export const LOCATIONS = 'Locations';
export const REPORTERS = 'Reporters';
export const INFLUENCER = 'INFLUENCER';
export const FOLLOW = 'FOLLOW';
export const FOLLOWING = 'FOLLOWING';
export const VIDEOS_ONLY = 'Videos only';
export const ACTIVATE_VIDEO_CONTENT =
  'activate to view only video content across all categories of news';
export const TRUE = 'true';
export const FALSE = 'false';
export const OF = 'of';
export const RELATED_SEARCHES = 'Related Searches';
export const SEPERATOR = '%2C';
export const MALE = 'Male';
export const FEMALE = 'Female';
export const OTHERS = 'Others';
export const AGE_TILL_TWENTYFIVE = '13-25';
export const AGE_TILL_FORTY = '26-40';
export const AGE_TILL_SIXTY = '41-60';
export const AGE_ABOVE_SIXTY = '60+';
export const CITIZEN_REPORTS = 'Citizen Reports';
export const UGC_REPORTER = 'UGC_REPORTER';
export const CITIZEN_REPORTER_CAPITAL = 'CITIZEN_REPORTER';
export const FOR_YOU = 'For You';
export const TRENDING = 'Trending';
export const SEVEN = 7;
export const SIX = 6;
export const FOR_YOU_ID = 'for_you';
export const TRENDING_ID = 'trending';
export const THREE = 3;
export const TEN = 10;
export const TWENTY = 20;
export const FOUR = 4;
export const HOME = 'Home';
export const TIME_UNITS =
  /onds|ond|utes|ute|ours|our|ays|ay|onths|onth|ears|ear/gi;
export const UTC_UNITS = /(T|Z)/g;
export const AADHAR_LIMIT = 12;
export const NOTIFICATIONS_URL = '/notifications';
export const COMMUNITY = 'Community';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const LOCALHOST_URL = 'http://localhost:3000/';
export const NO_LOCATION = 'No Location';
export const NO_JOINING_DATE = 'No Joining Date';
export const NO_TITLE = '[No Title]';
export const PUBLISH_NOW = 'Publish Now';
export const PUBLISH_LATER = 'Publish Later';
export const DRAFTS = 'Drafts';
export const NOTIFICATION_SETTINGS_URL = '/notification-settings';
export const STORY_FALLBACK = 'No Story';
export const DRAFTS_TIME_FORMAT = 'MMM dd, paa';
export const DOT = ' • ';
export const NO_DATE = 'No Date';
export const THIRTY = 30;
export const MEDIA = 'Media';
export const MEDIA_INFO = 'Media can be added, removed and edited from';
export const MOBILE_APP = 'Mobile App';
export const ONLY = 'Only';
export const REMOVE_BOOKMARK = 'Remove Bookmark';
export const BOOKMARK = 'Bookmark';
export const UNFOLLOW_REPORTER = 'Unfollow Reporter';
export const FOLLOW_REPORTER = 'Follow Reporter';
export const FLAG = 'Flag';
export const REPORT = 'Report';
export const FOLLOW_PUBLISHER = 'Follow Publisher';
export const UNFOLLOW_PUBLISHER = 'Unfollow Publisher';
export const COUNT = 'count';
export const PATH_TOP = '/top';
export const PATH_NEWS_POSTS = '/news-posts';
export const PATH_TERMS_OF_SERVICE = '/terms-ofservice';
export const ENTER = 'Enter';

export const LIKE = 'like';
export const DISLIKE = 'dislike';
export const NONE = 'none';
export const LIKE_NONE = 'like_none';
export const DISLIKE_NONE = 'dislike_none';
export const CITIZEN_FEED = 'CITIZEN_FEED';
export const DATE_FORMAT = 'dd-MM-yyyy';
export const DRIVING_LICENSE = '326';
export const AADHAR = '347';
export const PASSPORT = '306';
export const DOCUMENT_ID = '1qw2';
export const AUTHOR_NEWS = 'author-news';
export const LOGOUT = 'logout';
export const REDIRECT_URL = 'redirect_url';
export const PENDING = 'PENDING';
export const SUBMITTED = 'SUBMITTED';
export const PROCESSING = 'PROCESSING';
export const AGENCY = 'AGENCY';
export const ERROR_MSG = 'Something went wrong, Try again Later';
export const NEWS_DETAIL = 'news-details';
export const USER = 'USER';

export const ZERO = 0;
export const CHARACTER_LIMIT = 150;

//Training video related constant
export const TRAINING_VIDEO_STATUS = {
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
};
export const TRAINING_LANGUAGE = {
  English: 'en',
  Tamil: 'ta',
};
