import { useState, useEffect, useContext } from 'react';
import SearchNews from './SearchNews';
import searchService from '../../services/search.service';
import { Box, Button } from '@mui/material';
import {
  DistrictsListSkeleton,
  MobileListsSkeleton,
} from '../Skeleton/PublisherSkeleton';
import { CardSkeleton } from '../Skeleton/PublisherSkeleton';
import { useTranslation } from 'react-i18next';
import { UserDetailsProvider } from '../../store/user-details-context';
import './Search.css';
import { viewPort, MaxWidth } from '../../responsive/viewport.util';
import SearchUsers from './SearchUsers';

const SearchResult = ({
  newsType,
  totalCount,
  searchValue,
  isLoading,
  setIsLoading,
  startIndex,
  setStartIndex,
}) => {
  const count = 20;
  const { state: userState } = useContext(UserDetailsProvider);
  const newsSessionID = userState?.sessionId;
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [isPageChange, setPageChange] = useState(false);
  const [result, setResult] = useState([]);
  const isMobile = MaxWidth(viewPort.tablet);

  const { t } = useTranslation();

  /**
   * side effect to make the results array empty on button change
   */
  useEffect(() => {
    setResult([]);
    setIsLoading(true);
  }, [newsType]);

  /**
   * side effect to call fetch recent search api when there is a change in input search or active button
   */
  useEffect(() => {
    if (newsType === 'POSTS' && searchValue) {
      let newsParams = {
        search: searchValue,
        startIndex,
        count,
        newsType: 'NEWS',
      };
      if (newsSessionID) {
        newsParams['sessionId'] = newsSessionID;
      }
      searchService
        .fetchSearchResultList(newsParams)
        .then((response) => {
          if (newsType) {
            if (isPageChange) {
              setResult((result) => [...result, ...(response['news'] || [])]);
              setPageChange(false);
            } else {
              setResult(response['news'] || []);
              setPageChange(false);
            }
            response['news'].length + startIndex < totalCount
              ? setShowLoadMore(true)
              : setShowLoadMore(false);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [newsType, searchValue, startIndex, totalCount]);

  useEffect(() => {
    if (newsType === 'USERS') {
      const payload = {
        username: searchValue,
      };
      searchService
        .searchUsername(payload)
        .then((response) => {
          if (response?.authors?.length) {
            setResult(response?.authors);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [searchValue, newsType]);

  /*
   * Will update the startIndex value on click of loadmore
   */
  const handleNewsLoadMore = () => {
    setStartIndex((prevIndex) => prevIndex + count);
    setPageChange(true);
  };

  return (
    <>
      <div className='searchButtonsList'>
        <div className='searchMenuResultContainer'>
          {newsType === 'USERS' && (
            <Box sx={{ width: '100%' }}>
              {isLoading ? (
                isMobile ? (
                  <MobileListsSkeleton cards={4} />
                ) : (
                  <DistrictsListSkeleton cards={4} />
                )
              ) : (
                !isLoading && <SearchUsers usersResult={result} />
              )}
            </Box>
          )}
          {newsType === 'POSTS' && (
            <Box sx={{ width: '100%' }}>
              {isLoading ? (
                <CardSkeleton cards={1} />
              ) : (
                !isLoading && <SearchNews newsresult={result} />
              )}
            </Box>
          )}
          {newsType === 'POSTS' && showLoadMore && !isLoading && (
            <Button
              onClick={handleNewsLoadMore}
              color='primary'
              variant='contained'
              sx={{
                borderRadius: '0.625rem',
                padding: '0.75rem 1.5rem',
                fontWeight: '600',
                fontSize: isMobile ? '0.75rem' : '1rem',
                lineHeight: '1.25rem',
                maxWidth: isMobile ? '7.75rem' : '9.75rem',
                height: isMobile ? '2rem' : '3rem',
                width: '100%',
                textAlign: 'center',
                alignSelf: 'center',
              }}
            >
              {t('LOAD_MORE')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchResult;
