import { SvgIcon } from '@mui/material';

const SearchIconMobile = ({ styleProps, stroke }) => {
  return (
    <SvgIcon
      width='30'
      height='29'
      viewBox='0 0 30 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      sx={{ ...styleProps, fill: 'none' }}
    >
      <path
        d='M23 23L18.3807 18.3807M18.3807 18.3807C19.5871 17.1743 20.3333 15.5076 20.3333 13.6667C20.3333 9.98477 17.3486 7 13.6667 7C9.98477 7 7 9.98477 7 13.6667C7 17.3486 9.98477 20.3333 13.6667 20.3333C15.5076 20.3333 17.1743 19.5871 18.3807 18.3807Z'
        stroke={stroke}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIcon>
  );
};
export default SearchIconMobile;
