import React, { useState, useContext } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import YoutubeLogo from '../../assets/icons/youtube-logo.svg';
import FacebookLogo from '../../assets/icons/facebook-logo.svg';
import TwitterLogo from '../../assets/icons/twitter-logo.svg';
import InstagramLogo from '../../assets/icons/instagram-logo.svg';
import AppleLogo from '../../assets/icons/apple-logo.svg';
import AppleLogoDark from '../../assets/icons/AppleWhite-logo.svg';
import PalyStoreLogo from '../../assets/icons/playstore-logo.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ContactUsModal from '../ContactUsModal/ContactUsModal';
import { EnvironmentProvider } from '../../store/firebase-context';
import { MINMINI_V3 } from '../../themes/color.constant';

const Footer = () => {
  const history = useLocation();
  const validPathsMobile = ['/training', '/assessment'];

  const mobileView = !useMediaQuery('(min-width:600px)');

  const isPresent = validPathsMobile.some((path) =>
    history.pathname.includes(path),
  );

  const { t } = useTranslation();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const platform = getMobileOperatingSystem();
  const { environment } = useContext(EnvironmentProvider);

  /**
   * close ContactUs Modal
   */
  const closeModal = () => {
    setShowContactUsModal(false);
  };

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', or 'Web'.
   *
   * @returns {String}
   */
  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/Android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'Web';
  }

  return (
    <>
      {!isPresent && (
        <>
          {!mobileView && isDarkMode && <Divider variant='fullWidth' />}
          <Box
            sx={{
              display: 'flex',
              flexDirection: mobileView ? 'column' : 'row',
              justifyContent: 'center',
              width: '100%',
              height: !mobileView && '4rem',
              padding: !mobileView && '1.25rem 6.25vw',
              background: isDarkMode
                ? MINMINI_V3.grey.darkGrey
                : MINMINI_V3.grey.lightGrey,
            }}
          >
            {mobileView ? (
              <>
                <Box
                  sx={{
                    height: '6.125rem',
                    padding: '1.25rem',
                    textAlign: 'center',
                    paddingBottom: '0',
                    background: isDarkMode
                      ? MINMINI_V3.grey.darkGrey
                      : MINMINI_V3.primary.offWhite,
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }} variant='body1'>
                    {t('Get-the-app')}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {(platform === 'iOS' || platform === 'Web') && (
                      <Box
                        marginRight={2}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open(
                            environment.mobile_app_store_link_ios,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                      >
                        <img
                          src={isDarkMode ? AppleLogoDark : AppleLogo}
                          alt='apple-logo'
                        />
                      </Box>
                    )}
                    {(platform === 'Android' || platform === 'Web') && (
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open(
                            environment.mobile_app_store_link_android,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                      >
                        <img src={PalyStoreLogo} alt='playstore-logo' />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: '8.63rem',
                    textAlign: 'center',
                    padding: '1.25rem',
                    background: isDarkMode
                      ? MINMINI_V3.grey.bgDark
                      : MINMINI_V3.grey.lightGrey,
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }} variant='body1'>
                    {t('follow-us')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Box marginRight={2} sx={{ cursor: 'pointer' }}>
                      <img src={FacebookLogo} alt='facebook-logo' />
                    </Box>
                    <Box marginRight={2} sx={{ cursor: 'pointer' }}>
                      <img src={TwitterLogo} alt='twitter-logo' />
                    </Box>
                    <Box marginRight={2} sx={{ cursor: 'pointer' }}>
                      <img src={InstagramLogo} alt='instagram-logo' />
                    </Box>
                    <Box sx={{ cursor: 'pointer' }}>
                      <img src={YoutubeLogo} alt='youtube-logo' />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '1.5rem',
                      paddingBottom: '1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box marginRight={2}>
                      <Typography
                        onClick={() => {
                          window.open(
                            '/privacy-policy',
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                        sx={{ cursor: 'pointer' }}
                        variant='body1'
                      >
                        {t('privacy-policy')}
                      </Typography>
                    </Box>
                    <Divider
                      orientation='vertical'
                      variant='fullWidth'
                      sx={{
                        height: '1.5rem',
                        borderColor: (t) =>
                          isDarkMode
                            ? t.palette.grey[400]
                            : t.palette.grey[200],
                      }}
                    ></Divider>
                    <Box marginLeft={2}>
                      <Typography
                        onClick={() => {
                          setShowContactUsModal(true);
                        }}
                        sx={{ cursor: 'pointer' }}
                        variant='body1'
                      >
                        {t('contact-us')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    maxWidth: '78.75rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box marginRight={2}>
                      <Typography
                        onClick={() => {
                          window.open(
                            '/privacy-policy',
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                        sx={{ cursor: 'pointer' }}
                        variant='body1'
                      >
                        {t('privacy-policy')}
                      </Typography>
                    </Box>
                    <Divider orientation='vertical'></Divider>
                    <Box marginLeft={2} marginRight={2}>
                      <Typography
                        onClick={() => {
                          setShowContactUsModal(true);
                        }}
                        sx={{ cursor: 'pointer' }}
                        variant='body1'
                      >
                        {t('contact-us')}
                      </Typography>
                    </Box>
                    <Divider orientation='vertical'></Divider>

                    <Box marginLeft={2} marginRight={2}>
                      <Typography variant='body1'>{t('follow-us')}</Typography>
                    </Box>
                    <Box
                      marginRight={2}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://www.facebook.com/profile.php?id=100095339806102',
                          '_blank',
                          'noopener,noreferrer',
                        );
                      }}
                    >
                      <img src={FacebookLogo} alt='facebook-logo' />
                    </Box>
                    <Box
                      marginRight={2}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://twitter.com/minminiapp',
                          '_blank',
                          'noopener,noreferrer',
                        );
                      }}
                    >
                      <img src={TwitterLogo} alt='twitter-logo' />
                    </Box>
                    <Box
                      marginRight={2}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://www.instagram.com/minminiapp/',
                          '_blank',
                          'noopener,noreferrer',
                        );
                      }}
                    >
                      <img src={InstagramLogo} alt='instagram-logo' />
                    </Box>
                    <Box
                      marginRight={2}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://www.youtube.com/@minminiapp',
                          '_blank',
                          'noopener,noreferrer',
                        );
                      }}
                    >
                      <img src={YoutubeLogo} alt='youtube-logo' />
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box marginRight={2}>
                      <Typography sx={{ fontWeight: 600 }} variant='body1'>
                        {t('Get-the-app')}
                      </Typography>
                    </Box>
                    {(platform === 'iOS' || platform === 'Web') && (
                      <Box
                        marginRight={2}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open(
                            environment.mobile_app_store_link_ios,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                      >
                        <img
                          src={isDarkMode ? AppleLogoDark : AppleLogo}
                          alt='apple-logo'
                        />
                      </Box>
                    )}
                    {(platform === 'Android' || platform === 'Web') && (
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open(
                            environment.mobile_app_store_link_android,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                      >
                        <img src={PalyStoreLogo} alt='playstore-logo' />
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </>
      )}

      <ContactUsModal
        showContactUsModal={showContactUsModal}
        closeModal={closeModal}
      />
    </>
  );
};

export default Footer;
