import { useState, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DARK_COLOR, LIGHT_COLOR } from '../../../themes/color.constant';
import SuccessBadge from '../../../assets/icons/Badge-Success.svg';
import BackIcon from '../../../assets/icons/inactive-previous-arrow.svg';
import '../Assessment.css';
import { MaxWidth, viewPort } from '../../../responsive/viewport.util';
import ButtonLoader from '../../buttonLoader/ButtonLoader';
import { UserDetailsProvider } from '../../../store/user-details-context';
import assessmentService from '../../../services/assessment.service';
import { saveAs } from 'file-saver';
import {
  PDF_FILE_NAME,
  REPORTER_ID_FILE_NAME,
} from '../../../Constants/Constants';

function Congratulations() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isMobile = MaxWidth(viewPort.tablet);
  const { state: userState } = useContext(UserDetailsProvider);

  const [generateReporterIDLoader, setGenerateReporterIDLoader] =
    useState(false);
  const [geneatePdfLoader, setGeneratePdfLoader] = useState(false);
  const userId =
    userState?.userDetails?.userId || localStorage.getItem('userID');

  /**
   * Function to Download Certificate
   */
  const generatePDF = async () => {
    setGeneratePdfLoader(true);
    try {
      const response = await assessmentService.downloadCertificate({
        userId: userId,
      });
      setGeneratePdfLoader(false);
      response && saveAs(response, PDF_FILE_NAME);
    } catch (error) {
      setGeneratePdfLoader(false);
    }
  };

  /**
   * Function to Download reporter ID
   */
  const generateReporterID = async () => {
    setGenerateReporterIDLoader(true);
    try {
      const response = await assessmentService.downloadReporterID();
      setGenerateReporterIDLoader(false);
      if (response) {
        saveAs(response, REPORTER_ID_FILE_NAME);
      }
    } catch (error) {
      setGenerateReporterIDLoader(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <Box>
          <div className='mobileAssessmentTitle'>
            <span>
              <img
                src={BackIcon}
                className='mobileAssessmentTitleIcon'
                alt='back-icon'
                onClick={() => navigate('/')}
              />
            </span>
            <Box className='mobileAssessmentHeading'>
              <Typography sx={{ fontWeight: '600' }}>
                {t('become-a-reporter')}
              </Typography>
            </Box>
          </div>
          <hr className='mobileAssessmentHeadingLine' />
          <Box className='mobileBadgeScreen' sx={{ margin: '1rem' }}>
            <Typography
              variant='h5'
              sx={{
                color: (theme) => theme.palette.white,
                marginBottom: '1.25rem',
              }}
            >
              {t('Congratulations')}
            </Typography>
            <img
              src={SuccessBadge}
              alt='SuccessBadge'
              className='mobileSuccessBadge'
            />
            <Typography
              sx={{
                color: isDarkMode
                  ? DARK_COLOR.grey[600]
                  : LIGHT_COLOR.grey[400],
                marginBottom: '1rem',
              }}
              className='badgeScreenContent'
            >
              {t('you-have-successfully-registered')}
            </Typography>

            <Grid container display={'flex'} justifyContent='center'>
              <ButtonLoader
                variant='contained'
                color='primary'
                onClick={generatePDF}
                loading={geneatePdfLoader}
              >
                {t('DOWNLOAD_CERTIFICATE')}
              </ButtonLoader>
              <ButtonLoader
                variant='contained'
                color='primary'
                onClick={generateReporterID}
                loading={generateReporterIDLoader}
              >
                {t('DOWNLOAD_REPORTER_ID')}
              </ButtonLoader>
              <Button
                variant='contained'
                color='primary'
                onClick={() => window.location.replace('/')}
              >
                {t('done')}
              </Button>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box sx={{ padding: '1.25rem 4rem 4rem 4rem' }}>
          <Box sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
            <Box className='assesmentTitleSection'>
              <Box className={'assesmentTitleMd'} sx={{ marginTop: 0 }}>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant='h5'>{t('become-a-reporter')}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              className='badgeScreen'
              sx={{
                background: isDarkMode
                  ? DARK_COLOR.grey[200]
                  : LIGHT_COLOR.white[100],
              }}
            >
              <>
                <Typography
                  variant='h5'
                  sx={{
                    color: (theme) => theme.palette.success.main,
                    marginBottom: '1.25rem',
                  }}
                >
                  {t('CONGRATULATIONS')}
                </Typography>

                <img
                  src={SuccessBadge}
                  alt='SuccessBadge'
                  className='successBadge'
                />
                <Typography
                  sx={{
                    color: isDarkMode
                      ? DARK_COLOR.grey[600]
                      : LIGHT_COLOR.grey[400],
                    marginBottom: '1rem',
                  }}
                  className='badgeScreenContent'
                >
                  {t('you-have-successfully-registered')}
                </Typography>

                <Grid container display={'flex'} justifyContent='center'>
                  <ButtonLoader
                    variant='contained'
                    color='primary'
                    onClick={generatePDF}
                    loading={geneatePdfLoader}
                  >
                    {t('DOWNLOAD_CERTIFICATE')}
                  </ButtonLoader>
                  <ButtonLoader
                    variant='contained'
                    color='primary'
                    onClick={generateReporterID}
                    loading={generateReporterIDLoader}
                  >
                    {t('DOWNLOAD_REPORTER_ID')}
                  </ButtonLoader>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => window.location.replace('/')}
                  >
                    {t('done')}
                  </Button>
                </Grid>
              </>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Congratulations;
